import React, { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useHandleMenu } from '../../hooks/menu/useHandleMenu';
import PageDialogBesichtigungen from '../Dialogs/PageDialogBesichtigungen';
import PageDialogWeitere from '../Dialogs/PageDialogWeitere';
import MenuCount from './MenuCount';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMobileMenu } from '../../reducers/app';
import { useHandleUserRealtyActive } from '../../hooks/user/useHandleUserRealtyActive';
import { setUserRealtyActive } from '../../reducers/user';

function Submenu({ submenu }) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
	const navigate = useNavigate();

  const app = useSelector((state) => state.app);

  const { getLinkCssClass, updateMenu, determineVerkaufMenuId } = useHandleMenu();
  const { getBesichtigungenUnreadCount, getWeitereUnreadCount } = useHandleUserRealtyActive();

  const [openBesichtigungen, setOpenBesichtigungen] = useState(false);
  const [openWeitere, setOpenWeitere] = useState(false);

  const changeRealtyActive = (sublink) => {
		const sublinkParts = sublink.split('/');
		const currentImmo = user.userRealties.find((immo) => immo.catalogue_number.replace('/', '-') === sublinkParts[3]);

		if(!app.demoMode) {
			dispatch(setUserRealtyActive(currentImmo));
		
			const newMenuId = determineVerkaufMenuId(null, currentImmo);
			updateMenu(newMenuId);
		}

		if (currentImmo?.url) {
			navigate(app.root + '/immobilie/' + currentImmo.url);
		}
  };

	const closePageDialog = useCallback(() => {
		setOpenBesichtigungen(false);
		setOpenWeitere(false);

		if(app.demoMode) return;

		// update unread counts on notifications
		const lsArray = localStorage.getItem('activityIdsRead') ? localStorage.getItem('activityIdsRead').split(';') : [];
		if (lsArray.length > 0) {
			getBesichtigungenUnreadCount(lsArray);
			getWeitereUnreadCount(lsArray);
		}
	}, [getBesichtigungenUnreadCount, getWeitereUnreadCount]);

  const openPageDialog = (linkname) => {
		switch (linkname) {
			case 'Besichtigungen':
				setOpenBesichtigungen(true);
				break;
			case 'Weitere Aktivitäten':
				setOpenWeitere(true);
				break;
			default:
				break;
		}

		if (app.mobileMenuOpen) {
			dispatch(toggleMobileMenu());
		}
  };

  return (
    <>
      <ul className="header__nav__sub">
        {submenu.map((sub) => {
          let openAsPageDialog = false;
					if (sub.name === 'Besichtigungen' || sub.name === 'Weitere Aktivitäten') {
						openAsPageDialog = true;
					}
				
          const key = sub.name.toLowerCase().replace(/[^A-Z0-9]/gi, '');
          return (
            <li key={key} className={getLinkCssClass(sub)} data-id={`linkitem--${key}`} onClick={sub.link.includes('/immobilie/') ? () => changeRealtyActive(sub.link) : () => {}}>
              {sub.link && !openAsPageDialog ? (
                <Link to={sub.link} title={sub.name} className="header__nav__link">
                  <span className="header__nav__span">
                    {sub.name}
                    <MenuCount li={sub} />
                  </span>
                </Link>
              ) : (
                <span className="header__nav__span" onClick={() => openPageDialog(sub.name)}>
                  {sub.name}
                  <MenuCount li={sub} />
                </span>
              )}
            </li>
          );
        })}
      </ul>

      <PageDialogBesichtigungen open={openBesichtigungen} handleClose={() => closePageDialog()} />
      <PageDialogWeitere open={openWeitere} handleClose={() => closePageDialog()} />
    </>
  );
}

export default Submenu;
