import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import parseCurrency from '../../util/currencyParser';
import ButtonForwardAction from '../Buttons/ButtonForwardAction';
import FormInput from '../FormFields/FormInput';
import FormSelect from '../FormFields/FormSelect';
import LoadingSpinner from '../LoadingSpinner';

import { FINANZRECHNER_SITEINDEX_FORM, FINANZRECHNER_SITEINDEX_RESULTS } from '../../constants';
import { parseEBSPK_Institute } from '../../util/csvParser';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import { calculateDarlehen } from '../../api/PriceHubble';
import '../../styles/finanzrechner.scss';
import { setFormIsLoading } from '../../reducers/app';
import { fetchErrorText } from '../FormFields/util/formTexts';

function FinanzierungsRechnerForm() {
	const methods = useForm(); // Provides form state & methods
	const { control, watch, handleSubmit, setValue, setError, resetField, clearErrors, formState: { errors } } = methods;
	const state = watch("state");
	const previousStateRef = useRef(state);
	const district = watch("district");
	const loanValue = watch("loanValue");
	const loanTime = watch("loanTime");

	const [step, setStep] = useState(FINANZRECHNER_SITEINDEX_FORM);
	const [formError, setFormError] = useState('');
	const [calculatedRate, setCalculatedRate] = useState(0);
	const [resultingRate, setResultingRate] = useState(0);
	const [EBSPK_Institute_Data, setEBSPK_Institute_Data] = useState(null);


	const dispatch = useDispatch();

	const onSubmit = async (data) => {
		dispatch(setFormIsLoading(true));
		try {
			const calculatedDarlehen = await calculateDarlehen(data.loanValue, data.loanTime);
			dispatch(setFormIsLoading(false));

			const parsed = JSON.parse(calculatedDarlehen);
			setCalculatedRate(parsed.Envelope.Body.CalcDarlehenResponse.CalcDarlehenResult.Rate);
			setResultingRate(parsed.Envelope.Body.CalcDarlehenResponse.CalcDarlehenResult.Rate);
			
			setStep(FINANZRECHNER_SITEINDEX_RESULTS);
		} catch (e) {
			console.error('e', e);
			setFormError(fetchErrorText);
			dispatch(setFormIsLoading(false));
		}
	};

	useEffect(() => {
		// hard check if state has changed!!
    if (previousStateRef?.current !== state) {
			resetField("district");
  		setValue("district", "");
    }
  }, [state, resetField, setValue]);

	const loanValueDefault = 250000;
	const [loanValueEur, setLoanValueEur] = useState(loanValueDefault);
	const validateLoanValue = useCallback(() => {
		if(!loanValue) {
			return 'Bitte geben Sie die gewünschte Darlehenssumme an.'; 
		}
		else if(loanValue < 5000) {
			return "Der Mindestbetrag beträgt 5.000 EUR.";
		}
		else if(loanValue > 980000) {
			return "Der Höchstbetrag beträgt 980.000 EUR.";
		}

		return true;
	}, [loanValue]);

	useEffect(() => {
		if(loanValue) {
			clearErrors('loanValue');
			let numericValue = parseFloat(loanValue.toString().replace(/\./g, '').replace(',', '.'));
			
			// validate
			const errMsg = validateLoanValue(numericValue);
			if(typeof errMsg === 'string') {
				setError('loanValue', { type: "custom", message: errMsg });
			}
			
			if(isNaN(numericValue)) {
				setLoanValueEur('');
				setValue('loanValue', 5000);
			}
			else {
				const formatted = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 0 }).format(numericValue);
				setLoanValueEur(formatted);
				setValue('loanValue', numericValue);
			}
		}
	}, [loanValue, setValue, setError, validateLoanValue, clearErrors]);

	const loanTimeDefault = 25;
	const [loanTimeInput, setLoanTimeInput] = useState(loanTimeDefault);
	const validateLoanTime = useCallback(() => {
		if(!loanTime) {
			return 'Bitte geben Sie die Laufzeit an.'; 
		}
		else if(loanTime < 10) {
			return "Die Laufzeit muss mind. 10 Jahre betragen.";
		}
		else if(loanTime > 35) {
			return "Die Laufzeit darf max. 35 Jahre betragen.";
		}

		return true;
	}, [loanTime]);

	useEffect(() => {
		if(loanTime) {
			clearErrors('loanTime');
			let numericValue = parseFloat(loanTime.toString().replace(/\./g, '').replace(',', '.'));

			// validate
			const errMsg = validateLoanTime(numericValue);
			if(typeof errMsg === 'string') {
				setError('loanTime', { type: "custom", message: errMsg });
			}

			if(isNaN(numericValue)) {
				setLoanTimeInput(0);
				setValue('loanTime', 25);
			}
			else {
				setLoanTimeInput(numericValue);
				setValue('loanTime', numericValue);
			}
		}
	}, [loanTime, setError, setValue, validateLoanTime, clearErrors])

  const { isMobile } = useHandleScreenResize();

	/* set data on pageload */
  useEffect(() => {
    const setData = async () => {
      setEBSPK_Institute_Data(await parseEBSPK_Institute(true));
    };
    setData();
  }, []);

  return (
    <FormProvider {...methods}>
      <div className="finanzrechner-form">
        {step === FINANZRECHNER_SITEINDEX_FORM ? (
					<>
						<h3>Berechnen Sie Ihre Finanzierung</h3>
            <div className="finanzrechner-form__content">
              {EBSPK_Institute_Data && (
                <div className="form__row form__row--double form__row--double--mobilefull">
									<Controller
										name="state"
										control={control}
										rules={{ required: "Bitte wählen Sie ein Bundesland aus." }}
										render={({ field }) => (
											<FormSelect
												id="state"
												label={'Bundesland'}
												value={field.value}
												callback={field.onChange}
												error={errors.state?.message}
												options={Object.entries(EBSPK_Institute_Data).map(([key, value], index) => {
													return {
														id: index,
														text: key,
														content: value,
													};
												})}
											/>
										)}
									/>

									<Controller
										name="district"
										control={control}
										rules={{ required: "Bitte wählen Sie ein Institut aus." }}
										render={({ field }) => (
											<FormSelect
												id="district"
												label={'Institut'}
												value={field.value}
												callback={field.onChange}
												hasLockIcon={!state}
												disabled={!state}
												placeholder={!state ? 'Bitte erst Bundesland auswählen' : 'Bitte wählen'}
												error={errors.district?.message}
												options={
													state?.content
														? state.content.map((item, index) => {
																return {
																	id: index,
																	text: item.institute,
																	short: item.short,
																	url1: item.url1 ? item.url1 : '',
																	url2: item.url2 ? item.url2 : '',
																};
															})
														: []
												}
											/>
										)}
									/>
                </div>
              )}

							<Controller
                name="loanValue"
                control={control}
								defaultValue={loanValueDefault}
								rules={{
									required: "Bitte geben Sie die gewünschte Darlehenssumme an.",
									validate: (value) => validateLoanValue(value),
								 }}
                render={({ field }) => (
									<FormInput
										id="loanValue"
										label={'Finanzierungsbetrag'}
										type='text'
										hasSlider={true}
										minValue={5000}
										maxValue={980000}
										stepValue={1000}
										error={errors.loanValue?.message}
										sliderLabels={['5.000 EUR', '980.000 EUR']}
										sliderValue={field.value}
										value={loanValueEur}
										showFurtherInfoText={'currency'}
										cssClasses='mt-150rem'
										callback={field.onChange}
									/>
								)}
							/>

							<Controller
                name="loanTime"
                control={control}
								defaultValue={loanTimeDefault}
								rules={{ 
									required: "Bitte geben Sie die Laufzeit an.",
									validate: (value) => validateLoanTime(value)
								 }}
								render={({ field }) => (
									<FormInput
										id="loanTime"
										label={'Laufzeit'}
										hasSlider={true}
										minValue={10}
										maxValue={35}
										sliderLabels={['10 Jahre', '35 Jahre']}
										hasMarks={true}
										stepValue={5}
										error={errors.loanTime?.message}
										showFurtherInfoText={'years'}
										value={loanTimeInput}
										sliderValue={field.value}
										type={'number'}
										callback={field.onChange}
									/>
								)}
							/>
	
              <LoadingSpinner textColor="#1e3163" iconColor="#1e3163" />

              <div className="button-panel button-panel-mobilefullwidth">
                <ButtonForwardAction
                  buttonText="Finanzierung berechnen"
                  forwardAction={handleSubmit(onSubmit)}
                  buttonStyle={!isMobile ? 'blue' : 'orange'}
                />
              </div>
            </div>

            {formError && <p className="form__fielderror">{formError}</p>}
          </>
        ) : step === FINANZRECHNER_SITEINDEX_RESULTS ? (
          <>
						<h3>Ihre Berechnungsbasis</h3>
						<span className="textlink mobile-hidden" onClick={() => setStep(FINANZRECHNER_SITEINDEX_FORM)}>Angaben bearbeiten</span>

            <div className="finanzrechner-form__content">
              <div className="finanzrechner-form__content__results">
							<div className="mobile-hidden finanzrechner__result-wrapper w-60">
									<span>Infos zur Finanzierung</span>
									<h2 className="mb-5 font-140">Summe: {parseCurrency(parseFloat(loanValue))}</h2>
									<h2 className="mb-5 font-140">Laufzeit: {loanTime} Jahre</h2>
									<h2 className="mb-5 font-140">
										Institut: {state?.text + ', ' || ''}
										{district?.text || ''}
									</h2>
								</div>

                <div className="finanzrechner__result-wrapper">
                  <span>Monatliche Rate</span>
                  <span className='no-break'>{resultingRate ? parseCurrency(resultingRate) : parseCurrency(calculatedRate)}</span>
                </div>

								<div className="desktop-hidden finanzrechner__result-wrapper">
									<span>Infos zur Finanzierung</span>
									<span>
										{parseCurrency(parseFloat(loanValue))}, {loanTime} Jahre,{' '}
										{state?.text || ''}
										{district?.text || ''}
									</span>
								</div>
              </div>
            </div>

						<span className="textlink desktop-hidden" onClick={() => setStep(FINANZRECHNER_SITEINDEX_FORM)}>Angaben bearbeiten</span>

            <p>
              Die Finanzierungsexpert:innen der Erste Bank und Sparkassen beraten Sie gern unverbindlich zu einer Finanzierung. Oder Sie holen sich eine Online-Finanzierungszusage.
            </p>
						
            <div className="button-panel button-panel-mobilefullwidth">
              {district?.url1 && district?.url1 !== '' && (
                <a href={
									district?.short === 'erstebank'
										? district.url1 + `?vorhaben=kaufen&kosten=` + loanValue
										: district.url1
									}
									className="button button--orange" target="_blank" rel="noopener noreferrer"
								>
                  {/*Wird zu einem späteren Zeitpunkt immer 'Beratung und weiterrechnen' sein, soll für jetzt aber nur bei Erste Bank so heißen*/}
                  {district?.short === 'erstebank' ? 'Beratung und weiterrechnen' : 'Beratungstermin anfragen'}
                </a>
              )}
              {district?.url2 && district?.url2 !== '' && (
                <a href={district.url2} className="button button--blue" target="_blank" rel="noopener noreferrer">
                  Finanzierungszusage holen
                </a>
              )}
            </div>
          </>
        ) : null}
      </div>
    </FormProvider>
  );
}

export default FinanzierungsRechnerForm;
