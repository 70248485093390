import { useDispatch } from 'react-redux';

import { setSiteIndexRegisterForm } from '../../../reducers/registerForm';

import { REGFORM_SITEINDEX_EMAIL_BESTAETIGEN } from '../../../constants';

//ICONS
import IconLinkAbgelaufen from '../../Icons/IconLinkAbgelaufen';

function RegisterFormLinkAbgelaufen() {
  const dispatch = useDispatch();

  const handleResendConfirmationLink = () => {
    dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_EMAIL_BESTAETIGEN));
  };
  
  return (
    <>
      <div className="d-flex w-100 justify-content-center pt-40 pb-40">
        <IconLinkAbgelaufen color="#1E3163" cssClasses="mr-250rem" />
      </div>
      <h2 className="pt-200rem">Link abgelaufen</h2>
      <p className="pt-200rem">
        Der von Ihnen verwendete Link ist leider nicht mehr gültig, wurde bereits verwendet oder ist
        abgelaufen. Bitte fordern Sie einen neuen Link an, um die Registrierung abzuschließen.
      </p>

      <span className="textlink d-flex w-100 mt-200rem" onClick={() => handleResendConfirmationLink()}>
        Bestätigungslink nochmal anfordern
      </span>
    </>
  );
}

export default RegisterFormLinkAbgelaufen;
