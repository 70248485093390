import React, { useState, useEffect } from 'react';
import DoughnutChart from '../Charts/DoughnutChart';

import '../../styles/kennzahlen.scss';
import { useSelector } from 'react-redux';

const labelText = (label) => {
  switch (label) {
    case '0_17':
      return '0–17 Jahre';
    case '18_29':
      return '18–29 Jahre';
    case '30_49':
      return '30–49 Jahre';
    case '50_64':
      return '50–64 Jahre';
    case '65_plus':
      return '65+ Jahre';
    case 'youths':
      return 'Junge';
    case 'families_with_children':
      return 'Familien mit Kindern';
    case '31_50':
      return 'Midager (31-50 Jahre)';
    case '51_65':
      return 'Bestager (51-65 Jahre)';
    case 'senior_citizen':
      return 'Senioren (ab 66 Jahren)';
    default:
      break;
  }
};

function KennzahlPieChart({ kennzahl, category, hasButton, handleButtonClick }) {
  const user = useSelector((state) => state.user);

  const [labels, setLabels] = useState([]);
  const [absoluteValues, setAbsoluteValues] = useState([]);
  const [total, setTotal] = useState(0);

  const colorArray = ['#fff', '#57A1CA', '#0B1F42', '#0C426F', '#0D5D92'];

  useEffect(() => {
    let labelArray = [];
    let absoluteValuesArray = [];

    let total = 0;
    Object.entries(kennzahl).forEach((val) => {
      let valData = val[1];

      valData.forEach((v) => {
        let label = labelText(v.subtype);
        labelArray.push(label);
        absoluteValuesArray.push(v.absoluteValue);
        total += v.absoluteValue;
      });
    });

    setLabels(labelArray);
    setAbsoluteValues(absoluteValuesArray);
    setTotal(total);
  }, [user.marktinfoResultActive]);

  const chartData = {
    labels: labels,
    datasets: [
      {
        data: absoluteValues,
        backgroundColor: colorArray,
        borderWidth: 0
        // borderColor: '#f00'
      }
    ]
  };

  const config = {
    cutout: 110,
    plugins: {
      legend: {
        display: false
      }
    }
  };

  return (
    <>
      <h1 className="kennzahl__headline mobile-hidden">{category}</h1>
      <div className="kennzahl__piechart">
        <div className="kennzahl__piechart-wrapper">
          <DoughnutChart chartData={chartData} config={config} />
        </div>

        {labels && labels.length > 0 && (
          <div className="kennzahl__piechart__legend">
            {labels.map((l, index) => {
              let abs = absoluteValues[index];
              let percent = ((abs / total) * 100).toFixed(2);
              let color = colorArray[index];

              return (
                <div className="kennzahl__piechart__legend-item" key={l}>
                  <span
                    className="kennzahl__piechart--legendcolor"
                    style={{ backgroundColor: color }}
                  ></span>
                  <span className="kennzahl__piechart--legendval">{percent} %:</span>
                  <span className="kennzahl__piechart--legendlabel">{l}</span>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {hasButton && handleButtonClick && (
        <button className="button button--white-outline" onClick={handleButtonClick}>
          Details ansehen
        </button>
      )}
    </>
  );
}

export default KennzahlPieChart;
