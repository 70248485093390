export const demoweitere = [
	{
			"id": 27,
			"key": "27991511505",
			"label": "Schriftverkehr zur Immobilie",
			"dateFrom": "2025-02-13T19:14:27+01:00",
			"dateTo": null,
			"value": {
					"ActivityTypeId": 27,
					"ActivityTypeName": "Schriftverkehr",
					"Comment": "mit Adressen, mit Preisen, mit Links, mit folgenden Dateien: \"Basisinformationsblatt_DE000JV2TLS8.pdf\", Kaufpreis: 520.000,00 €",
					"Created_at": "2025-02-13T19:14:27+01:00",
					"Offertext": "<p>bla bla b.ub</p><p>https://apps.justimmo.at/nachricht/26977348/26294673/35929b8a1b9489ca0d7700491df7f47a495b634e</p>",
					"Files": [
							{
									"Type": 1,
									"Storage_key": "ldNigo2L7XS1MNFGQavh1.pdf",
									"Url": "https://api.justimmo.at/v1/file/ldNigo2L7XS1MNFGQavh1.pdf",
									"Original_filename": "Widerrufsformular.pdf",
									"Title": "Widerrufsformular",
									"Description": "",
									"Sort_order": null,
									"Uploaded_at": "2025-02-13T19:14:26+01:00",
									"Visibility": 1,
									"Active": true,
									"Group": null,
									"Original_filesize": 60216,
									"Mime_type": "application/pdf",
									"Width": 0,
									"Height": 0,
									"Thumbnails": null,
									"AdditionalProperties": {}
							},
							{
									"Type": 1,
									"Storage_key": "7uNiN0PSkPH4OfsmIG0ZjJ.pdf",
									"Url": "https://api.justimmo.at/v1/file/7uNiN0PSkPH4OfsmIG0ZjJ.pdf",
									"Original_filename": "Nebenkosten-Kauf.pdf",
									"Title": "Nebenkostenübersicht Kauf",
									"Description": "",
									"Sort_order": null,
									"Uploaded_at": "2025-02-13T19:14:27+01:00",
									"Visibility": 1,
									"Active": true,
									"Group": null,
									"Original_filesize": 1600028,
									"Mime_type": "application/pdf",
									"Width": 0,
									"Height": 0,
									"Thumbnails": null,
									"AdditionalProperties": {}
							},
							{
									"Type": 1,
									"Storage_key": "33nyJsQjNmmjeGpha3bPGJ.pdf",
									"Url": "https://api.justimmo.at/v1/file/33nyJsQjNmmjeGpha3bPGJ.pdf",
									"Original_filename": "Basisinformationsblatt_DE000JV2TLS8.pdf",
									"Title": "",
									"Description": "",
									"Sort_order": null,
									"Uploaded_at": "2025-02-13T19:14:27+01:00",
									"Visibility": 1,
									"Active": true,
									"Group": {
											"Id": 27
									},
									"Original_filesize": 159398,
									"Mime_type": "application/pdf",
									"Width": 0,
									"Height": 0,
									"Thumbnails": null,
									"AdditionalProperties": {}
							}
					],
					"From": "0001-01-01 00:00:00Z",
					"To": "0001-01-01 00:00:00Z"
			},
			"text": "",
			"filter_id": 3
	},
	{
			"id": 27,
			"key": "27990989205",
			"label": "Schriftverkehr zur Immobilie",
			"dateFrom": "2025-02-13T18:08:28+01:00",
			"dateTo": null,
			"value": {
					"ActivityTypeId": 27,
					"ActivityTypeName": "Schriftverkehr",
					"Comment": "mit Adressen, mit Preisen, mit Links, mit folgenden Dateien: \"b0939bcaca35455eb6c1ff91e796f8f3.pdf\", Kaufpreis: 520.000,00 €",
					"Created_at": "2025-02-13T18:08:28+01:00",
					"Offertext": "<p>Neue Dokumente for you</p><p>https://apps.justimmo.at/nachricht/26976637/26294673/b7ff94e0be009352f7d433bd9a800f74178a33ab</p>",
					"Files": [
							{
									"Type": 1,
									"Storage_key": "2oDxtlE1NA5oQwcSKQL5NS.pdf",
									"Url": "https://api.justimmo.at/v1/file/2oDxtlE1NA5oQwcSKQL5NS.pdf",
									"Original_filename": "Widerrufsformular.pdf",
									"Title": "Widerrufsformular",
									"Description": "",
									"Sort_order": null,
									"Uploaded_at": "2025-02-13T18:08:28+01:00",
									"Visibility": 1,
									"Active": true,
									"Group": null,
									"Original_filesize": 60216,
									"Mime_type": "application/pdf",
									"Width": 0,
									"Height": 0,
									"Thumbnails": null,
									"AdditionalProperties": {}
							},
							{
									"Type": 1,
									"Storage_key": "2FMQLeLu3cCmWuApUNAhkL.pdf",
									"Url": "https://api.justimmo.at/v1/file/2FMQLeLu3cCmWuApUNAhkL.pdf",
									"Original_filename": "Nebenkosten-Kauf.pdf",
									"Title": "Nebenkostenübersicht Kauf",
									"Description": "",
									"Sort_order": null,
									"Uploaded_at": "2025-02-13T18:08:28+01:00",
									"Visibility": 1,
									"Active": true,
									"Group": null,
									"Original_filesize": 1600028,
									"Mime_type": "application/pdf",
									"Width": 0,
									"Height": 0,
									"Thumbnails": null,
									"AdditionalProperties": {}
							},
							{
									"Type": 1,
									"Storage_key": "7ewaQvrQhHcz1BeLePnR3m.pdf",
									"Url": "https://api.justimmo.at/v1/file/7ewaQvrQhHcz1BeLePnR3m.pdf",
									"Original_filename": "b0939bcaca35455eb6c1ff91e796f8f3.pdf",
									"Title": "",
									"Description": "",
									"Sort_order": null,
									"Uploaded_at": "2025-02-13T18:08:28+01:00",
									"Visibility": 1,
									"Active": true,
									"Group": {
											"Id": 27
									},
									"Original_filesize": 4150550,
									"Mime_type": "application/pdf",
									"Width": 0,
									"Height": 0,
									"Thumbnails": null,
									"AdditionalProperties": {}
							}
					],
					"From": "0001-01-01 00:00:00Z",
					"To": "0001-01-01 00:00:00Z"
			},
			"text": "mit Adressen, mit Preisen, mit Links, mit folgenden Dateien: \"b0939bcaca35455eb6c1ff91e796f8f3.pdf\", Kaufpreis: 520.000,00 €",
			"filter_id": 3
	},
	{
			"id": 27,
			"key": "26703702405",
			"label": "Schriftverkehr zur Immobilie",
			"dateFrom": "2024-10-30T15:54:57+01:00",
			"dateTo": null,
			"value": {
					"ActivityTypeId": 27,
					"ActivityTypeName": "Schriftverkehr",
					"Comment": "mit Adressen, mit Preisen, mit Links, mit folgenden Dateien: \"2fwxN06lt8RrfL4YUJeFD4.jpg.pdf\", \"3255-30_-_Haus_-_Kauf_-_8053_-_Expose\", Kaufpreis: 520.000,00 €",
					"Created_at": "2024-10-30T15:54:57+01:00",
					"Offertext": "<p>https://apps.justimmo.at/nachricht/25642561/32454376/200f0716b1bc4f7844a99ec6971348e86cc99c12</p>",
					"Files": [
							{
									"Type": 1,
									"Storage_key": "nS8wykp1MtueiRH9MLrJt.pdf",
									"Url": "https://api.justimmo.at/v1/file/nS8wykp1MtueiRH9MLrJt.pdf",
									"Original_filename": "3255-30_-_Haus_-_Kauf_-_8053_-_Expose.pdf",
									"Title": "3255-30_-_Haus_-_Kauf_-_8053_-_Expose",
									"Description": "",
									"Sort_order": null,
									"Uploaded_at": "2024-10-30T15:54:58+01:00",
									"Visibility": 1,
									"Active": true,
									"Group": null,
									"Original_filesize": 8057497,
									"Mime_type": "application/pdf",
									"Width": 0,
									"Height": 0,
									"Thumbnails": null,
									"AdditionalProperties": {}
							},
							{
									"Type": 1,
									"Storage_key": "oMpO6hmvuxYSauHRVPufP.pdf",
									"Url": "https://api.justimmo.at/v1/file/oMpO6hmvuxYSauHRVPufP.pdf",
									"Original_filename": "Widerrufsformular.pdf",
									"Title": "Widerrufsformular",
									"Description": "",
									"Sort_order": null,
									"Uploaded_at": "2024-10-30T15:54:57+01:00",
									"Visibility": 1,
									"Active": true,
									"Group": null,
									"Original_filesize": 60216,
									"Mime_type": "application/pdf",
									"Width": 0,
									"Height": 0,
									"Thumbnails": null,
									"AdditionalProperties": {}
							},
							{
									"Type": 1,
									"Storage_key": "15L6E9abDI6XXpT63jy7qT.pdf",
									"Url": "https://api.justimmo.at/v1/file/15L6E9abDI6XXpT63jy7qT.pdf",
									"Original_filename": "Nebenkosten-Kauf.pdf",
									"Title": "Nebenkostenübersicht Kauf",
									"Description": "",
									"Sort_order": null,
									"Uploaded_at": "2024-10-30T15:54:57+01:00",
									"Visibility": 1,
									"Active": true,
									"Group": null,
									"Original_filesize": 1600028,
									"Mime_type": "application/pdf",
									"Width": 0,
									"Height": 0,
									"Thumbnails": null,
									"AdditionalProperties": {}
							},
							{
									"Type": 1,
									"Storage_key": "3A3fc5o7Nx7D8kERvpj3B4.pdf",
									"Url": "https://api.justimmo.at/v1/file/3A3fc5o7Nx7D8kERvpj3B4.pdf",
									"Original_filename": "2fwxN06lt8RrfL4YUJeFD4.jpg.pdf",
									"Title": "",
									"Description": "",
									"Sort_order": null,
									"Uploaded_at": "2024-10-30T15:54:57+01:00",
									"Visibility": 1,
									"Active": true,
									"Group": {
											"Id": 5
									},
									"Original_filesize": 291709,
									"Mime_type": "application/pdf",
									"Width": 0,
									"Height": 0,
									"Thumbnails": null,
									"AdditionalProperties": {}
							}
					],
					"From": "0001-01-01 00:00:00Z",
					"To": "0001-01-01 00:00:00Z"
			},
			"text": "mit Adressen, mit Preisen, mit Links, mit folgenden Dateien: \"2fwxN06lt8RrfL4YUJeFD4.jpg.pdf\", \"3255-30_-_Haus_-_Kauf_-_8053_-_Expose\", Kaufpreis: 520.000,00 €",
			"filter_id": 3
	}
];