import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ButtonForwardAction from '../Buttons/ButtonForwardAction';
import InfoDialogZustandImmo from '../Dialogs/InfoDialogZustandImmo';
import FormInput from '../FormFields/FormInput';
import FormSelect from '../FormFields/FormSelect';
import LoadingSpinner from '../LoadingSpinner';

import { fetchErrorText } from '../FormFields/util/formTexts';
import { validateFields } from '../FormFields/util/validateFields';

// STORE
import { setFormIsLoading } from '../../reducers/app';
import { setPageDialogOpenMarktinfoForm, setResetFieldsMarktinfoLightForm, setSiteIndexMarktinfoForm } from '../../reducers/marktinfoLight';
import { setMarktinfoLastResults, setMarktinfoResultActive, setUserObject } from '../../reducers/user';

// API
import { current } from '../../api/Onboarding';
import { getMarktinfoLight } from '../../api/PriceHubble';

import { MARKTINFO_SITEINDEX_RESULT } from '../../constants';
import { useHandleMarktinfo } from '../../hooks/user/useHandleMarktinfo';
import statesWithZips from '../FormFields/util/statesWithZips';

import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';

function MarktinfoLightForm() {
  const dispatch = useDispatch();
  let currentMarktinfoLight = useSelector((state) => state.marktinfoLight);
  let currentFormValidators = useSelector((state) => state.formValidators);
  let user = useSelector((state) => state.user);

  const { getMarktinfoResults, getMarktinfoLabel } = useHandleMarktinfo();

  const { isMobile } = useHandleScreenResize();

  const [infoDialogShown, setInfoDialogShown] = useState(false);
  const [error, setError] = useState('');

  const handleInfoModal = () => {
    setInfoDialogShown(!infoDialogShown);
  };
  const app = useSelector((state) => state.app);

  const forwardAction = async () => {
    setError('');
    if (app.formIsLoading) return;


    let validationResult = false;
    validationResult = validateFields(
      {
        street: currentMarktinfoLight.street,
        town: currentMarktinfoLight.town.id,
        zip: currentMarktinfoLight.typedZip,
      },
      dispatch,
    );
console.log('1');
    let marktinfoLightResult = [];
    if (validationResult) {
      dispatch(setFormIsLoading(true));

			if(app.demoMode) {
				setTimeout(() => {
					dispatch(setResetFieldsMarktinfoLightForm());
					dispatch(setFormIsLoading(false));
				}, 1000);
	
				return;
			}


      try {
        marktinfoLightResult = await getMarktinfoLight({
          city: currentMarktinfoLight.town.text,
          postCode: currentMarktinfoLight.zip.text,
          streetAndHouseNumber: currentMarktinfoLight.street,
        });			
				
        if (marktinfoLightResult) {
          if (user.isLoggedIn) {
            try {
              const currentUser = await current();
              dispatch(setFormIsLoading(false));
							
              if (currentUser.results?.length > 0) {
                dispatch(setUserObject(currentUser));

                // get+set results
                const marktinfoResults = getMarktinfoResults(currentUser);
                dispatch(setMarktinfoLastResults(marktinfoResults));

                // set active result to last calculation
                const keys = Object.keys(marktinfoResults);
                const lastKey = keys[keys.length - 1];
                let label = getMarktinfoLabel(marktinfoResults, keys.length - 1, lastKey);
                dispatch(setMarktinfoResultActive({ id: lastKey, text: label }));

                dispatch(setSiteIndexMarktinfoForm(MARKTINFO_SITEINDEX_RESULT));

                if (isMobile) {
                  dispatch(setPageDialogOpenMarktinfoForm(true));
                }
              } else {
                setError('Die Marktanalyse konnte leider nicht gespeichert werden.');
              }
            } catch (e) {
              dispatch(setFormIsLoading(false));
              console.error('e', e);
            }
          } else {
            dispatch(setFormIsLoading(false));

            // get+set results
            dispatch(setMarktinfoLastResults(marktinfoLightResult));

            dispatch(setSiteIndexMarktinfoForm(MARKTINFO_SITEINDEX_RESULT));
            if (isMobile) {
              dispatch(setPageDialogOpenMarktinfoForm(true));
            }
          }
        } else {
          dispatch(setFormIsLoading(false));
          setError(fetchErrorText);
        }
      } catch (e) {
        // handle error
        console.error('e', e);
        dispatch(setFormIsLoading(false));
        setError(fetchErrorText);
      }
    }
  };

  const formType = 'marktinfoLight';

  return (
    <div className="marktinfoForm">
      <h3 className="form__title">Immobiliendaten</h3>

      <FormInput
        label={'Straße und Hausnummer'}
        placeholder={'Straße und Hausnummer'}
        id={'inputStreet'}
        formType={formType}
        value={currentMarktinfoLight.street}
        error={currentFormValidators.streetError}
        
      />

      <div className={!isMobile ? 'form__row form__row--double' : null}>
        <FormInput
          label={'PLZ'}
          placeholder={'PLZ eingeben'}
          id={'inputZip'}
          formType={formType}
          type="number"
          error={currentFormValidators.zipError}
          
          value={currentMarktinfoLight.typedZip}
          hasList={true}
        />
        <FormSelect
          label={'Bundesland'}
          placeholder={'Bundesland eingeben'}
          value={currentMarktinfoLight.town.id > -1 ? currentMarktinfoLight.town : ''}
          formType={formType}
          id={'inputTown'}
          options={statesWithZips.map((state) => {
            return {
              id: state.priceHubbleId,
              text: state.label,
            };
          })}
          error={currentFormValidators.townError}
          
        />
      </div>

      <LoadingSpinner iconColor="#fff" textColor="#fff" />

      <ButtonForwardAction formType={formType} buttonText="Markt analysieren" forwardAction={forwardAction} buttonStyle="blue" />

      {error && (
        <p className="text-white">
          <br />
          {error}
        </p>
      )}

      <InfoDialogZustandImmo handleClose={handleInfoModal} open={infoDialogShown} />
    </div>
  );
}

export default MarktinfoLightForm;
