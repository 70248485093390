
import SuchprofilCard from '../../Cards/SuchprofilCard';



// STORE
import { SEARCHPROFILE_SITEINDEX_START, SEARCHPROFILE_STATE_ACTIVE, SEARCHPROFILE_STATE_INACTIVE } from '../../../constants';

import { useDispatch, useSelector } from 'react-redux';
import { useFetchSearchprofiles } from '../../../hooks/searchprofiles/useFetchSearchprofiles';
import { setSiteIndexSPForm } from '../../../reducers/searchProfileForm';
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';

function SearchProfileTooMany({ formType }) {
	const app = useSelector((state) => state.app);
	const dispatch = useDispatch();

	const { getSearchprofilesByState } = useFetchSearchprofiles();
	let activeSearchprofiles = getSearchprofilesByState(SEARCHPROFILE_STATE_ACTIVE);
	let deactiveSearchprofiles = getSearchprofilesByState(SEARCHPROFILE_STATE_INACTIVE);

	const forwardAction = () => {
		if(activeSearchprofiles.length < 3) {
			dispatch(setSiteIndexSPForm(SEARCHPROFILE_SITEINDEX_START));
		}
	}

  return (
    <>
    	<h2 className="mb-0">Suchprofil</h2>

			{ activeSearchprofiles.length >= 3 && !app.demoMode && (
				<div className="error-container">
					Sie haben bereits 3 aktive Suchprofile. Bitte deaktivieren Sie mindestens eines Ihrer Suchprofile, um ein neues erstellen zu können.
				</div>
			)}

			<h3 className="mt-200rem">Suchprofil deaktivieren</h3>
			<div className="mt-200rem">
				{activeSearchprofiles.map((sp) => (
						<SuchprofilCard
							key={sp.created_at}
							suchProfilData={sp}
							cssClasses="mb-100rem"
						/>
					))}
					{deactiveSearchprofiles.map((sp) => (
						<SuchprofilCard
							key={sp.created_at}
							suchProfilData={sp}
							cssClasses="mb-100rem"
						/>
					))}
			</div>

			<ButtonForwardAction disabled={activeSearchprofiles.length === 3} buttonStyle='blue' formType={formType} buttonText={'Weiter'} forwardAction={forwardAction} cssClasses={'w-100 mb-200rem'} />
    </>
  );
}

export default SearchProfileTooMany;
