import { useDispatch, useSelector } from 'react-redux';
import { clearStorage, closePageDialog, setMenuType, setRoot } from '../../reducers/app';
import { setResetFieldsKaufanbotForm } from '../../reducers/kaufAnbote';
import { setResetFieldsMarktinfoLightForm } from '../../reducers/marktinfoLight';
import { setResetFieldsSPForm } from '../../reducers/searchProfileForm';
import { setResetFieldsUser } from '../../reducers/user';
import { setResetFieldsWohnwertForm } from '../../reducers/wohnwertLight';

import { MENU_VERKAUF, PHASE2_VERKAUF_MIT_AKTIVITAET, ROLE_VENDOR } from '../../constants';
import { setResetFieldsLoginForm } from '../../reducers/loginForm';
import { clearMarktBericht } from '../../reducers/marktBericht';
import { setResetFieldsObjectRequest } from '../../reducers/objectRequest';
import { setResetFieldsRegisterForm } from '../../reducers/registerForm';
import { useHandleMenu } from '../menu/useHandleMenu';
import { resetCachedSimilarImmos } from '../../reducers/cachedSimilarImmos';
import { isDemoUrl } from '../../util/generalUtils';
import { useNavigate } from 'react-router';

export const useHandleApp = () => {
  const dispatch = useDispatch();
	const navigate = useNavigate();

  const {  updateMenu, getRealtyPhases } = useHandleMenu();

	const app = useSelector((state) => state.app);
	const loginForm = useSelector((state) => state.loginForm);
	const registerForm = useSelector((state) => state.registerForm);
	const marktinfoLight = useSelector((state) => state.marktinfoLight);
	let wohnwertLight = useSelector((state) => state.wohnwertLight);

  const resetStore = () => {
    console.log('resetStore');
    dispatch(clearStorage());
		dispatch(closePageDialog());
    dispatch(setResetFieldsUser());

    dispatch(setResetFieldsMarktinfoLightForm());
    dispatch(setResetFieldsWohnwertForm());
    dispatch(setResetFieldsSPForm());
    dispatch(setResetFieldsKaufanbotForm());
		dispatch(setResetFieldsObjectRequest());
    dispatch(clearMarktBericht());

		dispatch(resetCachedSimilarImmos());

    localStorage.removeItem('activityIdsRead');
		localStorage.removeItem('mysreal_contact_reason');
		localStorage.removeItem('mysreal_contact_immo');
		localStorage.removeItem('mysreal_searchform_values');
		localStorage.removeItem('profildeaktivieren-clicked');

		if(isDemoUrl()) {
			setDemoAndReload();
		}
  };

	const setDemoAndReload = async () => {
		await dispatch(setRoot('/demo'));

		updateMenu(PHASE2_VERKAUF_MIT_AKTIVITAET);
		dispatch(setMenuType(MENU_VERKAUF));

		navigate({ pathname: '/dashboard' });
	}

	const registerPathname = ['/confirm', '/direct-registration-confirm', '/set_password'];

  const closePageDialogs = () => {
		if(marktinfoLight.pageDialogOpen) {
			dispatch(setResetFieldsMarktinfoLightForm());
		}

		if(wohnwertLight.pageDialogOpen) {
			dispatch(setResetFieldsWohnwertForm());
		}

		if(loginForm.pageDialogOpen) {
			dispatch(setResetFieldsLoginForm());
		}
		if(registerForm.pageDialogOpen && !registerPathname.some(path => window.location.pathname.includes(path))) {
			dispatch(setResetFieldsRegisterForm());
		}		

		if(app.pageDialogOpenId !== '') {
			dispatch(closePageDialog());
		}
  };

  const handleMatomo = (currentUser) => {
    console.log('handleMatomo');
    let env = '';
    const host = window.location.host;
    if (host.indexOf('frontend.stable.srealportal.microf.cs.eb.lan.at') > -1) {
      env = 'ABNAHME';
    } else if (host.indexOf('my-sreal.at') > -1) {
      env = 'PROD';
    } else if (host.includes('localhost') || host.includes('127.0.0.1') || host.includes('sreal.k25-dev.at')) {
      env = 'DEV';
      // env = 'ABNAHME'; // fake environment
    }

    if (env === 'ABNAHME' || env === 'PROD') {
      // insert scrpt if it doesn't exist yet
      if (window._mtm === undefined) {
        var _mtm = (window._mtm = window._mtm || []);
        _mtm[0] = { 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' };
        var d = document,
          g = d.createElement('script'),
          s = d.getElementsByTagName('script')[0];
        g.async = true;
        g.src = env === 'ABNAHME' ? 'https://matomo.sreal.net/js/container_cdIwjw75_staging_51ce20ea507d7e9dd707498e.js' : 'https://matomo.sreal.net/js/container_cdIwjw75.js';
        s.parentNode.insertBefore(g, s);
      }

      var sha256 = require('js-sha256');

      let realtyPhases = getRealtyPhases();

      let userId = undefined;
      let userRealties = [];
      let abgeberRealties = [];
      let interessentRealties = [];
      if (currentUser && currentUser.justImmoContacts?.length > 0) {
        let contact = currentUser.justImmoContacts[0];
        userId = contact.id.toString();
        userId = sha256(userId);

        if (contact.realties.length > 0) {
          abgeberRealties = contact.realties
            .filter((r) => r.role === ROLE_VENDOR)
            .map((r) => {
              let justImmoRealtyId = r.realty.id;
              let realtyPhaseFound = realtyPhases.find((rp) => rp.realtyId === justImmoRealtyId);

              return {
                realtyId: justImmoRealtyId,
                phase: realtyPhaseFound ? realtyPhaseFound.phase : 1,
                role: 'Abgeber',
              };
            })
            .filter((id) => id !== undefined);

          if (abgeberRealties?.length > 0) {
            userRealties.push(...abgeberRealties);
          }
        }
      }

      // find realties of interessent
      interessentRealties = realtyPhases
        .map((rp) => {
          let foundInAbgeberArray = abgeberRealties.find((abgeberR) => abgeberR.realtyId === rp.realtyId);

          // only add interessent realties if phase 3 or 4
          if (!foundInAbgeberArray && rp.phase >= 3) {
            return {
              realtyId: rp.realtyId,
              phase: rp.phase,
              role: 'Interessent',
            };
          }
        })
        .filter((id) => id !== undefined);

      if (interessentRealties?.length > 0) {
        userRealties.push(...interessentRealties);
      }

      let direktRegParams = undefined;
      if (window.location.pathname.indexOf('/direct-registration-confirm') >= 0) {
        direktRegParams = {
          mtm_campaign: '08|0000|Direktregistrierungen',
          mtm_medium: 'email',
          mtm_source: 'system_mysreal',
          mtm_keyword: 'neueBeauftragung', // bestehendeBeauftragung
          mtm_placement: 'content', // cta
          mtm_group: 'customer_offline',
        };
      }

      window._mtm[1] = {
        contentgroup: {
          language: 'de',
        },
        visitor: {
          origin: env === 'ABNAHME' ? 'intern' : 'extern',
        },
        tracking_parameter: direktRegParams,
        user: {
          id: userId,
          real_estate_objects: userRealties,
        },
      };
    }
  };

  return { resetStore, closePageDialogs, handleMatomo, setDemoAndReload };
};
