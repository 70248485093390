import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import InfoDialog from '../Dialogs/InfoDialog';
import PageDialogKaufanbot from '../Dialogs/PageDialogKaufanbot';
import FormSelect from '../FormFields/FormSelect';
import Card from './Card';

// ICONS
import IconPaperOutlined from '../Icons/IconPaperOutlined';

// API
import { PAGEDIALOG_KAUFANBOT } from '../../constants';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import { useHandleFavorite } from '../../hooks/immo/useHandleFavorite';
import { useFetchKaufanbote } from '../../hooks/kaufanbote/useFetchKaufanbote';
import { setPageDialogOpenId } from '../../reducers/app';
import parseCurrency from '../../util/currencyParser';
import { parseDate } from '../../util/datetimeParser';
import { getKaufpreis, getMainArea } from '../../util/immoObjectParser';

function AbgegebeneAngeboteList({ list }) {
  const { getSentOffersList } = useFetchKaufanbote();
  
  let sentOffersList = getSentOffersList(list);
	if(!sentOffersList) return null;
	else {
		return (
			<>
				{sentOffersList?.length > 0 && (
					<ul className="list-style-none">
						{sentOffersList.map((anbotImmo, index) => (
							<li className="pb-150rem font-90" key={index}>
								<Link className="textlink" to={`../immobilie/${anbotImmo.url}`} title={anbotImmo.title}>
									Objekt {anbotImmo.catalogue_number} {anbotImmo.title}
								</Link>
								<span className="d-flex w-100 pt-50rem">Verschickt am: {parseDate(anbotImmo.offerCreatedAt)}</span>
								<span className="d-flex w-100">Angebotener Preis: {parseCurrency(anbotImmo.offeredPrice)}</span>
							</li>
						))}
					</ul>
				)}
			</>
		);
	}
}

function KaufanbotCard({ cssClasses, suchprofileMenu = null }) {
  cssClasses += ' card--kaufanbot';

	const user = useSelector((state) => state.user);
	const app = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const { isMobile } = useHandleScreenResize();
	const parseHTML = require('html-react-parser');

	const { reloadBasicFavList } = useHandleFavorite();

	const [wunschimmobilie, setWunschimmobilie] = useState(null);
	const handleSetWunschimmobilie = (val) => {
		if(!val || !val.immo) {
			setWunschimmobilie(null);
		}
		else {
			let immo = val.immo;
			let immoArea = getMainArea(immo);
			let immoAddressLine = immo.address.zip + ' ' + immo.address.city;

			if (immoArea) {
				immoAddressLine += ', ' + immoArea;
			}

			if (immo.rooms > 0) {
				immoAddressLine += ', ' + immo.rooms?.toString().replace('.', ',') + ' Zimmer';
			}

			let wunschImmo = {
				id: immo.id,
				addressLine: parseHTML(immoAddressLine),
				kaufpreis: getKaufpreis(immo),
				url: immo.url,
				title: immo.title,
				immo: immo,
				text: 'Objekt ' + immo.catalogue_number + ' - ' + immo.title,
			};

			setWunschimmobilie(wunschImmo);
		}
	}

	/* INFODIALOG */
  const [infoDialogAnfragenOpen, setInfoDialogAnfragenOpen] = useState(false);
	const handleInfoDialogAnfragenOpen = () => {
    setInfoDialogAnfragenOpen(!infoDialogAnfragenOpen);
  };

	/* SET FAVS */
	const [favsLoaded, setFavsLoaded] = useState(false);
	const [favList, setFavList] = useState([]);
	const getFavorites = useCallback(async () => {
		if(app.demoMode) {
			setFavsLoaded(true);
			return;	
		}

		if(favsLoaded) return;
		await reloadBasicFavList();
		setFavsLoaded(true);
	}, []);

	useEffect(() => {
		if (user.favourites.length > 0 && !app.demoMode) {
			let options = [{ optgroup: true, text: 'Favoriten' }];
			user.favourites.forEach((fav) => {
				options.push({
					text: 'Objekt ' + fav.catalogue_number + ' - ' + fav.title,
					id: fav.id,
					immo: fav,
				});
			});
			setFavList(options);
		}
	}, [user.favourites])

	const [spList, setSpList] = useState([]);
	useEffect(() => {
		if (suchprofileMenu?.length > 0) {
			const menu = suchprofileMenu.filter((m) => m.label !== 'Alle' && m.immos.length > 0);
			let spOptions = [];
			menu.forEach((m) => {
				let text = m.label !== 'Weitere Immobilien' ? 'Suchprofil ' + m.label : m.label;
				spOptions.push({ optgroup: true, text });
	
				m.immos?.forEach((immo) => {
					spOptions.push({
						text: 'Objekt ' + immo.catalogue_number + ' - ' + immo.title,
						id: immo.id,
						immo: immo,
					});
				});
			});
	
			spOptions = spOptions.filter((opt) => opt !== undefined);
			setSpList(spOptions);
		}
	}, [suchprofileMenu]);

	const [dropdownHeadline, setDropdownHeadline] = useState('');
	useEffect(() => {
		// check if favs loaded
		if(!favsLoaded) {
			getFavorites();
		}

		if(app.demoMode) {
			setDropdownHeadline('');
		}
		else {
			if(user.sentOffers?.length > 0) {
				if(user.sentOffers.length === 1) {
					setDropdownHeadline('1 verschickte Kaufanbotsanfrage');
				}
				else {
					setDropdownHeadline(user.sentOffers?.length + ' verschickte Kaufanbotsanfragen');
				}
			}
		}
	}, [getFavorites, setDropdownHeadline, user.sentOffers]);

  if (suchprofileMenu?.length > 0) {
    return (
      <>
        <Card
          cardStyle={isMobile ? 'topImage' : 'topImage2ColsRight'}
          cssClasses={`${cssClasses}`}
          icon={<IconPaperOutlined color="#fff" cssClasses={isMobile ? 'scale-0-5' : ''} />}
          dropdownHeadline={dropdownHeadline}
          dropdownChildren={<AbgegebeneAngeboteList list={[...favList, ...spList]} />}
          handleInfoModalDesktop={handleInfoDialogAnfragenOpen}
        >
          <div>
            <h3>Anfrage Kaufanbot abgeben</h3>

						{ !favsLoaded ? (
							<p className="loading-animation">
								Wunschimmobilien werden geladen
								<span>.</span><span>.</span><span>.</span>
							</p>
						) : (
							<>
								{ [...favList, ...spList].length === 0 ? (
									<p>Leider keine Wunschimmobilien zur Auswahl.</p>
								) : (
									<FormSelect
										id={'wunschimmobilie'}
										label={'Wunschimmobilie wählen'}
										placeholder={'Bitte wählen'}
										value={wunschimmobilie || ''}
										options={[...favList, ...spList]}
										callback={handleSetWunschimmobilie}
									/>
								)}

								<div className="d-flex flex-col mt-300rem">
									<div className="elements2cols">
										<div className="elements2cols__col mb-100rem">
											<span className="d-flex w-100 pb-50rem font-weight-600">Infos zum Objekt</span>
											<span className="font-weight-600 font-140 font-weight-800">{wunschimmobilie?.addressLine || 'PLZ Ort, Wohnfläche, Zimmeranzahl'}</span>
										</div>

										<div className="elements2cols__col mb-100rem">
											<span className="d-flex w-100 pb-50rem font-weight-600">Verkaufspreis</span>
											<span className="font-weight-700 font-140">{wunschimmobilie?.kaufpreis || '-,- €'}</span>
										</div>
									</div>

									<div className="button-panel align-items-center">
										{wunschimmobilie?.id > 0 ? (
											<>
												<button className="button button--blue mb-0" onClick={() => dispatch(setPageDialogOpenId(PAGEDIALOG_KAUFANBOT))}>
													Wunsch-Kaufpreis festlegen
												</button>
												<Link className={`textlink ${isMobile ? 'mt-100rem' : ''}`} target='_blank' to={`../immobilie/${wunschimmobilie?.url}`} title={wunschimmobilie?.title}>
													Zum Objekt
												</Link>
											</>
										) : (
											<button className="button mb-0 mt-0" disabled={true}>
												Wunsch-Kaufpreis festlegen
											</button>
										)}
									</div>
								</div>
							</>
						)}						
          </div>
        </Card>

        <PageDialogKaufanbot wunschimmobilie={wunschimmobilie} text='123' />

        <InfoDialog headline={dropdownHeadline} open={infoDialogAnfragenOpen} handleClose={handleInfoDialogAnfragenOpen}>
          <AbgegebeneAngeboteList list={[...favList, ...spList]} />
        </InfoDialog>
      </>
    );
  } else {
    return null;
  }
}

export default KaufanbotCard;
