import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Card from './Card';

// ICONS
import IconGutachtenHakerl from '../Icons/IconGutachtenHakerl';

// STORE

import { CONTACTFORM_ANLIEGEN_VERKAUF, PAGEDIALOG_CONTACT } from '../../constants';
import '../../styles/wohnwert.scss';

import { useHandleWohnwert } from '../../hooks/user/useHandleWohnwert';
import { setPageDialogOpenId } from '../../reducers/app';

function WohnwertProCard({ cssClasses }) {
  if (!cssClasses) cssClasses = 'wohnwertpro-card';
  else cssClasses += ' wohnwertpro-card';
 
  const dispatch = useDispatch();

  const { getWohnwertGutachten } = useHandleWohnwert();

  const handleGutachten = () => {
		localStorage.setItem('mysreal_contact_reason', CONTACTFORM_ANLIEGEN_VERKAUF);
		dispatch(setPageDialogOpenId(PAGEDIALOG_CONTACT));
  };

  const [isDesktop, setIsDesktop] = useState(false);
  const [gutachten, setGutachten] = useState(null);
  useEffect(() => {
    if (isDesktop) {
      setIsDesktop(true);
    }

    setGutachten(getWohnwertGutachten());
  }, []);

  return (
    <Card cssClasses={cssClasses + ' background-blue text-white'} cardStyle={'customContent'}>
      <div className="card__content card__content--wohnwert">
        <IconGutachtenHakerl color="#fff" cssClasses="mobile-hidden mb-20" />
        <h2 className="mb-100rem text-white">Wohnwert Pro</h2>
				<p className="font-90 h-100">Eine s REAL Immobilienexpert:in schaut sich Ihre Immobilie für eine unverbindliche Verkehrswertschätzung an. Wir freuen uns, Sie kennenzulernen!</p>

				<div className="d-flex d-flex--btnandicon mt-auto">
					<button className="button button--white-outline mt-100rem" onClick={handleGutachten}>
						Anfrage senden
					</button>
				</div>
      </div>
    </Card>
  );
}

export default WohnwertProCard;
