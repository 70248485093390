import { createSlice } from '@reduxjs/toolkit';
import { removeHashFromUrl } from '../util/generalUtils';

import { LOGINFORM_SITEINDEX_START, LOGINFORM_SITEINDEX_SUCCESSTOKEN, LOGINFORM_SITEINDEX_PWFORGET, LOGINFORM_SITEINDEX_LOGINSUCCESS } from '../constants';

const initialState = {
  siteIndex: LOGINFORM_SITEINDEX_START,
  headline: 'Login',
  pageDialogOpen: false,
  requestBody: {
    email: '',
    password: '',
    rememberMe: false,
  },
  phoneNumber: '',
  token: '',
  verificationCode: '',
};

export const loginFormSlice = createSlice({
  name: 'loginForm',
  initialState,
  reducers: {
    setSiteIndexLoginForm: (state, action) => {
      window.location.hash = action.payload;
      state.siteIndex = action.payload;

      switch (state.siteIndex) {
        case LOGINFORM_SITEINDEX_SUCCESSTOKEN:
          state.headline = 'SMS-Code';
          break;
        case LOGINFORM_SITEINDEX_LOGINSUCCESS:
          state.headline = 'Login erfolgreich';
          break;
        case LOGINFORM_SITEINDEX_PWFORGET:
          state.headline = 'Passwortvergabe';
          break;
        default:
          state.headline = 'Login';
          break;
      }
    },
    setPageDialogOpenLoginForm: (state, action) => {
      if (action.payload === true) {
        window.location.hash = state.siteIndex;
      } else {
        removeHashFromUrl();
      }

      state.pageDialogOpen = action.payload;
    },
    setEmailLoginForm: (state, action) => {
      state.requestBody.email = action.payload;
    },
    setPasswordLoginForm: (state, action) => {
      state.requestBody.password = action.payload;
    },
    setRememberMe: (state, action) => {
      state.requestBody.rememberMe = action.payload;
    },
    setPhoneNumberLoginForm: (state, action) => {
      state.phoneNumber = action.payload;
    },
    setTokenLoginForm: (state, action) => {
      state.token = action.payload;
    },
    setVerificationCode: (state, action) => {
      state.verificationCode = action.payload;
    },
    setResetFieldsLoginForm: (state) => {
      return initialState;
    },
  },
});

export const {
  setSiteIndexLoginForm,
  setPageDialogOpenLoginForm,
  setEmailLoginForm,
  setPasswordLoginForm,
  setRememberMe,
  setPhoneNumberLoginForm,
  setTokenLoginForm,
  setVerificationCode,
  setResetFieldsLoginForm,
} = loginFormSlice.actions;

export default loginFormSlice.reducer;
