import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFavorite, getFavorites, getRealtyDetails, setFavorite } from '../../api/JustImmo';
import { fetchErrorText } from '../../components/FormFields/util/formTexts';
import { MENU_KAUF } from '../../constants';
import { setFormIsLoading } from '../../reducers/app';
import { setUserFavourites } from '../../reducers/user';
import { parseImmo } from '../../util/immoObjectParser';

export const useHandleFavorite = () => {
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [favsLoading, setFavsLoading] = useState(true);
  const [deleteFavError, setDeleteFavError] = useState(false);
	const [hideUnfavDialog, setHideUnfavDialog] = useState(false);

	const [fullFavList, setFullFavList] = useState([]);
  /**
   * add immo to favourites
   * @param {Object} immo
   * @returns
   */
  const addToFavourites = async (immo) => {
    if (app.formIsLoading) return;

    // id in favourites always as String
    const immoId = immo.id?.toString();

    let favFound = user.favourites.find((f) => f.id?.toString() === immoId);

    if (!favFound) {
      try {
        dispatch(setFormIsLoading(true));
        await setFavorite(immo.id, immo.tenant_id);

				const basicImmoToSaveInStore = { 
					catalogue_number: immo.catalogue_number,
					address: immo.address,
					rooms: immo.rooms,
					title: immo.title,
					url: immo.url,
					tenant_id: immo.tenant_id,
					id: immo.id.toString() 
				};

        dispatch(setUserFavourites([...user.favourites, basicImmoToSaveInStore]));
        dispatch(setFormIsLoading(false));
      } catch (e) {
        dispatch(setFormIsLoading(false));
        // handle favorite error
        console.error('e', e);
      }
    }
  };

	const getFavIdFromFetch = async (immoId) => {
		if (!immoId) return null;

		try {
			dispatch(setFormIsLoading(true));
			const favorites = await getFavorites();			
			return favorites?.find((f) => f.realtyId?.toString() === immoId)?.id || null;

		} catch (error) {
			console.log('error while fetching favorites', error);
			return null;
		}
		finally {
			dispatch(setFormIsLoading(false));
		}
	}

  /**
   * delete immo to favourites
   * @param {Object} immo
   */
  const unfavorite = async (immo) => {
    setDeleteFavError('');

		if (!user.favourites?.length || !immo) {
			setDeleteFavError(fetchErrorText);
			return;
		}

    const immoId = immo.id?.toString();
		let favId = user.favourites.find((fav) => fav.id === immoId)?.favId;
		if (!favId) {
			favId = await getFavIdFromFetch(immoId);
		}

		if(!favId) {
			setDeleteFavError(fetchErrorText);
			return;
		}
		
		dispatch(setFormIsLoading(true));
		try {
			await deleteFavorite(favId);
			
			dispatch(setUserFavourites(user.favourites.filter((f) => f.id !== immoId)));
			setHideUnfavDialog(true);
		} catch (error) {
			setDeleteFavError(fetchErrorText);
		}
		finally {
			dispatch(setFormIsLoading(false));
		}
  }

	const reloadFavsWithRealtyDetails = async () => {
		if(app.demoMode) {
			setFavsLoading(false);	
			return;
		}

		if (!user.isLoggedIn || app.menuType !== MENU_KAUF) return;
		setFavsLoading(true);

		try {
			const favorites = await getFavorites();
			const favoritePromisesToAdd = favorites
        .map((fav) => {
          let realtyId = fav.realtyId?.toString();
          let tenantId = fav.tenantId?.toString();
          if (realtyId && tenantId) {
						return getRealtyDetails(realtyId, tenantId);
          }
        })
        .filter((promise) => promise !== undefined);

				
			if (!favoritePromisesToAdd) return;
			
			const completePromises = await Promise.all(favoritePromisesToAdd.map((p) => p.catch((e) => e)));
			const validResults = completePromises.filter((result) => !(result instanceof Error)).map((immo) => parseImmo(immo));
			setFavsLoading(false);

			if (validResults) {
				// remove invalid immos and remove duplicates
        const allFavs = validResults
          .filter((f) => f.url !== undefined)
          .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));

				setFullFavList(allFavs);
			}
		} catch (e) {
			console.log('e', e);
      setFavsLoading(false);
		}

	}

  /**
   * load favorites and save to store (if not already saved)
   * @returns
   */
  const reloadBasicFavList = async () => {
    if (!user.isLoggedIn || app.menuType !== MENU_KAUF || app.demoMode) return;

    setFavsLoading(true);
    try {
      const favorites = await getFavorites();

			const favIds = [];
      const favoritePromisesToAdd = favorites
        .map((fav) => {
					const favId = fav.id;
          let realtyId = fav.realtyId?.toString();
					favIds.push({realtyId: realtyId, favId: favId})

          let tenantId = fav.tenantId?.toString();
          if (realtyId && tenantId && user.favourites) {
            const userFavsIds = user.favourites.map((immo) => immo.id);
            const favFound = userFavsIds.includes(realtyId);
            if (!favFound) {
              return getRealtyDetails(realtyId, tenantId);
            }
          }
        })
        .filter((promise) => promise !== undefined);
      setFavsLoading(false);

      if (!favoritePromisesToAdd) return;

      const completePromises = await Promise.all(favoritePromisesToAdd.map((p) => p.catch((e) => e)));
      const validResults = completePromises.filter((result) => !(result instanceof Error)).map((immo) => parseImmo(immo));

      if (validResults) {
        let allFavs = [...user.favourites, ...validResults];

        // remove invalid immos and remove duplicates
        let favsToSaveInStore = allFavs
          .filter((f) => f.url !== undefined)
          .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));

				favsToSaveInStore = favsToSaveInStore.map((f) => {
					const immoId = f.id.toString();
					const favId =  favIds?.find((item) => item.realtyId === immoId)?.favId || null;

					return {
						catalogue_number: f.catalogue_number,
						address: f.address,
						rooms: f.rooms,
						title: f.title,
						url: f.url,
						tenant_id: f.tenant_id,
						id: immoId,
						favId: favId
					};
				});

        dispatch(setUserFavourites(favsToSaveInStore));
      }
    } catch (e) {
      console.log('e', e);
      setFavsLoading(false);
    }
  };

  return {
    addToFavourites,
		reloadFavsWithRealtyDetails,
    reloadBasicFavList,
    favsLoading,
    unfavorite,
    deleteFavError,
		fullFavList,
		hideUnfavDialog
  };
};
