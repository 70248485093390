
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import FormInput from '../../FormFields/FormInput';
import FormSelect from '../../FormFields/FormSelect';

import { exteriorSurfacesOptionsJustImmo, immobilienartOptionsJustImmo } from '../../FormFields/util/formOptions';


// STORE
import { JUSTIMMO_IMMOBILIENART_HAUS, JUSTIMMO_IMMOBILIENART_WOHNUNG } from '../../../constants';
import LoadingSpinner from '../../LoadingSpinner';


import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { autocomplete } from '../../../api/JustImmo';
import FormAutocomplete from '../../FormFields/FormAutocomplete';
import statesWithZips from '../../FormFields/util/statesWithZips';

function SearchData({ formType, id, searchProfileToEditId = null, isEditForm = false, nextStep, submit, error = '' }) {
	const { control, watch, handleSubmit, getValues, resetField, formState: { errors } } = useFormContext();
	const estateType = watch('estateType');

  document.addEventListener('wheel', function (event) {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  });

	const validateSPName = (name) => {
		if(name?.length > 25) {
			return 'Bitte geben Sie maximal 25 Zeichen an.';
		}
		return true; // Validation passed
	};

	/* AUTOCOMPLETE ADDRESS */
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);
  const autocompleteDistrict = useCallback(async (query) => {
    if (typeof query !== 'string') return;

    if (!query || query.length < 2) {
      setOptions([]); // Clear options if input is too short
      return;
    }

    setLoading(true);
    try {
			let results = await autocomplete(query);

			const selectedDistrict = getValues('district') || [];
			results = results.map((r) => {
        return {
          ...r,
          value: parseInt(r.value),
        };
      })
			.filter((location) => {
				// Filter out selected options
        return !selectedDistrict.some((selectedOption) => selectedOption.value === location.value);
      });

      // check if locations has options that are NOT disabled
      const hasOptions = results.find((location) => !location.disabled);
      if (!hasOptions) results = [];

      setOptions(results);
    } catch (error) {
      console.error('Error fetching autocomplete data:', error);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  }, []);

  const [typedDistrict, setTypedDistrict] = useState('');
  useEffect(() => {
    if (typedDistrict) {
      autocompleteDistrict(typedDistrict);
    }
  }, [typedDistrict, autocompleteDistrict]);

  /* AUTOCOMPLETE ADDRESS END */

  return (
    <>
      { !isEditForm && (
        <>
          <h2>Suchprofil</h2>
          <p>Erstellen Sie jetzt ein Suchprofil, melden Sie sich für die s REAL Vormerkung an und finden Sie Ihre Wunschimmobilie!</p>
        </>
      )}

      <h3 id={formType + '-errorname'} className="form__title pt-100rem">Suchprofil benennen</h3>
			<Controller
				name="name"
				control={control}
				rules={{
					required: "Bitte geben Sie einen Namen an." ,
					validate: (value) => validateSPName(value)
				}}
				render={({ field }) => (
					<FormInput
						id="name"
						label={'Name'}
						value={field.value} 
						required={true}
						error={errors.name?.message}
						callback={field.onChange}
						placeholder={'Suchprofil 1'}
					/>
				)}
			/>


			<p className="font-80 m-0">Maximal 25 Zeichen. Sie können den Namen später unter Einstellungen ändern.</p>

      <h3 className="form__title pt-100rem">Suchprofildaten</h3>
			<Controller
				name="state"
				control={control}
				rules={{ required: "Bitte wählen Sie ein Bundesland aus." }}
				render={({ field }) => (
					<FormSelect
						id="state"
						label={'Bundesland'}
						value={field.value}
						callback={field.onChange}
						error={errors.state?.message}
						options={statesWithZips.map((state) => {
							return {
								id: state.justImmoId,
								text: state.label,
							};
						})}
						required={true}
					/>
				)}
			/>

			<Controller
				name="district"
				control={control}
				defaultValue={[]}
				render={({ field }) => (
					<FormAutocomplete
						id="district"
						label="Bezirk/PLZ"
						loading={loading}
						placeholder={'Bezirk eingeben'}
						options={options}
						callback={field.onChange}
						callbackInput={setTypedDistrict}
						value={field.value}
						multiple={true}
					/>
				)}
			/>

			<Controller
				name="estateType"
				control={control}
				rules={{ required: "Bitte wählen Sie die Immobilienart aus." }}
				render={({ field }) => (
					<FormSelect
						id="estateType"
						label={'Immobilienart'}
						value={field.value}
						callback={field.onChange}
						error={errors.estateType?.message}
						options={immobilienartOptionsJustImmo}
						required={true}
					/>
				)}
			/>

			<Controller
				name="priceFrom"
				control={control}
				render={({ field }) => (
					<FormInput id={'priceFrom'} label={'Preis von'} placeholder={'Wert eingeben'} value={field.value} callback={field.onChange} type="number" minValue={0} maxValue={watch('priceTo')} />
				)}
			/>

			<Controller
				name="priceTo"
				control={control}
				render={({ field }) => (
					<FormInput id={'priceTo'} label={'Preis bis'} placeholder={'Wert eingeben'} value={field.value} callback={field.onChange} type="number" minValue={watch('priceFrom') || 0} />
				)}
			/>

			<Controller
				name="areaFrom"
				control={control}
				render={({ field }) => (
					<FormInput
						id={'areaFrom'}
						label={'Fläche von (in m²)'}
						placeholder="Wert eingeben"
						value={field.value}
						callback={field.onChange}
						type="number"
						minValue={0}
						maxValue={watch('areaTo')}
					/>
				)}
			/>

			<Controller
				name="areaTo"
				control={control}
				render={({ field }) => (
					<FormInput
						id={'areaTo'}
						label={'Fläche bis (in m²)'}
						placeholder="Wert eingeben"
						value={field.value}
						callback={field.onChange}
						type="number"
						minValue={watch('areaFrom') || 0}
					/>
				)}
			/>

			{ estateType?.id === JUSTIMMO_IMMOBILIENART_HAUS || estateType?.id === JUSTIMMO_IMMOBILIENART_WOHNUNG ? (
				<>
					<Controller
						name="roomsFrom"
						control={control}
						render={({ field }) => (
							<FormInput id={'roomsFrom'}label={'Anzahl Räume (mindestens)'} placeholder={'Wert eingeben'} value={field.value} callback={field.onChange} type="number" minValue={0} />
						)}
					/>

					<Controller
						name="exteriorSurface"
						control={control}
						render={({ field }) => (
							<FormSelect
								id={'exteriorSurface'}
								label={'Außenflächen'}
								value={field.value || []}
								options={exteriorSurfacesOptionsJustImmo}
								callback={field.onChange}
								multiple={true}
							/>
						)}
					/>
				</>
			) : null}

      <LoadingSpinner />

      <div className="button-panel">
				{ isEditForm ? (
					<ButtonForwardAction forwardAction={submit} buttonText='Speichern' buttonStyle={'blue'} />
				) : (
					<ButtonForwardAction forwardAction={handleSubmit(() =>  nextStep())} buttonText='Weiter' buttonStyle={'blue'} />
				)}

        { error && <p id={formType + '-error'} className="form__fielderror">{error}</p> }
      </div>
    </>
  );
}

export default SearchData;
