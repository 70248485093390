import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import headerImageMobile from '../assets/images/mysreal_HerotemplateMobil_593x950px_Neu2024-_0004_s-REAL-Sujets-2023-Webhero-16zu9-1.13.jpg';
import headerImage from '../assets/images/sREAL_Sujets_2023_Webhero_16zu9_1.2.jpg';
import Aktuelles from '../components/Aktuelles/Aktuelles';
import ImmoCard from '../components/Cards/ImmoCard';
import SearchProfileWithouthImmos from '../components/Cards/SearchProfileWithouthImmos';
import VorteilsweltCards from '../components/Cards/VorteilsweltCards';
import CarouselNew from '../components/Carousel/CarouselNew';
import DashboardHeaderFinalStats from '../components/Dashboard/DashboardHeaderFinalStats';
import InfoDialogGeldfluss from '../components/Dialogs/InfoDialogGeldfluss';
import InfoDialogKaufvertrag from '../components/Dialogs/InfoDialogKaufvertrag';
import FilterMenu from '../components/FilterMenu';
import Header from '../components/Header/Header';
import RecommendationLinkitem from '../components/Linkitems/RecommondationLinkitem';
import LinkToSearchProfiles from '../components/LinkToSearchProfiles';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import NewsTeaserCards from '../components/News/NewsTeaserCards';

// ICONS
import IconBarchartWithArrow from '../components/Icons/IconBarchartWithArrow';
import IconDocument from '../components/Icons/IconDocument';
import IconGutachtenHakerl from '../components/Icons/IconGutachtenHakerl';
import IconSearch from '../components/Icons/IconSearch';

import ImmoCardAbgeber from '../components/Cards/ImmoCardAbgeber';
import { MENU_KAUF, MENU_VERKAUF } from '../constants';

import LinkCardToAlleAngebote from '../components/Cards/LinkCardToAlleAngebote';
import MarktberichtCard from '../components/Cards/MarktberichtCard';
import HeaderSimilarImmos from '../components/Header/HeaderSimilarImmos';
import { abgeberimmo_phase3_parsed } from '../demodata/demo_abgeberimmo';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { useHandleMessages } from '../hooks/messages/useHandleMessages';
import { useFetchSearchprofiles } from '../hooks/searchprofiles/useFetchSearchprofiles';
import { useHandleUserRealtyActive } from '../hooks/user/useHandleUserRealtyActive';

function DashboardPhase3() {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

  const [openInfoDialogKV, setOpenInfoDialogKV] = useState(false);
  const handleOpenInfoDialogKV = () => {
    let newVal = !openInfoDialogKV;
    setOpenInfoDialogKV(newVal);
  };

  const { files, getKaeuferImmoPhase3Or4 } = useHandleUserRealtyActive();
  const { loadMessages } = useHandleMessages();

  const [immoCardAbgeberRealty, setImmoCardAbgeberRealty] = useState(null);

  const { isMobile } = useHandleScreenResize();

  const { getSearchprofilesMenu } = useFetchSearchprofiles();
  const [suchprofileMenu, setSuchprofileMenu] = useState(null);
  

  useEffect(() => {
    setSuchprofileMenuState();
  }, [user]);

  const setSuchprofileMenuState = () => {
    setSuchprofileMenu(getSearchprofilesMenu());
  };

  const [activeSuchprofileIndex, setActiveSuchprofileIndex] = useState(0);
  const handleSetActiveSuchprofileIndex = (event) => {
    let index = parseInt(event.target.dataset.index);
    setActiveSuchprofileIndex(index);
  };

 

  const [openInfoDialog, setOpenInfoDialog] = useState(false);

  const handleOpenInfoDialog = () => {
    let newVal = !openInfoDialog;
    setOpenInfoDialog(newVal);
  };

  const navigate = useNavigate();

  useEffect(() => {
		if(app.demoMode) {
			setImmoCardAbgeberRealty(abgeberimmo_phase3_parsed);
		}
    else if (app.menuType === MENU_VERKAUF && user.userRealtyActive?.id > 0) {
      setImmoCardAbgeberRealty(user.userRealtyActive);
    } else if (app.menuType === MENU_KAUF) {
      let kaeuferPhase3Immo = getKaeuferImmoPhase3Or4();
      if (kaeuferPhase3Immo && kaeuferPhase3Immo.id) {
        setImmoCardAbgeberRealty(kaeuferPhase3Immo);
      }
    }
  }, [app.menuId, app.menuType]);

  useEffect(() => {
    loadMessages();
  }, []);

  return (
    <>
      <MetaTags title={'Kaufvertrag | Die Online-Plattform für Ihre Immobilie'} imageName={'mysreal_hero_dashboard-kauf.jpg'} />

      {app.menuType === MENU_KAUF ? (
        <>
          <Header cssClasses="header--default header--fitcontent header-overflowhidden">
            <img src={!isMobile ? headerImage : headerImageMobile} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
            <div className="header__content-wrapper" id="header__suchprofile">
              <div className="header__suchprofile--top">
                <h1 className="text-white">Meine Angebote</h1>
                <div className="header__suchprofile--topfiltermenu mobile-hidden">
                  {suchprofileMenu && (
                    <FilterMenu
                      categories={suchprofileMenu}
                      activeIndex={activeSuchprofileIndex}
                      handleClick={handleSetActiveSuchprofileIndex}
                      cssClasses="filtermenu--inverted"
                      cutside={isMobile ? 'none' : 'right'}
                    />
                  )}
                  <button className="button button--white position-relative button--round" style={{ padding: '0.6rem' }} onClick={() => navigate('../immobiliensuche')}>
                    <IconSearch color="#2870ED" cssClasses={'position-center'} />
                  </button>
                </div>

                <div className="header__suchprofile--topfiltermenu desktop-hidden">
                  <button className="button button--white position-relative button--round" style={{ padding: '0.6rem' }} onClick={() => navigate('../immobiliensuche')}>
                    <IconSearch color="#2870ED" cssClasses={'position-center'} />
                  </button>
                </div>
                <div className="header__suchprofile--topfiltermenu desktop-hidden">
                  {suchprofileMenu && (
                    <FilterMenu
                      categories={suchprofileMenu}
                      activeIndex={activeSuchprofileIndex}
                      handleClick={handleSetActiveSuchprofileIndex}
                      cssClasses="filtermenu--inverted"
                      cutside={isMobile ? 'none' : 'right'}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="header__content-wrapper mt-0" id="header__suchprofile">
              <LinkToSearchProfiles cssClasses="w-100 textlink text-white text-nodecoration text-center hover-underline" linkText={'Suchprofile bearbeiten'} />
            </div>
            {suchprofileMenu &&
              suchprofileMenu.map((sp, index) => {
                let spMenuClass = 'header__suchprofile--immos';
                if (sp.key !== activeSuchprofileIndex) spMenuClass += ' is-hidden';

                let immos = sp.immos?.filter((immo) => immo.id);
                return (
                  <div key={index}>
                    {immos.length > 0 ? (
                      <CarouselNew cssClasses={spMenuClass} isMobile={isMobile}>
                        {immos.map((immo) => {
                          let status = '';
                          if (immo.activities.some((activity) => activity.id === '1591')) {
                            status = 'aktiv';
                          } else if (immo.activities.some((activity) => activity.id === '1594')) {
                            status = 'abgeschlossen';
                          }
                          return <ImmoCard retailObject={immo} key={immo.id} status={status} />;
                        })}
                        <LinkCardToAlleAngebote />
                      </CarouselNew>
                    ) : (
                      <CarouselNew cssClasses={spMenuClass} isMobile={isMobile}>
                        <SearchProfileWithouthImmos cssClasses={spMenuClass} />
                      </CarouselNew>
                    )}
                  </div>
                );
              })}
          </Header>

					<HeaderSimilarImmos />
        </>
      ) : (
        <DashboardHeaderFinalStats
          stats={{
            clicks: null,
            offers: null,
            sightings: null,
          }}
        />
      )}

      <MainContent>
        <div className="gray-background mt-0">
					<Aktuelles />
        </div>
        <div className={app.menuType === MENU_KAUF ? 'light-aubergine-background mt-0' : 'light-blue-background mt-0'}>
          <div className="w-desktop--center">
            {immoCardAbgeberRealty && (
              <div className="pt-200rem pb-200rem">
                {app.menuType === MENU_KAUF ? (
                  <h2 className="mt-0 mb-300rem text-white text-center--mobile">Mein Immobilienkauf</h2>
                ) : (
                  <h2 className="mt-0 mb-300rem text-white text-center--mobile">Mein Immobilienverkauf</h2>
                )}

                <ImmoCardAbgeber retailObject={immoCardAbgeberRealty} key={immoCardAbgeberRealty.id} />
              </div>
            )}

            <div className="cardgrid">
              <VorteilsweltCards
                color="background-aubergine"
                headline="Infos zum Geldfluss"
                text="Bis Immobilie und Geld die Besitzer:innen wechseln, sind einige Schritte nötig."
                icon={<IconBarchartWithArrow color="#fff" />}
              >
                <button className="button button--blue" onClick={handleOpenInfoDialog}>
                  Details anzeigen
                </button>
              </VorteilsweltCards>
              <VorteilsweltCards
                color="background-teal"
                headline="Kaufvertrag"
                text="Informieren Sie sich, was Sie bei der Abwicklung des Kaufvertrags erwartet."
                icon={<IconGutachtenHakerl color="#fff" />}
              >
                <button className="button button--blue" onClick={handleOpenInfoDialogKV}>
                  Kaufvertrags-Infos anzeigen
                </button>
              </VorteilsweltCards>
              <VorteilsweltCards
                color="background-blue"
                headline="Immo-drive"
                text={files.length > 0 ? 'Sie haben ' + files.length + ' neue Dokumente' : 'Es wurden noch keine Dokumente bereitgestellt.'}
                icon={<IconDocument color="#fff" />}
              >
                <button onClick={() => navigate(app.root + '/immo-drive')} className="button button--blue">
                  Details anzeigen
                </button>
              </VorteilsweltCards>
            </div>
          </div>
        </div>
        {app.menuType === MENU_VERKAUF && (
          <div className="w-desktop--center">
            <h2 className="mt-0">Meine Immobilie</h2>
            <MarktberichtCard />
          </div>
        )}
        <div className="w-desktop--center">
          {!user.recommendationSurveyCompleted ? <RecommendationLinkitem cssClasses="mb-0" /> : null}
        </div>

        <NewsTeaserCards cssClasses="w-desktop--slider mt-400rem" />

        <InfoDialogKaufvertrag open={openInfoDialogKV} handleClose={handleOpenInfoDialogKV} />
        <InfoDialogGeldfluss open={openInfoDialog} handleClose={handleOpenInfoDialog} />
      </MainContent>
    </>
  );
}

export default DashboardPhase3;
