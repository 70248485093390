import { useState, useEffect, useCallback } from 'react';
import { useHandleSimilarImmos } from '../../hooks/immo/useHandleSimilarImmos';

import { parseImmoSreal } from '../../util/immoObjectParser';
import ImmoCardSmall from '../Cards/ImmoCardSmall';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import { useSelector } from 'react-redux';

export const ImmoSimilarImmosSection = () => {
  const cachedSimilarImmos = useSelector((state) => state.cachedSimilarImmos);
  const app = useSelector((state) => state.app);

  const [isLoading, setIsLoading] = useState(true);
  const { getSimilarImmos } = useHandleSimilarImmos();
  const { isMobile } = useHandleScreenResize();

  // Use useCallback to memoize the function
  const fetchSimilarImmos = useCallback(async () => {
    if (app.demoMode) return;
    setIsLoading(true);

    try {
      await getSimilarImmos();
    } catch (error) {
      console.error('Error fetching similar immos:', error);
    } finally {
      setIsLoading(false);
    }
  }, [getSimilarImmos]);

  useEffect(() => {
    fetchSimilarImmos();
  }, [fetchSimilarImmos]); // Now properly memoized

  if (cachedSimilarImmos.cachedSimilarImmos?.length === 0) {
    return null;
  }

  return (
    <div className={`gray-background ${!isMobile ? 'mb--700rem' : ''}`}>
      <div className="w-desktop--center">
        <h2 className="mt-0">Weitere Empfehlungen für Sie</h2>
        { (cachedSimilarImmos.cachedSimilarImmos?.length > 0 && !app.demoMode) && <span>Wir haben Ihre Suchkriterien erweitert. Diese Immobilien könnten Ihnen auch gefallen:</span> }

        {app.demoMode ? (
          <p className="mt-100rem">Keine ähnlichen Immobilien gefunden.</p>
        ) : (
          <>
            {isLoading ? (
              <div>
                <span className="loading-animation">
                  Ähnliche Immobilien werden geladen
                  <span>.</span>
                  <span>.</span>
                  <span>.</span>
                </span>
              </div>
            ) : cachedSimilarImmos.cachedSimilarImmos?.length > 0 ? (
              <div className="cardgrid--4cols">
                {cachedSimilarImmos.cachedSimilarImmos.map((immo) => {
                  return <ImmoCardSmall retailObject={immo} key={immo.id} />;
                })}
              </div>
            ) : (
              <p className="mt-100rem">Keine ähnlichen Immobilien gefunden.</p>
            )}
          </>
        )}
      </div>
    </div>
  );
};
