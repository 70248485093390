import { useDispatch, useSelector } from 'react-redux';
import IconThumbUpDown from '../Icons/IconThumbUpDown';
import Card from './Card';

import { PAGEDIALOG_SATISFACTION } from '../../constants';
import { setPageDialogOpenId } from '../../reducers/app';
import { useEffect, useState } from 'react';

function SatisfactionCard({ cssClasses }) {
  const dispatch = useDispatch();
	const user = useSelector((state) => state.user);

	const [satisfactionSurveyCompleted, setSatisfactionSurveyCompleted] = useState(false);
	useEffect(() => {
    if (user.satisfactionSurveyCompleted) {
      setSatisfactionSurveyCompleted(user.satisfactionSurveyCompleted);
    }
  }, [user]);


	if(satisfactionSurveyCompleted) {
		return null;
	}
  return (
    <>
      <Card cssClasses={cssClasses + ' background-pink'} cardStyle={'customContent'}>
        <div className="card__content card__content--background">
          <div className="mb-10 mt-10">
            <IconThumbUpDown color="#fff" scale={2} />
          </div>
          <h4 className="mb-100rem text-white">Zufrieden mit s REAL?</h4>
          <p className="font-90 text-white">Wie war Ihre Erfahrung mit my-sreal at? Wir freuen uns über Ihr Feedback.</p>

					<div className="button-panel mb-0">
						<button className="button button--white-outline mb-0" onClick={() => dispatch(setPageDialogOpenId(PAGEDIALOG_SATISFACTION))}>
							Feedback geben
						</button>
					</div>
        </div>
      </Card>
    </>
  );
}
export default SatisfactionCard;
