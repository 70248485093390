import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { PAGEDIALOG_SUCHPROFILLIST } from '../constants';
import { setPageDialogOpenId } from '../reducers/app';

function LinkToSearchProfiles({ linkText, cssClasses }) {
  const dispatch = useDispatch();
	
  return (
    <Link
      onClick={() => dispatch(setPageDialogOpenId(PAGEDIALOG_SUCHPROFILLIST))}
      className={'textlink ' + cssClasses}
      title={linkText}
    >
      {linkText}
    </Link>
  );
}

export default LinkToSearchProfiles;
