import { useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

function ButtonForwardAction({
  formType = '',
  buttonText = '',
  forwardAction,
  buttonStyle = 'orange',
  preventEnter = false,
  cssClasses = '',
  disabled = false
}) {
  const app = useSelector((state) => state.app);

  // Build CSS classes using useMemo to avoid recalculating on every render
  const buttonClasses = useMemo(() => {
    const classes = ['button'];
    
    // Add custom classes
    if (cssClasses) {
      classes.push(cssClasses);
    }
    
    // Add style-specific class
    const styleClasses = {
      'orange': 'button--orange',
      'blue': 'button--blue',
      'blue-outline': 'button--gray-outline',
      'white': 'button--white',
      'white-outline': 'button--white-outline',
      'textlink': 'textlink'
    };
    
    if (buttonStyle === 'textlink') {
      // For textlink, replace the base classes
      return 'textlink';
    } else if (styleClasses[buttonStyle]) {
      classes.push(styleClasses[buttonStyle]);
    }
    
    // Add loading state
    if (app.formIsLoading) {
      classes.push('button--loading');
    }
    
    return classes.join(' ');
  }, [buttonStyle, cssClasses, app.formIsLoading]);

  // Handle Enter key press with useCallback to maintain reference stability
  const submitOnEnter = useCallback((event) => {
    if (preventEnter || document.activeElement.type === 'textarea') return;
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
			forwardAction();
    }
  }, [preventEnter, formType, forwardAction]);

  useEffect(() => {
    window.addEventListener('keydown', submitOnEnter);
    return () => window.removeEventListener('keydown', submitOnEnter);
    // Include all dependencies
  }, [submitOnEnter]);

  return (
    <button 
      className={buttonClasses} 
      onClick={forwardAction} 
      disabled={disabled || app.formIsLoading}
      type="button"
    >
      {buttonText}
    </button>
  );
}

export default ButtonForwardAction;