
import ButtonForwardAction from '../Buttons/ButtonForwardAction.js';
import FormSelect from '../FormFields/FormSelect.jsx';

import { Controller, useFormContext } from 'react-hook-form';
import { federalStatesJustImmo, immobilienartOptionsSearchForm, usageTypesJustImmo } from '../FormFields/util/formOptions.js';

function NeubauprojekteSucheForm({ submit }) {
	
	const { control } = useFormContext();
	
	return (
		<div className="searchForm">
			<Controller
				name="state"
				control={control}
				render={({ field }) => (
					<FormSelect
						id={'state'}
						label={'Bundesland'}
						placeholder="Bundesland wählen"
						value={field.value || []}
						options={federalStatesJustImmo}
						callback={field.onChange}
						multiple={true}
					/>
				)}
			/>

			<Controller
				name="usageType"
				control={control}
				render={({ field }) => (
					<FormSelect
						id={'usageType'}
						label={'Nutzungsart'}
						placeholder="Nutzungsart wählen"
						value={field.value || []}
						showBitteWaehlen={false}
						options={usageTypesJustImmo}
						callback={field.onChange}
					/>
				)}
			/>

			<Controller
				name="estateType"
				control={control}
				render={({ field }) => (
					<FormSelect
						id={'estateType'}
						label={'Objektart'}
						placeholder="Objektart wählen"
						value={field.value || []}
						options={immobilienartOptionsSearchForm.filter((o) => o.id === 'house' || o.id === 'flat')}
						callback={field.onChange}
					/>
				)}
			/>

			<div className="searchForm__buttons">
				<ButtonForwardAction forwardAction={submit} buttonText={'Neubauprojekt suchen'} />
			</div>
		</div>
	)

}

export default NeubauprojekteSucheForm;