import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageDialog from './PageDialog';


import { FormProvider, useForm } from 'react-hook-form';
import { createInquiry } from '../../api/Contact';
import {
	OBJECTREQUESTFORM_SITEINDEX_START,
	OBJECTREQUESTFORM_SITEINDEX_SUCCESS,
	OBJECTREQUESTFORM_SITEINDEX_WIDERRUF,
	PAGEDIALOG_OBJEKTANFRAGE
} from '../../constants';
import { useHandleFavorite } from '../../hooks/immo/useHandleFavorite';
import { closePageDialog, setFormIsLoading, setPageDialogOpenId } from '../../reducers/app';
import { fetchErrorText } from '../FormFields/util/formTexts';
import ObjectRequestMain from '../Forms/ObjectRequest/ObjectRequestMain';
import ObjectRequestSent from '../Forms/ObjectRequest/ObjectRequestSent';
import ObjectRequestWiderruf from '../Forms/ObjectRequest/ObjectRequestWiderruf';
import { phoneNumberPrefixOptions } from '../FormFields/util/formOptions';
import { checkIfHashIsPagedialog, findPhonePrefix } from '../../util/generalUtils';

import { setResetFieldsObjectRequest} from '../../reducers/objectRequest.js';

function PageDialogObjectRequestForm() {
	const methods = useForm(); // Provides form state & methods
	const { setValue } = methods;

	const app = useSelector((state) => state.app);
	const user = useSelector((state) => state.user);
	const objectRequest = useSelector((state) => state.objectRequest);

	const { addToFavourites } = useHandleFavorite();

	const dispatch = useDispatch();

	const [error, setError] = useState('');
	const [step, setStep] = useState(OBJECTREQUESTFORM_SITEINDEX_START);

	const austrianPrefix = phoneNumberPrefixOptions.find((option) => option.id === 43);
	const initialFormState = useMemo(() => {
		let phoneNum = '';
		let phonePrefix = austrianPrefix;
		if(user.userObject.phoneNumber) {
			phoneNum = user.userObject.phoneNumber;
			const foundPrefix = findPhonePrefix(phoneNum);
			
			if(foundPrefix) {
				phonePrefix = foundPrefix;
				const prefixLength = (foundPrefix.id).toString().length + 1; // + 1 for '+' character
        phoneNum = user.userObject.phoneNumber.substring(prefixLength);
			}
		}

		return {
			realtyId: objectRequest?.immo?.id ? objectRequest.immo.id : -1,
			tenantId: objectRequest?.immo?.tenant_id ? objectRequest.immo.tenant_id : -1,
			gender: user.userObject?.gender || '',
			titleBefore: user.userObject?.titleBefore || '',
			titleAfter: user.userObject?.titleAfter || '',
			firstName: user.userObject?.firstName || '',
			lastName: user.userObject?.lastName || '',
			email: user.userObject?.userName || '',
			phoneNumberPrefix: phonePrefix,
			phoneNumber: phoneNum,
			message: `Liebes s REAL-Team, ich möchte ein Exposé zur Immobilie ${objectRequest?.immo?.catalogue_number ? objectRequest.immo.catalogue_number : ''} anfragen.`,
			newsletterConfirmed: false,
			immo: null
		};
	}, []);	

	/**
	 * SET WINDOW HASH
	 * window hash changes when step changes
	 * used for tracking
	 */
	const setWindowHash = useCallback(() => {
    if (app.pageDialogOpenId !== PAGEDIALOG_OBJEKTANFRAGE) return;
		
		window.location.hash = step.toString();;
  }, [app.pageDialogOpenId, step]);

	useEffect(() => {
    setWindowHash();
  }, [step, app.pageDialogOpenId, setWindowHash]);

	useEffect(() => {
		if(app.pageDialogOpenId === '' && checkIfHashIsPagedialog(OBJECTREQUESTFORM_SITEINDEX_START)) {		
			dispatch(setPageDialogOpenId(PAGEDIALOG_OBJEKTANFRAGE));
		}
	}, [window.location.hash]);

	/**
	 * RESET FORM
	 * clear all form data and set step to 1
	 */
	const resetForm = useCallback(() => {
    setStep(OBJECTREQUESTFORM_SITEINDEX_START);
    methods.reset(initialFormState);
		setError('');
  }, [methods, initialFormState]);
	/* RESET FORM END */


	/**
	 * HANDLE CLOSE
	 * closes Pagedialog, resets formIsLoading state to false and resets form
	 */
  const handleClose = useCallback(() => {
    if (app.formIsLoading) return;

    dispatch(closePageDialog());
		dispatch(setResetFieldsObjectRequest());
    resetForm();
  }, [app.formIsLoading, dispatch, resetForm]);

	// reset form when opened and set immo data
	useEffect(() => {
		if(app.pageDialogOpenId === PAGEDIALOG_OBJEKTANFRAGE) {
			resetForm();

			if(objectRequest.immo?.id) {
				setValue('immo', objectRequest.immo);
        setValue('message', `Liebes s REAL-Team, ich möchte ein Exposé zur Immobilie ${objectRequest.immo.catalogue_number} anfragen.`);
			}
		}
	}, [app.pageDialogOpenId, resetForm, setValue, objectRequest.immo])
	/* HANDLE CLOSE END */

	/**
	 * HANDLE SUBMIT
	 * send data to BE
	 */
  const onSubmit = useCallback(async (data) => {
		dispatch(setFormIsLoading(true));
		setError('');

		let requestBody = {
			...data,
			phoneNumber: (data.phoneNumberPrefix?.id ? '+' + data.phoneNumberPrefix.id : '') + (data.phoneNumber || '').replace(/\s+/g, ''),
			cancellationNoticeConfirmed: true,
			realtyId: data.immo.id,
			tenantId: data.immo.tenant_id
		};
		delete(requestBody.phoneNumberPrefix);
		delete(requestBody.immo);

		try {
			await createInquiry(requestBody);
			dispatch(setFormIsLoading(false));

			if (user?.isLoggedIn && data.immo) {
				// check if already in favlist
				const foundInFavs = user.favourites?.find((f) => f.id === (data.immo.id).toString());
				
				if(!foundInFavs) {
					addToFavourites(data.immo);
				}
			}

			setStep(OBJECTREQUESTFORM_SITEINDEX_SUCCESS);
		} catch (e) {
			dispatch(setFormIsLoading(false));
			setError(fetchErrorText);
		}
	}, []);

  return (
    <PageDialog
			open={app.pageDialogOpenId === PAGEDIALOG_OBJEKTANFRAGE}
      handleClose={() => handleClose()}
      handleBack={() => setStep(OBJECTREQUESTFORM_SITEINDEX_START)}
      headline={'Objekt anfragen'}
      showBackArrow={step === OBJECTREQUESTFORM_SITEINDEX_WIDERRUF}
    >	
			<FormProvider {...methods}>
				<div className="objectRequestForm p-100rem">
					{step === OBJECTREQUESTFORM_SITEINDEX_START ? (
						<ObjectRequestMain nextStep={() => setStep(OBJECTREQUESTFORM_SITEINDEX_WIDERRUF)} error={error} />
					) : step === OBJECTREQUESTFORM_SITEINDEX_WIDERRUF ? (
						<ObjectRequestWiderruf submit={methods.handleSubmit(onSubmit)} error={error} />
					) : step === OBJECTREQUESTFORM_SITEINDEX_SUCCESS ? (
						<ObjectRequestSent handleClose={() => handleClose()} />
					) : null}
				</div>
			</FormProvider>
    </PageDialog>
  );
}

export default PageDialogObjectRequestForm;
