import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  setEmailFrequency,
  setEmailTimeOfDay,
  setIsLoggedIn,
  setLastLoggedIn,
  setMailTypes,
  setMarktinfoLastResults,
  setRecommendationSurveyCompleted,
  setResetFieldsUser,
  setSatisfactionSurveyCompleted,
  setUserObject,
  setUserRealties,
  setUserRealtyActive,
  setWantsToReceiveMails,
  setWohnwertLastResults,
} from '../../reducers/user';

import { setFormIsLoading, setMenuType } from '../../reducers/app';
import { setResetFieldsMarktinfoLightForm } from '../../reducers/marktinfoLight';
import { setResetFieldsWohnwertForm } from '../../reducers/wohnwertLight';

import { getRealtyDetails } from '../../api/JustImmo';
import { getJustImmoContactsWithCategory } from '../../util/generalUtils';
import { getDataForImmoCard } from '../../util/immoObjectParser';

import { MENU_KAUF, MENU_VERKAUF, PHASE1_VERKAUF, ROLE_ABGEBER_NAME, ROLE_INTERESSENT_NAME, ROLE_VENDOR } from '../../constants';

import { useFetchKaufanbote } from '../kaufanbote/useFetchKaufanbote';
import { useFetchSearchprofiles } from '../searchprofiles/useFetchSearchprofiles';
import { useHandleMarktinfo } from './useHandleMarktinfo';
import { useHandleWohnwert } from './useHandleWohnwert';
import { useHandleMenu } from '../menu/useHandleMenu';
import { useCallback } from 'react';

//? Wird in Immodetail verwendet, um alle relevanten funktionen zum favorisieren dieser zu bekommen
export const useHandleUser = (firstLogin = false) => {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getMarktinfoResults } = useHandleMarktinfo();
  const { getWohnwertResults } = useHandleWohnwert();
  const { getAndSetSearchprofiles } = useFetchSearchprofiles();
  const { getAndSetKaufanbote } = useFetchKaufanbote();
  const { updateMenu, determineKaufMenuId, determineVerkaufMenuId } = useHandleMenu();

  const getImmoToAdd = async (immoId, tenantId, updated_at) => {
    try {
      return getDataForImmoCard(await getRealtyDetails(immoId, tenantId), updated_at);
    } catch (error) {
      // Handle immo error
      console.log(error);
    }
  };

  const getAllImmosToAdd = async (contact) => {
    let immoPromises = contact.realties
      .filter((r) => r.role === ROLE_VENDOR)
      .map((r, index) => {
        let tenantId = contact.tenant_id;
        if (r.realty?.id && tenantId) {
          const immoId = r.realty.id;

          return getRealtyDetails(immoId, tenantId);
        }
      });

    let immos = await Promise.all(immoPromises);
    immos = immos.map((i) => {
      const findContactRealty = contact.realties.find((r) => r.realty.id === i.id);
      let updated_at = null;
      if (findContactRealty?.realty?.updated_at) {
        updated_at = findContactRealty.realty.updated_at;
      }
      return getDataForImmoCard(i, updated_at);
    });

    dispatch(setUserRealties(immos));
  };

  const updateImmoInUserRealties = async (immoId, tenantId, updated_at) => {
    try {
      const updatedRealty = getDataForImmoCard(await getRealtyDetails(immoId, tenantId), updated_at);
      const updatedRealtyArray = user.userRealties.map((r) =>
        r.id === updatedRealty.id
          ? {
              ...updatedRealty,
              updated_at: updated_at,
            }
          : r,
      );

      dispatch(setUserRealties(updatedRealtyArray));

      // if active userRealty gets updated - update store
      if (user.userRealtyActive?.id === updatedRealty.id) {
        dispatch(setUserRealtyActive(updatedRealty));
      }
    } catch (error) {
      // Handle immo error
      console.log(error);
    }
  };

  /**
   * check if userRealties are the same as in contact.Realties
   * @param {Array} contactRealties
   */
  const checkRemoveUserRealty = (contactRealties) => {
    const currenctContactRealtyIds = contactRealties.map((r) => r.realty.id);

    const allUserRealties = user.userRealties.filter((userRealty) => {
      if (currenctContactRealtyIds.includes(userRealty.id)) {
        return userRealty;
      } else {
        //Reset userRealtyActive if removed
        if (user.userRealtyActive?.id === userRealty.id) {
          dispatch(setUserRealtyActive({}));
        }
        return false;
      }
    });

    // realty was removed - update stroe
    if (allUserRealties.length !== user.userRealties.length) {
      dispatch(setUserRealties(allUserRealties));
    }
  };

  const setupUser = async (currentUser) => {
    if (firstLogin) {
      dispatch(setResetFieldsUser());
      dispatch(setFormIsLoading(false));
      dispatch(setResetFieldsMarktinfoLightForm());
      dispatch(setResetFieldsWohnwertForm());
      dispatch(setLastLoggedIn(Date.now()));
    }

    dispatch(setIsLoggedIn(true));

		const isInteressent = getJustImmoContactsWithCategory(currentUser, ROLE_INTERESSENT_NAME);
		const isAbgeber = getJustImmoContactsWithCategory(currentUser, ROLE_ABGEBER_NAME);
    if (firstLogin || app.menuType === '') {
      if (isInteressent) {
        dispatch(setMenuType(MENU_KAUF));
        navigate(app.root + '/dashboard');
      } else {
        dispatch(setMenuType(MENU_VERKAUF));
        navigate(app.root + '/dashboard');
      }
    }

    dispatch(setUserObject(currentUser));
    const userRealtyPhases = currentUser.realtyPhases;

    // check abgeber realties
    if (currentUser.justImmoContacts && currentUser.justImmoContacts.length > 0) {
      currentUser.justImmoContacts.forEach((contact) => {
        if (contact.realties && contact.realties.length > 0) {
          // after first login - put all contact.realties into store
          if (firstLogin) {
            getAllImmosToAdd(contact);
          } else {
            // only add if not already in store
            // or update if updated_at has changed
            contact.realties
              .filter((r) => r.role === ROLE_VENDOR)
              .map(async (r) => {
                let tenantId = contact.tenant_id;
                if (r.realty?.id && tenantId) {
                  const immoId = r.realty.id;
                  const currentUpdatedAt = new Date(r.realty.updated_at).getTime();

                  let immoFound = false;
                  if (user.userRealties?.length > 0) {
                    immoFound = user.userRealties.find((r) => r.id === immoId);
                  }

                  if (!immoFound) {
                    let immo = await getImmoToAdd(immoId, tenantId, currentUpdatedAt);
                    if (immo) {
                      dispatch(setUserRealties([...user.userRealties, immo]));
                    }
                  } else {
                    // check if existing immos should be updated
                    let immoFoundUpdatedAt = new Date(immoFound.updated_at).getTime();
                    let lastVendorActivity = new Date(currentUser.lastVendorActivity).getTime();

                    if (currentUpdatedAt > immoFoundUpdatedAt) {
                      await updateImmoInUserRealties(immoId, tenantId, currentUpdatedAt);
                    }
                    if (lastVendorActivity > immoFoundUpdatedAt) {
                      await updateImmoInUserRealties(immoId, tenantId, lastVendorActivity);
                    }
                  }
                }
              });

            checkRemoveUserRealty(contact.realties);
          }
        } else {
          dispatch(setUserRealties([]));
        }
      });
    }

    // check interessent searchprofile + kaufanbote
    if (app.menuType === MENU_KAUF || (firstLogin && isInteressent)) {
      let userResultSearchProfiles = currentUser.results.filter((res) => res.type === 'SearchProfile');

      let userSearchprofilesWithImmos = [];
      if (userResultSearchProfiles) {
        userSearchprofilesWithImmos = await getAndSetSearchprofiles(true, userResultSearchProfiles);
      }

      const kaufanbotelist = await getAndSetKaufanbote();
						
			// check for new menuID
			const newMenuId = determineKaufMenuId(userRealtyPhases, userSearchprofilesWithImmos, kaufanbotelist);
			console.log('set kauf menu  from userdata', newMenuId);
			updateMenu(newMenuId);
    }

    // only update store when additional marktinfoResults exist
    const marktinfoResults = getMarktinfoResults(currentUser);
    if (marktinfoResults) {
      dispatch(setMarktinfoLastResults(marktinfoResults));
    }

    // only update store when additional marktinfoResults exist
    const wohnwertResults = getWohnwertResults(currentUser);
    if (wohnwertResults) {
      dispatch(setWohnwertLastResults(wohnwertResults));
    }

    const wantsToReceiveMails = Boolean(currentUser?.emailNotificationActivities || currentUser?.emailNotificationDocuments || currentUser?.emailNotificationViewings);
    if (user.emailSettings?.wantsToReceiveMails !== wantsToReceiveMails) {
      dispatch(setWantsToReceiveMails(wantsToReceiveMails));
    }

    if (user.emailSettings?.mailTypes?.find((mailType) => mailType.id === 'documents')?.value !== currentUser.emailNotificationDocuments) {
      dispatch(setMailTypes('documents'));
    }
    if (user.emailSettings?.mailTypes?.find((mailType) => mailType.id === 'activities')?.value !== currentUser.emailNotificationActivities) {
      dispatch(setMailTypes('activities'));
    }
    if (user.emailSettings?.mailTypes?.find((mailType) => mailType.id === 'viewings')?.value !== currentUser.emailNotificationViewings) {
      dispatch(setMailTypes('viewings'));
    }
    if (user.emailSettings?.intervalDays !== currentUser?.emailNotificationIntervalDays) {
      dispatch(setEmailFrequency(currentUser.emailNotificationIntervalDays));
    }
    if (user.emailSettings?.hour !== currentUser?.emailNotificationHour) {
      dispatch(setEmailTimeOfDay(currentUser.emailNotificationHour));
    }

    if (user.recommendationSurveyCompleted === false) {
      if (currentUser.surveys?.find((survey) => survey.type === 'Weiterempfehlung')) {
        dispatch(setRecommendationSurveyCompleted(true));
      }
    }

    if (user.satisfactionSurveyCompleted === false) {
      // if survey not yet completed - check for user.emails
      if (currentUser.emails?.find((email) => email.subject === 'Zufriedenheit my-sreal.at') || currentUser.surveys?.find((survey) => survey.type === 'Zufriedenheit')) {
        dispatch(setSatisfactionSurveyCompleted(true));
      }
    }

		if(!isInteressent && !isAbgeber) {
			updateMenu(PHASE1_VERKAUF);
		}
  };

  return { setupUser };
};
