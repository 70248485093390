import { useSelector } from 'react-redux';

import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import FormCheckbox from '../../FormFields/FormCheckbox';
import FormInput from '../../FormFields/FormInput';
import FormRadio from '../../FormFields/FormRadio';
import LoadingSpinner from '../../LoadingSpinner';

import { genderOptions } from '../../FormFields/util/formOptions';

import { Controller, useFormContext } from 'react-hook-form';
import FormSelectPhoneNumberRHF from '../../FormFields/customFields/FormSelectPhoneNumberRHF';
import FormTextDsgvo from '../../FormFields/util/formTextDsgvo';

function UserData({ formType, id = '', prevStep, submit, error = '' }) {
	const { control, watch, formState: { errors } } = useFormContext();
  const user = useSelector((state) => state.user);
	
  return (
    <>
      <h2>Persönliche Daten</h2>

      <div className="d-flex justify-content-between mt-200rem">
        <h3 className="form__title mt-0">Kontakt</h3>
        <span className="font-80">* Pflichtfelder</span>
      </div>

			<Controller
				name="gender"
				control={control}
				rules={{ required: "Bitte wählen Sie eine Anrede aus." }}
				render={({ field }) => (
					<FormRadio 
						id="gender"
						options={genderOptions}
						error={errors.gender?.message}
						selectedValue={field.value}
						callback={field.onChange}
					/>
				)}
			/>


			<div className="form__row form__row--double">
				<Controller
					name="titleBefore"
					control={control}
					render={({ field }) => (
						<FormInput
							id="titleBefore"
							label={'Titel (vorgestellt)'}
							value={field.value} 
							callback={field.onChange}
						/>
					)}
				/>

				<Controller
					name="titleAfter"
					control={control}
					render={({ field }) => (
						<FormInput
							id="titleAfter"
							label={'Titel (nachgestellt)'}
							value={field.value} 
							callback={field.onChange}
						/>
					)}
				/>
      </div>

      <Controller
				name="firstName"
				control={control}
				rules={{ required: "Bitte geben Sie einen Vornamen ein." }}
				render={({ field }) => (
					<FormInput
						id="firstName"
						label={'Vorname'}
						value={field.value}
						error={errors.firstName?.message}
						required={true}
						callback={field.onChange}
					/>
				)}
			/>

			<Controller
				name="lastName"
				control={control}
				rules={{ required: "Bitte geben Sie einen Nachnamen ein." }}
				render={({ field }) => (
					<FormInput
						id={'lastName'}
						label={'Nachname'}
						value={field.value}
						error={errors.lastName?.message}
						required={true}
						callback={field.onChange}
					/>
				)}
			/>

			<Controller
				name="email"
				control={control}
				rules={{ 
					required: "Bitte geben Sie eine E-Mail-Adresse ein.",
					pattern: {
						value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
						message: "Die E-Mail-Adresse ist ungültig."
					}
				}}
				render={({ field }) => (
					<FormInput
						id="email"
						type={'email'}
						label={'E-Mail-Adresse'}
						value={field.value}
						error={errors.email?.message}
						required={true}
						callback={field.onChange}
					/>
				)}
			/>

			<FormSelectPhoneNumberRHF />

			{!user.isLoggedIn && (
				<>
					<h3 className="form__title pt-100rem">Zustimmungen</h3>
					<Controller
						name="privacyAggrement"
						control={control}
						rules={{ required: "Dies ist ein Pflichtfeld, bitte akzeptieren." }}
						render={({ field }) => (
							<FormCheckbox
								id="privacyAggrement"
								label={<FormTextDsgvo />}
								required={true}
								value={field.value}
								error={errors.privacyAggrement?.message}
								callback={field.onChange}
							/>
						)}
					/>

					<Controller
						name="createUser"
						control={control}
						rules={{ required: "Bitte akzeptieren." }}
						render={({ field }) => (
							<FormCheckbox
								id="createUser"
								label={'Jetzt direkt für das sREAL Kundenportal registrieren und von den vielen Vorteilen profitieren.'}
								value={field.value}
								error={errors.createUser?.message}
								required={true}
								callback={field.onChange}
							/>
						)}
					/>
					
					<Controller
						name="password"
						control={control}
						rules={{
							required: "Bitte geben Sie ein Passwort ein.",
							minLength: {
								value: 8,
								message: "Mindestens 8 Zeichen erforderlich",
							},
							pattern: {
								value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
								message: "Passwort ungültig. Beachten Sie die Anforderungen.",
							},
						}}
						render={({ field }) => (
							<FormInput
								type="password"
								id="password"
								label="Passwort"
								value={field.value}
								callback={field.onChange}
								error={errors.password?.message}
								required={true}
							/>
						)}
					/>

					<span className="form--info">Mindestens 8 Zeichen, 1 Klein- und Großbuchstabe, 1 Ziffer und 1 Sonderzeichen</span>

					<Controller
						name="passwordConfirm"
						control={control}
						rules={{
							required: "Bitte geben Sie ein Passwort ein.",
							validate: (value) => value === watch("password") || "Die Passwörter stimmen nicht überein.",
						}}
						render={({ field }) => (
							<FormInput
								type="password"
								id="passwordConfirm"
								label="Passwort wiederholen"
								value={field.value}
								callback={field.onChange}
								error={errors.passwordConfirm?.message}
								required={true}
							/>
						)}
					/>
				</>
			)}
 
      {error && <p id={formType + '-error'} className="form__fielderror">{error}</p>}
      <LoadingSpinner />

      <div className="button-panel pt-100rem">
        <button className="button button--gray-outline" onClick={prevStep}>Zurück</button>
        <ButtonForwardAction buttonText="Suchprofil erstellen" forwardAction={submit} buttonStyle="blue" />
      </div>
    </>
  );
}

export default UserData;
