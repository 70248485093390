import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { MENU_KAUF, MENU_VERKAUF, PHASE2_KAUF_SP_AKTIV, PHASE2_VERKAUF_MIT_AKTIVITAET } from '../../constants';
import { setMenuType } from '../../reducers/app';
import { useHandleMenu } from '../../hooks/menu/useHandleMenu';
function MenuButtonSwitcher({ cssClasses = '' }) {
  const dispatch = useDispatch();

  let user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

  cssClasses += ' buttonswitcher--wrapper';

	const { updateMenu, determineKaufMenuId, determineVerkaufMenuId } = useHandleMenu();

  const handleKaufKlick = () => {
    dispatch(setMenuType(MENU_KAUF));

		if(app.demoMode) {
			updateMenu(PHASE2_KAUF_SP_AKTIV);
		}
		else {
			const newMenuId = determineKaufMenuId();
			updateMenu(newMenuId);
		}
  };

  const handleVerkaufKlick = () => {
    dispatch(setMenuType(MENU_VERKAUF));

		if(app.demoMode) {
			updateMenu(PHASE2_VERKAUF_MIT_AKTIVITAET);
		}
		else {
			const newMenuId = determineVerkaufMenuId();
			updateMenu(newMenuId);
		}
  };

  if (user.isLoggedIn || app.demoMode) {
    return (
      <div className={cssClasses}>
        {app.menuType === MENU_VERKAUF ? (
          <>
            <Link to={`${app.root}/dashboard`} className="button active" onClick={() => handleVerkaufKlick()}>
              Verkaufen
            </Link>
            <Link to={`${app.root}/dashboard`} className="button" onClick={() => handleKaufKlick()}>
              Kaufen
            </Link>
          </>
        ) : app.menuType === MENU_KAUF ? (
          <>
            <Link to={`${app.root}/dashboard`} className="button" onClick={() => handleVerkaufKlick()}>
              Verkaufen
            </Link>

            <Link to={`${app.root}/dashboard`} className="button active" onClick={() => handleKaufKlick()}>
              Kaufen
            </Link>
          </>
        ) : null}
      </div>
    );
  } else return null;
}

export default MenuButtonSwitcher;
