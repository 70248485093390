import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getNewsDetailNew } from '../../api/News';
import { getDefaultNewsObject, newsParser } from '../../util/newsParser';

export const useFetchNewsDetails = () => {
  const [currentNews, setCurrentNews] = useState(getDefaultNewsObject());
  const navigate = useNavigate();
  const location = useLocation();

  const app = useSelector((state) => state.app);

  // on load
  useEffect(() => {
    // Check if we are on a news detail page
    if (location.pathname.includes('news') && !location.pathname.endsWith('news')) {
      let lastPartOfUrl = location.pathname.split('/');
			let newsSlug = lastPartOfUrl[lastPartOfUrl.length - 1];

			if (!newsSlug) {
				window.location = app.root + '/404';
			} else {
				getAndSetCurrentNews(newsSlug);
			}
    }
  }, []);

  const getAndSetCurrentNews = async (slug) => {
    try {
      const newsDetails = await getNewsDetailNew(slug);
      setCurrentNews(newsParser(newsDetails));
    } catch (e) {
      window.location = app.root + '/404';
    }
  };

  const navigateBack = () => {
    navigate(-1);
  };

  return { currentNews, navigateBack };
};
