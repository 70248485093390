import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CardGridPlaceholderElement from '../components/Cards/CardGridPlaceholderElement';
import ImmoCard from '../components/Cards/ImmoCard';
import SearchProfileWithouthImmos from '../components/Cards/SearchProfileWithouthImmos';
import FilterMenu from '../components/FilterMenu';
import Header from '../components/Header/Header';
import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import { isEqual } from 'lodash';
import '../styles/alleangebote.scss';
import { ImmoSimilarImmosSection } from '../components/Immo/ImmoSimilarImmosSection';

// ICONS
import IconHouseSearch from '../components/Icons/IconHouseSearch';
import LinkToSearchProfiles from '../components/LinkToSearchProfiles';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { useFetchSearchprofiles } from '../hooks/searchprofiles/useFetchSearchprofiles';

function AlleAngebote() {
  const user = useSelector((state) => state.user, isEqual);
  const [suchprofileMenu, setSuchprofileMenu] = useState(null);
  const [currentSuchprofile, setCurrentSuchprofile] = useState([]);
  const { getSearchprofilesMenu } = useFetchSearchprofiles();
  const { isMobile } = useHandleScreenResize();

  const [activeSuchprofileIndex, setActiveSuchprofileIndex] = useState(0);

  const handleSetActiveSuchprofileIndex = (event) => {
    let index = parseInt(event.target.dataset.index);
    setActiveSuchprofileIndex(index);
  };

  useEffect(() => {
    setSuchprofileMenuState();
    setCurrentSuchprofile(user.searchProfiles.filter((sp) => sp.state === 'active'));
  }, [user]);

  const setSuchprofileMenuState = async () => {
    let menu = getSearchprofilesMenu();
    setSuchprofileMenu(menu);
  };

  return (
    <>
      <MetaTags title={'Alle Angebote | Die Online-Plattform für Ihre Immobilie'} />

      {isMobile ? (
        <HeaderSmallWithIcon cssClasses="header--purplebg" clas icon={<IconHouseSearch />} iconActive={true} />
      ) : (
        <Header cssClasses="header--default header--purplebg header--fitcontent pb-500rem header--alle-angebote">
          <div className="d-flex w-desktop--center justify-content-between align-items-end pb-100rem">
            <h1 className="text-white mt-200rem mb-100">Alle Angebote</h1>
            {suchprofileMenu && (
              <FilterMenu categories={suchprofileMenu} activeIndex={activeSuchprofileIndex} handleClick={handleSetActiveSuchprofileIndex} cssClasses={'filtermenu--inverted text-purple'} />
            )}
          </div>
          <div className="w-desktop--center elements2cols align-items-center pt-0">
            <LinkToSearchProfiles linkText={'Suchprofile bearbeiten'} />
          </div>
        </Header>
      )}

      <MainContent cssClasses="alle-angebote">
        <div className="alle-angebote__options">
          <div className="w-desktop--center">
            <h1>Alle Angebote</h1>

            {suchprofileMenu && <FilterMenu categories={suchprofileMenu} activeIndex={activeSuchprofileIndex} handleClick={handleSetActiveSuchprofileIndex} />}
          </div>

          <div className="w-desktop--center">
            <LinkToSearchProfiles linkText={'Suchprofile bearbeiten'} />
          </div>
        </div>
        <div className="w-desktop--center">
          <div className="cardgrid">
            {!suchprofileMenu && <p className="page__emptymessage">Daten werden geladen.</p>}

            {suchprofileMenu && suchprofileMenu.map((sp, index) => {
                let spMenuClass = '';
                if (sp.key !== activeSuchprofileIndex) spMenuClass += ' is-hidden';

                return (
                  <Fragment key={index}>
                    {sp.immos.length > 0 ? (
                      <>
                        {sp.immos
                          .filter((immo) => immo.id)
                          .map((immo) => {
                            return <ImmoCard retailObject={immo} key={immo.id} cssClasses={spMenuClass} />;
                          })}

                        <CardGridPlaceholderElement array={sp.immos} cssClasses={spMenuClass} />
                      </>
                    ) : (
                      <SearchProfileWithouthImmos cssClasses={spMenuClass} key={sp.key} />
                    )}
                  </Fragment>
                );
              })}
          </div>
        </div>
        <ImmoSimilarImmosSection searchProfiles={user.searchProfiles} />
      </MainContent>
    </>
  );
}

export default AlleAngebote;
