import { useSelector } from 'react-redux';

import Card from './Card';

// ICONS
import IconHouse from '../Icons/IconHouse';

function LinkCardToAlleAngeboteSmall({ retailObject, cssClasses, isAbgeber, status, wohneinheiten }) {
  const app = useSelector((state) => state.app);

  if (wohneinheiten) cssClasses += ' immo-card--residential';

  return (
    <Card
      cardStyle={'teaserMiniImage'}
      linkTo={`${app.root}/angebote`}
      cssClasses={'linkOnFullCard with-shadow bg-blue'}
      icon={<IconHouse cssClasses={'scale-1-5'} color="#fff" />}
    >
      <div className="immo-card__image--container">
        <div className="immo-card__image bg-white">
          <IconHouse color={'#2970ed'} cssClasses={'scale-2'} />
        </div>
      </div>
      <div className="immo-card__content--container-small font-80 text-white">
        <span className="mb-50rem">Weitere Empfehlungen?</span>
        <span className="text-bold">Alle Anzeigen</span>
      </div>
    </Card>
  );
}

export default LinkCardToAlleAngeboteSmall;
