import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PAGEDIALOG_SUCHPROFILLIST, SEARCHPROFILE_STATE_ACTIVE, SEARCHPROFILE_STATE_INACTIVE, SP_ACTIVE_INDEX, SP_INACTIVE_INDEX } from '../../constants';

import { closePageDialog } from '../../reducers/app';
import SuchprofilCard from '../Cards/SuchprofilCard';
import FilterMenu from '../FilterMenu';
import PageDialog from './PageDialog';
import { demoSearchProfile } from '../../demodata/demosearchprofile';

function PageDialogSearchprofilesList() {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

  const [filterIndex, setFilterIndex] = useState(SP_ACTIVE_INDEX);
  const dispatch = useDispatch();

  let suchProfilMenu = [];
  suchProfilMenu[SP_ACTIVE_INDEX] = {
    label: 'Aktive Suchprofile',
    state: SEARCHPROFILE_STATE_ACTIVE,
  };
  suchProfilMenu[SP_INACTIVE_INDEX] = {
    label: 'Inaktive Suchprofile',
    state: SEARCHPROFILE_STATE_INACTIVE,
  };

  const handleSetSPFilter = (event) => {
    let index = event.target.dataset.index;
    setFilterIndex(index);
  };

  return (
    <PageDialog
      id={'PageDialogSearchprofilesList'}
      open={app.pageDialogOpenId === PAGEDIALOG_SUCHPROFILLIST}
      handleClose={() => dispatch(closePageDialog())}
      headline={'Meine Suchprofile'}
      showBackArrow={false}
    >
      <div id="searchProfileList">
        <div className="w-desktop--center headline--withfiltermenu">
          <FilterMenu activeIndex={filterIndex} categories={suchProfilMenu} handleClick={handleSetSPFilter} />
        </div>

        {app.demoMode ? (
          <div className="mt-200rem">
            {[demoSearchProfile].map((sp) => (
              <SuchprofilCard key={sp.created_at} suchProfilData={sp} cssClasses="mb-100rem" />
            ))}
          </div>
        ) : (
          <>
            {user.searchProfiles?.filter((sp) => sp.state === suchProfilMenu[filterIndex].state).length === 0 ? (
              <div className="w-desktop--center mt-200rem">
                <p>Keine Suchprofile vorhanden.</p>
              </div>
            ) : (
              <div className="mt-200rem">
                {user.searchProfiles
                  .filter((sp) => sp.state === suchProfilMenu[filterIndex].state)
                  .map((sp) => (
                    <SuchprofilCard key={sp.created_at} suchProfilData={sp} cssClasses="mb-100rem" />
                  ))}
              </div>
            )}
          </>
        )}
      </div>
    </PageDialog>
  );
}

export default PageDialogSearchprofilesList;
