import { AKTUELLES_FILTER_NACHRICHTEN } from '../constants';

const chatlist1_phase2 = [
  {
    id: 'p2c1m1',
    author: 'sreal',
    isRead: true,
    subject: 'Wohnwert Pro Anfrage',
    sentAt: new Date('2025-02-15T10:30:00'),
    body: 'Liebe Frau Rando, danke, dass Sie unseren Wohnwert Pro genutzt haben. Mit dem Marktbericht erhalten Sie wichtige Informationen über Ihre Immobilie, wie die sozio-ökonomische Entwicklung und wichtige Infrastruktur Ihrer Umgebung. Bei Fragen stehe ich Ihnen gerne zu Verfügung. Liebe Grüße Herr Hans Braun',
    replyToMessageId: null,
  },
  {
    id: 'p2c1m2',
    author: 'user',
    isRead: true,
    subject: 'Wohnwert Pro Anfrage',
    sentAt: new Date('2025-02-15T10:35:00'),
    body: 'Lieber Herr Braun, der Marktbericht ist sehr interessant und aufschlussreich. Ich spiele mit dem Gedanken meine Wohnung im dritten Bezirk zu verkaufen. Haben Sie die Woche für einen Termin Zeit, ich bin nächste Woche nicht in der Stadt. LG Sigi Rando',
    replyToMessageId: null,
  },
  {
    id: 'p2c1m3',
    author: 'sreal',
    isRead: true,
    subject: 'Wohnwert Pro Anfrage',
    sentAt: new Date('2025-02-16T10:30:00'),
    body: 'Liebe Frau Rando, Gerne doch. Wir können uns einen Besichtigungstermin für Freitag, 18 Uhr ausmachen und ich schaue mir die Wohnung an. Die wichtigen Daten habe ich bereits aus dem Marktbericht, den Sie erstellt haben. LG Hans Braun',
    replyToMessageId: null,
  },
  {
    id: 'p2c1m4',
    author: 'user',
    isRead: true,
    subject: 'Wohnwert Pro Anfrage',
    sentAt: new Date('2025-02-16T14:35:00'),
    body: 'Lieber Herr Braun Freitag, 18 Uhr passt für mich sehr gut. LG Sigi Rando',
    replyToMessageId: null,
  },
];

export const chat1_phase2 = { chatList: chatlist1_phase2, filter_id: AKTUELLES_FILTER_NACHRICHTEN, dateFrom: new Date('2025-02-15T10:35:00') };

const chatlist2_phase2 = [
  {
    id: 'p2c2m1',
    author: 'sreal',
    isRead: true,
    subject: 'Verkaufsauftrag unterzeichnen',
    sentAt: new Date('2025-02-15T10:30:00'),
    body: 'Liebe Frau Rando, ich hoffe Sie haben einen schönen Aufenthalt in Athen. Ich habe wie besprochen den Verkaufsauftrag vorbereitet und schicke ihn zum Unterzeichnen mit. Bitte zeitnah retournieren, damit wir mit der Vermarktung starten können. Schönen Urlaub und lG Hans Braun',
    replyToMessageId: null,
  },
  {
    id: 'p2c2m2',
    author: 'user',
    isRead: true,
    subject: 'Verkaufsauftrag unterzeichnen',
    sentAt: new Date('2025-02-15T10:35:00'),
    body: 'Lieber Herr Braun, vielen Lieben Dank. Ja, es ist schön ein paar Tage Auszeit zu genießen. Verkaufsauftrag schicke ich unterzeichnet zurück. LG Sigi Rando',
    replyToMessageId: null,
  },
];

export const chat2_phase2 = { chatList: chatlist2_phase2, filter_id: AKTUELLES_FILTER_NACHRICHTEN, dateFrom: new Date('2025-02-15T10:35:00') };

const chatlist1_phase3 = [
  {
    id: 'p3c1m1',
    author: 'sreal',
    isRead: true,
    subject: 'Ihre Wohnung ist online!',
    sentAt: new Date('2025-02-15T10:30:00'),
    body: 'Guten Tag Herr Müller! Es freut mich Ihnen mitteilen zu können, dass wir heute mit der Vermarktung ihrer Wohnung beginnen konnten. Das Inserat hat bereits einige Klicks gesammelt. Ich freue mich auf die ersten Anfragen.',
    replyToMessageId: null,
  },
  {
    id: 'p3c1m2',
    author: 'user',
    isRead: true,
    subject: 'Ihre Wohnung ist online!',
    sentAt: new Date('2025-02-15T10:35:00'),
    body: 'Guten Tag! Vielen Dank – das klingt schon mal sehr gut 😊 Ich bin gespannt, wie die Vermarktung anläuft.',
    replyToMessageId: null,
  },
  {
    id: 'p3c1m3',
    author: 'sreal',
    isRead: true,
    subject: 'Ihre Wohnung ist online!',
    sentAt: new Date('2025-02-15T10:40:00'),
    body: 'Ich werde Sie stehts auf dem Laufenden halten! Bis bald!',
    replyToMessageId: null,
  },
];

export const chat1_phase3 = { chatList: chatlist1_phase3, filter_id: AKTUELLES_FILTER_NACHRICHTEN, dateFrom: new Date('2025-02-15T10:35:00') };

const chatlist2_phase3 = [
  {
    id: 'p3c2m1',
    author: 'sreal',
    isRead: true,
    subject: 'Update zu den Besichtigungen',
    sentAt: new Date('2025-02-15T10:30:00'),
    body: 'Hallo Herr Müller! Es freut mich Sie über die ersten Besichtigungen informieren zu können. Ich konnte bereits drei Interessenten die Wohnung vor Ort zeigen. Besonders die letzte Besichtigung war sehr vielversprechend. Hier treffen sich die Interessenten nächste Woche mit ihrer Bankberater:in, um über einen möglichen Kredit zu sprechen. ',
    replyToMessageId: null,
  },
  {
    id: 'p3c2m2',
    author: 'user',
    isRead: true,
    subject: 'Update zu den Besichtigungen',
    sentAt: new Date('2025-02-15T10:35:00'),
    body: 'Das klingt ja super! Wie waren die Rückmeldungen der anderen Interessenten?',
    replyToMessageId: null,
  },
  {
    id: 'p3c2m3',
    author: 'sreal',
    isRead: true,
    subject: 'Update zu den Besichtigungen',
    sentAt: new Date('2025-02-15T10:40:00'),
    body: 'Ein Paar, dass mit mir besichtigt hat, schaut sich kommende Woche noch eine andere Wohnung an und entscheidet anschließend. Hier werde ich ebenfalls nochmal den Kontakt für eine finale Rückmeldung suchen. Die anderen Interessenten haben sich nach langen Überlegungen gegen die Wohnung entschieden, da sie eine separate Küche wünschen.',
    replyToMessageId: null,
  },
  {
    id: 'p3c2m4',
    author: 'user',
    isRead: true,
    subject: 'Update zu den Besichtigungen',
    sentAt: new Date('2025-02-15T10:45:00'),
    body: 'Verstehe! Bis bald!',
    replyToMessageId: null,
  },
];

export const chat2_phase3 = { chatList: chatlist2_phase3, filter_id: AKTUELLES_FILTER_NACHRICHTEN, dateFrom: new Date('2025-02-15T10:35:00') };

const chatlist3_phase3 = [
  {
    id: 'p3c3m1',
    author: 'sreal',
    isRead: true,
    subject: 'Update zur Vermarktung',
    sentAt: new Date('2025-03-15T10:30:00'),
    body: 'Lieber Herr Müller, es freut mich Ihnen mitteilen zu können, dass wir ihre Immobilie in unserem nächsten Wohnen-Magazin inserieren werden. Zudem folgt ein Posting auf unserer Instagram-Seite und Ihr Objekt wird auf Immoscout24 in der kommenden Woche vorgereiht. Dadurch werden wir nochmal mehr Aufmerksamkeit erzielen.',
    replyToMessageId: null,
  },
  {
    id: 'p3c3m2',
    author: 'user',
    isRead: true,
    subject: 'Update zur Vermarktung',
    sentAt: new Date('2025-03-15T10:35:00'),
    body: 'Wow das klingt ja super! Vielen Dank für das Update! ',
    replyToMessageId: null,
  },
  {
    id: 'p3c3m3',
    author: 'sreal',
    isRead: true,
    subject: 'Update zur Vermarktung',
    sentAt: new Date('2025-03-15T10:40:00'),
    body: 'Sehr gerne – ich halte Sie weiterhin auf dem Laufenden!',
    replyToMessageId: null,
  },
];

export const chat3_phase3 = { chatList: chatlist3_phase3, filter_id: AKTUELLES_FILTER_NACHRICHTEN, dateFrom: new Date('2025-02-15T10:35:00') };

const chatlist4_phase3 = [
  {
    id: 'p3c4m1',
    author: 'sreal',
    isRead: true,
    subject: 'Gute Neuigkeiten: Zwei mögliche Angebote in Aussicht!',
    sentAt: new Date('2025-03-15T10:30:00'),
    body: 'Guten Tag Herr Müller! Ich habe gute Nachrichten! Es sieht so aus, als würden in den nächsten Tagen zwei Interessenten ein Angebot für Ihre Immobilie abgeben.',
    replyToMessageId: null,
  },
  {
    id: 'p3c4m2',
    author: 'user',
    isRead: true,
    subject: 'Gute Neuigkeiten: Zwei mögliche Angebote in Aussicht!',
    sentAt: new Date('2025-03-15T10:35:00'),
    body: 'Das ist ja großartig! Haben die Interessenten bereits konkrete Zahlen genannt?',
    replyToMessageId: null,
  },
  {
    id: 'p3c4m3',
    author: 'sreal',
    isRead: true,
    subject: 'Gute Neuigkeiten: Zwei mögliche Angebote in Aussicht!',
    sentAt: new Date('2025-03-15T10:40:00'),
    body: 'Noch nicht ganz, aber beide haben signalisiert, aber beide haben signalisiert, dass sie ernsthaft interessiert sind und die Gespräche mit der Bank bezüglich eines Kredits bereits erfolgreich beendet wurden. Ich rechne damit, dass die Angebote in den kommenden 5 Tagen vorliegen.',
    replyToMessageId: null,
  },
  {
    id: 'p3c4m4',
    author: 'user',
    isRead: true,
    subject: 'Gute Neuigkeiten: Zwei mögliche Angebote in Aussicht!',
    sentAt: new Date('2025-03-15T10:42:00'),
    body: 'Das klingt sehr vielversprechend! Halten Sie mich bitte auf dem Laufenden.',
    replyToMessageId: null,
  },
  {
    id: 'p3c4m5',
    author: 'sreal',
    isRead: true,
    subject: 'Gute Neuigkeiten: Zwei mögliche Angebote in Aussicht!',
    sentAt: new Date('2025-03-15T10:50:00'),
    body: 'Auf jeden Fall! Ich melde mich sofort, sobald die Angebote da sind und wir die nächsten gemeinsamen Schritte besprechen können.',
    replyToMessageId: null,
  },
];

export const chat4_phase3 = { chatList: chatlist4_phase3, filter_id: AKTUELLES_FILTER_NACHRICHTEN, dateFrom: new Date('2025-02-15T10:35:00') };

const chatlist1_phase4 = [
  {
    id: 'p4c1m1',
    author: 'sreal',
    isRead: true,
    subject: 'Große Nachfrage',
    sentAt: new Date('2025-02-15T10:30:00'),
    body: 'Liebe Frau Grünwald, es freut mich, dass Sie sich für unser Kundenportal registriert haben! Das Interesse an Ihrer Wohnung ist sehr groß! Wie Sie auch aus dem Dashboard entnehmen können. Die Anzeigen schon mehrere tausende Klicks. ',
    replyToMessageId: null,
  },
  {
    id: 'p4c1m2',
    author: 'user',
    isRead: true,
    subject: 'Große Nachfrage',
    sentAt: new Date('2025-02-15T10:35:00'),
    body: 'Hallo Frau Hofer, das ist ja sensationell!! Hat es auch schon Besichtigungsanfragen gegeben? ',
    replyToMessageId: null,
  },
  {
    id: 'p4c1m3',
    author: 'sreal',
    isRead: true,
    subject: 'Große Nachfrage',
    sentAt: new Date('2025-02-15T10:40:00'),
    body: 'Ja, die ersten Besichtigungen finden schon diese Woche statt. Ich melde mich morgen telefonisch bei Ihnen!',
    replyToMessageId: null,
  },
  {
    id: 'p4c1m4',
    author: 'user',
    isRead: true,
    subject: 'Große Nachfrage',
    sentAt: new Date('2025-02-15T10:35:00'),
    body: 'Ich habe gerade gesehen, dass schon drei Besichtigungen stattgefunden haben – sensationell!',
    replyToMessageId: null,
  },
  {
		id: 'p4c1m5',
    author: 'sreal',
    isRead: true,
    subject: 'Große Nachfrage',
    sentAt: new Date('2025-02-15T10:47:00'),
    body: 'Ja, ich übermittle Ihnen noch heute das erste Kaufanbot per E-Mail. Sie finden das Dokument dann auch in mysreal in Ihren Immodrive.',
    replyToMessageId: null,
  },
  {
		id: 'p4c1m68',
    author: 'user',
    isRead: true,
    subject: 'Große Nachfrage',
    sentAt: new Date('2025-02-15T10:59:00'),
    body: 'Das sind tolle Neuigkeiten. Vielen Dank!',
    replyToMessageId: null,
  },
];

export const chat1_phase4 = { chatList: chatlist1_phase4, filter_id: AKTUELLES_FILTER_NACHRICHTEN, dateFrom: new Date('2025-02-15T10:35:00') };

const chatlist2_phase4 = [
  {
		id: 'p5c1m01',
    author: 'sreal',
    isRead: true,
    subject: 'Kaufvertragsunterzeichnung',
    sentAt: new Date('2025-02-15T10:30:00'),
    body: 'Hallo Frau Grünwald, der Termin für den Kaufvertrag mit Herrn Dr. Pacher findet morgen, dem 24.03.2025 um 12:00 Uhr im s REAL-Büro am Rochusmarkt statt. Die genaue Adresse lautet: Landstraßer Hauptstraße 60, 1030 Wien. Bitte um Mitnahme eines amtlichen Lichtbildausweises.',
    replyToMessageId: null,
  },
  {
		id: 'p5c1m02',
    author: 'user',
    isRead: true,
    subject: 'Kaufvertragsunterzeichnung',
    sentAt: new Date('2025-02-15T10:35:00'),
    body: 'Danke für die Erinnerung. Bis morgen!',
    replyToMessageId: null,
  },
];

export const chat2_phase4 = { chatList: chatlist2_phase4, filter_id: AKTUELLES_FILTER_NACHRICHTEN, dateFrom: new Date('2025-02-15T10:35:00') };

const chatlist3_phase4 = [
  {
		id: 'p30c1m01',
    author: 'sreal',
    isRead: true,
    subject: 'Schlüsselübergabe',
    sentAt: new Date('2025-02-15T10:30:00'),
    body: 'Liebe Frau Grünwald, den Termin für die Schlüsselübergabe habe ich mit den Käufer:innen für den 01.04. Um 13 Uhr koordiniert. Wir sehen uns dort! 😊',
    replyToMessageId: null,
  },
  {
		id: 'p30c1m02',
    author: 'user',
    isRead: true,
    subject: 'Schlüsselübergabe',
    sentAt: new Date('2025-02-15T10:35:00'),
    body: 'Alles klar, danke für die Info! Ich möchte mich nochmal für die Vermittlung bedanken. Ich denke die Wohnung ist bei den neuen Eigentümer:innen sehr gut aufgehoben!! ',
    replyToMessageId: null,
  },
];

export const chat3_phase4 = { chatList: chatlist3_phase4, filter_id: AKTUELLES_FILTER_NACHRICHTEN, dateFrom: new Date('2025-02-15T10:35:00') };
