import { useSelector } from 'react-redux';

import FormTextDsgvo from '../../FormFields/util/formTextDsgvo';

// FORM FIELDS
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import FormCheckbox from '../../FormFields/FormCheckbox';
import FormInput from '../../FormFields/FormInput';
import FormSelect from '../../FormFields/FormSelect';
import Textarea from '../../FormFields/Textarea';
import LoadingSpinner from '../../LoadingSpinner';

import { CONTACTFORM_ANLIEGEN_SUPPORT } from '../../../constants';

import statesWithZips from '../../FormFields/util/statesWithZips';

// STORE

// API
import { Controller, useFormContext } from 'react-hook-form';
import FileUpload from '../../FormFields/FileUpload';

function ContactIhreNachricht({ prevStep, submit, error = ''}) {
	const { control, watch, getValues, handleSubmit, resetField, formState: {errors} } = useFormContext();

	const reason = getValues('reason');
	const createUser = watch('createUser');

  let user = useSelector((state) => state.user);

  const federalStateOptions = statesWithZips.map((state) => {
    return {
      id: state.justImmoId,
      text: state.label,
    };
  });

  return (
    <>
      {reason?.id === CONTACTFORM_ANLIEGEN_SUPPORT ? (
        <>
          <h3 className="form__title">Wo suchen oder verkaufen Sie Ihre Immobilie?</h3>
					<Controller
						name="state"
						control={control}
						rules={{ required: "Bitte wählen Sie ein Bundesland aus." }}
						render={({ field }) => (
							<FormSelect
								id={'state'}
								label={'Bundesland'}
								placeholder="Bitte wählen"
								value={field.value}
								error={errors.state?.message}
								required={true}
								options={federalStateOptions}
								callback={field.onChange}
							/>
						)}
					/>

          <h3 className="form__title">Ihre Nachricht*</h3>
					<Controller
						name="message"
						control={control}
						rules={{ required: "Bitte geben Sie eine Nachricht ein." }}
						render={({ field }) => (
							<Textarea
								id="message"
								required={true}
								value={field.value}
								callback={field.onChange}
								error={errors.message?.message}
							/>
						)}
					/>


          <h3 className="form__title">Anhang hinzufügen (optional)</h3>
					<Controller
						name="file"
						control={control}
						render={({ field }) => (
							<FileUpload
								setFile={field.onChange}
								currentFile={field.value}
							/>
						)}
					/>
        </>
      ) : (
        <>
          <h3 className="form__title">Ihre Nachricht (optional)</h3>
          <Controller
						name="message"
						control={control}
						render={({ field }) => (
							<Textarea
								id="message"
								value={field.value}
								callback={field.onChange}
							/>
						)}
					/>
        </>
      )}

      <h3 className="form__title">Zustimmungen</h3>
			<Controller
				name="privacyAggrement"
				control={control}
				rules={{ required: "Dies ist ein Pflichtfeld, bitte akzeptieren." }}
				render={({ field }) => (
					<FormCheckbox
						id="privacyAggrement"
						label={<FormTextDsgvo />}
						required={true}
						value={field.value}
						error={errors.privacyAggrement?.message}
						callback={field.onChange}
					/>
				)}
			/>

      {!user.isLoggedIn && (
        <>
          <Controller
						name="createUser"
						control={control}
						render={({ field }) => (
							<FormCheckbox
								id="createUser"
								label={'Jetzt direkt für das sREAL Kundenportal registrieren und von den vielen Vorteilen profitieren.'}
								value={field.value}
								callback={field.onChange}
							/>
						)}
					/>

					{createUser === true && (
            <>
							<Controller
								name="password"
								control={control}
								rules={{
									required: "Bitte geben Sie ein Passwort ein.",
									minLength: {
										value: 8,
										message: "Mindestens 8 Zeichen erforderlich",
									},
									pattern: {
										value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
										message: "Passwort ungültig. Beachten Sie die Anforderungen.",
									},
								}}
								render={({ field }) => (
									<FormInput
										type="password"
										id="password"
										label="Passwort"
										value={field.value}
										callback={field.onChange}
										error={errors.password?.message}
										required={true}
									/>
								)}
							/>

							<span className="form--info">Mindestens 8 Zeichen, 1 Klein- und Großbuchstabe, 1 Ziffer und 1 Sonderzeichen</span>

							<Controller
								name="passwordConfirm"
								control={control}
								rules={{
									required: "Bitte geben Sie ein Passwort ein.",
									validate: (value) => value === watch("password") || "Die Passwörter stimmen nicht überein.",
								}}
								render={({ field }) => (
									<FormInput
										type="password"
										id="passwordConfirm"
										label="Passwort wiederholen"
										value={field.value}
										callback={field.onChange}
										error={errors.passwordConfirm?.message}
										required={true}
									/>
								)}
							/>
						</>
          )}
        </>
      )}

      <LoadingSpinner />

      <div className="button-panel pt-150rem">
        <button className="button button--gray-outline" onClick={prevStep}>
          Zurück
        </button>
        {/* <ButtonForwardAction buttonText="Nachricht senden" forwardAction={handleSubmit((data) => handleSendMessage(data))} buttonStyle="blue" /> */}
				<ButtonForwardAction buttonText="Nachricht senden" forwardAction={submit} buttonStyle="blue" />
      </div>
      {error && <p className="form__fielderror">{error}</p>}
    </>
  );
}

export default ContactIhreNachricht;
