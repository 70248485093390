import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseDate } from '../../util/datetimeParser';

import useOutsideAlerter from '../useOutsideAlerter';
import LogoutButton from './LogoutButton';
import SelectIhreImmobilie from './SelectIhreImmobilie';

import { Link } from 'react-router-dom';
import { useHandleMenu } from '../../hooks/menu/useHandleMenu';
import { toggleDesktopMenu } from '../../reducers/app';
import { demoUserObject } from '../../demodata/demouser.js';

function Flyout({ isDemo = false }) {
  let user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleOpenFlyout = () => {
    setTimeout(() => {
      dispatch(toggleDesktopMenu());
    }, 50);
  };

	const { linkProfil, linkFaqs, getLinkCssClass } = useHandleMenu();

  const flyoutRef = useRef(null);
  useOutsideAlerter(flyoutRef, handleOpenFlyout);

  return (
    <div className="flyout" ref={flyoutRef}>
      <div className="mb-100rem">
				{ isDemo ? (
					<>
						<span className="font-weight-600">
							Hallo,
							<br />
							{demoUserObject.firstName} {demoUserObject.lastName}!
						</span>
						<br />
						<span className="font-90">Letzter Login: {parseDate(new Date(), 'DD.MM.YYYY HH:mm')}</span>
					</>
				) : (
					<>
						{user && user.userObject && user.userObject.firstName && user.userObject.lastName && (
							<span className="font-weight-600">
								Hallo,
								<br />
								{user.userObject.firstName} {user.userObject.lastName}!
							</span>
						)}

						{user.lastLoggedIn !== '' && (
							<>
								<br />
								<span className="font-90">Letzter Login: {parseDate(user.lastLoggedIn, 'DD.MM.YYYY HH:mm')}</span>
							</>
						)}
					</>
				)}
      </div>

      <SelectIhreImmobilie />

			<ul className='header__nav'>
				<li className={getLinkCssClass(linkFaqs)} key={linkFaqs.name + '-flyout'}>
					<Link to={linkFaqs.link} title={linkFaqs.name} className="header__nav__link">
						<span className="header__nav__span">{linkFaqs.name}</span>
					</Link>
				</li>
				<li className={getLinkCssClass(linkProfil)} key={linkProfil.name + '-flyout'}>
					<Link to={linkProfil.link} title={linkProfil.name} className="header__nav__link">
						<span className="header__nav__span">{linkProfil.name}</span>
					</Link>
				</li>				
			</ul>

      <LogoutButton />

      <a href="https://www.sreal.at" target="_blank" className="text-link d-flex mt-200rem" rel="noreferrer">
        sREAL.at aufrufen
      </a>
    </div>
  );
}

export default Flyout;
