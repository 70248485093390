import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import IconEye from '../components/Icons/IconEye';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';

import BesichtigungenContent from '../components/ContentBlocks/BesichtigungenContent';

function Besichtigungen() {
  return (
    <>
      <MetaTags title={'Besichtigungen | Die Online-Plattform für Ihre Immobilie'} description={'Hier finden Sie alle aktuellen und vergangenen Besichtigungen'} />

      <HeaderSmallWithIcon icon={<IconEye />} iconActive={true} headlineDesktop="Besichtigungen" />

      <MainContent cssClasses="w-desktop--center p-100rem">
        <h1 className="desktop-hidden">Besichtigungen</h1>
        <BesichtigungenContent containerSelector={'#besichtigungen-cards'} />
      </MainContent>
    </>
  );
}

export default Besichtigungen;
