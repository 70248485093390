import httpClient from './index';
import { ERR_LOGIN_EXPIRED } from '../constants';
import { isDemoUrl } from '../util/generalUtils';

const register = async (requestBody) => {
	if (!requestBody) throw new Error('requestBody is missing');

  try {
    const resp = await httpClient.post('/Auth/register', requestBody);
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const registerChangePhonenumber = async (phoneNumber) => {
  if (!phoneNumber) return false;

  try {
    const resp = await httpClient.post(`/Auth/register_change_phonenumber?phoneNumber=${phoneNumber}`);
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const token = async (data) => {
  let { token, rememberMe, initial } = data;

  if (typeof rememberMe !== 'boolean') rememberMe = true;

  try {
    const resp = await httpClient.post(`/Auth/token${initial ? '?initial=true' : ''}`, {
      token: token,
      // rememberMe: rememberMe,
    });
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const confirm = async (data) => {
  data.mode = 1;

  try {
    const resp = await httpClient.post('/Auth/confirm', data);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const directRegisterConfirm = async (data) => {
  data.mode = 1;

  try {
    const resp = await httpClient.post('/Auth/direct_register_confirm', data);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const current = async () => {
	if(isDemoUrl()) return null;

  try {
    const resp = await httpClient.post('/Auth/current');
    return resp.data;
  } catch (e) {
    // Logout abgelaufen
    if (e.response.status === 401) {
      throw new Error(ERR_LOGIN_EXPIRED);
    }

    throw e;
  }
};

const resendConfirm = async (email) => {
	if (!email) throw new Error('email is missing');

  try {
    const resp = await httpClient.post('/Auth/resendConfirm?email=' + email);
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const login = async (requestBody) => {
	if(isDemoUrl()) return null;
	
	if (!requestBody) throw new Error('requestBody is missing');

  try {
    const resp = await httpClient.post('/Auth/login', requestBody);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

const logout = async () => {
  try {
    const resp = await httpClient.post('/Auth/logout');
    return resp.data;
  } catch (e) {
    throw e;
  }
};

// ----------------------------------
// todo

const forgot_password = async (data) => {
	if (!data) throw new Error('data is missing');

  try {
    const resp = await httpClient.post('/Auth/forgot_password', data);
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export { register, registerChangePhonenumber, login, token, directRegisterConfirm, current, confirm, resendConfirm, logout, forgot_password };
