export const demobesichtigungen = [
	{
			"id": 8,
			"key": "240374364100",
			"label": "Besichtigung der Immobilie",
			"dateFrom": "2025-03-05 14:00:00Z",
			"dateTo": null,
			"value": {
					"ActivityTypeId": 8,
					"ActivityTypeName": "Besichtigung",
					"Comment": "Heute haben wieder 2 Besichtigungen stattgefunden. Ich denke dass das Interesse sehr groß ist. Ich halte Sie auf dem Laufenden! Freundliche Grüße Hans Braun",
					"Created_at": "2025-02-21T15:53:18+02:00",
					"Offertext": null,
					"Files": [],
					"From": "2025-03-05 14:00:00Z",
					"To": "0001-01-01 00:00:00Z"
			},
			"text": "Heute haben wieder 2 Besichtigungen stattgefunden. Ich denke dass das Interesse sehr groß ist. Ich halte Sie auf dem Laufenden! Freundliche Grüße Hans Braun",
			"filter_id": 2
	},
	{
		"id": 8,
		"key": "240372009100",
		"label": "Besichtigung der Immobilie",
		"dateFrom": "2025-02-05 10:00:00Z",
		"dateTo": null,
		"value": {
				"ActivityTypeId": 8,
				"ActivityTypeName": "Besichtigung",
				"Comment": "Nun geht es richtig los! Heute hat die erste Besichtigung stattgefunden. Ein sehr nettes Pärchen hat sich die Wohnung angesehen. Sie werden sich nun beraten und melden sich bei mir. Ich halte Sie auf dem Laufenden! Freundliche Grüße Hans Braun",
				"Created_at": "2025-02-05T15:37:05+02:00",
				"Offertext": null,
				"Files": [],
				"From": "2025-02-05 10:00:00Z",
				"To": "0001-01-01 00:00:00Z"
		},
		"text": "Nun geht es richtig los! Heute hat die erste Besichtigung stattgefunden. Ein sehr nettes Pärchen hat sich die Wohnung angesehen. Sie werden sich nun beraten und melden sich bei mir. Ich halte Sie auf dem Laufenden! Freundliche Grüße Hans Braun",
		"filter_id": 2
	},
	{
		"id": 8,
		"key": "240372009100",
		"label": "Besichtigung der Immobilie",
		"dateFrom": "2025-02-13 10:00:00Z",
		"dateTo": null,
		"value": {
				"ActivityTypeId": 8,
				"ActivityTypeName": "Besichtigung",
				"Comment": "Auch heute war ich wieder für Besichtigungen vor Ort. Ich melde mich, sobald es Neuigkeiten gibt. Freundliche Grüße Hans Braun",
				"Created_at": "2025-02-13T15:37:05+02:00",
				"Offertext": null,
				"Files": [],
				"From": "2025-02-13 10:00:00Z",
				"To": "0001-01-01 00:00:00Z"
		},
		"text": "Auch heute war ich wieder für Besichtigungen vor Ort. Ich melde mich, sobald es Neuigkeiten gibt. Freundliche Grüße Hans Braun",
		"filter_id": 2
	},
	{
		"id": 8,
		"key": "240372009100",
		"label": "Besichtigung der Immobilie",
		"dateFrom": "2025-02-13 10:00:00Z",
		"dateTo": null,
		"value": {
				"ActivityTypeId": 8,
				"ActivityTypeName": "Besichtigung",
				"Comment": "Heute Abend fand eine sehr vielversprechende Besichtigung statt. Ich melde mich, sobald es Neuigkeiten gibt. Freundliche Grüße Hans Braun",
				"Created_at": "2025-02-13T15:37:05+02:00",
				"Offertext": null,
				"Files": [],
				"From": "2025-02-13 10:00:00Z",
				"To": "0001-01-01 00:00:00Z"
		},
		"text": "Heute Abend fand eine sehr vielversprechende Besichtigung statt. Ich melde mich, sobald es Neuigkeiten gibt. Freundliche Grüße Hans Braun",
		"filter_id": 2
	},
	{
		"id": 8,
		"key": "240372009100",
		"label": "Besichtigung der Immobilie",
		"dateFrom": "2025-02-19 10:00:00Z",
		"dateTo": null,
		"value": {
				"ActivityTypeId": 8,
				"ActivityTypeName": "Besichtigung",
				"Comment": "Auch die heutige Besichtigung lief gut und es gibt eine Kaufabsicht. Ich habe den Kunden nun auf Wunsch an unsere Finanzierer von der Erste Bank weitergeleitet. Die sind immer sehr schnell. Ich halte Sie auf dem Laufenden! Freundliche Grüße Hans Braun",
				"Created_at": "2025-02-19T15:37:05+02:00",
				"Offertext": null,
				"Files": [],
				"From": "2025-02-19 10:00:00Z",
				"To": "0001-01-01 00:00:00Z"
		},
		"text": "Auch die heutige Besichtigung lief gut und es gibt eine Kaufabsicht. Ich habe den Kunden nun auf Wunsch an unsere Finanzierer von der Erste Bank weitergeleitet. Die sind immer sehr schnell. Ich halte Sie auf dem Laufenden! Freundliche Grüße Hans Braun",
		"filter_id": 2
	},
	{
		"id": 8,
		"key": "240372009100",
		"label": "Besichtigung der Immobilie",
		"dateFrom": "2025-02-19 10:00:00Z",
		"dateTo": null,
		"value": {
				"ActivityTypeId": 8,
				"ActivityTypeName": "Besichtigung",
				"Comment": "Auch die heutige Besichtigung lief gut und es gibt eine Kaufabsicht. Ich habe den Kunden nun auf Wunsch an unsere Finanzierer von der Erste Bank weitergeleitet. Die sind immer sehr schnell. Ich halte Sie auf dem Laufenden! Freundliche Grüße Hans Braun",
				"Created_at": "2025-02-19T15:37:05+02:00",
				"Offertext": null,
				"Files": [],
				"From": "2025-02-19 10:00:00Z",
				"To": "0001-01-01 00:00:00Z"
		},
		"text": "Auch die heutige Besichtigung lief gut und es gibt eine Kaufabsicht. Ich habe den Kunden nun auf Wunsch an unsere Finanzierer von der Erste Bank weitergeleitet. Die sind immer sehr schnell. Ich halte Sie auf dem Laufenden! Freundliche Grüße Hans Braun",
		"filter_id": 2
	},
	{
			"id": 8,
			"key": "240372009100",
			"label": "Besichtigung der Immobilie",
			"dateFrom": "2025-02-21 10:00:00Z",
			"dateTo": null,
			"value": {
					"ActivityTypeId": 8,
					"ActivityTypeName": "Besichtigung",
					"Comment": "Heute hat eine sehr nette Besichtigung stattgefunden. Der Interessent besichtig die Woche noch eine weitere Immobilien und meldet sich dann bei mir. Ich halte Sie auf dem Laufenden. Freundliche Grüße Hans Braun",
					"Created_at": "2025-02-21T15:37:05+02:00",
					"Offertext": null,
					"Files": [],
					"From": "2025-02-21 10:00:00Z",
					"To": "0001-01-01 00:00:00Z"
			},
			"text": "Heute hat eine sehr nette Besichtigung stattgefunden. Der Interessent besichtig die Woche noch eine weitere Immobilien und meldet sich dann bei mir. Ich halte Sie auf dem Laufenden. Freundliche Grüße Hans Braun",
			"filter_id": 2
	},
];