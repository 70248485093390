import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Card from './Card';
import IconDocument from '../Icons/IconDocument';

// STORE
import { setPageDialogOpenId} from '../../reducers/app';

import { CONTACTFORM_ANLIEGEN_IMMOGUTACHTEN, PAGEDIALOG_CONTACT } from '../../constants';
import { useHandleWohnwert } from '../../hooks/user/useHandleWohnwert';
import '../../styles/wohnwert.scss';

function GutachtenCard({ cssClasses }) {
  if (!cssClasses) cssClasses = 'wohnwertpro-card';
  else cssClasses += ' wohnwertpro-card';

  
  const dispatch = useDispatch();

  const { getWohnwertGutachten } = useHandleWohnwert();

	const handleGutachten = () => {
		localStorage.setItem('mysreal_contact_reason', CONTACTFORM_ANLIEGEN_IMMOGUTACHTEN);
		dispatch(setPageDialogOpenId(PAGEDIALOG_CONTACT));
  };

  const [isDesktop, setIsDesktop] = useState(false);
  const [gutachten, setGutachten] = useState(null);
  useEffect(() => {
    if (isDesktop) {
      setIsDesktop(true);
    }

    setGutachten(getWohnwertGutachten());
  }, []);

  return (
    <Card cssClasses={cssClasses + ' background-turquoise text-white'} cardStyle={'customContent'}>
      <div className="card__content card__content--wohnwert">
        <div className="mb-10 mt-10">
          <IconDocument color="#fff" cssClasses="mobile-hidden mb-20" scale={2} />
        </div>
        <h2 className="mb-100rem text-white">Gutachten anfragen</h2>

     		<>
          <p className="font-90 h-100">Sollten Sie ein professionelles Immobiliengutachten benötigen, stehen Ihnen unsere Immobilienbewerter:innen zu Verfügung.</p>

          <div className="d-flex d-flex--btnandicon mt-auto">
            <div>
              <button className="button button--white-outline mt-100rem" onClick={handleGutachten}>
                Anfrage senden
              </button>
            </div>
          </div>
        </>
      </div>
    </Card>
  );
}

export default GutachtenCard;
