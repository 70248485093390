import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { immoSearchErrorText, immoSearchNoResultsText, neubauprojekteSearchNoResultsText } from '../../components/FormFields/util/formTexts';

//STORE
import { setFormIsLoading } from '../../reducers/app';

// API
import { getRealtySearch, getResidentialRealtySearch } from '../../api/Sreal';

import { getRealtyList } from '../../api/JustImmo';
import { getKaufpreisNum, parseImmo } from '../../util/immoObjectParser';

export const useFetchImmoSearch = () => {
  const dispatch = useDispatch();

  const [error, setError] = useState(false);

  const [immoList, setImmoList] = useState([]);
  const [immoListCnt, setImmoListCnt] = useState(0);
  const [neubauList, setNeubauList] = useState([]);
  const [neubauListCnt, setNeubauListCnt] = useState(0);
	const [neubauLoading, setNeubauLoading] = useState([]);

  const handleScrollToResults = () => {
    const resultListEl = document.querySelector('#immoresult-list');
		if(!resultListEl) return;
    window.scrollTo({
      top: resultListEl.offsetTop,
      behavior: 'smooth',
    });
  };

	const filterWohneinheiten = (wohneinheiten, requestBody) => {
    if (!wohneinheiten || wohneinheiten.length === 0) return [];

    const newWohneinheiten = wohneinheiten.filter((w) => {
      const immoLivingArea = parseInt(w.areas.living_area);
      const areaFrom = parseInt(requestBody.areaFrom);
      const areaTo = parseInt(requestBody.areaTo);
      const areaFromMatch = areaFrom > 0 ? immoLivingArea >= areaFrom : true;
      const areaToMatch = areaTo > 0 ? immoLivingArea <= areaTo : true;

      const immoPrice = getKaufpreisNum(w);
      const priceFrom = parseInt(requestBody.priceFrom);
      const priceTo = parseInt(requestBody.priceTo);
      const priceFromMatch = priceFrom > 0 ? immoPrice >= priceFrom : true;
      const priceToMatch = priceTo > 0 ? immoPrice <= priceTo : true;

      const roomsFrom = parseInt(requestBody.roomsFrom);
      const roomsTo = parseInt(requestBody.roomsTo);
      const roomsFromMatch = roomsFrom ? w.rooms >= roomsFrom : true;
      const roomsToMatch = roomsTo ? w.rooms <= roomsTo : true;

      return areaFromMatch && areaToMatch && priceFromMatch && priceToMatch && roomsFromMatch && roomsToMatch;
    });

    return newWohneinheiten;
  };

  async function getRealtiesWithWohneinheiten(list, requestBody) {
    if (!list) return [];
    let residential_projects = list.filter((realty) => realty.sreal_type_id === 'residential_projects');

    // Fetch realty objects for each project and handle errors
    const realtiesWithObjects = await Promise.all(
      residential_projects.map(async (r) => {
        try {
          // const immo_wohneinheiten = await getRealtyList(13864141, 3255); //TESTING
          const immo_wohneinheiten = await getRealtyList(r.crm_id, r.tenant_id);
          const wohneinheiten = immo_wohneinheiten?.items?.map(parseImmo).filter(Boolean) || [];
					const filteredWohneinheiten = filterWohneinheiten(wohneinheiten, requestBody);
          return { ...r, wohneinheiten: filteredWohneinheiten };
        } catch (error) {
          console.error(`Error in getRealtyList:`, error);
          return r;
        }
      }),
    );

    let newList = list.map((realty) => {
      let realtyWithObjects = realtiesWithObjects.find((realtyWithObjects) => realtyWithObjects.id === realty.id);
      return realtyWithObjects || realty;
    });

    return newList;
  }

	/* group items in pairs of 3 for layout */
  const groupRealtyItems = (realtyItems) => {
    const groupedItems = [];
    let currentRow = [];

    realtyItems.forEach((item) => {
      // If the item has wohneinheiten (spans full width)
      if (item.wohneinheiten?.length > 0) {
        // Add the residential item as a full row
        groupedItems.push([item]);
      } else {
        // For regular realty items, accumulate them in the current row
        currentRow.push(item);

        // If the current row has 3 items, push it into the groupedItems and reset
        if (currentRow.length === 3) {
          groupedItems.push(currentRow);
          currentRow = [];
        }
      }
    });

    // Push any remaining items in the last row (if < 3)
    if (currentRow.length > 0) {
      groupedItems.push(currentRow);
    }

    return groupedItems;
  };

  const setRealtySearchList = async (requestBody = null, page = 1) => {
    if (!requestBody) {
      setImmoList([]);
      setImmoListCnt(0);
      return;
    }

    setError(false);
		dispatch(setFormIsLoading(true));

    const res = await getRealtySearch(requestBody, page);
    if (res.realties) {
      const updatedRealties = await getRealtiesWithWohneinheiten(res.realties, requestBody);

			let currentImmoList = immoList?.flat();
      const newImmoList = page === 1 ? updatedRealties : currentImmoList.concat(updatedRealties);

			setImmoList(groupRealtyItems(newImmoList));
      setImmoListCnt(res.resultCount);

      dispatch(setFormIsLoading(false));

      if (res.resultCount === 0) {
        setError(immoSearchNoResultsText);
      }

			if(page === 1) {
				handleScrollToResults();
			}
    } else {
      setImmoList([]);
      setImmoListCnt(0);

      dispatch(setFormIsLoading(false));
      setError(immoSearchErrorText);

      if(page === 1) {
				handleScrollToResults();
			}
    }
  };

  const setResidentialProjectsList = async (requestBody, page = 1) => {
		if (!requestBody) {
      setImmoList([]);
      setImmoListCnt(0);
      return;
    }

		setError(false);
		setNeubauLoading(true);
		dispatch(setFormIsLoading(true));

    const res = await getResidentialRealtySearch(requestBody, page);
    if (res.realties) {
      const updatedRealties = await getRealtiesWithWohneinheiten(res.realties, requestBody);
			

			let currentNeubauList = neubauList?.flat();
			const newNeubauList = page === 1 ? updatedRealties : currentNeubauList.concat(updatedRealties);

			
			setNeubauList(groupRealtyItems(newNeubauList));
      setNeubauListCnt(res.resultCount);

      dispatch(setFormIsLoading(false));
			setNeubauLoading(false);

      if (res.resultCount === 0) {
        setError(neubauprojekteSearchNoResultsText);
      }
    } else {
      setNeubauList([]);
      setNeubauListCnt(0);

      dispatch(setFormIsLoading(false));
      setError(immoSearchErrorText);
			setNeubauLoading(false);
    }
  };

  return {
    handleScrollToResults,
    setRealtySearchList,
    setResidentialProjectsList,
    error,
    immoList,
    immoListCnt,
    neubauList,
    neubauListCnt,
		neubauLoading
  };
};
