import React, { useEffect, } from 'react';
import { useNavigate } from 'react-router-dom';
import headerImage from '../assets/images/sREAL-2024-Webhero-16zu9-1-5-Verkaufen.jpg';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import MetaTags from '../components/MetaTags';
import Header from '../components/Header/Header';
import Quicklinks from '../components/Quicklinks';
import Card from '../components/Cards/Card';
import IconCheckmarkRound from '../components/Icons/IconCheckmarkRound';
import ContactFormCard from '../components/Cards/ContactFormCard';
import { useDispatch, useSelector } from 'react-redux';
import { setPageDialogOpenRegisterForm, setResetFieldsRegisterForm } from '../reducers/registerForm';
import ImmodriveImg from '../assets/images/Verkauf-Immodrive.png';
import MaklerupdatesImg from '../assets/images/Verkauf-Maklerupdates.png';
import { useLocation } from 'react-router-dom';
import HeaderCtaButtons from '../components/Header/HeaderCtaButtons';
import { setMenuType } from '../reducers/app';
import { MENU_KAUF, MENU_VERKAUF } from '../constants';
import { useHandleMenu } from '../hooks/menu/useHandleMenu';

function VerkaufenLanding() {
  const { isMobile } = useHandleScreenResize();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.user);

  const app = useSelector((state) => state.app);
	const {  updateMenu, determineVerkaufMenuId } = useHandleMenu();
  const navigate = useNavigate();

  const goToDashboard = () => {
		if(app.menuType === MENU_KAUF) {
			dispatch(setMenuType(MENU_VERKAUF));
			const newMenuId = determineVerkaufMenuId();
			updateMenu(newMenuId);
		}

    navigate('../' + app.root + '/dashboard');
  };

  useEffect(() => {
    const hash = location.hash;

    const scrollToElement = (id) => {
      const element = document.getElementById(id);
      if (element) {
        const offset = !isMobile ? 90 : 90;
        const elementPosition = element.offsetTop - offset;
        window.scrollTo({
          top: elementPosition,
          behavior: 'smooth',
        });
      }
    };

    switch (hash) {
      case '#info-statistiken':
        scrollToElement('fstatistiken');
        break;
      case '#aktuelles-board':
        scrollToElement('updates');
        break;
      case '#dokumente':
        scrollToElement('immodrive');
        break;
      default:
        window.scrollTo(0, 0);
    }
  }, [location.hash]);

  const headerCtabuttons = [
    <HeaderCtaButtons key="headerCtabuttons">
      {!user.isLoggedIn ? (
        <button className="button button--orange" onClick={() => dispatch(setPageDialogOpenRegisterForm(true))}>
          Jetzt registrieren
        </button>
      ) : (
        <button className="button button--orange" onClick={() => goToDashboard()}>
          Dashboard anzeigen
        </button>
      )}
    </HeaderCtaButtons>,
  ];

  const handleOpenRegisterForm = () => {
    dispatch(setResetFieldsRegisterForm());
    dispatch(setPageDialogOpenRegisterForm(true));
  };

  return (
    <>
      <MetaTags
        title={'Immobilie kaufen auf my-sreal.at | Die Online-Plattform für Ihre Immobilie'}
        description={'Sie wollen eine Wohnung kaufen oder ein Haus kaufen? my-sreal.at ist die Online-Plattform für Ihren Immobilienkauf. Einfach, transparent und digital kaufen.'}
        imageName={'mysreal_hero_kauf.jpg'}
      />
      <Header cssClasses="header--default header--withimage header--verkaufen">
        {!isMobile ? <img src={headerImage} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" /> : <div className="background-darkblue header__image" />}

        <div className="header__content-wrapper">
          <div className="header__content">
            <h1 className="mt-0 mb-0 text-white">
              Statistiken - alle Daten zu
              <br />
              Ihrer Immobilie im
              <br />
              Überblick
            </h1>
            <ul className="list--withcheckmark mt-100rem">
              <li>Verschickte Immobilienangebote zu Ihrer Immobilie</li>
              <li>Tagesaktuell alle erfolgten Besichtigungen in der Übersicht</li>
              <li>Klicks auf Ihr Immobilieninserat nach Plattform</li>
            </ul>
          </div>
          <div className="header__ctabuttons-wrapper">{headerCtabuttons}</div>
        </div>
      </Header>

      <Quicklinks
        list={[
          { label: 'immo-drive', id: 'immodrive' },
          { label: 'Aktuelles Board', id: 'updates' },
          { label: 'Kontakt', id: 'kontakt' },
        ]}
      />

      <div className="w-desktop--center">
        <p className="text-center">
          Mit s REAL verkaufen Sie Ihre Immobilie schnell, professionell und zum besten Preis. Das my-sreal Kundenportal macht Ihnen den Verkauf dabei so informativ und komfortabel wie möglich.
        </p>
      </div>
			
      <div className="w-desktop--center">
				<Card id="immodrive" cssClasses="card--landingpage mb-300rem" cardStyle={'topImage2Cols'} imagePath={ImmodriveImg}>
					<div>
						<h2>immo-drive</h2>
						<h4>Die digitale Ablage für alle kaufrelevanten Unterlagen</h4>
						<div className="landingpage-examples">
							<div className="line">
								<div className="lineicon">
									<IconCheckmarkRound />
								</div>
								<div className="linetext">Alle Unterlagen gesichert an einem Ort</div>
							</div>
							<div className="line">
								<div className="lineicon">
									<IconCheckmarkRound />
								</div>
								<div className="linetext">Mit einem Klick gesamte Bibliothek herunterladen</div>
							</div>
							<div className="line">
								<div className="lineicon">
									<IconCheckmarkRound />
								</div>
								<div className="linetext">Sicherer Prozess in der Übermittlung an Kaufinteressent:innen für klare Nachvollziebarkeit</div>
							</div>
						</div>
						{!user.isLoggedIn && (
							<button className="button button--blue" onClick={() => handleOpenRegisterForm()}>
								Jetzt registrieren
							</button>
						)}
					</div>
				</Card>
       
				<Card id="updates" cssClasses="card--landingpage mb-300rem" cardStyle={'topImage2Cols'} imagePath={MaklerupdatesImg}>
					<div>
						<h2>Aktuelles-Board</h2>
						<h4>Ihre Makler:in hält Sie immer up-to-date und benachrichtigt Sie zu allen Neuigkeiten:</h4>
						<div className="landingpage-examples">
							<div className="line">
								<div className="lineicon">
									<IconCheckmarkRound />
								</div>
								<div className="linetext">Aktivitäten</div>
							</div>
							<div className="line">
								<div className="lineicon">
									<IconCheckmarkRound />
								</div>
								<div className="linetext">Besichtigungsfeedback</div>
							</div>
							<div className="line">
								<div className="lineicon">
									<IconCheckmarkRound />
								</div>
								<div className="linetext">Nachrichten</div>
							</div>
							<div className="line">
								<div className="lineicon">
									<IconCheckmarkRound />
								</div>
								<div className="linetext">Neue Dokumente</div>
							</div>
							<div className="line">
								<div className="lineicon">
									<IconCheckmarkRound />
								</div>
								<div className="linetext">und mehr</div>
							</div>
						</div>

						{!user.isLoggedIn && (
							<button className="button button--blue" onClick={() => handleOpenRegisterForm()}>
								Jetzt registrieren
							</button>
						)}
					</div>
				</Card>

				<ContactFormCard id="kontakt" />
      </div>
    </>
  );
}

export default VerkaufenLanding;
