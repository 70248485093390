import IconHouseSearch from '../Icons/IconHouseSearch';
import LinkToSearchProfiles from '../LinkToSearchProfiles';
import Card from './Card';

import { SP_WITHOUT_IMMOS_TEXT } from '../../constants';

function SearchProfileWithouthImmos({ cssClasses }) {
  return (
    <Card cardStyle="topImage" cssClasses={`searchprofil-withoutimmos-card immo-card ${cssClasses}`} icon={<IconHouseSearch color="#fff" />}>
      <h4>Noch keine Angebote vorhanden</h4>
      <p>{SP_WITHOUT_IMMOS_TEXT}</p>
      <div>
        <LinkToSearchProfiles linkText={'Bestehendes Suchprofil bearbeiten'} />
      </div>
    </Card>
  );
}

export default SearchProfileWithouthImmos;
