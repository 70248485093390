import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMenuType, setRoot } from '../reducers/app';
import '../styles/stickysidebar.scss';
import IconArrowBack from './Icons/IconArrowBack';

import { useNavigate } from 'react-router-dom';
import { MENU_KAUF, MENU_VERKAUF, PHASE0, PHASE2_KAUF_SP_AKTIV, PHASE2_VERKAUF_MIT_AKTIVITAET, PHASE3_VERKAUF, PHASE4_VERKAUF, ROLE_INTERESSENT_NAME, root } from '../constants';
import { useHandleMenu } from '../hooks/menu/useHandleMenu';
import { getJustImmoContactsWithCategory } from '../util/generalUtils';

function StickyButtonDemo({ cssClasses = '' }) {
	const app = useSelector((state) => state.app);
	const user = useSelector((state) => state.user);

	const {  updateMenu, determineKaufMenuId, determineVerkaufMenuId } = useHandleMenu();

  const dispatch = useDispatch();
	const navigate = useNavigate();

 	const undoDemo = async () => {
		await dispatch(setRoot(root));

		if(!user.isLoggedIn) {
			dispatch(setMenuType(''));
			updateMenu(PHASE0);

			navigate({ pathname: root });
		}
		else {
			let isInteressent = getJustImmoContactsWithCategory(user.userObject, ROLE_INTERESSENT_NAME);
			if(isInteressent) {
				dispatch(setMenuType(MENU_KAUF));
				const newMenuId = determineKaufMenuId();
				updateMenu(newMenuId);
			}
			else {
				dispatch(setMenuType(MENU_VERKAUF));
				const newMenuId = determineVerkaufMenuId();
				updateMenu(newMenuId);
			}

			navigate(`${root}/dashboard`);
		}
	}

	const setDemoPhase = (newphase) => {
		if(!newphase) return;

		updateMenu(newphase);

		if(newphase === PHASE2_VERKAUF_MIT_AKTIVITAET || newphase === PHASE3_VERKAUF || newphase === PHASE4_VERKAUF) {
			dispatch(setMenuType(MENU_VERKAUF));
		}
		else if(newphase === PHASE2_KAUF_SP_AKTIV){
			dispatch(setMenuType(MENU_KAUF));
		}
	}

  return (
		<>
			<div className="stickySidebar-wrapper">
				<div className={`stickySidebar__btn ${cssClasses}`} onClick={() => undoDemo()}>
					<IconArrowBack />
					<span>Demo <br/>verlassen</span>
				</div>

				<div className='stickySidebar__panel'>
					<span className={`${app.menuId === PHASE2_VERKAUF_MIT_AKTIVITAET ? 'active' : ''}`} onClick={() => setDemoPhase(PHASE2_VERKAUF_MIT_AKTIVITAET)}> 
						Vermarktung<br/>
						Verkäufer:in
					</span>
					<span className={`${app.menuId === PHASE3_VERKAUF ? 'active' : ''}`} onClick={() => setDemoPhase(PHASE3_VERKAUF)}> 
						Verkauf
					</span>
					<span className={`${app.menuId === PHASE4_VERKAUF ? 'active' : ''}`} onClick={() => setDemoPhase(PHASE4_VERKAUF)}> 
						Nach dem<br/> 
						Verkauf
					</span>
					<span className={`${app.menuId === PHASE2_KAUF_SP_AKTIV ? 'active' : ''}`} onClick={() => setDemoPhase(PHASE2_KAUF_SP_AKTIV)}> 
						Dashboard<br/>
						Käufer:in
					</span>
				</div>
				
			</div>
		</>
  );
}

export default StickyButtonDemo;
