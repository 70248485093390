import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { send } from '../../api/Contact';
import { downloadDocument, downloadMarktberichtUrl } from '../../api/Documents';
import { getMarketReportList } from '../../api/JustImmo';
import marktBerichtImage from '../../assets/images/Mockup-Marktbericht-HQ.png';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import { setPreviousMarktBericht } from '../../reducers/marktBericht';
import { setPageDialogOpenRegisterForm, setResetFieldsRegisterForm } from '../../reducers/registerForm';
import '../../styles/wohnwert.scss';
import ButtonForwardAction from '../Buttons/ButtonForwardAction';
import MarktBerichtForm from '../Forms/MarktberichtForm';
import IconLoadingSpinner from '../Icons/IconLoadingSpinner';
import Card from './Card';

function MarktberichtCard({ cssClasses = '' }) {
  const dispatch = useDispatch();
  let marktberichtStore = useSelector((state) => state.marktBericht);
  let user = useSelector((state) => state.user);
	let app = useSelector((state) => state.app);

  const { isMobile } = useHandleScreenResize();
  let [lastMarktbericht, setLastMarktBericht] = useState(marktberichtStore.previousMarktBericht || null);

  let [wohnwertProSent, setWohnwertProSent] = useState(user?.userObject?.emails?.find((email) => email.type.includes('WohnwertPro')) || false);
  const [marktBerichtLoading, setMarktBerichtLoading] = useState(false);

  cssClasses += ' marktbericht-card';
  cssClasses = cssClasses.trim();

  const handleOpenRegisterForm = () => {
    dispatch(setResetFieldsRegisterForm());
    dispatch(setPageDialogOpenRegisterForm(true));
  };

  // const handleSendWohnwertPro = async () => {
  //   try {
  //     await send({
  //       reason: 'WohnwertPro',
  //       register: {},
  //       location: lastMarktbericht.location,
  //     });

  //     setWohnwertProSent(true);
  //   } catch (error) {
  //     console.log('error', error);
  //   }
  // };

  useEffect(() => {
    if (user.isLoggedIn && !marktberichtStore.previousMarktBericht) {
      setMarktBerichtLoading(true);
    }
  }, []);

  useEffect(() => {
    if (marktberichtStore.previousMarktBericht) {
      setLastMarktBericht(marktberichtStore.previousMarktBericht);
      setMarktBerichtLoading(false);
    }
  }, [marktberichtStore.previousMarktBericht]);

  useEffect(() => {
    if (user.isLoggedIn) {
      getMarketReports();
    }

    async function getMarketReports() {
      setMarktBerichtLoading(true);
      const response = await getMarketReportList();
      if (response && response.list?.length > 0) {
        setLastMarktBericht(response.list[0]);
        if (marktberichtStore.previousMarktBericht?.fileId !== response.list[0].fileId) {
          dispatch(setPreviousMarktBericht(response.list[0]));
        }
      }
      setMarktBerichtLoading(false);
    }
  }, [user]);

  // const downloadMarktbericht = async (lastMarktbericht) => {
  //   try {
  //     const marktberichtFile = await downloadDocument(lastMarktbericht.fileId, `Marktbericht_${lastMarktbericht.location?.zip}`)
  //     const url = window.URL.createObjectURL(marktberichtFile);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = `Marktbericht_${lastMarktbericht.location?.zip}`;
  //     a.click();
  //   } catch (e) {
  //     //handle realty file error
  //     console.error('e', e);
  //   }
  // };

  return (
    <Card cssClasses={cssClasses} cardStyle={'customContent'}>
      {!user.isLoggedIn && !app.demoMode? (
        <>
          <div className="card__content text-center">
            <h3>Kostenloser Marktbericht für Ihre Immobilie</h3>
          </div>
          <div className="marktbericht-card__content">
            <div className="marktbericht-card__text">
              <p>Erhalten Sie wichtige Infos zur Lage Ihrer Immobilie: Daten zu Infrastruktur, Erreichbarkeit, Lärmbelastung, Bevölkerungsentwicklung und mehr.</p>
              <p>Ideal für einen möglichen Verkauf Ihrer Immobilie.</p>
              <ul className="list--withcheckmark mt-100rem">
                <li>Adresse eingeben</li>
                <li>Bericht anfordern</li>
                <li>Direkt im Dashboard erhalten</li>
              </ul>
              <p className="text-bold pt-150rem">Jetzt registrieren und Marktbericht anfordern.</p>
              <ButtonForwardAction buttonText="Jetzt registrieren" forwardAction={handleOpenRegisterForm} buttonStyle="white" />
            </div>
            <img src={marktBerichtImage} alt="Marktbericht" className="marktbericht-card__image" />
          </div>
        </>
      ) : marktBerichtLoading ? (
        <>
          <div className="card__content text-center">
            <h3>Marktbericht</h3>
          </div>
          <div className="marktbericht-card__content">
            <div className="marktbericht-card__loading">
              <div className="marktbericht-card__loading-icon">
                <IconLoadingSpinner color={'#fff'} />
                <span className="text-white">Bitte haben Sie einen Moment Geduld.</span>
              </div>
            </div>
            <img src={marktBerichtImage} alt="Marktbericht" className="marktbericht-card__image" />
          </div>
        </>
      ) : (lastMarktbericht && !lastMarktbericht.fileId) || !lastMarktbericht || app.demoMode ? (
        <>
          <div className="card__content text-center">
            <h3>Marktbericht</h3>
          </div>
          <div className="marktbericht-card__content">
            <div className="marktbericht-card__text">
              <MarktBerichtForm />
            </div>
            <img src={marktBerichtImage} alt="Marktbericht" className="marktbericht-card__image" />
          </div>
        </>
      ) : lastMarktbericht && lastMarktbericht.fileId ? (
        <>
          <div className="card__content text-center">
            <h3>Marktbericht</h3>
          </div>
          <div className="marktbericht-card__content">
            <div className="marktbericht-card__text">
              <div>
                <p>
                  Ihr Marktbericht für die Adresse {lastMarktbericht.location?.street} {lastMarktbericht.location?.number}, {lastMarktbericht.location?.zip} {lastMarktbericht.location?.city},
                  angefordert am {moment(lastMarktbericht.submitted).format('DD.MM.YYYY')}, ist hier abrufbar:
                </p>

                <a className="textlink" href={downloadMarktberichtUrl(lastMarktbericht.fileId, `Marktbericht_${lastMarktbericht.location?.zip}`)} target="_blank" rel="noreferrer">
                  Marktbericht herunterladen
                </a>
                {/* <br/> */}
                {/* <span className="textlink"onClick={async () => await downloadMarktbericht(lastMarktbericht) }>
										Marktbericht herunterladen
									</span> */}
              </div>
              {/* <div className={wohnwertProSent ? 'mt-300rem' : ''}>
                <p className="text-bold">Als Ergänzung zu Ihrem Marktbericht:</p>
                <p>
                  die <span className="text-bold">gratis Wohnwert Pro Berechnung.</span> Holen Sie sich die professionelle Verkehrswertschätzung zum Wert Ihrer Immobilie - unverbindlich und kostenlos
                </p>
                {!wohnwertProSent ? (
                  <FormCheckbox
                    label={'Ich bin einverstanden, dass ich für eine Bewertung von einer s REAL Expert:in kontaktiert werde'}
                    required={true}
                    formType={'marktBericht'}
                    id="wohnwertProChecked"
                    value={marktberichtStore.wohnwertProChecked}
                  />
                ) : null }

                {!wohnwertProSent ? (
                  <ButtonForwardAction buttonText="Gratis anfordern" forwardAction={handleSendWohnwertPro} buttonStyle="white" disabled={!marktberichtStore.wohnwertProChecked} />
                ) : (
                  <div className="d-flex align-items-center">
                    <ButtonForwardAction buttonText="Angefragt" buttonStyle="white" disabled={true} />
                    <span className="text-white text-bold pl-10">Danke für Ihre Anfrage. Eine Immobilienexpert:in meldet sich bei Ihnen. </span>
                  </div>
                )}
              </div> */}
            </div>
            <img src={marktBerichtImage} alt="Marktbericht" className="marktbericht-card__image" />
          </div>
        </>
      ) : null}
    </Card>
  );
}

export default MarktberichtCard;
