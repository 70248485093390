import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import headerImage from '../assets/images/sREAL-2024-Webhero-16zu9-1-5-Kaufen.jpg';
import Header from '../components/Header/Header';
import MetaTags from '../components/MetaTags';
import Quicklinks from '../components/Quicklinks';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import Card from '../components/Cards/Card';
import IconCheckmarkRound from '../components/Icons/IconCheckmarkRound';
import ContactFormCard from '../components/Cards/ContactFormCard';
import { useDispatch, useSelector } from 'react-redux';
import { setPageDialogOpenRegisterForm, setResetFieldsRegisterForm } from '../reducers/registerForm';
import MarktanalyseImg from '../assets/images/Kauf-Marktanalyse.png';
import SucherdashbaordImg from '../assets/images/Kauf-Angebote.png';
import { useLocation } from 'react-router-dom';
import { setMenuType } from '../reducers/app';
import { MENU_KAUF, MENU_VERKAUF } from '../constants';
import { useHandleMenu } from '../hooks/menu/useHandleMenu';

function KaufenLanding() {
  const { isMobile } = useHandleScreenResize();
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state) => state.user);

  const app = useSelector((state) => state.app);
  const navigate = useNavigate();

	const {  updateMenu, determineKaufMenuId } = useHandleMenu();

  const goToDashboard = () => {
    if(app.menuType === MENU_VERKAUF) {
			dispatch(setMenuType(MENU_KAUF));
			const newMenuId = determineKaufMenuId();
			updateMenu(newMenuId);
		}

    navigate('../' + app.root + '/dashboard');
  };

  useEffect(() => {
    const hash = location.hash;

    const scrollToElement = (id) => {
      const element = document.getElementById(id);
      if (element) {
        const offset = 90;
        const elementPosition = element.offsetTop - offset;
        window.scrollTo({
          top: elementPosition,
          behavior: 'smooth',
        });
      }
    };

    switch (hash) {
      case '#such-profile':
        scrollToElement('suchprofile');
        break;
      case '#digitales-produkt':
        scrollToElement('fDigital');
        break;
      case '#markt-analyse':
        scrollToElement('marktanalyse');
        break;
      default:
        window.scrollTo(0, 0);
    }
  }, [location.hash]);

  const handleOpenRegisterForm = () => {
    dispatch(setResetFieldsRegisterForm());
    dispatch(setPageDialogOpenRegisterForm(true));
  };

  return (
    <>
      <MetaTags
        title={'Immobilie kaufen auf my-sreal.at | Die Online-Plattform für Ihre Immobilie'}
        description={'Sie wollen eine Wohnung kaufen oder ein Haus kaufen? my-sreal.at ist die Online-Plattform für Ihren Immobilienkauf. Einfach, transparent und digital kaufen.'}
        imageName={'mysreal_hero_kauf.jpg'}
      />
      <Header cssClasses="header--default header--withimage header--kaufen">
        {!isMobile ? <img src={headerImage} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" /> : <div className="background-aubergine header__image" />}
        <div className="header__content-wrapper">
          <div className="header__content">
            <h1 className="mt-0 mb-0 text-white">
              Mit my-sreal.at wickeln
              <br />
              Sie Ihren Immobilienkauf
              <br />
              digital ab.
            </h1>
            <ul className="list--withcheckmark mt-100rem">
              <li>Finanzierung direkt in my-sreal berechnen</li>
              <li>Digitale Kaufanbotsanfrage für Ihre Traumimmobilie</li>
              <li>Verfolgen Sie den kompletten Kaufprozess transparent in Ihrem Dashboard</li>
            </ul>
          </div>
          <div className="header__ctabuttons-wrapper mt-100rem">
            {!user.isLoggedIn ? (
              <button className="button button--orange" onClick={() => handleOpenRegisterForm()}>
                Jetzt registrieren
              </button>
            ) : (
              <button className="button button--orange" onClick={() => goToDashboard()}>
                Dashboard anzeigen
              </button>
            )}
          </div>
        </div>

        {/* cta buttons mobile-version fixed */}
        {/* {headerCtabuttons} */}
      </Header>
      <Quicklinks
        list={[
          { label: 'Sucherdashboard', id: 'suchprofile' },
          { label: 'Marktanalyse', id: 'marktanalyse' },
          { label: 'Kontakt', id: 'kontakt' },
        ]}
      />
      <div className="w-desktop--center">
        <p className="text-center">
          Mit my-sreal suchen Sie Immobilien wie ein Profi. Bearbeiten Sie Suchprofile einfach und flexibel, wenn sich Ihre Wünsche ändern. Und managen Sie Ihre Immobilienangebote mit wenigen Klicks.
        </p>
      </div>
      <div className="w-desktop--center">
				<Card cssClasses="card--landingpage mb-300rem" cardStyle={'topImage2Cols'} imagePath={SucherdashbaordImg} id="suchprofile">
					<div>
						<h2>Praktisches Sucherdashboard</h2>
						<div className="landingpage-examples">
							<div className="line">
								<div className="lineicon">
									<IconCheckmarkRound />
								</div>
								<div className="linetext">Alle Immobilienangebote auf einen Blick</div>
							</div>
							<div className="line">
								<div className="lineicon">
									<IconCheckmarkRound />
								</div>
								<div className="linetext">Bearbeiten Sie bis zu 3 aktive Suchprofile</div>
							</div>
							<div className="line">
								<div className="lineicon">
									<IconCheckmarkRound />
								</div>
								<div className="linetext">Bestimmen Sie Ihre Angebotsfavoriten</div>
							</div>
							<div className="line">
								<div className="lineicon">
									<IconCheckmarkRound />
								</div>
								<div className="linetext">Finden Sie alle kaufrelevanten Unterlagen an einem Ort</div>
							</div>
						</div>
						{!user.isLoggedIn && (
							<button className="button button--blue" onClick={() => handleOpenRegisterForm()}>
								Jetzt registrieren
							</button>
						)}
					</div>
				</Card>

				<Card cssClasses="card--landingpage mb-300rem" cardStyle={'topImage2Cols'} imagePath={MarktanalyseImg} id="marktanalyse">
					<div>
						<h2>Marktanalyse</h2>
						<div className="landingpage-examples">
							<div className="line">
								<div className="lineicon">
									<IconCheckmarkRound />
								</div>
								<div className="linetext">Überprüfen Sie eine Adresse auf ihre Infrastruktur</div>
							</div>
							<div className="line">
								<div className="lineicon">
									<IconCheckmarkRound />
								</div>
								<div className="linetext">Lage-Score zu Bildung, Gesundheit, Gastronomie, Einkaufen und Freizeit</div>
							</div>
							<div className="line">
								<div className="lineicon">
									<IconCheckmarkRound />
								</div>
								<div className="linetext">Bis zu 10 Marktanalysen können Sie in Ihrem Dashboard speichern und abrufen</div>
							</div>
						</div>
						{!user.isLoggedIn && (
							<button className="button button--blue" onClick={() => handleOpenRegisterForm()}>
								Jetzt registrieren
							</button>
						)}
					</div>
				</Card>

				<ContactFormCard id="kontakt" />
      </div>
    </>
  );
}

export default KaufenLanding;
