import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { persistReducer, persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import persistConfig from './storage';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import appSlice from './reducers/app';
import kaufAnbotSlice from './reducers/kaufAnbote';
import objectRequestSlice from './reducers/objectRequest';

import cachedSimilarImmosSlice from './reducers/cachedSimilarImmos';
import formValidatorsSlice from './reducers/formValidators';
import loginFormSlice from './reducers/loginForm';
import marktinfoLightSlice from './reducers/marktinfoLight';
import marktBerichtSlice from './reducers/marktBericht';
import notificationsSlice from './reducers/notifications';
import registerFormSlice from './reducers/registerForm';
import registerFormChangePhoneNumberSlice from './reducers/registerFormChangePhoneNumber';
import userSlice from './reducers/user';
import wohnwertLightSlice from './reducers/wohnwertLight';

const reducers = {
  app: appSlice,
  user: userSlice,
  loginForm: loginFormSlice,
  registerForm: registerFormSlice,
  registerFormChangePhoneNumber: registerFormChangePhoneNumberSlice,
  formValidators: formValidatorsSlice,
  marktinfoLight: marktinfoLightSlice,
  marktBericht: marktBerichtSlice,
  wohnwertLight: wohnwertLightSlice,
  kaufAnbote: kaufAnbotSlice,
  objectRequest: objectRequestSlice,
  cachedSimilarImmos: cachedSimilarImmosSlice,
  notifications: notificationsSlice,
};

const rootReducer = combineReducers(reducers);
const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
});

let persistor = persistStore(store);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Router>,
);
