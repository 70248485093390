import Card from './Card';
import IconBox from '../IconBox';

function IconContentCard({ cssClasses = '', iconBox, children }) {
  if (!cssClasses) cssClasses = 'icon-content-card';
  else cssClasses += ' icon-content-card';

  let cardStyle = 'customContent';

  return (
    <>
      <Card cssClasses={cssClasses + ' text-center'} cardStyle={cardStyle}>
        <div className="card__content">
          <div>
            <h2>Von Anfang bis Ende bestens betreut</h2>
            <p className="icon-content-card__subheadline">
              Sie suchen eine Immobilie oder möchten ein Objekt verkaufen? Dann ist jetzt der richtige Zeitpunkt sich zu registrieren. s REAL unterstützt Sie ab Ihrem ersten Suchprofil oder
              Beratungsgespräch zu einem Immobilienverkauf bis zur erfolgreichen Übergabe und darüber hinaus. Dabei ist das Portal ihr ständiger Begleiter.{' '}
            </p>
            <div className="icon-box-wrapper">
              {iconBox.map((iconBox, index) => {
                return <IconBox headline={iconBox.headline} color={iconBox.color} text={iconBox.text} icon={iconBox.icon} key={'iconbox-' + index} />;
              })}
            </div>
          </div>
          {children}
        </div>
      </Card>
    </>
  );
}

export default IconContentCard;
