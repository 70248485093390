import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setPrivacyAggrementRegisterForm } from '../../reducers/registerForm';
import { setRememberMe } from '../../reducers/loginForm';
import { setMarktBerichtIsOwner, setWohnwertProChecked } from '../../reducers/marktBericht';
import { setWantsToReceiveMails } from '../../reducers/user';

const FormCheckbox = ({ id = '', label, error = '', value, formType = '', callback, labelFirst = false, cssClasses = '', disabled = false }) => {
  const dispatch = useDispatch();
	let app = useSelector((state) => state.app);

  const handleCheckboxChange = () => {
    if (callback) {
      callback(!value);
    } else {
     if (formType === 'loginForm') {
        switch (id) {
          case 'inputRememberMe':
            dispatch(setRememberMe(!value));
            break;
          default:
            break;
        }
      } else if (formType === 'registerForm') {
        switch (id) {
          case 'inputPrivacyAggrement':
            dispatch(setPrivacyAggrementRegisterForm(!value));
            break;

          default:
            break;
        }
      } else if (formType === 'marktBericht') {
        switch (id) {
          case 'inputIsOwner':
            dispatch(setMarktBerichtIsOwner(!value));
            break;
          case 'wohnwertProChecked':
            dispatch(setWohnwertProChecked(!value));
            break;
          default:
            break;
        }
      } else if (formType === 'profileEmailSettingsForm') {
        switch (id) {
          case 'emailSettingsWantsToReceiveMails':
            dispatch(setWantsToReceiveMails(!value));
            break;
          default:
            break;
        }
      } else if (formType === 'marktBericht') {
        switch (id) {
          case 'inputIsOwner':
            dispatch(setMarktBerichtIsOwner(!value));
            break;
          default:
            break;
        }
      }
    }
  };

  return (
    <div className={`${cssClasses} form__field form__field--checkbox`}>
      {labelFirst && (
        <label htmlFor={id}>
          {label}
          {error && <p className={'form__fielderror'}>{error}</p>}
        </label>
      )}

      <input
        id={id}
        className="input"
        type="checkbox"
        checked={value}
				disabled={disabled || app.formIsLoading}
        onChange={(e) => {
          handleCheckboxChange();
        }}
      />

      {!labelFirst && (
        <label htmlFor={id}>
          {label}
          {error && <p className={'form__fielderror'}>{error}</p>}
        </label>
      )}
    </div>
  );
};

export default FormCheckbox;
