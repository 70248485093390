import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// FORM
import FormCheckbox from '../../FormFields/FormCheckbox';
import FormInput from '../../FormFields/FormInput';
import FormRadio from '../../FormFields/FormRadio';
import { genderOptions } from '../../FormFields/util/formOptions';
import FormSelectPhoneNumber from '../../FormFields/customFields/FormSelectPhoneNumber';
import { validateFields } from '../../FormFields/util/validateFields';
import '../../../styles/vorwahllist.scss';

import {
  REGFORM_SITEINDEX_PW,
  REGFORM_SITEINDEX_INFO_MOBILNUMMER,
  REGFORM_SITEINDEX_START,
  REGFORM_SITEINDEX_EMAIL_BESTAETIGEN,
  DIREKTREGFORM_SITEINDEX_START,
  DIREKTREGFORM_SITEINDEX_INFO_MOBILNUMMER,
  DIREKTREGFORM_SITEINDEX_PW,
  DIREKTREGFORMINTERESSENT_SITEINDEX_START,
} from '../../../constants';

// STORE
import { setSiteIndexRegisterForm } from '../../../reducers/registerForm';
import FormTextDsgvo from '../../FormFields/util/formTextDsgvo';
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import InfoDialogPhoneNumber from '../../Dialogs/InfoDialogPhoneNumber';
import { combineReduxPhoneParts } from '../../../util/phoneNumberParser';
import { useLocation, useSearchParams } from 'react-router-dom';

function RegisterFormMain() {
  let registerForm = useSelector((state) => state.registerForm);
  let currentFormValidators = useSelector((state) => state.formValidators);
  const dispatch = useDispatch();
  let location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [hasPrefilledFields, setHasPrefilledFields] = useState(false);

  useEffect(() => {
    const potentialRegisterBody = getPotentialRegisterBody(location);
    if (potentialRegisterBody) {
      setHasPrefilledFields(true);
    }
  }, []);

  const [openInfoDialogMobilnummer, setOpenInfoDialogMobilnummer] = useState(false);
  const handleSetOpenInfoDialogMobilnummer = () => {
    let val = !openInfoDialogMobilnummer;

    if (isDirektReg) {
      if (val) {
        window.location.hash = DIREKTREGFORM_SITEINDEX_INFO_MOBILNUMMER;
      } else {
        window.location.hash = DIREKTREGFORM_SITEINDEX_START;
      }
    }
    if (isDirektregInteressent) {
      if (val) {
        window.location.hash = DIREKTREGFORM_SITEINDEX_INFO_MOBILNUMMER;
      } else {
        window.location.hash = DIREKTREGFORMINTERESSENT_SITEINDEX_START;
      }
    } else {
      if (val) {
        window.location.hash = REGFORM_SITEINDEX_INFO_MOBILNUMMER;
      } else {
        window.location.hash = REGFORM_SITEINDEX_START;
      }
    }

    setOpenInfoDialogMobilnummer(val);
  };

  const getPotentialRegisterBody = (url) => {
    let parsedURL = url.pathname.split('/').filter((part) => part !== '');
    let registerBodyForRegisterForm = null;
    const UUID_REGEX = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;
    const UUIDIndex = parsedURL.findIndex((element) => UUID_REGEX.test(element));
    const urlHasTrackingId = UUIDIndex !== -1 && UUIDIndex < parsedURL.length - 1;

    if (urlHasTrackingId) {
      if (parsedURL.length > UUIDIndex + 2) {
        try {
          const decodedRegisterBody = window.atob(parsedURL[UUIDIndex + 1]);
          try {
            const parsedBody = JSON.parse(decodedRegisterBody);
            if (parsedBody && typeof parsedBody === 'object') {
              registerBodyForRegisterForm = parsedBody;
            }
          } catch {
            registerBodyForRegisterForm = decodedRegisterBody;
          }
        } catch {
          registerBodyForRegisterForm = parsedURL[UUIDIndex + 1];
        }
      }
    }

    return registerBodyForRegisterForm;
  };

  const handleEmailAdresseBestaetigen = () => {
    dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_EMAIL_BESTAETIGEN));
  };

  const formType = 'registerForm';
  const forwardAction = () => {
    let validationResult = validateFields(
      {
        gender: registerForm.requestBody.gender,
        firstName: registerForm.requestBody.firstName,
        lastName: registerForm.requestBody.lastName,
        email: registerForm.requestBody.email,
        phone: combineReduxPhoneParts(registerForm.phoneNumberPrefix, registerForm.parsedPhoneNumber),
        privacyPolicy: registerForm.requestBody.privacyAggrement,
      },
      dispatch,
    );

    if (validationResult) {
      if (isDirektReg || isDirektregInteressent) {
        dispatch(setSiteIndexRegisterForm(DIREKTREGFORM_SITEINDEX_PW));
      } else {
        dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_PW));
      }
    }
  };

  const [isDirektReg, setIsDirektReg] = useState(false);
  const [isDirektregInteressent, setIsDirektregInteressent] = useState(false);
  useEffect(() => {
    if (location.pathname.indexOf('/direct-registration-confirm') >= 0) {
      searchParams.delete('UserId');
      searchParams.delete('Code');
      setSearchParams(searchParams);

      if (registerForm.siteIndex === DIREKTREGFORM_SITEINDEX_START) {
        setIsDirektReg(true);
        setIsDirektregInteressent(false);
        dispatch(setSiteIndexRegisterForm(DIREKTREGFORM_SITEINDEX_START));
      } else if (registerForm.siteIndex === DIREKTREGFORMINTERESSENT_SITEINDEX_START) {
        setIsDirektReg(false);
        setIsDirektregInteressent(true);
        dispatch(setSiteIndexRegisterForm(DIREKTREGFORMINTERESSENT_SITEINDEX_START));
      }
    }
  }, []);

  return (
    <>
      <h2 className="pb-200rem">
        Jetzt registrieren
        <br />
        bei my-sreal.at
      </h2>

      <FormRadio options={genderOptions} id={'inputGender'} selectedValue={registerForm.requestBody.gender} error={currentFormValidators.genderError} formType={formType} />

      <div className="form__row form__row--double">
        <FormInput type={'text'} id={'inputTitleBefore'} label={'Titel (vorgestellt)'} value={registerForm.requestBody.titleBefore} required={false} formType={formType} />

        <FormInput type={'text'} id={'inputTitleAfter'} label={'Titel (nachgestellt)'} value={registerForm.requestBody.titleAfter} required={false} formType={formType} />
      </div>

      <FormInput
        type={'text'}
        id={'inputFirstName'}
        label={'Vorname'}
        value={registerForm.requestBody.firstName}
        error={currentFormValidators.firstNameError}
        required={true}
        disabled={isDirektReg ? true : false}
        formType={formType}
      />

      <FormInput
        type={'text'}
        id={'inputLastName'}
        label={'Nachname'}
        value={registerForm.requestBody.lastName}
        error={currentFormValidators.lastNameError}
        required={true}
        disabled={isDirektReg ? true : false}
        formType={formType}
      />

      <FormInput
        type={'email'}
        id={'inputEmail'}
        label={'E-Mail-Adresse'}
        value={registerForm.requestBody.email}
        error={currentFormValidators.emailError}
        required={true}
        disabled={isDirektReg || isDirektregInteressent || hasPrefilledFields ? true : false}
        formType={formType}
      />

      <FormSelectPhoneNumber formType={formType} form={registerForm} disabled={isDirektReg ? true : false} />

      <span className="textlink font-80 mt-50rem d-flex" onClick={handleSetOpenInfoDialogMobilnummer}>
        Deshalb benötigen wir Ihre Mobilnummer
      </span>

      <h3 className="form__title pt-100rem">Zustimmungen</h3>
      <FormCheckbox
        id="inputPrivacyAggrement"
        label={<FormTextDsgvo />}
        required={true}
        value={registerForm.requestBody.privacyAggrement}
        error={currentFormValidators.privacyPolicyError}
        formType={formType}
      />

      <div className="button-panel pt-200rem">
        <ButtonForwardAction formType={formType} forwardAction={forwardAction} buttonText={'Registrieren'} />
      </div>

      {!isDirektReg && !isDirektregInteressent && (
        <p className="mb-200rem font-90">
          Sie haben die Registrierung begonnen aber noch nicht abgeschlossen?
          <span className="textlink d-flex w-100 mt-50rem" onClick={() => handleEmailAdresseBestaetigen()}>
            Bestätigungslink nochmal anfordern
          </span>
        </p>
      )}

      <InfoDialogPhoneNumber open={openInfoDialogMobilnummer} handleClose={handleSetOpenInfoDialogMobilnummer} />
    </>
  );
}

export default RegisterFormMain;
