import { motion } from 'framer-motion';

function SearchProfileCreated({ handleClose }) {
  const pathVariants = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      transition: {
        duration: 1.5,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <>
      <div className="d-flex w-100 justify-content-center pt-40 pb-40">
        <svg data-name="Gruppe 17258" xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 130 130">
          <g data-name="Gruppe 17259">
            <path data-name="Pfad 10500" d="M65,0a65,65,0,1,0,65,65A65.191,65.191,0,0,0,65,0m0,120.792A55.792,55.792,0,1,1,120.792,65,56.061,56.061,0,0,1,65,120.792" fill="#0eb43f" />
            <motion.path
              data-name="Pfad 10501"
              d="M13.84,50.92,33.118,66.333a4.258,4.258,0,0,0,6.34-1.184L69.41,13.672"
              transform="translate(23.643 23.356)"
              fill="none"
              stroke="#0eb43f"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="10.5"
              variants={pathVariants}
              initial="hidden"
              animate="visible"
            />
          </g>
        </svg>
      </div>

      <h2>Ihr Suchprofil wurde erfolgreich erstellt.</h2>
      <p>Ihr Suchprofil wurde erfolgreich erstellt und befindet sich nun in Bearbeitung. Eine Immobilienexpert:in schaltet Sie in Kürze frei.</p>

      <div className="button-panel pt-100rem">
        <button className="button button--gray-outline" onClick={() => handleClose()}>
          Schließen
        </button>
      </div>
    </>
  );
}

export default SearchProfileCreated;
