import { useDispatch, useSelector } from 'react-redux';

import ImmoCardAbgeber from '../Cards/ImmoCardAbgeber';
import Linkitem from '../Linkitems/Linkitem';
import IconHouse from '../Icons/IconHouse';

import { CONTACTFORM_ANLIEGEN_VERKAUF, PAGEDIALOG_CONTACT } from '../../constants';

// STORE
import { setPageDialogOpenId } from '../../reducers/app';
import { demoUserObject } from '../../demodata/demouser';

function ProfileImmobilien({ currentUserRealties }) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();


  const handleWeitereImmos = () => {
		localStorage.setItem('mysreal_contact_reason', CONTACTFORM_ANLIEGEN_VERKAUF);
		dispatch(setPageDialogOpenId(PAGEDIALOG_CONTACT));
  };
	
  return (
    <>
      {(user || demoUserObject) && currentUserRealties?.length > 0 ? (
        <>
          {currentUserRealties.length > 1 ? <h2 className="mt-300rem text-center--mobile">Meine Immobilien</h2> : <h2 className="mt-300rem text-center--mobile">Meine Immobilie</h2>}

          {currentUserRealties.map((immo) => {
            return <ImmoCardAbgeber retailObject={immo} key={immo.id} />;
          })}

          <Linkitem
            cssClasses={'linkitem--blue linkitem--einzeilig-desktop'}
            handleLink={handleWeitereImmos}
            icon={<IconHouse />}
            headline="Weitere Immobilie verkaufen"
            text="Unsere Immobilienexpert:innen beraten Sie gerne"
          />
        </>
      ) : (
        <>
          <h2 className="mt-300rem">Immobilie verkaufen?</h2>
          <Linkitem
            cssClasses={'linkitem--blue linkitem--einzeilig-desktop'}
            handleLink={handleWeitereImmos}
            icon={<IconHouse />}
            headline="Immobilie verkaufen"
            text="Unsere Immobilienexpert:innen beraten Sie gerne"
          />
        </>
      )}
    </>
  );
}

export default ProfileImmobilien;
