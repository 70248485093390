import { interessentimmo1_parsed, interessentimmo2_parsed, interessentimmo3_parsed, interessentimmo4_parsed } from './demo_interessentimmos';

export const demoSearchProfile = {
	"id": 3290899,
	"contact": {
			"id": 32212189
	},
	"agent": {
			"id": 23618961
	},
	"creator": {
			"id": 32212189
	},
	"created_at": "2025-02-06T15:34:19+02:00",
	"updated_at": "2025-03-23T21:29:32+01:00",
	"expires_at": "2025-09-23",
	"state": "active",
	"auto_description": "Kauf, Zimmer: ab 1, Ort: Niederösterreich, Wohnung",
	"notes": "",
	"criteria": {
			"types": [
					{
							"id": 2,
							"name": "Wohnung",
							"openimmo_key": "Wohnung"
					}
			],
			"subtypes": [],
			"occupancy": "residential",
			"marketing_type": "buy",
			"rent_duration": {
					"from": null,
					"unlimited": false
			},
			"price": {
					"from": null,
					"to": null
			},
			"price_per_area": {
					"from": null,
					"to": null
			},
			"rooms": {
					"from": 3,
					"to": null
			},
			"yield": {},
			"site_area": {
					"from": null,
					"to": null
			},
			"living_area": {
					"from": null,
					"to": null
			},
			"floor_area": {
					"from": null,
					"to": null
			},
			"office_area": {
					"from": null,
					"to": null
			},
			"sales_area": {
					"from": null,
					"to": null
			},
			"storage_area": {
					"from": null,
					"to": null
			},
			"commission_free": false,
			"location": {
					"location_ids": [
							{
									"type": "city",
									"id": 3491,
									"text": "Mödling"
							},
							{
								"type": "city",
								"id": 3492,
								"text": "Mannswörth"
							},
							{
							"type": "city",
							"id": 3493,
							"text": "Oberwaltersdorf"
						}
					]
			},
			"architecture": null,
			"year_of_construction": {
					"from": null,
					"to": null
			},
			"conditions": [],
			"energy_performance_class": null,
			"category_ids": [],
			"furnishings": [],
			"covered_balcony": {
					"enabled": false,
					"from": null
			},
			"balcony": {
					"enabled": false,
					"from": null
			},
			"terrace": {
					"enabled": false,
					"from": null
			},
			"garden": {
					"enabled": false,
					"from": null
			},
			"accessible": false,
			"features": []
	},
	"search_contract": false,
	"search_contract_accepted_at": null,
	"automatic_hits": false,
	"name": "Demo Suchprofil",
	// "immos": []
}



export const demoSearchProfileMenu = [
	{
			"key": 0,
			"label": "Alle",
			"immos": [
				interessentimmo1_parsed,
				interessentimmo2_parsed,
				interessentimmo3_parsed,
				interessentimmo4_parsed
			]
	},
	{
			"key": 1,
			"label": "Demo Suchprofil",
			"immos": [
				interessentimmo1_parsed,
				interessentimmo2_parsed,
				interessentimmo3_parsed
			]
	},
	{
			"key": 2,
			"label": "Weitere Immobilien",
			"immos": [
				interessentimmo4_parsed
			]
	}
];