import { useDispatch, useSelector } from 'react-redux';
import { getMySearchProfiles, getSearchProfilesResult } from '../../api/JustImmo';
import { current } from '../../api/Onboarding';
import {
  JUSTIMMO_TYPE_CITY_ID,
  JUSTIMMO_TYPE_CITY_LABEL,
  JUSTIMMO_TYPE_CITYZIP_ID,
  JUSTIMMO_TYPE_CITYZIP_LABEL,
  JUSTIMMO_TYPE_FEDERAL_STATE_ID,
  JUSTIMMO_TYPE_FEDERAL_STATE_LABEL,
  JUSTIMMO_TYPE_MUNICIPALITY_ID,
  JUSTIMMO_TYPE_MUNICIPALITY_LABEL,
  JUSTIMMO_TYPE_POLITICAL_DISTRICT_ID,
  JUSTIMMO_TYPE_POLITICAL_DISTRICT_LABEL,
  JUSTIMMO_TYPE_ZIP_ID,
  JUSTIMMO_TYPE_ZIP_LABEL,
  SEARCHPROFILE_STATE_EXPIRED,
  SEARCHPROFILE_STATE_INACTIVE,
} from '../../constants';
import { setUserSearchProfiles } from '../../reducers/user';
import { getDataForImmoCard } from '../../util/immoObjectParser';
import { useCallback } from 'react';
import { demoSearchProfileMenu } from '../../demodata/demosearchprofile';

export const useFetchSearchprofiles = () => {
  const user = useSelector((state) => state.user);
	const app = useSelector((state) => state.app);
  const kaufanbote = useSelector((state) => state.kaufAnbote);
  const dispatch = useDispatch();

  const getAndSetSearchprofiles = async (loadWithHits = true, userResultSearchProfiles = null) => {
    try {
 			const sp = await getMySearchProfiles();
      if (!sp) return;

			if(!userResultSearchProfiles && user.userObject?.results) {
				userResultSearchProfiles = user.userObject.results.filter((res) => res.type === 'SearchProfile');
			}
		
      // Add names to search profiles
      let spWithName = sp.map((item, index) => {
				const defaultName = `Suchprofil ${index}`;
				let spName = '';
				
				if (userResultSearchProfiles?.length) {	
					const foundUserSearchprofile = userResultSearchProfiles?.find((sp) => sp?.metaJson?.id === item.id);
					spName = foundUserSearchprofile.metaJson?.name || defaultName;
				}

				return {
					...item,
					name: spName || defaultName
				}
      	});

      if (loadWithHits) {
        const searchProfileResults = await getAllSearchProfileHits();
        if (searchProfileResults) {
          spWithName = spWithName.map((sp) => ({
            ...sp,
            immos: searchProfileResults
              .filter((res) => res.searchProfileId === sp.id)
              .map((res) => getDataForImmoCard(res.realty)),
          }));
        }
      }
      
      dispatch(setUserSearchProfiles(spWithName));

			// needed for useHandleUser() hook
			if(loadWithHits) return spWithName;

    } catch (error) {
      console.error('Error fetching search profiles:', error);
    }
  };

  /**
   * Get search profiles filtered by state
   * @param {string} state - State to filter by
   * @returns {array} - Filtered search profiles
   */
  const getSearchprofilesByState = (state) => {
    return user.searchProfiles.filter((sp) => sp.state === state);
  };

  /**
   * Get all search profile hits from API
   * @returns {Promise<array>} - Search profile hits
   */
  const getAllSearchProfileHits = async () => {
    try {
      return await getSearchProfilesResult();
    } catch (error) {
      console.error('Error fetching search profile results:', error);
      return [];
    }
  };

  /**
   * sort an array of searchprofile hits
   * sort by last expose date (= searchprfile hit was created)
   */
  const sortSearchprofileHits = (immoArray) => {
    if (!immoArray?.length) return [];
    
    return [...immoArray].sort((a, b) => {
      const exposeA = a.documentsPrivate?.find((doc) => doc.isExpose);
      const exposeB = b.documentsPrivate?.find((doc) => doc.isExpose);
      
      if (exposeA?.originalDate && exposeB?.originalDate) {
        return new Date(exposeB.originalDate) - new Date(exposeA.originalDate);
      }
      return 0;
    });
  };
  
  /**
   * Build menu structure for search profiles
   * @returns {array} - Menu structure with property data
   */
  const getSearchprofilesMenu = useCallback(() => {
		if(app.demoMode) return demoSearchProfileMenu;

		if(!user.isLoggedIn) return;
    // Filter active search profiles
    const activeProfiles = user.searchProfiles.filter((sp) => sp.state !== SEARCHPROFILE_STATE_INACTIVE && sp.state !== SEARCHPROFILE_STATE_EXPIRED);
    
    // Create menu items for each search profile
    let menu = activeProfiles.map((sp, index) => ({
      key: index + 1,
      label: sp.name,
      immos: sortSearchprofileHits(sp.immos),
    }));

    // Get all properties from search profiles
    const spImmos = menu
      .filter((m) => m.immos?.length > 0)
      .flatMap((m) => m.immos);

    // Get additional properties not in search profiles
    let weitereImmos = [];
    if (kaufanbote.kaufanboteList?.length > 0) {
      const allSpImmosIds = spImmos.map((immo) => immo.id);
      weitereImmos = sortSearchprofileHits(
        kaufanbote.kaufanboteList.filter((immo) => !allSpImmosIds.includes(immo.id))
      );
    }

    // Combine and sort all properties
    const alleImmos = sortSearchprofileHits([...spImmos, ...weitereImmos]);

    // Add "All" option at beginning
    menu.unshift({
      key: 0,
      label: 'Alle',
      immos: alleImmos,
    });

    menu.push({
      key: menu.length,
      label: 'Weitere Immobilien',
      immos: weitereImmos,
    });
    
    return menu;
  }, [user.searchProfiles, user.isLoggedIn, kaufanbote.kaufanboteList]);

  /**
   * Extract federal state from location IDs
   * @param {array} location_ids - Location data array
   * @returns {object|null} - Federal state object or null
   */
  const getFederalStateFromRequestBody = (location_ids) => {
    if (!location_ids) return null;
    
    return location_ids.find(
      (loc) => loc.type === JUSTIMMO_TYPE_FEDERAL_STATE_LABEL || 
               loc.type === JUSTIMMO_TYPE_FEDERAL_STATE_ID
    );
  };

  /**
   * Extract districts from location IDs
   * @param {array} location_ids - Location data array
   * @returns {array|null} - Districts array or null
   */
  const getDistrictsFromRequestBody = (location_ids) => {
    if (!location_ids) return null;
    
    return location_ids.filter(
      (loc) => loc.type !== JUSTIMMO_TYPE_FEDERAL_STATE_LABEL && 
               loc.type !== JUSTIMMO_TYPE_FEDERAL_STATE_ID
    );
  };

  /**
   * Convert location type label to ID
   * @param {string|number} label - Location type label
   * @returns {number|null} - Location type ID
   */
  const getLocationTypeIdByLabel = (label) => {
    if (!isNaN(label)) return label;
    
    const typeMap = {
      [JUSTIMMO_TYPE_POLITICAL_DISTRICT_LABEL]: JUSTIMMO_TYPE_POLITICAL_DISTRICT_ID,
      [JUSTIMMO_TYPE_CITY_LABEL]: JUSTIMMO_TYPE_CITY_ID,
      [JUSTIMMO_TYPE_MUNICIPALITY_LABEL]: JUSTIMMO_TYPE_MUNICIPALITY_ID,
      [JUSTIMMO_TYPE_ZIP_LABEL]: JUSTIMMO_TYPE_ZIP_ID,
      [JUSTIMMO_TYPE_CITYZIP_LABEL]: JUSTIMMO_TYPE_CITYZIP_ID,
    };
    
    return typeMap[label] || null;
  };

  return {
    getAndSetSearchprofiles,
    getSearchprofilesByState,
    getSearchprofilesMenu,
    getFederalStateFromRequestBody,
    getDistrictsFromRequestBody,
    getLocationTypeIdByLabel,
  };
};
