import PageDialog from './PageDialog';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import { FormProvider, useForm } from 'react-hook-form';
import { createSearchProfile, renameSearchProfile, updateSearchProfile } from '../../api/JustImmo';
import { getLocations } from '../../api/Sreal';
import { JUSTIMMO_IMMOBILIENART_HAUS, JUSTIMMO_IMMOBILIENART_WOHNUNG, JUSTIMMO_TYPE_FEDERAL_STATE_ID, MENU_KAUF, PAGEDIALOG_SUCHPROFIL, PHASE1_KAUF, PHASE2_KAUF_SP_AKTIV, SEARCHPROFILE_SITEINDEX_EMAIL, SEARCHPROFILE_SITEINDEX_START, SEARCHPROFILE_SITEINDEX_TOOMANY, SEARCHPROFILE_SITEINDEX_USERDATA, SEARCHPROFILE_STATE_ACTIVE } from '../../constants';
import { useHandleMenu } from '../../hooks/menu/useHandleMenu';
import { useFetchSearchprofiles } from '../../hooks/searchprofiles/useFetchSearchprofiles';
import { closePageDialog, setFormIsLoading, setPageDialogOpenId } from '../../reducers/app';
import { setCachedSimilarImmos, setLastCachedDate } from '../../reducers/cachedSimilarImmos';
import { tryUpdateUserResult, updateSearchProfileInUser } from '../../reducers/user';
import { checkIfHashIsPagedialog, findPhonePrefix, handleScrollToErrorPageDialog, isJson } from '../../util/generalUtils';
import { exteriorSurfacesOptionsJustImmo, immobilienartOptionsJustImmo, phoneNumberPrefixOptions } from '../FormFields/util/formOptions';
import { fetchErrorText, searchProfileNameAlreadyExistsErrorText, tooManySearchProfilesErrorText } from '../FormFields/util/formTexts';
import statesWithZips from '../FormFields/util/statesWithZips';
import SearchData from '../Forms/SearchProfile/SearchData';
import SearchProfileCreated from '../Forms/SearchProfile/SearchProfileCreated';
import SearchProfileTooMany from '../Forms/SearchProfile/SearchProfileTooMany';
import UserData from '../Forms/SearchProfile/UserData';

function PageDialogSearchProfileForm() {
	const methods = useForm(); // Provides form state & methods
	const { setValue, watch } = methods; 

  const app = useSelector((state) => state.app);
	const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

	const [step, setStep] = useState(SEARCHPROFILE_SITEINDEX_START);
	const [error, setError] = useState('');
	const [editFormId, setEditFormId] = useState(null);

	const { getAndSetSearchprofiles, getFederalStateFromRequestBody, getDistrictsFromRequestBody, getLocationTypeIdByLabel, getSearchprofilesByState } = useFetchSearchprofiles();
	const {  updateMenu } = useHandleMenu();

	const austrianPrefix = phoneNumberPrefixOptions.find((option) => option.id === 43);
	
	const initialFormState = useMemo(() => {
		let phoneNum = '';
		let phonePrefix = austrianPrefix;
		if(user.userObject.phoneNumber) {
			phoneNum = user.userObject.phoneNumber;
			const foundPrefix = findPhonePrefix(phoneNum);
			
			if(foundPrefix) {
				phonePrefix = foundPrefix;
				const prefixLength = (foundPrefix.id).toString().length + 1; // + 1 for '+' character
        phoneNum = user.userObject.phoneNumber.substring(prefixLength);
			}
		}

		return {
			name: '',
			state: '',
			district: [],
			estateType: '',
			priceTo: '',
      priceFrom: '',
			areaFrom: '',
      areaTo: '',
			roomsFrom: '',
			exteriorSurface: [],
			gender: user.userObject?.gender || '',
			titleBefore: user.userObject?.titleBefore || '',
			titleAfter: user.userObject?.titleAfter || '',
			firstName: user.userObject?.firstName || '',
			lastName: user.userObject?.lastName || '',
			email: user.userObject?.userName || '',
			phoneNumberPrefix: phonePrefix,
			phoneNumber: phoneNum,
			privacyAggrement: user.isLoggedIn ? true : false,
			createUser: false,
		}
	}, [user.userObject, user.isLoggedIn, austrianPrefix]);

	/**
	 * SET WINDOW HASH
	 * window hash changes when "reason" or step changes
	 * used for tracking
	 */
	const setWindowHash = useCallback(() => {
    if (app.pageDialogOpenId !== PAGEDIALOG_SUCHPROFIL) return;
			window.location.hash = step.toString();;
    
  }, [app.pageDialogOpenId, step]);

	useEffect(() => {
    setWindowHash();
  }, [step, app.pageDialogOpenId, setWindowHash]);

	useEffect(() => {
		if(app.pageDialogOpenId === '' && checkIfHashIsPagedialog(SEARCHPROFILE_SITEINDEX_START)) {		
			dispatch(setPageDialogOpenId(PAGEDIALOG_SUCHPROFIL));
		}
	}, [window.location.hash]);

	/* SET WINDOW HASH END */

	/**
	 * RESET FORM
	 * clear all form data and set step to 1
	 */
	const resetForm = useCallback(() => {
    setStep(SEARCHPROFILE_SITEINDEX_START);
    methods.reset(initialFormState);
		setError('');
  }, [methods, initialFormState]);
	/* RESET FORM END */

	/**
	 * HANDLE CLOSE
	 * closes Pagedialog, resets formIsLoading state to false and resets form
	 */
	const handleClose = useCallback(() => {
		if (app.formIsLoading) return;

		dispatch(closePageDialog());
		resetForm();
	}, [app.formIsLoading, dispatch, resetForm]);
		/* HANDLE CLOSE END */

	/** 
	 * set values from search form (if exist)
	 * searchForm values are saved in localStorage
	 */
	const setDataFromSearchForm = useCallback(() => {
		if(!window.location.pathname.includes('immobiliensuche')) return;

		const lsSearchForm = localStorage.getItem('mysreal_searchform_values');
		if (lsSearchForm && isJson(lsSearchForm)) {
			const searchFormValues = JSON.parse(lsSearchForm);

			const spStateOptions = statesWithZips.map((state) => {
				return {
					id: state.justImmoId,
					text: state.label,
				}
			});

			Object.entries(searchFormValues).forEach(([key, value]) => {
				if(value) {	
					switch (key) {
						case 'address':
							const matchState = spStateOptions.find((option) => 
								value.some((v) => v.label === option.text)
							);

							if(matchState) {
								setValue('state', matchState);
							}
							break;
						case 'estateType':
							const matchEstateType = immobilienartOptionsJustImmo.find((option) => 
								value.some((v) => v.text === option.text)
							);

							if(matchEstateType) {
								setValue('estateType', matchEstateType);
							}
							break;
						case 'features':
							let matchExteriorSurface = [];

							value.forEach((v) => {
								if(v.id === 'garden') {
									matchExteriorSurface.push(exteriorSurfacesOptionsJustImmo.find((option) => option.id === 'garden'));
								}
								if(v.id === 'balconyOrTerace') {
									matchExteriorSurface.push(exteriorSurfacesOptionsJustImmo.find((option) => option.id === 'balcony'));
									matchExteriorSurface.push(exteriorSurfacesOptionsJustImmo.find((option) => option.id === 'terrace'));
								}
							});

							if(matchExteriorSurface) {
								setValue('exteriorSurface', matchExteriorSurface);
							}
							break;
						default:
							setValue(key, value);
							break;
					}
					
				}
			});
		}
	}, [setValue]);

	const findFederalStateByDistrictInList = (districtName) => {
    let federalStateObject = null;
    const federalState = statesWithZips
      .filter((state) => state.zipCodes?.length > 0)
      .map((state) => {
        let hasDistrictId = state.zipCodes.find((zip) => districtName.includes(zip));

        if (hasDistrictId) {
          return state;
        }
      })
      .filter((state) => state !== undefined);

    if (federalState && federalState[0] && federalState[0].justImmoId && federalState[0].label) {
      federalStateObject = {
        type: JUSTIMMO_TYPE_FEDERAL_STATE_ID,
        id: federalState[0].justImmoId,
        text: federalState[0].label,
      };
    }
    return federalStateObject;
  }

	const setDataToEdit = useCallback(async (editValues) => {
		if(!editValues) return;
	
		const criteria = editValues.criteria;
		if(!criteria || !editValues.name || !editValues.id) return;

		setValue('name', editValues.name);

		let federalState = getFederalStateFromRequestBody(criteria.location.location_ids);
		let districts = getDistrictsFromRequestBody(criteria.location.location_ids);

		if(federalState) {
			setValue('state', federalState);
		
		}
		else if (districts?.length > 0) {
			// get federalState from district
			let districtName = districts[0]?.text;

			if (districtName) {
				// if districtName contains digits
				let regexDigits = /\d/;

				if (regexDigits.test(districtName)) {
					let federalStateObject = findFederalStateByDistrictInList(districtName);
					if (federalStateObject) {
						setValue('state', federalStateObject);
					}
				} else {
					// if districtName contains only letters - look for district with zip in getLocations()
					try {
						const locList = await getLocations(districtName);

						let districtWithZip = locList.find((loc) => {
							// 2 = 'c_'
							// 4 = 1234 for zip
							// 1 = space
							const minValueLength = 7 + districtName.length;
							const pattern = new RegExp(`^c_\\d{4} ${districtName}$`);
							if (loc.value.length >= minValueLength && pattern.test(loc.value)) {
								return loc;
							}
							return null;
						});

						if (districtWithZip && districtWithZip.label) {
							districtWithZip = districtWithZip.label;

							let federalStateObject = findFederalStateByDistrictInList(districtWithZip);
							if (federalStateObject) {
								setValue('state', federalStateObject);
							}
						}
					} catch (e) {
						// Handle error
						console.error('e', e);
					}
				}
			}
		}

		if (districts?.length > 0) {
			districts = districts.map((item) => {
				return { id: item.id?.toString(), label: item.text, text: item.text, type: getLocationTypeIdByLabel(item.type) };
			});
			setValue('district', districts);
		}

		// get correct data for estateType
		let estateTypeVal = null;
		if (criteria.types[0]) {
			let estateId = criteria.types[0].id;
			let estateName = criteria.types[0].name || criteria.types[0].text;
			if (estateId && estateName) {
				estateTypeVal = { id: estateId, text: estateName };
				setValue('estateType', estateTypeVal);
			}
		}

		setValue('priceFrom', criteria.price?.from || '');
		setValue('priceTo', criteria.price?.to || '');			

		setValue('areaFrom', criteria.living_area?.from || criteria.siteArea?.from || '');
		setValue('areaTo', criteria.living_area?.to || criteria.siteArea?.to || '');

		setValue('roomsFrom', criteria.rooms?.from || '');
			
		let exteriorSurface = [];
		if(criteria.balcony?.enabled) {
			exteriorSurface.push(exteriorSurfacesOptionsJustImmo.find((option) => option.id === 'balcony'));
		}
		if(criteria.terrace?.enabled) {
			exteriorSurface.push(exteriorSurfacesOptionsJustImmo.find((option) => option.id === 'terrace'));
		}
		if(criteria.covered_balcony?.enabled) {
			exteriorSurface.push(exteriorSurfacesOptionsJustImmo.find((option) => option.id === 'covered_balcony'));
		}
		if(criteria.garden?.enabled) {
			exteriorSurface.push(exteriorSurfacesOptionsJustImmo.find((option) => option.id === 'garden'));
		}

		setValue('exteriorSurface', exteriorSurface);	

		setEditFormId(editValues.id);
		localStorage.removeItem('mysreal_searchprofile_edit');
	}, [setValue]);
	
	// reset form when opened
	useEffect(() => {
		if(app.pageDialogOpenId === PAGEDIALOG_SUCHPROFIL) {
			resetForm();

			let activeSearchprofiles = getSearchprofilesByState(SEARCHPROFILE_STATE_ACTIVE);

			const lsEditData = localStorage.getItem('mysreal_searchprofile_edit');
			if (lsEditData && isJson(lsEditData)) {
				const editValues = JSON.parse(lsEditData);
				setDataToEdit(editValues);
			}
			else if(activeSearchprofiles.length === 3 && !app.demoMode) {
				setStep(SEARCHPROFILE_SITEINDEX_TOOMANY)
			}
			else {
				setDataFromSearchForm();
			}
		}
	}, [app.pageDialogOpenId, app.root, setDataToEdit, resetForm, setDataFromSearchForm]);

  const pageDialogId = 'PageDialogSearchProfileForm';
	const formType = 'searchProfileForm';

	/**
	 * HANDLE SUBMIT
	 * send data to BE
	 */
	const onSubmit = useCallback(async (data) => {
		dispatch(setFormIsLoading(true));
		setError('');

		// if estateType is house/apartment - send are as livingarea otherwise as sitearea
		let livingArea = {
				"from": null,
				"to": null
		};

		let siteArea = {
			"from": null,
			"to": null
		};

		let roomsFrom = data.roomsFrom || null;
		let exteriorSurface = data.exteriorSurface || [];

		if(data.areaFrom !== '' || data.areaTo !== '') {
			if(data.estateType?.id === JUSTIMMO_IMMOBILIENART_HAUS || data.estateType?.id === JUSTIMMO_IMMOBILIENART_WOHNUNG) {
				livingArea.from = data.areaFrom;
				livingArea.to = data.areaTo;
			}
			else {
				siteArea.from = data.areaFrom;
				siteArea.to = data.areaTo;
				roomsFrom = null;
				exteriorSurface = [];
			}
		}

		let locationIds = [{
			type: 0,
			id: (data.state?.id).toString(),
			text: data.state?.text
		}];

		if(data.district?.length > 0) {
			locationIds = data.district.map((d) => {
				return {
					type: d.type,
					id: d.value ? (d.value).toString() : d.id.toString(),
					text: d.label
				}
			});
		}

		const criteria = {
				"type_ids": [data.estateType.id],
				"location": {
						"location_ids": locationIds
				},
				"price": {
						"from": data.priceFrom || null,
						"to": data.priceTo || null
				},
				"living_area": livingArea,
				"site_area": siteArea,
				"rooms": {
						"from": roomsFrom,
						"to": null
				},
				"furnishing_ids": [],
				"covered_balcony": {
						"enabled": exteriorSurface.find((o) => o.id === 'covered_balcony') ? true : false
				},
				"balcony": {
						"enabled": exteriorSurface.find((o) => o.id === 'balcony') ? true : false,
				},
				"terrace": {
						"enabled": exteriorSurface.find((o) => o.id === 'terrace') ? true : false,
						"from": null
				},
				"garden": {
						"enabled": exteriorSurface.find((o) => o.id === 'garden') ? true : false,
						"from": null
				}
		};

		const newSearchProfileRequestBody = {
			register: {
				gender: data.gender || '',
				titleBefore: data.titleBefore || '',
				titleAfter: data.titleAfter || '',
				firstName: data.firstName || '',
				lastName: data.lastName || '',
				email: data.email || '',
				phoneNumber: (data.phoneNumberPrefix?.id ? '+' + data.phoneNumberPrefix.id : '') + (data.phoneNumber || '').replace(/\s+/g, ''),
				password: data.password || '',
				privacyAggrement: !!data.privacyAggrement,
			},
			state: data.state?.text || '',
			criteria: criteria,
			createUser: !!data.createUser,
		};

		// ONLY SHOW SUCCESS IF DEMO MODE - no endpoint calls
		if(app.demoMode) {
			dispatch(setFormIsLoading(false));
			
			if(!editFormId) {
				//create
				setStep(SEARCHPROFILE_SITEINDEX_EMAIL);
			}
			else {
				handleClose();
			}

			return;
		}
	
		if(!editFormId) {
			try {
				await createSearchProfile(data.name, newSearchProfileRequestBody);
				dispatch(setFormIsLoading(false));		
				
				await getAndSetSearchprofiles();
				
				dispatch(setCachedSimilarImmos([]));
				dispatch(setLastCachedDate(null));
				
				setStep(SEARCHPROFILE_SITEINDEX_EMAIL);

				if(app.menuType === MENU_KAUF && app.menuId === PHASE1_KAUF) {
					updateMenu(PHASE2_KAUF_SP_AKTIV);
				}
			} catch (e) {
				const data = e.response?.data;
				dispatch(setFormIsLoading(false));
				
				if (data === 'This user already has 3 active search profiles') {
					setError(tooManySearchProfilesErrorText);
				} else if (data === 'A search profile with this name already exists for this user') {
					setError(searchProfileNameAlreadyExistsErrorText);
				} else if (data === 'Duplicate Username') {
					let errmsg = 'Die Mailadresse ist bereits vergeben.';
					setError(errmsg);
				} else {
					setError(fetchErrorText);
				}
				
				handleScrollToErrorPageDialog(formType, '#' + pageDialogId);
			}
		}
		else {
			// edit
			const editSearchProfileRequestBody = newSearchProfileRequestBody.criteria;

			try {
				await renameSearchProfile(editFormId, data.name);
				await updateSearchProfile(editFormId, editSearchProfileRequestBody);

				dispatch(updateSearchProfileInUser({ id: editFormId, name: data.name, criteria: editSearchProfileRequestBody }));
				dispatch(tryUpdateUserResult({ id: editFormId, name: data.name }));

				// reset cached similar immos date damit neu gefetcht wird
				dispatch(setCachedSimilarImmos([]));
				dispatch(setLastCachedDate(null));

				dispatch(setFormIsLoading(false));
				handleClose();
			} catch (e) {
				console.error('e', e);
				dispatch(setFormIsLoading(false));
				setError(fetchErrorText);
				handleScrollToErrorPageDialog(formType, '#' + pageDialogId);
			}
		}
	}, [dispatch, getAndSetSearchprofiles]);

  return (
    <PageDialog
      id={pageDialogId}
			open={app.pageDialogOpenId === PAGEDIALOG_SUCHPROFIL}
      handleClose={handleClose}
      handleBack={() => setStep(SEARCHPROFILE_SITEINDEX_START)}
      headline={!editFormId ? 'Suchprofil anlegen' : 'Suchprofil bearbeiten'}
      showBackArrow={step === SEARCHPROFILE_SITEINDEX_USERDATA}
    >
			<FormProvider {...methods}>
				<div className="searchProfileForm p-100rem">
					{step === SEARCHPROFILE_SITEINDEX_START ? (
						<SearchData formType={formType} id={pageDialogId} isEditForm={!!editFormId} nextStep={() => setStep(SEARCHPROFILE_SITEINDEX_USERDATA)} submit={methods.handleSubmit(onSubmit)} error={error} />
					) : step === SEARCHPROFILE_SITEINDEX_USERDATA ? (
						<UserData formType={formType} id={pageDialogId} prevStep={() => setStep(SEARCHPROFILE_SITEINDEX_START)} submit={methods.handleSubmit(onSubmit)} />
					) : step === SEARCHPROFILE_SITEINDEX_EMAIL ? (
						<SearchProfileCreated handleClose={handleClose} />
					) : step === SEARCHPROFILE_SITEINDEX_TOOMANY ?
						<SearchProfileTooMany />
					: null }
				</div>
			</FormProvider>
    </PageDialog>
  );
}

export default PageDialogSearchProfileForm;
