import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { WOHNWERTLIGHT_SITEINDEX_RESULT } from '../../constants';
import { bitteWaehlenOption } from './util/formOptions';

import { setMarktinfoResultActive, setWohnwertResultsActive } from '../../reducers/user';


import { setMarktinfoLightTown } from '../../reducers/marktinfoLight';

import { setSiteIndexWohnwertForm, setWohnwertLightDistrict, setWohnwertLightFederalState, setWohnwertLightRealEstateType, setWohnwertLightTypedPostCode } from '../../reducers/wohnwertLight';

import { setPhoneNumberPrefixRegisterForm, setPhoneNumberRegisterForm } from '../../reducers/registerForm';


import { Chip, ListSubheader } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { setChangedParsedPhoneNumberPrefix, setChangedParsedPhoneNumberPrefixCheck } from '../../reducers/registerFormChangePhoneNumber';

function FormSelect({
  label,
  id,
  showBitteWaehlen = false,
  placeholder,
  options,
  disabled,
  value = bitteWaehlenOption,
  hasLockIcon,
  formType,
  required,
  error = '',
  cssClasses,
  multiple = false,
  callback,
}) {
  const [showResetIcon, setShowResetIcon] = useState(false);
  const [open, setOpen] = useState(false);
  const openRef = useRef(open);
  if (!cssClasses) cssClasses = '';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const selectRef = useRef(null);

  
  let app = useSelector((state) => state.app);

  const parseHTML = require('html-react-parser');

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    openRef.current = open;
  }, [open]);

  useEffect(() => {
    const handleScroll = () => {
      if (openRef.current) handleClose();
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
		if(app.formIsLoading) {
			setShowResetIcon(false);
		}
		else if (Object.keys(value).length > 0 && value !== bitteWaehlenOption && !disabled && !hasLockIcon && id !== 'sorting' && id !== 'previous_calculations' && id !== 'userRealties') {
      setShowResetIcon(true);
    } else {
      setShowResetIcon(false);
    }
  }, [value, app.formIsLoading, disabled, hasLockIcon, id]);


  const handleReset = () => {
    if (multiple) {
      handleChange([]);
    } else {
      handleChange('');
    }
  };

  const handleDelete = (val) => {
    let filteredValue = value.filter((v) => v.id !== val.id);
    handleChange(filteredValue);
  };


  const handleChange = async (val) => {
    if (callback) {
			if(multiple && value.length > 0) {
				const uniqueData = val.filter((item, index, self) => 
					index === self.findIndex((t) => t.id === item.id)
				);
	
				// Check for duplicates and remove them
				const filteredData = uniqueData.filter((item) => 
					!val.some((selected) => selected.id === item.id && selected !== item)
				);

				callback(filteredData);
			}
			else {
				callback(val);
			}
		}
		else {
     if (formType === 'marktinfoLight') {
        switch (id) {
          case 'inputTown':
            dispatch(setMarktinfoLightTown(val));
            break;
          case 'previous_calculations':
            dispatch(setMarktinfoResultActive(val));
            break;
          default:
            break;
        }
      } else if (formType === 'wohnwertLight') {
        switch (id) {
          case 'inputFederalState':
            dispatch(setWohnwertLightFederalState(val));
            dispatch(setWohnwertLightDistrict({}));
            dispatch(setWohnwertLightTypedPostCode(''));
            break;
          case 'realEstateType':
            dispatch(setWohnwertLightRealEstateType(val));
            break;
          case 'previous_calculations':
            dispatch(setSiteIndexWohnwertForm(WOHNWERTLIGHT_SITEINDEX_RESULT));
            dispatch(setWohnwertResultsActive(val));
            break;
          default:
            break;
        }
      } else if (formType === 'registerForm') {
        switch (id) {
          case 'selectPhoneNumberPrefix':
            dispatch(setPhoneNumberPrefixRegisterForm(val));
            dispatch(setPhoneNumberRegisterForm({ ...val, text: val.text.substring(0, val.text.indexOf(' ')) }));
            break;
          default:
            break;
        }
      } else if (formType === 'registerFormChangePhoneNumber') {
        switch (id) {
          case 'selectPhoneNumberPrefix':
            dispatch(setChangedParsedPhoneNumberPrefix(val));
            break;
          case 'selectPhoneNumberPrefixCheck':
            dispatch(setChangedParsedPhoneNumberPrefixCheck(val));
            break;
          default:
            break;
        }
      }
    }
  };

  let selectOptions = options;
  if (showBitteWaehlen) {
    if (typeof showBitteWaehlen === 'object') {
      selectOptions = [showBitteWaehlen, ...options];
    } else {
      selectOptions = [bitteWaehlenOption, ...options];
    }
  }

  if (!placeholder) placeholder = label;

  return (
    <div className={`${cssClasses} form__field`}>
      <InputLabel htmlFor={id}>
        {label} {!!required ? '*' : ''}
      </InputLabel>

      <div className={`select-wrapper ${hasLockIcon && disabled ? 'locked' : ''}`}>
        <Select
          ref={selectRef}
          className={`select ${multiple ? 'multiple' : ''} ${multiple && value.length > 0 ? 'select--withChips' : ''}`}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          name={id}
          id={id}
          multiple={multiple}
          onChange={(e) => handleChange(e.target.value)}
          renderValue={(selected) => {
            if (multiple && selected && selected.length > 0) {
              return selected.map((option) => {
                return <Chip key={option.id} label={option.text} onMouseDown={(event) => event.stopPropagation()} onDelete={(e) => handleDelete(option)} />;
              });
            } else if (selected && selected.text) {
              return selected.text;
            }
            return placeholder;
          }}
          value={value}
          disabled={disabled || app.formIsLoading}
          displayEmpty={true}
          inputProps={{ MenuProps: { disableScrollLock: true } }}
        >
          {selectOptions.map((option, index) => {
            if (option.optgroup) {
              return <ListSubheader key={index}>{option.text}</ListSubheader>;
            } else {
              return (
                <MenuItem
									value={option}
									key={option.id + '-' + index} 
									className={
										multiple && value.length > 0 && value.find((v) => v.id === option.id)
										? 'Mui-selected'
										: !multiple && value.id === option.id && 'Mui-selected'
									}
								>
                  {id === 'selectPhoneNumberPrefix' ? (
                    // Custom styling um die Vorwahlen schöner darzustellen
                    <div className="select__phoneprefix">
                      <span>{option.text.match(/\+\d+/)[0]}</span>
                      <span>{option.text.match(/\(.*\)/)[0]}</span>
                    </div>
                  ) : (
                    option.text
                  )}
                </MenuItem>
              );
            }
          })}
        </Select>

        {showResetIcon && <span className="form__field--reset" onClick={handleReset}></span>}
      </div>

      {error && <p className={'form__fielderror'}>{error}</p>}
    </div>
  );
}

export default FormSelect;
