import { useHandleUserRealtyActive } from '../../hooks/user/useHandleUserRealtyActive';
import WeitereAktivitaetenCard from '../Cards/WeitereAktivitaetenCard';

import { useSelector } from 'react-redux';
import { useHandleVisibleElements } from '../../hooks/app/useHandleVisibleElements';
import { useEffect, useState } from 'react';
import { userReadActivities } from '../../api/User';

function WeitereAktivitaetenContent({ containerSelector }) {
  const user = useSelector((state) => state.user);
	const app = useSelector((state) => state.app);

  const { weitere, weitereLoaded } = useHandleUserRealtyActive();
  const { getVisibleElements, visibleElements } = useHandleVisibleElements();

  const realtyActivitiesSeenArray = user.userObject.realtyActivitiesSeen ? user.userObject.realtyActivitiesSeen.split(';') : [];
  const [weitereIdsRead, setWeitereIdsRead] = useState(realtyActivitiesSeenArray);

  useEffect(() => {
    if (weitereLoaded) {
      const elementSelector = '.marketing-card';
      getVisibleElements(containerSelector, elementSelector);
    }
  }, [weitereLoaded]);

  useEffect(() => {
		if(!app.demoMode) {
			// Convert the Set to an array
			const visibleElementsArray = Array.from(visibleElements);
			const ids = visibleElementsArray
				.map((el) => {
					let dataEl = el.querySelector('.js-dataid');
					if (!dataEl) return null;

					let dataId = 'w-' + dataEl.dataset.id;
					return dataId;
				})
				.filter((id) => id !== 'w-undefined' && id !== null);

			const newIdsArray = [...weitereIdsRead, ...ids];
			const uniqueIdsArray = [...new Set(newIdsArray)];

			setWeitereIdsRead(uniqueIdsArray);

			// you need localStorage data for unmount function
			localStorage.setItem('activityIdsRead', uniqueIdsArray.join(';'));
		}
  }, [visibleElements]);

  useEffect(() => {
    // This function will be called just before the component is unmounted
    return () => {
      // only call setReadActivities if there are more read activities in localStorage.
      const lsArray = localStorage.getItem('activityIdsRead') ? localStorage.getItem('activityIdsRead').split(';') : [];
      if (lsArray.length > realtyActivitiesSeenArray.length) {
        setReadActivities();
      }
    };
  }, []);

  const setReadActivities = async () => {
    try {
      await userReadActivities(localStorage.getItem('activityIdsRead'));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {weitere.length > 0 ? (
        <div className="cardgrid" id="weitere-cards">
          {weitere.map((w, index) => {
            return (
              <WeitereAktivitaetenCard
                // icon={<IconAnnouncment />}
                headline={w.label}
                dateFrom={w.dateFrom}
                dateTo={w.dateTo}
                comment={w.value.Comment}
                key={w.id + '-' + w.value.Created_at + '-' + index}
                id={w.key}
                unread={!weitereIdsRead.find((itemId) => itemId.includes(String(w.key)))}
              />
            );
          })}
        </div>
      ) : (
        <p className="page__emptymessage">Derzeit wurden noch keine weiteren Marketingaktivitäten durchgeführt.</p>
      )}
    </>
  );
}

export default WeitereAktivitaetenContent;
