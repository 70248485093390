import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	ACTIVITY_BESICHTIGUNG_ID,
	ACTIVITY_EMAIL_ID,
	ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERKAUFT_ID,
	ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERMIETET_ID,
	AKTUELLES_FILTER_BESICHTIGUNGEN,
	AKTUELLES_FILTER_DOKUMENTE,
	AKTUELLES_FILTER_WEITERE,
	MENU_KAUF,
	MENU_VERKAUF,
	PHASE1_VERKAUF,
	PHASE2_VERKAUF_MIT_AKTIVITAET,
	PHASE3_KAUF,
	PHASE4_KAUF,
} from '../../constants';
import { abgeberimmo_phase2, abgeberimmo_phase3, abgeberimmo_phase4 } from '../../demodata/demo_abgeberimmo';
import { demobesichtigungen } from '../../demodata/demobesichtigungen';
import { demofiles } from '../../demodata/demofiles';
import { demoweitere } from '../../demodata/demoweitereaktivitaeten';
import { setUnreadBesichtigungen, setUnreadWeitere } from '../../reducers/notifications';
import { parseImmo } from '../../util/immoObjectParser';
import { useFetchSearchprofiles } from '../searchprofiles/useFetchSearchprofiles';

export const useHandleUserRealtyActive = (loadData = true) => {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);
	const kaufanbote = useSelector((state) => state.kaufAnbote);

  const dispatch = useDispatch();

  const [besichtigungen, setBesichtigungen] = useState([]);
  const [besichtigungenLoaded, setBesichtigungenLoaded] = useState(false);

  const [weitere, setWeitere] = useState([]);
  const [weitereLoaded, setWeitereLoaded] = useState(false);

  const [files, setFiles] = useState([]);
  const [filesLoaded, setFilesLoaded] = useState(false);

  const [marketingActivities, setMarketingActivities] = useState([]);
  const [marketingActivitiesLoaded, setMarketingActivitiesLoaded] = useState(false);

  const { getSearchprofilesMenu } = useFetchSearchprofiles();

  useEffect(() => {
    loadAllRealtyData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadAllRealtyData() {
    if (loadData) {
      getBesichtigungen();
      getWeitere();
      getDocuments();
      getMarketingActivities();
    }
  }

  function getBesichtigungen() {
    if (app.menuId === PHASE1_VERKAUF) {
      setBesichtigungenLoaded(true);
      return;
    }

		if(app.demoMode) {
			let immo = parseImmo(abgeberimmo_phase2);
			if(app.menuId === PHASE2_VERKAUF_MIT_AKTIVITAET) {
				immo = parseImmo(abgeberimmo_phase2);
			}
			else if(app.menuId === PHASE3_KAUF) {
				immo = parseImmo(abgeberimmo_phase3);
			}
			else if(app.menuId === PHASE4_KAUF) {
				immo = parseImmo(abgeberimmo_phase4);
			}

			let activities = renderBesichtigungActivities(immo.activities).filter((a) => a !== undefined);
			setBesichtigungen(activities);
		}
		else {
			// Abgeber: check besichtigungen of userRealtyActive
			if (app.menuType === MENU_VERKAUF && app.menuId === PHASE2_VERKAUF_MIT_AKTIVITAET) {
				if (user.userRealtyActive?.id && user.userRealtyActive?.id > -1 && user.userRealtyActive.activities?.length > 0) {
					let activities = renderBesichtigungActivities(user.userRealtyActive.activities).filter((a) => a !== undefined);
					setBesichtigungen(activities);
				}
			}
		}

		setBesichtigungenLoaded(true);
  }

  function renderBesichtigungActivities(activities, immo) {
    if (!activities || activities.length === 0) return [];

    let activitiesB = activities
      .flat()
      .filter((a) => a.id === ACTIVITY_BESICHTIGUNG_ID)
      .map((a) => {
        let text = '';
        if (a.value?.Comment) {
          text = a.value.Comment;
        } else if (a.value?.Offertext) {
          text = a.value.Offertext;
        }

        return {
          ...a,
          immo: immo,
          text: text,
          filter_id: AKTUELLES_FILTER_BESICHTIGUNGEN,
        };
      });

    return activitiesB;
  }

  /**
   * get searchprofile hits and kaufanbot list (saved in searchprofilesMenu)
   * @returns Array
   */
  const getKaeuferRealties = () => {
		if(!user.isLoggedIn) return [];

    let kaeuferImmos = [];
    const menuSP = getSearchprofilesMenu();

    if (menuSP) {
      kaeuferImmos = menuSP
        .filter((m) => m.immos && m.immos.length > 0 && m.label === 'Alle')
        .map((m) => m.immos)
        .flat();
    }
    return kaeuferImmos;
  };


	/**
   * get käufer realty from offers that has phase 3
   * @returns Object|null
   */
  const getKaeuferImmoPhase3Or4 = useCallback((multiple = false) => {
    if (!user.isLoggedIn) return null;

		const spImmos = user.searchProfiles.filter((sp) => sp.immos?.length > 0);
		const kaufanbotImmos = kaufanbote.kaufanboteList || [];
		const kaeuferImmos = [...spImmos, ...kaufanbotImmos];

		let kaeuferImmosPhase3 = null;
    if (kaeuferImmos && kaeuferImmos.length > 0) {
      let kaeuferImmosIds = kaeuferImmos.map((k) => k.id);
      let phaseImmos = user.userObject?.realtyPhases?.filter((p) => kaeuferImmosIds?.includes(p?.realtyId) && (p?.phase === 3 || p.phase === 4));

      if (phaseImmos && phaseImmos.length > 0 && !multiple) {
        kaeuferImmosPhase3 = kaeuferImmos.find((k) => k.id === phaseImmos[0].realtyId);
      } else if (phaseImmos && phaseImmos.length > 0 && multiple) {
        kaeuferImmosPhase3 = [];
        phaseImmos.forEach((p) => {
          kaeuferImmosPhase3.push(kaeuferImmos.find((k) => k.id === p.realtyId));
        });
      }
    }
    return kaeuferImmosPhase3;
  }, [user.isLoggedIn, user.userObject?.realtyPhases, user.searchProfiles, kaufanbote.kaufanboteList]);

  /**
   * get all activities except besichtigungen, vermittelt + email anbot
   * @returns Array
   */
  function getWeitere() {
    if (app.menuId === PHASE1_VERKAUF) {
      setWeitereLoaded(true);
      return;
    }
		
		if(app.demoMode) {
			let immo = parseImmo(abgeberimmo_phase2);
			if(app.menuId === PHASE2_VERKAUF_MIT_AKTIVITAET) {
				immo = parseImmo(abgeberimmo_phase2);
			}
			else if(app.menuId === PHASE3_KAUF) {
				immo = parseImmo(abgeberimmo_phase3);
			}
			else if(app.menuId === PHASE4_KAUF) {
				immo = parseImmo(abgeberimmo_phase4);
			}

			let activities = immo.activities
				.flat()
				.filter(
					(a) =>
						a.id !== ACTIVITY_BESICHTIGUNG_ID &&
						a.id !== ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERKAUFT_ID &&
						a.id !== ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERMIETET_ID &&
						a.id !== ACTIVITY_EMAIL_ID,
				)
				.map((a) => {
					let text = '';
					if (a.value?.Comment) {
						text = a.value.Comment;
					} else if (a.value?.Offertext) {
						text = a.value.Offertext;
					}

					return {
						...a,
						text: text,
						filter_id: AKTUELLES_FILTER_WEITERE,
					};
				});
			

			setWeitere(activities);
		}
		else {
			if (app.menuType === MENU_VERKAUF && app.menuId === PHASE2_VERKAUF_MIT_AKTIVITAET) {
				if (user.userRealtyActive?.id && user.userRealtyActive?.id > -1) {
					if (user.userRealtyActive.activities?.length > 0) {
						let activities = user.userRealtyActive.activities
							.flat()
							.filter(
								(a) =>
									a.id !== ACTIVITY_BESICHTIGUNG_ID &&
									a.id !== ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERKAUFT_ID &&
									a.id !== ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERMIETET_ID &&
									a.id !== ACTIVITY_EMAIL_ID,
							)
							.map((a) => {
								let text = '';
								if (a.value?.Comment) {
									text = a.value.Comment;
								} else if (a.value?.Offertext) {
									text = a.value.Offertext;
								}

								return {
									...a,
									text: text,
									filter_id: AKTUELLES_FILTER_WEITERE,
								};
							});

						setWeitere(activities);
					}
				}
			}
		}

    setWeitereLoaded(true);
  }

  /**
   * set private documents of current realty
   */
  function getDocuments() {
    if (app.menuId === PHASE1_VERKAUF || app.menuType === PHASE1_VERKAUF) {
      setFilesLoaded(true);
      return;
    }

		if(app.demoMode) {
			setFiles(demofiles);
		}
		else {

			if (app.menuType === MENU_KAUF) {
				let kaeuferPhase3Immo = getKaeuferImmoPhase3Or4();
				if (user.userRealtyActive) {
					let filesArray =
						user.userRealtyActive.documentsPrivate
							?.filter((file) => file.isActivityFile && file.isActivityFile === true)
							?.map((file) => {
								return {
									...file,
									filter_id: AKTUELLES_FILTER_DOKUMENTE,
									dateFrom: file.uploaded_at,
								};
							}) || [];

					setFiles(filesArray);
				} else if (kaeuferPhase3Immo && kaeuferPhase3Immo.id) {
					let filesArray =
						kaeuferPhase3Immo.documentsPrivate
							?.filter((file) => file.isActivityFile && file.isActivityFile === true)
							?.map((file) => {
								return {
									...file,
									filter_id: AKTUELLES_FILTER_DOKUMENTE,
									dateFrom: file.uploaded_at,
								};
							}) || [];

					setFiles(filesArray);
				}
			} else {
				if (user.userRealtyActive?.id && user.userRealtyActive?.id > -1 && user.userRealtyActive.documentsPrivate?.length > 0) {
					let filesArray = user.userRealtyActive.documentsPrivate.map((file) => {
						return {
							...file,
							isPrivate: true,
							filter_id: AKTUELLES_FILTER_DOKUMENTE,
							dateFrom: file.uploaded_at,
						};
					});

					user.userRealtyActive.documentsPublic?.forEach((file) => {
						filesArray.push({
							...file,
							isPrivate: false,
							filter_id: AKTUELLES_FILTER_DOKUMENTE,
							dateFrom: file.uploaded_at,
						});
					});

					setFiles(filesArray);
				}
			}
		}

    setFilesLoaded(true);
  }

  function reloadData() {
    setBesichtigungenLoaded(false);
    setWeitereLoaded(false);
    setFilesLoaded(false);

    setTimeout(() => {
      loadAllRealtyData();
    }, 200);
  }

  /* get count of all besichtigungen unread */
  function getBesichtigungenUnreadCount(idsArray = null) {
		if(app.demoMode) return 6;

    if (idsArray === null && user.userObject?.realtyActivitiesSeen) {
      idsArray = user.userObject.realtyActivitiesSeen.split(';');
    } else if (idsArray === null) {
      idsArray = [];
    }
    const besichtigungenSeen = idsArray.filter((b) => b.includes('b-'));
    const besUnread = besichtigungen.length - besichtigungenSeen.length;
    dispatch(setUnreadBesichtigungen(besUnread)); // count
    return besUnread;
  }

  /* get count of all besichtigungen unread */
  function getWeitereUnreadCount(idsArray = null) {
		if(app.demoMode) return 7;

    if (idsArray === null && user.userObject?.realtyActivitiesSeen) {
      idsArray = user.userObject.realtyActivitiesSeen.split(';');
    } else if (idsArray === null) {
      idsArray = [];
    }
    const weitereSeen = idsArray.filter((w) => w.includes('w-'));
    const weitereUnread = weitere.length - weitereSeen.length;
    dispatch(setUnreadWeitere(weitereUnread)); // count
    return weitereUnread;
  }

  function getMarketingActivities() {
		if(app.demoMode) {
			setMarketingActivities(demobesichtigungen.length + demoweitere.length);
			setMarketingActivitiesLoaded(true);
			return;
		}

    let activitiesBesichtigungen = [];
    let activitiesWeitere = [];
    if (user.userRealtyActive?.id && user.userRealtyActive?.id > -1) {
      if (user.userRealtyActive.activities?.length > 0) {
        let activities = renderBesichtigungActivities(user.userRealtyActive.activities).filter((a) => a !== undefined);
        activitiesBesichtigungen = activities;
      }
    }
    if (user.userRealtyActive?.id && user.userRealtyActive?.id > -1) {
      if (user.userRealtyActive.activities?.length > 0) {
        let activities = user.userRealtyActive.activities
          .flat()
          .filter(
            (a) =>
              a.id !== ACTIVITY_BESICHTIGUNG_ID &&
              a.id !== ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERKAUFT_ID &&
              a.id !== ACTIVITY_OBJEKTSTATUSAENDERUNG_VERMITTELT_VERMIETET_ID &&
              a.id !== ACTIVITY_EMAIL_ID,
          )
          .map((a) => {
            let text = '';
            if (a.value?.Comment) {
              text = a.value.Comment;
            } else if (a.value?.Offertext) {
              text = a.value.Offertext;
            }

            return {
              ...a,
              text: text,
              filter_id: AKTUELLES_FILTER_WEITERE,
            };
          });

        activitiesWeitere = activities;
      }
    }

    setMarketingActivities(activitiesBesichtigungen.length + activitiesWeitere.length);
    setMarketingActivitiesLoaded(true);
    return marketingActivities;
  }

  useEffect(() => {
    if (!besichtigungenLoaded && !weitereLoaded && !filesLoaded) {
      loadAllRealtyData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [besichtigungenLoaded, weitereLoaded, filesLoaded]);

  return {
    besichtigungen,
    besichtigungenLoaded,
    weitere,
    weitereLoaded,
    files,
    filesLoaded,
    getKaeuferRealties,
    getKaeuferImmoPhase3Or4,
    reloadData,
    getBesichtigungenUnreadCount,
    getWeitereUnreadCount,
    marketingActivities,
    marketingActivitiesLoaded,
  };
};
