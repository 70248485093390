import { useEffect, useState } from 'react';
import { getNewsNew } from '../../api/News';

export const useFetchNews = () => {

  const [selectedFilter, setSelectedFilter] = useState(0);
  const [news, setNews] = useState([]);
  const [newsError, setNewsError] = useState(null);
  const [shownEntries, setShownEntries] = useState(12);
  const [didNewsLoad, setDidNewsLoad] = useState(false);
  const [maxPages, setMaxPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getAndSetNews();
  }, []);

  const getAndSetNews = async (page = 1) => {
    try {
      const res = await getNewsNew(page);
      let newsitems = [];
      if (res.items && res.items.length > 0) {
        if (page === 1) {
          newsitems = res.items;
        } else {
          // add to existing
          newsitems = [...news, ...res.items];
        }

        res.items.forEach((newsElement) => {
          if (newsElement.tags) {
            const heroTag = newsElement.tags.find((tag) => tag.name.includes('hero') || tag.name.includes('Hero'));
            if (heroTag) {
              newsElement.hasHeroTag = true;
            }
          }
        });

        setMaxPages(res.maxPages);
      }

      newsitems = sortbyPublishedOn(newsitems);
      setNews(newsitems);
      setNewsError(null);
    } catch (error) {
      setNewsError(error);
    } finally {
      setDidNewsLoad(true);
    }
  };

  const sortbyPublishedOn = (news) => {
    return news.sort((a, b) => {
      const dateA = new Date(a.published_on);
      const dateB = new Date(b.published_on);

      // Handle cases where date is missing or invalid
      if (!dateA || isNaN(dateA)) return 1;
      if (!dateB || isNaN(dateB)) return -1;

      return dateB - dateA;
    });
  };

  const showMoreEntries = (increasePage = false) => {
    if (increasePage) {
      setCurrentPage(currentPage + 1);
      getAndSetNews(currentPage + 1);
    }

    setShownEntries((shownEntries) => shownEntries + 12);
  };

  const handleSetActiveFilterIndex = (event) => {
    let index = event.target.dataset.index;
    setShownEntries(12);
    setSelectedFilter(parseInt(index));
  };

  return {
    selectedFilter,
    news,
    newsError,
    shownEntries,
    setShownEntries,
    didNewsLoad,
    handleSetActiveFilterIndex,
    showMoreEntries,
    getAndSetNews,
    currentPage,
    maxPages,
    sortbyPublishedOn,
  };
};
