import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { newsParser } from '../../util/newsParser';
import { teaserTextParser } from '../../util/teaserTextParser';
import Card from './Card';
import IconSpeechBubble from '../Icons/IconSpeechBubble';
import CardGridPlaceholderElement from './CardGridPlaceholderElement';
import IconExternalLink from '../Icons/IconExternalLink';

function NewsCardsHighlight({ news, selectedFilter, filterOptions, cssClasses, topic }) {
  const app = useSelector((state) => state.app);
  const [parsedText, setParsedText] = useState('');
  const [selectedNewsItem, setSelectedNewsItem] = useState(null);
  const titleRef = useRef(null);

  if (!cssClasses) cssClasses = '';

  // Parse news items using newsParser utility function
  news = news.map((n) => newsParser(n));

  // Apply selected filter
  if (selectedFilter > 0) {
    news = news.filter((item) => {
      let filterSlug = filterOptions[selectedFilter].slug;
      let itemTopicsSlugs = item.topicsArray.map((t) => t.slug);
      return itemTopicsSlugs.includes(filterSlug);
    });
  }

  // Effect to select initial news item when news or filter changes
  useEffect(() => {
    if (!selectedNewsItem && news.length > 0) {
      setSelectedNewsItem(news[0]);
    }
  }, [news, selectedFilter]);

  // Effect to trim content when selectedNewsItem changes
  useEffect(() => {
    if (selectedNewsItem) trimContent();
  }, [selectedNewsItem]);

  // Effect to cycle through news items automatically
  useEffect(() => {
    const intervalId = setInterval(() => {
      setSelectedNewsItem((prevSelectedNewsItem) => {
        const currentIndex = news.findIndex((item) => item.slug === prevSelectedNewsItem.slug);
        const nextIndex = (currentIndex + 1) % news.length;
        return news[nextIndex];
      });
    }, 5000);
    return () => clearInterval(intervalId);
  }, [news]);

  // Function to trim content based on title height
  const trimContent = () => {
    const title = titleRef.current;
    if (title) {
      const titleHeight = title.offsetHeight;
      if (titleHeight < 30) {
        setParsedText(teaserTextParser(selectedNewsItem.summary, 180));
      } else if (titleHeight < 60) {
        setParsedText(teaserTextParser(selectedNewsItem.summary, 150));
      } else if (titleHeight < 90) {
        setParsedText(teaserTextParser(selectedNewsItem.summary, 110));
      } else {
        setParsedText(teaserTextParser(selectedNewsItem.summary, 40));
      }
    }
  };

  // Render NewsCards component
  return (
    <>
      {selectedNewsItem && (
        <>
          <Card
            cardStyle={'topImage'}
            cssClasses={'card--highlight-active bg-white with-shadow'}
            imagePath={selectedNewsItem.header_picture !== '' ? selectedNewsItem.header_picture : null}
            icon={selectedNewsItem.header_picture !== '' ? null : <IconSpeechBubble color="#fff" />}
            linkOnFullCard={true}
            linkTo={selectedNewsItem.external ? selectedNewsItem.source : app.root + '/news/' + selectedNewsItem.slug}
            openLinkInNewTab={selectedNewsItem.external}
          >
            {selectedNewsItem.external && (
              <div className="news-card__label news-card-highlight__label immo-card__label--active">
                <IconExternalLink color="#fff" />
                <span className="text-white">externer Artikel</span>
              </div>
            )}
            <div className="card__content__text">
              <p className="cardMeta mb-50rem">
                {selectedNewsItem.topics.length > 0 ? ` ${selectedNewsItem.topics}` : ''}
                {selectedNewsItem.published_on ? ` | ${selectedNewsItem.published_on}` : ''}
              </p>
              <p className="font-110 font-weight-600 lh-130 mb-50rem" ref={titleRef}>
                {selectedNewsItem.title}
              </p>
              <div className="news-card__teaser">
                <p className="cardText">{parsedText}</p>
              </div>
            </div>
            <span className="card__readmorelink">Mehr lesen</span>
          </Card>

          <div className={`cardgrid ${cssClasses}`}>
            {news.map((n) => (
              <div className={`news-card ${selectedNewsItem && selectedNewsItem.slug === n.slug ? 'news-card--active' : ''}`} onClick={() => setSelectedNewsItem(n)} key={n.slug}>
                <Card
                  cardStyle="customContent"
                  cssClasses={'bg-white with-shadow'}
                  imagePath={n.header_picture !== '' ? n.header_picture : null}
                  icon={n.header_picture !== '' ? null : <IconSpeechBubble color="#fff" />}
                >
                  <div className="d-flex">
                    <img src={n.header_picture !== '' ? n.header_picture : null} alt={n.title} />
                    <div className="card__content__text">
                      <p className="cardMeta">{n.topics.length > 0 ? ` ${n.topics}` : ''}</p>
                      <p className="font-110 font-weight-600 lh-130" ref={titleRef}>
                        {n.title}
                      </p>
                    </div>
                  </div>
                </Card>
              </div>
            ))}
            <CardGridPlaceholderElement array={news} />
          </div>
        </>
      )}
    </>
  );
}

export default NewsCardsHighlight;
