import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import headerImage from '../assets/images/sREAL_Sujets_2023_Webhero_16zu9_1.1.jpg';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ImmoCard from '../components/Cards/ImmoCard';
import PageDialogSearchProfileForm from '../components/Dialogs/PageDialogSearchProfileForm';
import { neubauprojekteSearchNoResultsText } from '../components/FormFields/util/formTexts';
import Header from '../components/Header/Header';
import LoadingSpinner from '../components/LoadingSpinner';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { useFetchImmoSearch } from '../hooks/immo/useFetchImmoSearch';
import { parseImmoSreal } from '../util/immoObjectParser';

import { FormProvider, useForm } from 'react-hook-form';
import SearchProfilFixedBar from '../components/fixedbar/SearchProfilFixedBar';
import NeubauprojekteSucheForm from '../components/Forms/NeubauprojekteSucheForm';
import IconArrowDown from '../components/Icons/IconArrowDown';
import IconLoadingSpinner from '../components/Icons/IconLoadingSpinner';

function Neubauprojekte() {
	const methods = useForm();
	const { getValues, handleSubmit } = methods;

  const { setResidentialProjectsList, error, neubauList, neubauListCnt, neubauLoading } = useFetchImmoSearch();

  const app = useSelector((state) => state.app);
  const { isMobile } = useHandleScreenResize();
  
	const [page, setPage] = useState(1);


		/* SUBMIT FORM */
		const onSubmit = useCallback(async (data) => {
			setPage(1);
			const formData = getValues();
			setResidentialProjectsList(formData, 1);
		}, [setResidentialProjectsList]);

		/* LOAD ON FIRST pAGE LOAD */
		useEffect(() => {
			const formData = getValues();
			setResidentialProjectsList(formData);	
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		/* SHOW MORE IMMOS */
		const handleShowMoreImmos = () => {
			const formData = getValues();
	
			let currentPage = page;
			const newPage = currentPage + 1;
			setPage(newPage);
	
			setResidentialProjectsList(formData, newPage);
		};

  return (
    <>
      <MetaTags
        title={'Immobilien suchen auf my-sreal.at | Die Online-Plattform für Ihre Immobilie'}
        description={
          'Sie sind auf der Suche, denn Sie wollen eine Wohnung kaufen oder ein Haus kaufen? my-sreal.at ist die Online-Plattform für Ihren Immobilienkauf. Einfach, transparent und digital suchen, finden und kaufen.'
        }
        imageName={'mysreal_hero_immosuche.jpg'}
      />

			<FormProvider {...methods}>

				{!isMobile ? (
					<Header cssClasses="header--default header--withimage header--immosuche" overlappedContent={true}>
						<img src={headerImage} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
						<div className="header__content-wrapper header__content-wrapper--top">
							<h1 className="text-white mt-500rem mb-0">Neubauprojekte</h1>
						</div>
					</Header>
				) : (
					<Header cssClasses="header--immosuche header--immosuche-mobil">
						<div className="header__content-wrapper header__content-wrapper--top">
							<h1>Neubauprojekte</h1>
						</div>
						<Accordion className="accordion accordion--searchform" defaultExpanded={false}>
							<AccordionSummary expandIcon={<IconArrowDown color="#fff" />} aria-controls="panel1bh-content" id="panel1bh-header">
								<h2 className="pl-100rem pr-100rem">Suche anpassen</h2>
							</AccordionSummary>
							<AccordionDetails>
							<NeubauprojekteSucheForm submit={handleSubmit(onSubmit)} />
							</AccordionDetails>
						</Accordion>
					</Header>
				)}

				<MainContent cssClasses="main-content--immosuche">
					<div className="w-desktop--center">
						<div className="immosuche">
							<NeubauprojekteSucheForm submit={handleSubmit(onSubmit)} />
						</div>

						<SearchProfilFixedBar />

						<div className="pb-100rem" id="immoresult-list">
							{error ? (
								<>
									<h2 className="mb-200rem">Ergebnisse (0)</h2>
									<p>{error}</p>
								</>
							) : (
								<>
									{ neubauLoading ? (
										<p className={'page__loadingmessage mt-200rem'}>
											<IconLoadingSpinner color={'#5d5d5d'} />
											
											<span className="loading-animation ml-10">
												Ergebnisse werden geladen
												<span>.</span><span>.</span><span>.</span>
											</span>
										</p>
									) : (
										<>
											<h2>Ergebnisse ({neubauListCnt})</h2>
											{neubauList.length > 0 ? (
												<>
													<div className="cardgrid">
														{neubauList?.map((group, groupIndex) => (
															<Fragment key={groupIndex}>
																{group?.length > 0 && group.map((immo) => (
																	<ImmoCard wohneinheiten={immo.wohneinheiten} retailObject={parseImmoSreal(immo)} key={immo.id} />
																))}
															</Fragment>
														))}
													</div>

													{neubauListCnt > neubauList.flat().length && (
														<div className="d-flex justify-content-center">
															{app.formIsLoading ? (
																<LoadingSpinner />
															) : (
																<span className="textlink" onClick={handleShowMoreImmos}>
																	Weitere Ergebnisse laden
																</span>
															)}
														</div>
													)}
												</>
											) : (
												<p>{neubauprojekteSearchNoResultsText}</p>
											)}
										</>
									)}
								</>
							)}
						</div>
					</div>
				</MainContent>
			</FormProvider>

      <PageDialogSearchProfileForm></PageDialogSearchProfileForm>
    </>
  );
}

export default Neubauprojekte;
