import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import '../../styles/producthighlights.scss';

//producthighlights-img-1.png

function ProductHighlights({
  cssClasses = '',
  fullWidth,
  isMobile = false,
  mainHeadline,
  mainSubline,
  imagePath1,
  imagePath2,
  imagePath3,
  imagePath4,
  imagePath1Mobile,
  imagePath2Mobile,
  imagePath3Mobile,
  imagePath4Mobile,
  color1,
  color2,
  color3,
  color4,
  button1,
  button2,
  button3,
  button4,
  buttonlink1,
  buttonlink2,
  buttonlink3,
  buttonlink4,
  headline1,
  headline2,
  headline3,
  headline4,
	id = ''
}) {
  const app = useSelector((state) => state.app);

  if (fullWidth) {
    cssClasses += ' producthighlights--fullWidth';
  }
  return (
    <div className={cssClasses + ' producthighlights'} id={id}>
      <div className="producthighlights__text">
        <h2>{mainHeadline}</h2>
        {mainSubline.split('<br>').map((line, index) => (
          <React.Fragment key={index}>
            <p>{line}</p>
            <br />
          </React.Fragment>
        ))}
      </div>
      {!fullWidth && (
        <div className="producthighlights__boxes">
          {(imagePath4 || color4) && (
            <>
              <div className={'box box-small ' + color1}>
                {imagePath1 && <img src={imagePath1} alt={headline1}></img>}
                <h4>{headline1}</h4>
                {button1 && (
                  <Link to={app.root + buttonlink1} title="immo-drive anzeigen" className="button button--white-outline">
                    {button1}
                  </Link>
                )}
              </div>
              <div className={'box box-small ' + color2}>
                {imagePath2 && <img src={imagePath2} alt={headline2}></img>}
                <h4>{headline2}</h4>
                {button2 && (
                  <Link to={app.root + buttonlink2} title="immo-drive anzeigen" className="button button--white-outline">
                    {button2}
                  </Link>
                )}
              </div>
              <div className={'box box-small ' + color3}>
                {imagePath3 && <img src={imagePath3} alt={headline3}></img>}
                <h4>{headline3}</h4>
                {button3 && (
                  <Link to={app.root + buttonlink3} title="immo-drive anzeigen" className="button button--white-outline">
                    {button3}
                  </Link>
                )}
              </div>
              <div className={'box box-small ' + color4}>
                {imagePath4 && <img src={imagePath4} alt={headline4}></img>}
                <h4>{headline4}</h4>
                {button4 && (
                  <Link to={app.root + buttonlink4} title="immo-drive anzeigen" className="button button--white-outline">
                    {button4}
                  </Link>
                )}
              </div>
            </>
          )}
          {!imagePath4 && !color4 && (
            <>
              <div className={`box ${isMobile ? 'box-small' : 'box-large'} ${color1}`}>
                {imagePath1 && <img src={imagePath1} alt={headline1}></img>}
                <h4>{headline1}</h4>
                {button1 && (
                  <Link to={app.root + buttonlink1} title="immo-drive anzeigen" className="button button--white-outline">
                    {button1}
                  </Link>
                )}
              </div>
              <div className={'box box-small ' + color2}>
                {imagePath2 && <img src={imagePath2} alt={headline2}></img>}
                <h4>{headline2}</h4>
                {button2 && (
                  <Link to={app.root + buttonlink2} title="immo-drive anzeigen" className="button button--white-outline">
                    {button2}
                  </Link>
                )}
              </div>
              <div className={'box box-small ' + color3}>
                {imagePath3 && <img src={imagePath3} alt={headline3}></img>}
                <h4>{headline3}</h4>
                {button3 && (
                  <Link to={app.root + buttonlink3} title="immo-drive anzeigen" className="button button--white-outline">
                    {button3}
                  </Link>
                )}
              </div>
            </>
          )}
        </div>
      )}
      {fullWidth && (
        <div className="producthighlights__boxes">
          <div className={`box ${isMobile ? 'box-small' : 'box-large'} ${color1}`}>
            {imagePath1Mobile ? (
              <>
                {imagePath1 && (
                  <>
                    <img className="desktop-hidden" src={imagePath1Mobile} alt={headline1}></img>
                    <img className="mobile-hidden" src={imagePath1} alt={headline1}></img>
                  </>
                )}
              </>
            ) : (
              <>{imagePath1 && <img src={imagePath1} alt={headline1}></img>}</>
            )}
            <h4>{headline1}</h4>
            {button1 && (
              <Link to={app.root + buttonlink1} title="immo-drive anzeigen" className="button button--white-outline">
                {button1}
              </Link>
            )}
          </div>
          <div className={'box box-small ' + color2}>
            {imagePath2Mobile ? (
              <>
                {imagePath2 && (
                  <>
                    <img className="desktop-hidden" src={imagePath2Mobile} alt={headline2}></img>
                    <img className="mobile-hidden" src={imagePath2} alt={headline2}></img>
                  </>
                )}
              </>
            ) : (
              <>{imagePath2 && <img src={imagePath2} alt={headline2}></img>}</>
            )}
            <h4>{headline2}</h4>
            {button2 && (
              <Link to={app.root + buttonlink2} title="immo-drive anzeigen" className="button button--white-outline">
                {button2}
              </Link>
            )}
          </div>
          <div className={'box box-small ' + color3}>
            {imagePath3Mobile ? (
              <>
                {imagePath3 && (
                  <>
                    <img className="desktop-hidden" src={imagePath3Mobile} alt={headline3}></img>
                    <img className="mobile-hidden" src={imagePath3} alt={headline3}></img>
                  </>
                )}
              </>
            ) : (
              <>{imagePath3 && <img src={imagePath3} alt={headline3}></img>}</>
            )}
            <h4>{headline3}</h4>
            {button3 && (
              <Link to={app.root + buttonlink3} title="immo-drive anzeigen" className="button button--white-outline">
                {button3}
              </Link>
            )}
          </div>
          <div className={`box ${isMobile ? 'box-small' : 'box-large'} ${color4}`}>
            {imagePath4Mobile ? (
              <>
                {imagePath4 && (
                  <>
                    <img className="desktop-hidden" src={imagePath4Mobile} alt={headline4}></img>
                    <img className="mobile-hidden" src={imagePath4} alt={headline4}></img>
                  </>
                )}
              </>
            ) : (
              <>{imagePath4 && <img src={imagePath4} alt={headline4}></img>}</>
            )}
            <h4>{headline4}</h4>
            {button4 && (
              <Link to={app.root + buttonlink4} title="immo-drive anzeigen" className="button button--white-outline">
                {button4}
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductHighlights;
