import AktuellesNachrichten from '../components/Aktuelles/AktuellesNachrichten';
import Header from '../components/Header/Header';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';

import { useEffect, useState } from 'react';
import CardGridPlaceholderElement from '../components/Cards/CardGridPlaceholderElement';
import IconLoadingSpinner from '../components/Icons/IconLoadingSpinner';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { useHandleMessages } from '../hooks/messages/useHandleMessages';
import PageDialogChat from '../components/Dialogs/PageDialogChat';

function Nachrichten() {
  const { threads, threadsLoading, loadMessages } = useHandleMessages();
  const [activeThreadId, setActiveThreadId] = useState(null);
  const [isDesktop, setIsDesktop] = useState(false);

  const { isMobile } = useHandleScreenResize();

  useEffect(() => {
    loadMessages();

    if (!isMobile) {
      setIsDesktop(true);
    }
  }, []);

  const getActiveThread = () => {
    if (!activeThreadId) return null;
    return threads.find((thread) => thread.chatList[0].id === activeThreadId)?.chatList || null;
  };

  const handleThreadClick = (threadId) => {
    setActiveThreadId(threadId);
  };

  const handleCloseDialog = () => {
    setActiveThreadId(null);
  };

  return (
    <>
      <MetaTags title={'Nachrichten | Die Online-Plattform für Ihre Immobilie'} />
      <Header cssClasses="header--darkbluebg header--darkblue-withouticon header--overlapped">
        <div className="header__desktopheadlines mb-20">
          <h1 className="text-white w-desktop--center mt-200rem mb-100">Nachrichten</h1>
        </div>
      </Header>
      <MainContent cssClasses="nachrichten">
        <div className="w-desktop--center elements3cols">
          {threadsLoading && threads.length === 0 ? (
						<p className={'page__loadingmessage'}>
							<IconLoadingSpinner color={isDesktop ? '#ffffff' : '#5d5d5d'} />
							
							<span className="loading-animation ml-10">
								Ihre Nachrichten werden geladen
								<span>.</span><span>.</span><span>.</span>
							</span>
						</p>
          ) : (
            <>
              {threads?.length === 0 ? (
                <p className="page__emptymessage">Sie haben derzeit noch keine Nachrichten empfangen. Sobald eine Makler:in mit Ihnen in Kontakt ist, finden Sie hier Ihre Konversationen.</p>
              ) : (
                <>
                  {threads.map((item, index) => {
                    return (
                      <AktuellesNachrichten
                        thread={item.chatList}
                        onThreadClick={() => handleThreadClick(item.chatList[0].id)}
                        handleCloseDialog={handleCloseDialog}
                        cssClasses={'elements3cols__col linkitem-green'}
                        reloadMessagesCallback={async () => await loadMessages()}
                      />
                    );
                  })}

                  <CardGridPlaceholderElement array={threads} type="linkitem" cssClasses="elements3cols__col" />
                </>
              )}
            </>
          )}
        </div>
        <PageDialogChat open={!!activeThreadId} handleClose={handleCloseDialog} thread={getActiveThread()} reloadMessagesCallback={async () => await loadMessages()} />
      </MainContent>
    </>
  );
}

export default Nachrichten;
