import { useDispatch, useSelector } from 'react-redux';

import PageDialog from './PageDialog';

import { KAUFANBOT_SITEINDEX_MAIN, KAUFANBOT_SITEINDEX_SUCCES, PAGEDIALOG_KAUFANBOT } from '../../constants';

// STORE
import { useState } from 'react';
import { postSendOffer } from '../../api/JustImmo';
import { closePageDialog, setFormIsLoading } from '../../reducers/app';
import { setUserSentOffers } from '../../reducers/user';
import ButtonForwardAction from '../Buttons/ButtonForwardAction';
import FormInput from '../FormFields/FormInput';
import { fetchErrorText } from '../FormFields/util/formTexts';
import IconSuccess from '../Icons/IconSuccess';
import LoadingSpinner from '../LoadingSpinner';

function PageDialogKaufanbot({ wunschimmobilie }) {
  const app = useSelector((state) => state.app);
	const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const [step, setStep] = useState(KAUFANBOT_SITEINDEX_MAIN);
  const [error, setError] = useState('');
	const [wunschKaufpreis, setWunschKaufpreis] = useState();

  const handleClose = () => {
    if (app.formIsLoading) return;
    dispatch(closePageDialog());
		setStep(KAUFANBOT_SITEINDEX_MAIN);
		setError('');
		setWunschKaufpreis();
  };

  const sendKaufanbot = async () => {
    if (app.formIsLoading) return;
    setError('');

    if (!wunschKaufpreis?.match(/^[0-9]+$/) || wunschKaufpreis < 1) {
      setError('Bitte geben Sie Ihr Angebot als ganze Zahl ohne Satzzeichen ein.');
      return;
    }

    if (!(parseInt(wunschKaufpreis) > 0)) {
      setError('Bitte geben Sie Ihren Wunsch-Kaufpreis ein.');
    } else {
      dispatch(setFormIsLoading(true));

			if(app.demoMode) {
				setTimeout(() => {
					dispatch(setFormIsLoading(false));
					setStep(KAUFANBOT_SITEINDEX_SUCCES);
				}, 1000);
	
				return;
			}

      const tenantId = wunschimmobilie.immo.tenant_id;
      const realtyId = wunschimmobilie.immo.id;

      try {
        await postSendOffer(tenantId, realtyId, wunschKaufpreis);

        dispatch(setFormIsLoading(false));
        setStep(KAUFANBOT_SITEINDEX_SUCCES);

				const existingOffers = user.sentOffers;
				const newOffer = {
					metaJson: {
						RealtyId: realtyId,
						Price: wunschKaufpreis
					},
					created: new Date().toISOString().replace('Z', '+00:00')
				}
				
				dispatch(setUserSentOffers([...existingOffers, newOffer]));

      } catch (e) {
        dispatch(setFormIsLoading(false));
        setError(fetchErrorText);
        console.error('e', e);
      }
    }
  };

	if(!wunschimmobilie) {
		return null;
	}
	else {
		return (
			<PageDialog 
				open={app.pageDialogOpenId === PAGEDIALOG_KAUFANBOT} 
				handleClose={handleClose} 
				headline={'Anfrage Kaufanbot'} 
				showBackArrow={false}
			>
				<div className="p-100rem">
					{step === KAUFANBOT_SITEINDEX_MAIN ? (
						<>
							<h3 className="form__title">Wunschimmobilie</h3>

							<div className="d-flex flex-col mb-200rem">
								<span className="pt-100rem">Infos zum Objekt</span>
								<span className="font-weight-700 pt-50rem">{wunschimmobilie?.addressLine}</span>

								<span className="pt-200rem">Verkaufspreis</span>
								<span className="font-weight-600 font-170 pt-50rem">{wunschimmobilie?.kaufpreis}</span>
							</div>

							<FormInput
								id={'wunschKaufpreis'}
								label={'Wunsch-Kaufpreis'}
								placeholder={'Preis für Anbot eingeben'}
								type={'number'}
								value={wunschKaufpreis}
								callback={(value) => setWunschKaufpreis(value)}
							/>

							<p className='mt-200rem'>
								s REAL wird beim Abschicken dieses Formulars unverbindlich informiert, dass Sie ein Kaufanbot für Ihre Wunschimmobilie abgeben wollen.
							</p>
							<p className='mt-100rem'>
								Eine Makler:in nimmt anschließend mit Ihnen für die Besprechung eines schriftlichen Kaufanbots Kontakt auf.
							</p>

							<LoadingSpinner />

							{error && <p className="form__fielderror">{error}</p>}

							<div className="button-panel">
								<ButtonForwardAction buttonText={'Anfrage Kaufanbot abschicken'} buttonStyle="blue" forwardAction={() => sendKaufanbot()} />
							</div>
							
						</>
					) : step === KAUFANBOT_SITEINDEX_SUCCES ? (
						<>
							<div className="d-flex w-100 justify-content-center pt-40 pb-40">
								<IconSuccess color="#1E3163" />
							</div>

							<h2 className="pt-200rem">Anfrage für die Kaufanbot-Erstellung abgeschickt.</h2>
							<p className="pt-200rem pb-200rem lh-130">Eine Makler:in meldet sich in Kürze bei Ihnen, um ein schriftliches Kaufanbot mit Ihnen zu besprechen.</p>

							<div className="button-panel">
								<button className="button button--gray-outline" onClick={() => handleClose()}>
									Schließen
								</button>
							</div>
						</>
					) : null }
				</div>
			</PageDialog>
		);
	}
}

export default PageDialogKaufanbot;
