import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import { useFetchNews } from '../../hooks/news/useFetchNews';
import NewsCards from '../Cards/NewsCards';

function NewsTeaserCards({ cssClasses }) {
  const { news } = useFetchNews();

  if (!cssClasses) cssClasses = '';

  const { isMobile } = useHandleScreenResize();

  return (
    news?.length > 0 && (
      <div className={cssClasses}>
        {/* News Headline with Link mobile+desktop */}
        <div className={`${!isMobile ? 'w-desktop--center pt-0' : ''}`}>
          <h2 className="mb-200rem mt-0 text-center--mobile">Meine News</h2>
        </div>

				<NewsCards news={news.slice(0, 6)} carousel={true} />
      </div>
    )
  );
}

export default NewsTeaserCards;
