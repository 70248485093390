import ButtonForwardAction from '../Buttons/ButtonForwardAction';
import FormInput from '../FormFields/FormInput';
import FormSelect from '../FormFields/FormSelect';

import { featuresOptions, immobilienartOptionsSearchForm } from '../FormFields/util/formOptions';

import '../../styles/searchform.scss';
//HOOKS
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getLocations, getRealtySubTypes } from '../../api/Sreal';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import FormAutocomplete from '../FormFields/FormAutocomplete';

function SearchForm({ submit, resetForm }) {
  const {
    control,
    watch,
    handleSubmit,
    getValues,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext();

  const estateType = watch('estateType');
  const subEstateType = watch('subEstateType');

  const { isMobile } = useHandleScreenResize();

  const [subEstateTypeOptions, setSubEstateTypeOptions] = useState([]);

  const [isExpanded, setIsExpanded] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  /**
   * FIND SUBTYPES BY ESTATETYPE
   * reset subtypes that are not longer valid
   * @param {Object} query
   */
  const getSubEstateTypes = useCallback(async (query) => {
		if (query.length > 0) {
			const subTypes = await getRealtySubTypes(query.map((val) => val.id));

			if (subTypes.length > 0) {
				setSubEstateTypeOptions(subTypes);

				// check if subtype still valid
				if (subEstateType?.length > 0) {
					const newSubtypes = subEstateType
						.map((sub) => {
							return subTypes.find((option) => sub.id === option.id);
						})
						.filter((newSub) => newSub !== undefined);
					setValue('subEstateType', newSubtypes);
				}
			} else {
				setSubEstateTypeOptions([]);
			}
		} else {
			setSubEstateTypeOptions([]);
		}
	}, [setValue]);

  useEffect(() => {
    if (estateType && estateType.length > 0) {
      getSubEstateTypes(estateType);
    } else {
      resetField('subEstateType');
    }
  }, [estateType, getSubEstateTypes, resetField]);
  /* FIND SUBTYPES BY ESTATETYPE */

  /* AUTOCOMPLETE ADDRESS */
  const autocompleteAddress = useCallback(async (query) => {
    if (typeof query !== 'string') return;

    if (!query || query.length < 2) {
      setOptions([]); // Clear options if input is too short
      return;
    }

    setLoading(true);
    try {
      let locations = await getLocations(query);

      // Filter out selected options
      const selectedAddress = getValues('address') || [];
      locations = locations.filter((location) => {
        return !selectedAddress.some((selectedOption) => selectedOption.value === location.value);
      });

      // check if locations has options that are NOT disabled
      const hasOptions = locations.find((location) => !location.disabled);
      if (!hasOptions) locations = [];

      setOptions(locations);
    } catch (error) {
      console.error('Error fetching autocomplete data:', error);
      setOptions([]);
    } finally {
      setLoading(false);
    }
  }, []);

  const [typedAddress, setTypedAddress] = useState('');
  useEffect(() => {
    if (typedAddress) {
      autocompleteAddress(typedAddress);
    }
  }, [typedAddress, autocompleteAddress]);

  /* AUTOCOMPLETE ADDRESS END */

  useEffect(() => {
    if (isMobile) {
      setIsExpanded(true);
    }
  }, [isMobile]);

  const handleExpanded = () => {
    const oldVal = isExpanded;
    setIsExpanded(!oldVal);
  };

  if (isExpanded) {
    return (
      <div id="searchForm" className="searchForm searchForm--longversion">
        <div className="immosearch-form">
          <div className="immosearch-form__col">
            <Controller
              name="address"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <FormAutocomplete
                  id="address"
                  label="Ort, PLZ oder Objektnummer"
                  loading={loading}
                  placeholder={'Ort, PLZ oder Objektnummer'}
                  options={options}
                  callback={field.onChange}
                  callbackInput={setTypedAddress}
                  value={field.value}
                  multiple={true}
                />
              )}
            />
          </div>
          <div className="immosearch-form__col">
            <div className="form__row form__row--double">
              <Controller
                name="areaFrom"
                control={control}
                render={({ field }) => (
                  <FormInput id={'areaFrom'} label={'Fläche min'} placeholder={'Fläche min'} value={field.value} callback={field.onChange} type="number" minValue={0} maxValue={watch('areaTo')} />
                )}
              />
              <Controller
                name="areaTo"
                control={control}
                render={({ field }) => (
                  <FormInput id={'areaTo'} label={'Fläche max'} placeholder={'Fläche max'} value={field.value} callback={field.onChange} type="number" minValue={watch('areaFrom') || 0} />
                )}
              />
            </div>
          </div>
          <div className="immosearch-form__col">
            <div className="form__row form__row--double">
              <Controller
                name="priceFrom"
                control={control}
                render={({ field }) => (
                  <FormInput id={'priceFrom'} label={'Preis min'} placeholder={'Preis min'} value={field.value} callback={field.onChange} type="number" minValue={0} maxValue={watch('priceTo')} />
                )}
              />

              <Controller
                name="priceTo"
                control={control}
                render={({ field }) => (
                  <FormInput id={'priceTo'} label={'Preis max'} placeholder={'Preis max'} value={field.value} callback={field.onChange} type="number" minValue={watch('priceFrom') || 0} />
                )}
              />
            </div>
          </div>
        </div>

        <div className="immosearch-form">
          <div className="immosearch-form__col">
            <Controller
              name="estateType"
              control={control}
              render={({ field }) => (
                <FormSelect
                  id={'estateType'}
                  label={'Objektart'}
                  placeholder="Objektart wählen"
                  value={field.value || []}
                  options={immobilienartOptionsSearchForm}
                  callback={field.onChange}
                  multiple={true}
                />
              )}
            />
          </div>

          <div className="immosearch-form__col">
            <div className="form__row form__row--double">
              <Controller
                name="surfaceAreaFrom"
                control={control}
                render={({ field }) => (
                  <FormInput
                    id={'surfaceAreaFrom'}
                    label={'Grundfläche von'}
                    placeholder={'Grundfläche von'}
                    value={field.value}
                    callback={field.onChange}
                    type="number"
                    minValue={0}
                    maxValue={watch('surfaceAreaTo')}
                  />
                )}
              />

              <Controller
                name="surfaceAreaTo"
                control={control}
                render={({ field }) => (
                  <FormInput
                    id={'surfaceAreaTo'}
                    label={'Grundfläche bis'}
                    placeholder={'Grundfläche bis'}
                    value={field.value}
                    callback={field.onChange}
                    type="number"
                    minValue={watch('surfaceAreaFrom') || 0}
                  />
                )}
              />
            </div>
          </div>
          <div className="immosearch-form__col">
            <div className="form__row form__row--double">
              <Controller
                name="roomsFrom"
                control={control}
                render={({ field }) => (
                  <FormInput id={'roomsFrom'}label={'Zimmer von'} placeholder={'Zimmer von'} value={field.value} callback={field.onChange} type="number" minValue={0} maxValue={watch('roomsTo')} />
                )}
              />

              <Controller
                name="roomsTo"
                control={control}
                render={({ field }) => (
                  <FormInput id={'roomsTo'} label={'Zimmer bis'} placeholder={'Zimmer bis'} value={field.value} callback={field.onChange} type="number" minValue={watch('roomsFrom') || 0} />
                )}
              />
            </div>
          </div>
        </div>

        <div className="immosearch-form">
          <div className="immosearch-form__col">
            <Controller
              name="subEstateType"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormSelect
                  id="subEstateType"
                  label="Unterobjektart"
                  hasLockIcon={!estateType || estateType.length === 0}
                  disabled={!estateType || estateType.length === 0}
                  placeholder={!estateType || estateType.length === 0 ? 'Bitte erst Objektart auswählen' : 'Unterobjektart wählen'}
                  options={subEstateTypeOptions}
                  callback={field.onChange}
                  value={field.value || []}
                  multiple={true}
                />
              )}
            />
          </div>

          <div className="immosearch-form__col">
            <Controller
              name="features"
              control={control}
              render={({ field }) => (
                <FormSelect
                  id={'features'}
                  label={'Ausstattungsmerkmale'}
                  placeholder="Ausstattungsmerkmale wählen"
                  value={field.value || []}
                  options={featuresOptions}
                  callback={field.onChange}
                  multiple={true}
                />
              )}
            />
          </div>
          <div className="immosearch-form__col">
            <Controller
              name="fullText"
              control={control}
              render={({ field }) => <FormInput id="fullText" label={'Volltextsuche'} placeholder={'Volltextsuche'} value={field.value} callback={field.onChange} />}
            />
          </div>
        </div>

        <div className="searchForm__buttons searchForm__buttons--expanded">
          <div className="searchForm__buttons--expanded__left">
            <ButtonForwardAction forwardAction={submit} buttonText={'Immobilie suchen'} />

            <span className="textlink text-white d-flex flex-col align-items-center pt-100rem pb-50rem mobile-hidden" onClick={handleExpanded}>
              Einfache Suche aufrufen
            </span>
          </div>

          <button className="button button--white-outline mb-0" onClick={resetForm}>
            Suche löschen
          </button>
        </div>
      </div>
    );
  } else {
    // not expanded
    return (
      <div id="searchForm" className="searchForm">
        <Controller
          name="address"
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <FormAutocomplete
              id="address"
              label="Ort, PLZ oder Objektnummer"
              loading={loading}
              placeholder={'Ort, PLZ oder Objektnummer'}
              options={options}
              callback={field.onChange}
              callbackInput={setTypedAddress}
              value={field.value}
              multiple={true}
            />
          )}
        />

        <Controller
          name="estateType"
          control={control}
          render={({ field }) => (
            <FormSelect
              id={'estateType'}
              label={'Objektart'}
              placeholder="Objektart wählen"
              value={field.value || []}
              options={immobilienartOptionsSearchForm}
              callback={field.onChange}
              multiple={true}
            />
          )}
        />

        <div className="form__row form__row--double">
          <Controller
            name="areaFrom"
            control={control}
            render={({ field }) => <FormInput id={'areaFrom'} label={'Fläche min'} placeholder={'Fläche min'} value={field.value} callback={field.onChange} type="number" minValue={0} />}
          />

          <Controller
            name="priceTo"
            control={control}
            render={({ field }) => <FormInput id={'priceTo'} label={'Preis max'} placeholder={'Preis max'} value={field.value} callback={field.onChange} type="number" minValue={0} />}
          />
        </div>

        <div className="searchForm__buttons">
          <ButtonForwardAction forwardAction={submit} buttonText={'Immobilie suchen'} />

          <span className="textlink text-white w-100 d-flex flex-col align-items-center pt-100rem pb-50rem" onClick={handleExpanded}>
            Erweiterte Suche aufrufen
          </span>
        </div>
      </div>
    );
  }
}

export default SearchForm;
