import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// ICONS
import IconSmiley from '../Icons/IconSmiley';
import parseCurrency from '../../util/currencyParser';

import '../../styles/wohnwert.scss';

function WohnwertLightFormResultWrapper({ children }) {
  let user = useSelector((state) => state.user);
	let app = useSelector((state) => state.app);

  const [von, setVon] = useState(0);
  const [bis, setBis] = useState(0);
  const [federalState, setFederalState] = useState('');
  const [district, setDistrict] = useState('');
  const [estateType, setEstateType] = useState('');
  const [estateSize, setEstateSize] = useState(0);
  const [estateCondition, setEstateCondition] = useState(0);
    
  useEffect(() => {
    if (user.wohnwertResultsActive && user.wohnwertResultsActive.id > -1 && !app.demoMode) {
      let index = user.wohnwertResultsActive.id;
      let result = user.wohnwertLastResults[index];
      
      if(result.von) {
        setVon(result.von);
      }

      if(result.bis) {
        setBis(result.bis);
      }

      if(result.federalState) {
        setFederalState(result.federalState);
      }

      if(result.district) {
        setDistrict(result.district);
      }

      if(result.estateType) {
        setEstateType(result.estateType);
      }

      if(result.estateSize) {
        setEstateSize(result.estateSize);
      }

      if(result.estateCondition && result.estateCondition > -1) {
        setEstateCondition(result.estateCondition);
      }
    }
  
  }, [user.wohnwertResultsActive])

  if ((user.wohnwertResultsActive && user.wohnwertResultsActive.id > -1) || app.demoMode) {
    return (
      <div className="wohnwertlight__formresult-wrapper">
        <div>
          <div className="wohnwertlight__formresult">
            <span className="lowlight">Von</span>
            <span className="highlight">{!app.demoMode ? parseCurrency(von) : '180.000 €'}</span>
          </div>

          <div className="wohnwertlight__formresult">
            <span className="lowlight">Bis</span>
            <span className="highlight">{!app.demoMode ? parseCurrency(bis) : '210.000 €'}</span>
          </div>
        </div>

        <div className="mt-20 font-100 lh-160">
          <span>Ihre Angaben:</span>
          <div>
						{ !app.demoMode ? (
							<>
								{federalState}, {district}, {estateType}, {estateSize} m²
								<IconSmiley level={estateCondition} color="#fff" />
								{children}
							</>
						) : (
							<>
								Wien, 1180, Wohnung, 80 m²
								<IconSmiley level={3} color="#fff" />
							</>
						)}
          </div>
        </div>
      </div>
    );
  } else return null;
}

export default WohnwertLightFormResultWrapper;
