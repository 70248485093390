import { useSelector } from 'react-redux';
import { isJson, isInArray } from '../../util/generalUtils';
import { kennzahlenLabelsArray } from '../../constants';

export const useHandleMarktinfo = () => {
  const user = useSelector((state) => state.user);


  /**
   * check results for marktinfoLight form and update store
   * @param {Object} currentUser
   * @returns
   */
  const getMarktinfoResults = (userObject) => {
    // UPDATE MYSREAL-413: Nur noch PointsofInterest+LocationScores anzeigen und richtig aus den Results holen
    let results = [];
    let userMarktinfoResults = userObject.results
      .filter((calc) => calc.type === 'OfferStatistics' || calc.type === 'Valuation' || calc.type === 'LocationScores' || calc.type === 'PointsOfInterest' || calc.type === 'SocioEconomics')
      .filter(
        (calc) =>
          calc.request && isJson(calc.request) && Object.keys(JSON.parse(calc.request)).length > 0 && calc.response && isJson(calc.response) && Object.keys(JSON.parse(calc.response)).length > 0,
      );

    if (!userMarktinfoResults) return user.marktinfoLastResults;

    results = userMarktinfoResults.reduce((groups, calcItem) => {
      const group = groups[calcItem.key] || [];

      if (calcItem.type === 'LocationScores' || calcItem.type === 'SocioEconomics') {
        let request = [];
        request['Request'] = JSON.parse(calcItem.request);

        if (request) {
          group.push(request);
          groups[calcItem.key] = group;
        }
      }

      let response = [];
      response[calcItem.type] = JSON.parse(calcItem.response);

      if (response) {
        group.push(response);
        groups[calcItem.key] = group;
      }

      return groups;
    }, {});

    return results;
  };

  /**
   * get combined data from locationScores and POIs
   * @param {Object} locationScores
   * @param {Object} pointsOfInterest
   * @returns
   */
  const parseScoreCategories = (locationScores, pointsOfInterest) => {
    let scoreCategories = {};

    Object.entries(locationScores).forEach((key, val) => {
      if (key[0] !== 'scores') {
        scoreCategories[key[0]] = [{ score: Math.round(5 * key[1] * 10) / 10 }, []];
      }
    });

    if (pointsOfInterest && pointsOfInterest.items && pointsOfInterest.items.length > 0) {
      pointsOfInterest = pointsOfInterest.items;

      pointsOfInterest.forEach((pointOfInterest) => {
        for (let [key, value] of Object.entries(scoreCategories)) {
          if (pointOfInterest.category === key && value[1].length < 5 && pointOfInterest.hasOwnProperty('name') && !isInArray(pointOfInterest, value[1])) {
            if (pointOfInterest.category === 'shopping') {
              if (pointOfInterest.subcategory === 'supermarket') {
                value[1].push(pointOfInterest);
              }
            } else {
              value[1].push(pointOfInterest);
            }
          }
        }
      });

      pointsOfInterest.forEach((pointOfInterest) => {
        if (
          pointOfInterest.category === 'education' &&
          !!scoreCategories['family'] &&
          !!scoreCategories['family'][1] &&
          scoreCategories['family'][1].length < 5 &&
          pointOfInterest.hasOwnProperty('name') &&
          !isInArray(pointOfInterest, scoreCategories['family'][1])
        ) {
          scoreCategories['family'][1].push(pointOfInterest);
        }
      });

      Object.entries(scoreCategories).forEach(([key, val]) => {
        switch (key) {
          case 'health':
            delete Object.assign(scoreCategories, {
              Gesundheit: scoreCategories['health'],
            })['health'];

            /*
                    if(scoreCategories[KENNZAHL_LABEL_HEALTH][1]) {
                      scoreCategories[KENNZAHL_LABEL_HEALTH][1].forEach((entry) => {
                        switch (entry.subcategory) {
                          case "pharmacy":
                            entry.subcategory = "Apotheke";
                            break;
                          case "doctor":
                            entry.subcategory = "Arzt";
                            break;
                          case "nursing_home":
                            entry.subcategory = "Pflegeheim";
                            break;
                          case "hospital":
                            entry.subcategory = "Krankenhaus";
                            break;
                          case "dentist":
                            entry.subcategory = "Zahnarzt";
                            break;
                          case "veterinary":
                            entry.subcategory = "Tierarzt";
                            break;
                          case "clinic":
                            entry.subcategory = "Klinik";
                            break;
                          default:
                            break;
                        }
      
                        return entry;
                      });
                    }
                    */
            break;
          case 'leisure':
            delete Object.assign(scoreCategories, {
              Freizeit: scoreCategories['leisure'],
            })['leisure'];
            /*
                    scoreCategories[KENNZAHL_LABEL_LEISURE][1].forEach((entry) => {
                      switch (entry.subcategory) {
                        case "park":
                          entry.subcategory = "Park";
                          break;
                        case "sport":
                          entry.subcategory = "Sport";
                          break;
                        case "playground":
                          entry.subcategory = "Spielplatz";
                          break;
                        case "entertainment":
                          entry.subcategory = "Unterhaltung";
                          break;
                        default:
                          break;
                      }
                    });
                    */
            break;
          case 'shopping':
            delete Object.assign(scoreCategories, {
              Einkaufen: scoreCategories['shopping'],
            })['shopping'];
            /*
                    scoreCategories[KENNZAHL_LABEL_SHOPPING][1].forEach((entry) => {
                      switch (entry.subcategory) {
                        case "supermarket":
                          entry.subcategory = "Supermarkt";
                          break;
                        case "convenience_store":
                          entry.subcategory = "Gemischtwarenladen";
                          break;
                        default:
                          break;
                      }
                    });
                    */
            break;
          case 'catering':
            delete Object.assign(scoreCategories, {
              Gastronomie: scoreCategories['catering'],
            })['catering'];
            /*
                    scoreCategories[KENNZAHL_LABEL_CATERING][1].forEach((entry) => {
                      switch (entry.subcategory) {
                        case "restaurant":
                          entry.subcategory = "Restaurant";
                          break;
                        case "bar":
                          entry.subcategory = "Bar";
                          break;
                        case "cafe":
                          entry.subcategory = "Cafe";
                          break;
                        default:
                          break;
                      }
                    });
                    */
            break;
          case 'family':
            delete Object.assign(scoreCategories, {
              Bildung: scoreCategories['family'],
            })['family'];

            /*
                    scoreCategories[KENNZAHL_LABEL_FAMILY][1].forEach((entry) => {
                      switch (entry.subcategory) {
                        case "kindergarten":
                          entry.subcategory = "Kindergarten";
                          break;
                        case "school":
                          entry.subcategory = "Schule";
                          break;
                        case "university":
                          entry.subcategory = "Universität";
                          break;
                        case "primary_school":
                          entry.subcategory = "Grundschule";
                          break;
                        case "secondary_school":
                          entry.subcategory = "Gymnasium";
                          break;
                        default:
                          break;
                      }
                    });
                    */
            break;
          default:
            break;
        }
      });

      Object.entries(scoreCategories).forEach(([key, val]) => {
        if (!val[1] || val[1].length === 0) {
          delete scoreCategories[key];
        }
      });
    }

    return scoreCategories;
  };

  /**
   * map data from offerStatistic s
   * @param {Object} data
   * @returns
   */
  let parseOfferStatistics = (data) => {
    let offerstats = {};

    if (data && data.items && data.items[0] && data.items[0].statistics) {
      offerstats = data.items[0].statistics;

      //? der index wird für release weggenommen, damit bei der Anzeige die val nicht angezeigt wird
      // TODO Gehört wieder rein für Phase 2
      offerstats = offerstats.slice(0, 1);

      // Returnt auch ein leeres array, wenn die results keine value haben (kommt bei ländlichen gebieten vor)
      if (!offerstats[0].hasOwnProperty('value')) {
        return [];
      }
    }

    return offerstats;
  };

  /**
   * get data for kennzahlen
   * @param {Array|Object} results
   * @returns [categories, values]
   */
  const getKennzahlenData = (results) => {
    const [locationScores, pointsOfInterest] = mapDataToVariables(results);
    let scoreCategories = {};

    if (locationScores && pointsOfInterest) {
      scoreCategories = parseScoreCategories(locationScores, pointsOfInterest);
    }

    const [scoreCategoriesArray, scoreValuesArray] = getScoreData(scoreCategories);

    return [scoreCategoriesArray, scoreValuesArray];
  };

  /**
   * map data from correct fields of results
   * @param {Array} results
   * @returns [locationScores, pointsOfInterest, valuation, offerStatistics, socioEconomics]
   */
  function mapDataToVariables(results) {
    let locationScores = {};
    let pointsOfInterest = [];
    let valuation = [];
    let offerStatistics = [];
    let socioEconomics = [];

    // unterschiedliches Mapping bei loggedIn und !loggedIn weil Daten anders gespeichert werden
    if (!user.isLoggedIn) {
      results = results[0];
      if (results.LocationScores) {
        locationScores = results.LocationScores;
      }
      if (results.PointsOfInterest) {
        pointsOfInterest = results.PointsOfInterest;
      }
      if (results.Valuation && results.Valuation.valuations) {
        valuation = results.Valuation.valuations;
      }
      if (results.OfferStatistics && results.OfferStatistics.data) {
        offerStatistics = parseOfferStatistics(results.OfferStatistics.data);
      }
      if (results[0] && results[0].SocioEconomics) {
        socioEconomics = results[0].SocioEconomics;
      }
    } else {
      results.forEach((item) => {
        if (item.LocationScores) {
          locationScores = item.LocationScores;
        }
        if (item.PointsOfInterest) {
          pointsOfInterest = item.PointsOfInterest;
        }
        if (item.Valuation && item.Valuation.valuations) {
          valuation = item.Valuation.valuations;
        }
        if (item.OfferStatistics) {
          offerStatistics = parseOfferStatistics(item.OfferStatistics);
        }
        if (item.SocioEconomics) {
          socioEconomics = item.SocioEconomics;
        }
      });
    }

    return [locationScores, pointsOfInterest, valuation, offerStatistics, socioEconomics];
  }

  /**
   * get scoreCategories data (Freizeit, Gesundheit, Einkaufen, Gastronomie, Bildung)
   * @param {Array} scoreCategories
   * @returns [scoreCategoriesArray, scoreValuesArray]
   */
  function getScoreData(scoreCategories) {
    let scoreCategoriesArray = [];
    let scoreValuesArray = [];

    if (scoreCategories) {
      scoreCategoriesArray = Object.entries(scoreCategories).map((item) => {
        let label = item[0];
        if (kennzahlenLabelsArray[label]) label = kennzahlenLabelsArray[label];
        return { label: label };
      });

      scoreValuesArray = Object.entries(scoreCategories).map((item) => {
        let value = item[1];
        return {
          value: value[0].score,
          percentage: (value[0].score / 5) * 100,
          resultText: `Der Standort-Score für diesen Standort basiert auf Entfernungen zu den nächstgelegenen Points of Interest in der Kategorie ${item[0]}. Die Bewertung ist landesweit normalisiert.`,
          pointsOfInterest: value[1],
        };
      });
    }

    return [scoreCategoriesArray, scoreValuesArray];
  }

  /**
   * get label to identify marktinfo-calculation
   * @param {Array} marktinfoResults
   * @param {Integer} index
   * @returns
   */
  const getMarktinfoLabel = (marktinfoResults, index, key) => {
    // default label "Marktanalyse 0"
    let label = 'Marktanalyse ' + index;

    if (marktinfoResults[key]) {
      const option = marktinfoResults[key].find((option) => {
        if (option.Request) {
          return option;
        }
      });

      if (option && option.Request) {
        let r = option.Request;
        if (r.city && r.postCode && r.street && r.houseNumber) {
          label = r.city.trim() + ', ' + r.postCode.trim() + ', ' + r.street.trim() + ' ' + r.houseNumber.trim();
        } else if (r.postCode) {
          label = 'Marktanalyse für ' + r.postCode.trim();
        }
      }
    }

    return label;
  };

  return { getMarktinfoResults, getKennzahlenData, getMarktinfoLabel };
};
