import { createSlice } from '@reduxjs/toolkit';
import { removeHashFromUrl } from '../util/generalUtils';
import { WOHNWERTLIGHT_SITEINDEX_FORM } from '../constants';

const initialState = {
  siteIndex: WOHNWERTLIGHT_SITEINDEX_FORM,
  selectedPreviousCalculation: null,
  pageDialogOpen: false,
  federalState: {}, // Heißt nicht state weil es verwirrend wäre (setState)
  district: {},
  typedPostCode: '',
  estateType: {},
  estateSize: '', // number in m²
  estateCondition: ''
};

export const wohnwertLightSlice = createSlice({
  name: 'wohnwertLight',
  initialState,
  reducers: {
    setSiteIndexWohnwertForm: (state, action) => {
      window.location.hash = action.payload;
      state.siteIndex = action.payload;
    },
    setPageDialogOpenWohnwertForm: (state, action) => {
      // check hash
      if (action.payload === true) {
        window.location.hash = state.siteIndex;
      } else {
        removeHashFromUrl();
      }

      state.pageDialogOpen = action.payload;
    },
    setSelectedPreviousCalculation: (state, action) => {
      state.selectedPreviousCalculation = action.payload;
    },
    setWohnwertLightFederalState: (state, action) => {
      state.federalState = action.payload;
    },
    setWohnwertLightDistrict: (state, action) => {
      state.district = action.payload;
    },
    setWohnwertLightTypedPostCode: (state, action) => {
      state.typedPostCode = action.payload;
    },
    setWohnwertLightRealEstateType: (state, action) => {
      state.estateType = action.payload;
    },
    setWohnwertLightRealEstateSize: (state, action) => {
      state.estateSize = action.payload;
    },
    setWohnwertLightRealEstateCondition: (state, action) => {
      state.estateCondition = action.payload;
    },
    setResetFieldsWohnwertForm: (state) => {
      return initialState;
    }
  }
});

export const {
  setSiteIndexWohnwertForm,
  setPageDialogOpenWohnwertForm,
  setWohnwertLightFederalState,
  setWohnwertLightDistrict,
  setWohnwertLightTypedPostCode,
  setWohnwertLightRealEstateType,
  setWohnwertLightRealEstateSize,
  setWohnwertLightRealEstateCondition,
  setResetFieldsWohnwertForm,
  setSelectedPreviousCalculation
} = wohnwertLightSlice.actions;

export default wohnwertLightSlice.reducer;
