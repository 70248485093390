
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findMarketReportStreet, findMarketReportStreetNumber, requestMarketReport } from '../../api/JustImmo';
import { PAGEDIALOG_CONTACT } from '../../constants';
import { setFormIsLoading, setPageDialogOpenId } from '../../reducers/app';
import { setPreviousMarktBericht } from '../../reducers/marktBericht';
import ButtonForwardAction from '../Buttons/ButtonForwardAction';
import FormCheckbox from '../FormFields/FormCheckbox';
import FormInput from '../FormFields/FormInput';
import IconAlert from '../Icons/IconAlert';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import '../../styles/marktbericht.scss';
import { isJson } from '../../util/generalUtils';
import FormAutocomplete from '../FormFields/FormAutocomplete';
import { appBarClasses } from '@mui/material';
import MarktberichtFormSuccess from './Marktbericht/MarktberichtFormSuccess';

function MarktBerichtForm() {
	const methods = useForm(); // Provides form state & methods
	const { control, watch, setValue, getValues, handleSubmit, formState: { errors }  } = methods;

	const street = watch('street');
	const city = watch('city');
	const zipCode = watch('zipCode');

	const [options, setOptions] = useState([]);
	const [optionsHouseNumber, setOptionsHouseNumber] = useState([]);
  const [loading, setLoading] = useState(false);
	const [loadingHouseNumber, setLoadingHouseNumber] = useState(false);


  const dispatch = useDispatch();
  let currentMarktBericht = useSelector((state) => state.marktBericht);
	let app = useSelector((state) => state.app);
  
  const [adressNotFoundErrorShown, setAdressNotFoundErrorShown] = useState(false);
  const [marketReportErrorShown, setMarketReportErrorShown] = useState(false);
  const [remainingTime, setRemainingTime] = useState(30);
  const [isRetryDisabled, setIsRetryDisabled] = useState(false);


	/* AUTOCOMPLETE STREET */
	const getAndSetMarktBerichtStreets = useCallback(async (query) => {
		if (typeof query !== 'string') return;

		if (!query || query.length < 3) {
			setOptions([]); // Clear options if input is too short
			return;
		}

		setLoading(true);
		try {
			let locations = await findMarketReportStreet({ street: query });
			locations = locations.items.map((location) => {
				return {
					value: JSON.stringify(location),
					label: `${location.streetName}, ${location.zipCode} ${location.cityName}`,
				};
			});

			setOptions(locations);
		} catch (error) {
			console.error('Error fetching autocomplete data:', error);
			setOptions([]);
		} finally {
			setLoading(false);
		}
	}, []);

	const [typedStreet, setTypedStreet] = useState('');
	useEffect(() => {
    if (typedStreet) {
			getAndSetMarktBerichtStreets(typedStreet);
    }
  }, [typedStreet, getAndSetMarktBerichtStreets]);
	/* AUTOCOMPLETE STREET END */

	/* AUTOCOMPLETE FROM STREET */
	const [streetNameOnly, setStreetNameOnly] = useState('');
	useEffect(() => {
		
		if(street?.value && isJson(street.value)) {
			const streetObj = JSON.parse(street.value);

			if(streetObj.cityName) {
				setValue('city', streetObj.cityName);
			}

			if(streetObj.zipCode) {
				setValue('zipCode', streetObj.zipCode);
			}

			if(streetObj.streetName) {
				setStreetNameOnly(streetObj.streetName);
			}
		}
		else {
			setStreetNameOnly('');
		}
	}, [street, setValue]);
	/* AUTOCOMPLETE FROM STREET END */

	/* AUTOCOMPLETE HOUSENUMBER */
	const getAndSetMarktBerichtHouseNumber = useCallback(async (query) => {
		if (typeof query !== 'string') return;

		if (!query || query.length < 1) {
			setOptionsHouseNumber([]); // Clear options if input is too short
			return;
		}

		setLoadingHouseNumber(true);
		try {
			let numbers = await findMarketReportStreetNumber({
				street: streetNameOnly,
				city: getValues('city'),
				zipCode: getValues('zipCode'),
				numberPrefix: query,
			});

			numbers = numbers.items.map((number) => {
				return {
					value: number,
					label: number,
				};
			});

			setOptionsHouseNumber(numbers);
		} catch (error) {
			console.error('Error fetching autocomplete data:', error);
			setOptionsHouseNumber([]);
		} finally {
			setLoadingHouseNumber(false);
		}
	}, [streetNameOnly, getValues]);

	const [typedHousenumber, setTypedHousenumber] = useState('');
	useEffect(() => {
    if (typedHousenumber) {
			getAndSetMarktBerichtHouseNumber(typedHousenumber);
    }
  }, [typedHousenumber, getAndSetMarktBerichtHouseNumber]);
	/* AUTOCOMPLETE HOUSENUMBER END */

  useEffect(() => {
    let timer;
    if (marketReportErrorShown) {
      setIsRetryDisabled(true);
      setRemainingTime(30);

      timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1) {
            setIsRetryDisabled(false);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [marketReportErrorShown]);

	const [demoModeSubmitted, setDemoModeSubmitted] = useState(false);
	const onSubmit = async (data) => {	
		dispatch(setFormIsLoading(true));

		setMarketReportErrorShown(false);
		setAdressNotFoundErrorShown(false);

		const requestBody = {
			country: '40', // Muss laut Justimmo immer "40" sein
			street: streetNameOnly,
			number: getValues('housenumber')?.value,
			zip: getValues('zipCode'),
			city: getValues('city'),
		};

		if(app.demoMode) {
			setTimeout(() => {
				dispatch(setFormIsLoading(false));
				setDemoModeSubmitted(true);
			}, 1000);

			return;
		}

		try {
			const response = await requestMarketReport(requestBody);
			dispatch(setPreviousMarktBericht(response.newReport));
		} catch (error) {
			if (error.response.status === 404) {
				setAdressNotFoundErrorShown(true);
			} else {
				setMarketReportErrorShown(true);
			}
		}
		finally {
			dispatch(setFormIsLoading(false));
		}
	};

  return (
    <div className="marktberichtForm">
      { marketReportErrorShown ? (
        <div className="marktbericht-error">
          <div className="marktbericht-errorbox">
            <IconAlert />
            <p>Die Abfrage war nicht erfolgreich. Bitte versuchen Sie es in <span className="text-bold">{remainingTime} Sekunden</span> erneut.</p>
          </div>
          <ButtonForwardAction buttonText="Erneut versuchen" forwardAction={() => setMarketReportErrorShown(false)} buttonStyle="white" disabled={isRetryDisabled} />
        </div>
      ) : !currentMarktBericht.previousMarktBericht || (app.demoMode && !demoModeSubmitted) ? (
        <>
          <p>Als Eigentümer:in erhalten Sie Ihren Marktbericht <span className="text-bold">kostenlos</span> direkt im Dashboard von my-sreal.</p>

					<FormProvider {...methods}>
						<div className='form__row form__row--double form__row--double--mobilefull'>
							<Controller
								name="street"
								control={control}
								rules={{ required: "Bitte geben Sie die Straße der Immobilie an." }}
								render={({ field }) => (
									<FormAutocomplete
										id="street"
										label="Straße"
										required={true}
										loading={loading}
										placeholder={'Straße eingeben'}
										options={options}
										callback={field.onChange}
										callbackInput={setTypedStreet}
										value={field.value}
										cssClasses={'w-60'}
										error={errors.street?.message}
									/>
								)}
							/>

							<Controller
								name="housenumber"
								control={control}
								rules={{ required: "Bitte geben Sie die Hausnummer der Immobilie an." }}
								render={({ field }) => (
									<FormAutocomplete
										id="housenumber"
										label="Hausnummer"
										required={true}
										loading={loadingHouseNumber}
										placeholder={!streetNameOnly || !city || !zipCode ? 'Bitte erst Daten ausfüllen' : 'Hausnummer'}
										options={optionsHouseNumber}
										callback={field.onChange}
										callbackInput={setTypedHousenumber}
										value={field.value}
										cssClasses={'w-40'}
										disabled={!streetNameOnly || !city || !zipCode}
										error={errors.housenumber?.message}
									/>
								)}
							/>
						</div>

						<div className={'form__row form__row--double form__row--double--mobilefull'}>
							<Controller
								name="zipCode"
								control={control}
								rules={{ required: "Bitte wählen Sie die PLZ der Immobilie aus." }}
								render={({ field }) => (
									<FormInput
										id={'zipCode'}
										label={'PLZ'}
										placeholder="PLZ eingeben"
										value={field.value}
										callback={field.onChange}
										required={true}
										type='number'
										error={errors.zipCode?.message}
									/>
								)}
							/>

							<Controller
								name="city"
								control={control}
								rules={{ required: "Bitte wählen Sie den Ort der Immobilie aus." }}
								render={({ field }) => (
									<FormInput
										id={'city'}
										label={'Ort'}
										placeholder="Ort eingeben"
										value={field.value}
										callback={field.onChange}
										required={true}
										error={errors.city?.message}
									/>
								)}
							/>
						</div>

						<Controller
							name="isOwner"
							control={control}
							rules={{ required: "Dies ist ein Pflichtfeld, bitte akzeptieren." }}
							render={({ field }) => (
								<FormCheckbox
									id="isOwner"
									cssClasses={'checkbox--white'}
									label={'Ja, ich bin Eigentümer:in der angegebenen Immobilie'}
									required={true}
									value={field.value}
									callback={field.onChange}
									error={errors.isOwner?.message}
								/>
							)}
						/>

		
          	<p className="font-80 mt-0">Mit Anfordern des Marktberichts stimmen Sie einer möglichen Kontaktaufnahme durch s REAL zu.</p>

						<div className="button-panel align-items-center">
						
							<ButtonForwardAction 
								buttonText="Marktbericht anfordern" 
								forwardAction={handleSubmit(onSubmit)}
								buttonStyle="white"
							/>

							{ adressNotFoundErrorShown && (
								<p className="marktbericht-errortext-notfound">
									Adresse konnte nicht gefunden werden. <br />
									<span className="textlink" onClick={() => dispatch(setPageDialogOpenId(PAGEDIALOG_CONTACT))}>
										Für Unterstützung hier kontaktieren.
									</span>
								</p>
							)}
						</div>
					</FormProvider>
        </>
      ) : (app.demoMode && demoModeSubmitted) ? (
        <MarktberichtFormSuccess />
      ) : <MarktberichtFormSuccess /> }
    </div>
  );
}

export default MarktBerichtForm;
