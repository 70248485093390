import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { getRealtyFile } from '../api/JustImmo';
import { phoneNumberPrefixOptions } from '../components/FormFields/util/formOptions';
import { CONTACTFORM_ANLIEGEN_ALLG, CONTACTFORM_ANLIEGEN_PROFIL, CONTACTFORM_ANLIEGEN_PROFIL_DEAKTIVIEREN, CONTACTFORM_ANLIEGEN_SUCHE } from '../constants';
import { abgeberimmo_phase2, abgeberimmo_phase3, abgeberimmo_phase4 } from '../demodata/demo_abgeberimmo';
import { interessentimmo1, interessentimmo2, interessentimmo3, interessentimmo4 } from '../demodata/demo_interessentimmos';

const downloadDoc = (url, name) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.target = '_blank';
  link.click();

  // For Firefox it is necessary to delay revoking the ObjectURL.
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
  }, 250);
};

const fetchFile = async (url) => {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch file: ${response.statusText}`);
  }
  const arrayBuffer = await response.arrayBuffer();
  return new Blob([arrayBuffer]);
};

function parseBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

const downloadLocalFilesAsZip = async (files, zipName) => {
  if (!files) return;

  const zip = new JSZip();

  const addFileToZip = async (file) => {
    try {
      const filePath = `/demofiles/${file.url}`;
      const response = await fetch(filePath);

      const blob = await response.blob();
      zip.file(file.original_filename, blob);
    } catch (err) {
      console.error(`Error adding file to ZIP: ${file.original_filename}`, err);
    }
  };

  // Process all files in parallel
  await Promise.all(files.map((file) => addFileToZip(file)));

  try {
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, `${zipName}.zip`);
  } catch (err) {
    console.error('Error generating ZIP file', err);
  }
};


const downloadZip = async (files, zipname) => {
  if (!files) return;

  const zip = new JSZip();

  const downloadFile = async (file) => {
    try {
      const fileUrl = file.url ? file.url : file.Url;

      //download single file as blob
      let response = null;
      if (file.visibility === 1) {
        response = await getRealtyFile(file.immo.id, file.immo.tenant_id, file.storage_key);
      } else if(file.isDemo) {
				response = await fetch('/demofiles/' + file.url);
				// url = response.url;
			}
			else {
        response = await fetchFile(fileUrl);
      }

      //add file to zip
      const name = file.headline + fileUrl.substring(fileUrl.lastIndexOf('.'));
      return zip.file(name, response);
    } catch (err) {
      console.log(err);
    }
  };

  //this gives us promises
  const filePromises = files.map((file) => downloadFile(file));
  //wait for all of them to resolve
  await Promise.all(filePromises);

  try {
    const blob = await zip.generateAsync({ type: 'blob' });
    saveAs(blob, zipname + '.zip');
  } catch (err) {
    // handle blob creation error
    console.log(err);
  }
};

const isImage = (file) => {
  let isImage = false;
  if (file.indexOf('.jpg') > -1 || file.indexOf('.jpeg') > -1 || file.indexOf('.png') > -1) {
    isImage = true;
  }

  return isImage;
};

const getJustImmoContactsWithCategory = (userObject, roleName) => {
  let contacts = [];
  if (userObject && userObject.justImmoContacts) {
    userObject.justImmoContacts.forEach((contact) => {
      let catFound = contact.categories.find((cat) => cat.name.indexOf(roleName) > -1);

      if (catFound) {
        contacts.push(contact);
      }
    });
  }

  if (contacts.length > 0) {
    return true;
  } else {
    return false;
  }
};

//write a function that takes an object and an array and checks if the object is in the array by its property "name"
function isInArray(object, array) {
  return array.some(function (item) {
    return item.name === object.name;
  });
}

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

function removeHashFromUrl() {
  var uri = window.location.toString();

  if (uri.indexOf('#') > 0) {
    var clean_uri = uri.substring(0, uri.indexOf('#'));

    window.history.replaceState({}, document.title, clean_uri);
  }
}

function removeLastDirectoryFromUrl(dir) {
  const url = new URL(window.location);
  let lastPartOfUrl = url.href.split('/');
  lastPartOfUrl = lastPartOfUrl[lastPartOfUrl.length - 1];

  if (lastPartOfUrl.indexOf(dir) > -1) {
    window.history.pushState({}, '', url.href.substring(0, url.href.lastIndexOf('/')));
  }
}

function scrollToElement(id) {
  if (!document) return;
	if(id.includes('#direktregistrieren&Data=')) return;
	
  const el = document.querySelector(id);

  if (el) {
    let scrollTimer = setTimeout(() => {
      window.scrollTo({
        top: el.offsetTop - 50,
        behavior: 'smooth',
      });
    }, 500);

    return () => {
      clearTimeout(scrollTimer);
    };
  }
}

function isZip(value) {
  let isZip = false;
  if (/^\d{4}$/.test(value)) {
    isZip = true;
  }

  return isZip;
}

const handleScrollToErrorPageDialog = (formType = '', pageDialogId = '', errorElId = '') => {
  setTimeout(() => {
    if (errorElId === '') {
      errorElId = '#' + formType + '-error';
    }
    const errorEl = document.querySelector(errorElId);
    const pageDialogEl = document.querySelector(pageDialogId + ' .MuiDialogContent-root');

    if (errorEl && pageDialogEl) {
      pageDialogEl.scrollTo({
        top: errorEl.offsetTop,
        behavior: 'smooth',
      });
    }
  }, 500);
};

// helper function for redux phone parsing
const findAndParsePhoneNumber = (obj, propertyName, phoneNumber) => {
  if (!obj) return;
  for (let key in obj) {
    if (typeof obj[key] === 'object') {
      findAndParsePhoneNumber(obj[key], propertyName, phoneNumber);
    } else if (key === propertyName) {
      parseReduxPhoneNumber(obj, propertyName, phoneNumber);
    }
  }
};

const parseReduxPhoneNumber = (previousState, propertyName, phoneNumber) => {
  if (phoneNumber === '') {
    // reset phonenumber
    previousState[propertyName] = '';
  } else {
    const lastChar = phoneNumber.substr(phoneNumber.length - 1);
    if (!lastChar.match(/^[0-9 ]+$/)) {
      return;
    } else {
      var value = phoneNumber.split(' ').join('');
      if (value.length > 3) {
        value = value.replace(/(\d{3})/, '$1 ');
      }
      previousState[propertyName] = value;
    }
  }
};

// helper function that updates the right portion of the phone number, depending on the payload type
const setPhoneNumber = (stateNestedObj, propertyName, state, payload) => {
  if (typeof payload === 'object' && payload !== null && payload.text) {
    // update phone number prefix portion
    if (payload.text === undefined) return;
    stateNestedObj[propertyName] = payload.text.replace(/\s/g, '') + state.parsedPhoneNumber.replace(/\s/g, '');
  } else {
    // update the rest of the number
    if (state.phoneNumberPrefix.text === undefined) return;
    stateNestedObj[propertyName] = state.phoneNumberPrefix.text.substring(0, state.phoneNumberPrefix.text.indexOf(' ')) + payload;
  }
};

const findPhonePrefix = (userPhone) => {
  // try finding the prefix with 4 characters
  let foundPrefix = phoneNumberPrefixOptions.find((prefix) => userPhone.startsWith(prefix.text));
  // if not found, the prefix has 3 characters
  if (!foundPrefix) {
    foundPrefix = phoneNumberPrefixOptions.find((prefix) => userPhone.startsWith(prefix.text.substring(0, 3)));
  }
  return foundPrefix;
};

const checkIfShortRouteContactForm = (reason) => {
  if (!reason) return true;
  if (reason.id === CONTACTFORM_ANLIEGEN_ALLG || reason.id === CONTACTFORM_ANLIEGEN_SUCHE || reason.id === CONTACTFORM_ANLIEGEN_PROFIL || reason.id === CONTACTFORM_ANLIEGEN_PROFIL_DEAKTIVIEREN) {
    return true;
  } else return false;
};

const checkIfHashIsPagedialog = (stepHash) => {
  if (!window.location.hash || !stepHash) return false;
  const hash = window.location.hash.replace('#', '');

  if (hash === stepHash) {
    return true;
  }

  return false;
};

const isDemoUrl = () => {
  const currentAppRoot = window.location.pathname.split('/')?.[1];
  if (currentAppRoot === 'demo') {
    return true;
  } else return false;
};

const isDemoAbgeberImmo = (id) => {
	if(isDemoUrl() === false) return false;
	if(!id) return false;

	const immoFound = [abgeberimmo_phase2, abgeberimmo_phase3, abgeberimmo_phase4].find((immo) => parseInt(immo.id) === parseInt(id));
	return immoFound;
}

const isDemoInteressentImmo = (id) => {
	if(isDemoUrl() === false) return false;
	if(!id) return false;

	const immoFound = [interessentimmo1, interessentimmo2, interessentimmo3, interessentimmo4].find((immo) => parseInt(immo.id) === parseInt(id));
	return immoFound;
}

export {
  checkIfHashIsPagedialog,
  checkIfShortRouteContactForm,
  downloadDoc,
  downloadZip,
	downloadLocalFilesAsZip,
  findAndParsePhoneNumber,
  findPhonePrefix,
  getJustImmoContactsWithCategory,
  handleScrollToErrorPageDialog,
  isDemoUrl,
	isDemoAbgeberImmo,
	isDemoInteressentImmo,
  isImage,
  isInArray,
  isJson,
  isZip,
  parseBytes,
  parseReduxPhoneNumber,
  removeHashFromUrl,
  removeLastDirectoryFromUrl,
  scrollToElement,
  setPhoneNumber,
	fetchFile
};
