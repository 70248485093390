import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import FormCheckbox from '../../FormFields/FormCheckbox';
import FormInput from '../../FormFields/FormInput';
import FormRadio from '../../FormFields/FormRadio';
import Textarea from '../../FormFields/Textarea';

import { genderOptions } from '../../FormFields/util/formOptions';

// STORE
import { Controller, useFormContext } from 'react-hook-form';

import FormSelectPhoneNumberRHF from '../../FormFields/customFields/FormSelectPhoneNumberRHF';
import FormTextDsgvo from '../../FormFields/util/formTextDsgvo';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

function ObjectRequestMain({ nextStep }) {
	const { control, handleSubmit, watch, setValue, formState: { errors } } = useFormContext();

  return (
    <>
      <h2 className="form__title">Persönliche Daten</h2>
      <p>
        Um Ihnen detaillierte Informationen zu unseren Immobilienangeboten übermitteln zu können,
        bedarf es eines Maklervertrages. Über diesen, unsere AGB und Ihre Rechte als Konsument:in
        möchten wir Sie gerne umfassend informieren. Bitte entnehmen Sie diese unserer Homepage und
        unmittelbar unter diesem Link:{' '}
        <a
          href="https://www.sreal.at/de/agb"
          target="_blank"
          rel="noopener noreferrer"
          title="s REAL AGB und Information zum Maklervertrag"
        >
          s REAL AGB und Information zum Maklervertrag.
        </a>
        <br />
        <br />
        Der Maklervertrag wird damit für Sie unkompliziert abgeschlossen, wenn Sie auf den Button
        „Absenden und Maklervertrag zustimmen“ klicken. Diese Informationen über unsere
        Immobilienangebote sind selbstverständlich kostenlos! Die Vermittlungsprovision fällt nur
        an, wenn Sie eine bestimmte Immobilie dann tatsächlich kaufen oder mieten, also wir für Sie
        erfolgreich tätig sein konnten.
      </p>

      <h3 className="form__title">Kontakt</h3>

			<Controller
				name="gender"
				control={control}
				rules={{ required: "Bitte wählen Sie eine Anrede aus." }}
				render={({ field }) => (
					<FormRadio 
						id="gender"
						options={genderOptions}
						error={errors.gender?.message}
						selectedValue={field.value}
						callback={field.onChange}
					/>
				)}
			/>

      <div className="form__row form__row--double">
				<Controller
					name="titleBefore"
					control={control}
					render={({ field }) => (
						<FormInput
							id="titleBefore"
							label={'Titel (vorgestellt)'}
							value={field.value} 
							callback={field.onChange}
						/>
					)}
				/>

				<Controller
					name="titleAfter"
					control={control}
					render={({ field }) => (
						<FormInput
							id="titleAfter"
							label={'Titel (nachgestellt)'}
							value={field.value} 
							callback={field.onChange}
						/>
					)}
				/>
      </div>

			<Controller
				name="firstName"
				control={control}
				rules={{ required: "Bitte geben Sie einen Vornamen ein." }}
				render={({ field }) => (
					<FormInput
						id="firstName"
						label={'Vorname'}
						value={field.value}
						error={errors.firstName?.message}
						required={true}
						callback={field.onChange}
					/>
				)}
			/>

			<Controller
				name="lastName"
				control={control}
				rules={{ required: "Bitte geben Sie einen Nachnamen ein." }}
				render={({ field }) => (
					<FormInput
						id={'lastName'}
						label={'Nachname'}
						value={field.value}
						error={errors.lastName?.message}
						required={true}
						callback={field.onChange}
					/>
				)}
			/>

			<Controller
				name="email"
				control={control}
				rules={{ 
					required: "Bitte geben Sie eine E-Mail-Adresse ein.",
					pattern: {
						value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
						message: "Die E-Mail-Adresse ist ungültig."
					}
				}}
				render={({ field }) => (
					<FormInput
						id="email"
						type={'email'}
						label={'E-Mail-Adresse'}
						value={field.value}
						error={errors.email?.message}
						required={true}
						callback={field.onChange}
					/>
				)}
			/>

			<FormSelectPhoneNumberRHF />

      <h3 className="form__title pt-200rem">Ihre Nachricht</h3>
      <Controller
				name="message"
				control={control}
				render={({ field }) => (
					<Textarea
						id="message"
						value={field.value}
						callback={field.onChange}
					/>
				)}
			/>

      <span className="form--info">
        Ihre angegebenen Daten werden zum Bearbeiten Ihrer Anfrage verwendet. Es gelten unsere{' '}
        <FormTextDsgvo objektanfrage={true} />
      </span>

			<Controller
				name="newsletterConfirmed"
				control={control}
				render={({ field }) => (
					<FormCheckbox
						id="newsletterConfirmed"
						label={'Ich bin damit einverstanden, dass ich zukünftig per E-Mail von s REAL Immobilien über Produkte, Services und Events informiert werde. Meine persönlichen Daten werden nur dafür genutzt. Diese Zustimmung kann ich jederzeit widerrufen.'}
						value={field.value}
						callback={field.onChange}
					/>
				)}
			/>

      <div className="button-panel pt-100rem">
        <ButtonForwardAction
          forwardAction={handleSubmit(() => nextStep())}
          buttonText={'Absenden und Maklervertrag zustimmen'}
        />
      </div>
    </>
  );
}

export default ObjectRequestMain;
