//*React
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

//*Components
import Aktuelles from '../components/Aktuelles/Aktuelles';
import Card from '../components/Cards/Card';
import ImmoDriveEmptyCard from '../components/Cards/ImmoDriveEmptyCard';
import DashboardHeaderStats from '../components/Dashboard/DashboardHeaderStats';
import HeaderCtaButtons from '../components/Header/HeaderCtaButtons';
import RecommendationLinkitem from '../components/Linkitems/RecommondationLinkitem';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import NewsTeaserCards from '../components/News/NewsTeaserCards';
import MarktberichtCard from '../components/Cards/MarktberichtCard';

//*Images
import IconHouseTree from '../components/Icons/IconHouseTree';

//*Utils
import ImmoDriveCard from '../components/Cards/ImmoDriveCard';
import { CONTACTFORM_ANLIEGEN_VERKAUF, PAGEDIALOG_CONTACT } from '../constants';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { useFetchStats } from '../hooks/stats/useFetchStats';
import { useHandleUserRealtyActive } from '../hooks/user/useHandleUserRealtyActive';
import { setPageDialogOpenId } from '../reducers/app';


function DashboardVKPhase1() {
  const user = useSelector((state) => state.user);
	const app = useSelector((state) => state.app);
  const { getData, inseratKlicksData, verschickteAngeboteData, besichtigungenData, dataError } = useFetchStats();

  const { files } = useHandleUserRealtyActive();

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      const dummy = true;
      getData(dummy);
    }
    fetchData();
  }, []);

  const { isMobile } = useHandleScreenResize();

  const hanldeSRealBeauftragen = () => {
		localStorage.setItem('mysreal_contact_reason', CONTACTFORM_ANLIEGEN_VERKAUF);
		dispatch(setPageDialogOpenId(PAGEDIALOG_CONTACT));
  };

  const headerCtabuttons = [
    <HeaderCtaButtons key="headerCtabuttons">
      <button className="button button--orange" onClick={hanldeSRealBeauftragen}>
        Jetzt Termin vereinbaren
      </button>
    </HeaderCtaButtons>,
  ];

  return (
    <>
      <MetaTags title={'Dashboard | Die Online-Plattform für Ihre Immobilie'} imageName={'sREAL_Sujets_2023_Webhero_16zu9_1.4.jpg'} />

      <DashboardHeaderStats inseratKlicksData={inseratKlicksData} verschickteAngeboteData={verschickteAngeboteData} besichtigungenData={besichtigungenData} dataError={dataError}>
        <div className="header__content-overlay z-index-999"></div>
        <div className="header__content-wrapper z-index-9999">
          <div className="header__content">
            <h1 className="mt-0 mb-0 text-white">Hier finden Sie künftig Ihre Vermarktungsdetails</h1>
          </div>
          <div className="header__ctabuttons-wrapper">{headerCtabuttons}</div>
        </div>

        {/* cta buttons mobile-version fixed */}
        {/* {headerCtabuttons} */}
      </DashboardHeaderStats>

      <MainContent>
        {user.userObject?.directRegistrationCompleted === null && (
          <div className="gray-background--no-margin">
            <Aktuelles />
          </div>
        )}

        <div className={!isMobile ? 'w-desktop--center' : 'pt-200rem pb-0 mb-0 w-desktop--center'}>
          <h2 className="mb-200rem">Meine Immobilie</h2>
          <Card cssClasses={'immoabgeber-card--empty with-shadow mb-400rem'} cardStyle={'topImage2Cols'} icon={<IconHouseTree cssClasses="w-50" color="#fff" />}>
            <div>
              <h3 className="mb-100rem">Noch keine Immobilie in Vermarktung</h3>
              <p className="pt-50rem">Sobald wir Ihre Immobilie für die Vermarktung aufbereitet haben, finden Sie hier immer Ihr aktuelles Immobilieninserat.</p>
            </div>
          </Card>

          
          <MarktberichtCard />

					{files.length > 0 && (
						<div className="mt-400rem">
							<h2>immo-drive</h2>
							<ImmoDriveCard files={files} />
						</div>
					)}

          {!user.recommendationSurveyCompleted ? <RecommendationLinkitem cssClasses="mb-0 mb-400rem" /> : null}
        </div>

        <NewsTeaserCards cssClasses="mt-400rem w-desktop--slider" />
      </MainContent>
    </>
  );
}

export default DashboardVKPhase1;
