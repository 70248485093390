//*React
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
//*Icons
import userPic from '../../../../../shared/icons/profil.svg';
import Flyout from './Flyout';
import HeaderHamburger from './HeaderHamburger';
import Logo from './Logo';
import Nav from './Nav';
import MenuButtonSwitcher from './MenuButtonSwitcher';
//*Reducers
import { toggleDesktopMenu, toggleMobileMenu } from '../../reducers/app';
import { setPageDialogOpenLoginForm, setResetFieldsLoginForm } from '../../reducers/loginForm';
import { setPageDialogOpenRegisterForm, setResetFieldsRegisterForm } from '../../reducers/registerForm';
//*Styles
import '../../styles/header.scss';
import '../../styles/headerslider.scss';

// DEMO
import { isDemoUrl } from '../../util/generalUtils';
import { demoUserObject } from '../../demodata/demouser.js';


function Header({ cssClasses = '', children, overlappedContent }) {
  let user = useSelector((state) => state.user);
  let app = useSelector((state) => state.app);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleOpenRegisterForm = () => {
    dispatch(setResetFieldsRegisterForm());
    dispatch(setPageDialogOpenRegisterForm(true));
  };

  const handleOpenLoginForm = () => {
    dispatch(setResetFieldsLoginForm());
    dispatch(setPageDialogOpenLoginForm(true));
  };

  const handleOpenFlyout = () => {
    if (!app.desktopMenuOpen) {
      dispatch(toggleDesktopMenu());
    }
  };

  useEffect(() => {
    if (app.desktopMenuOpen) {
      dispatch(toggleDesktopMenu());
    }
    if (app.mobileMenuOpen) {
      dispatch(toggleMobileMenu());
    }
    // eslint-disable-next-line
  }, [app.menuType, location]);

	const isDemo = isDemoUrl();

  return (
    <>
      {app.desktopMenuOpen && <Flyout isDemo={isDemo} />}
      <div id="header" className={overlappedContent ? `header--overlapped ${cssClasses}` : cssClasses}>
        <header id="sreal-header">
          <Logo cssClasses="header__logo" />

					{ isDemo ? (
						<>
							<div className="header__buttonright">
								<MenuButtonSwitcher />
								<div className="header__userpic" onClick={() => handleOpenFlyout()}>
									<img src={userPic} alt={`${demoUserObject.firstName} ${demoUserObject.lastName}`} className="image--round" />
								</div>

								<HeaderHamburger />
							</div>
						</>
					) : (
						<>					
							{user.isLoggedIn ? (
								<div className="header__buttonright">
									<MenuButtonSwitcher />
									<div className="header__userpic" onClick={() => handleOpenFlyout()}>
										<img src={userPic} alt={`${user.firstName} ${user.lastName}`} className="image--round" />
									</div>

									<HeaderHamburger />
								</div>
							) : (
								<div className="header__buttonright">
									<button className="button button--white button--login-menu" onClick={() => handleOpenLoginForm()}>
										Login
									</button>
									<button className="button button--white-outline button--register-menu" onClick={() => handleOpenRegisterForm()}>
										Registrieren
									</button>
									<HeaderHamburger />
								</div>
							)}
						</>
					)}

          <Nav activeMobileMenu={app.mobileMenuOpen} />
        </header>

        {children}
      </div>
    </>
  );
}

export default Header;
