import { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import { useHandleSimilarImmos } from '../../hooks/immo/useHandleSimilarImmos';
import CarouselNew from '../Carousel/CarouselNew';
import ImmoCardSmall from '../Cards/ImmoCardSmall';
import LinkCardToAlleAngeboteSmall from '../Cards/LinkCardToAlleAngeboteSmall';
import { parseImmoSreal } from '../../util/immoObjectParser';

// Memoize the component to prevent unnecessary re-renders
const HeaderSimilarImmos = memo(function HeaderSimilarImmos() {
  const cachedSimilarImmos = useSelector((state) => state.cachedSimilarImmos);
  const app = useSelector((state) => state.app);

  const { isMobile, isTablet } = useHandleScreenResize();
  const { getSimilarImmos } = useHandleSimilarImmos();

  // Use useCallback to memoize the function
  const fetchSimilarImmos = useCallback(async () => {
    if (app.demoMode) return;

    try {
      await getSimilarImmos();
    } catch (error) {
      console.error('Error fetching similar immos:', error);
    }
  }, [getSimilarImmos]);

  useEffect(() => {
    fetchSimilarImmos();
  }, [fetchSimilarImmos]); // Now properly memoized

  if (cachedSimilarImmos.cachedSimilarImmos?.length === 0) {
    return null;
  }

  return (
    <>
      {!app.demoMode && (
        <div className="header__similar-immos pt-150rem">
          <div className="header__similar-immos__inner mb-150rem">
            <div className="header__similar-immos__header-wrapper">
              <div className="header__similar-immos__header mb-50rem">
                <span className="text-white text-bold">Das könnte Ihnen auch gefallen</span>
                <CarouselNew isMobile={isMobile} fourslides={!isTablet}>
                  {cachedSimilarImmos.cachedSimilarImmos?.slice(0, 8).map((immo) => (
                    <ImmoCardSmall retailObject={immo} key={immo.id} />
                  ))}
                  <LinkCardToAlleAngeboteSmall />
                </CarouselNew>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default HeaderSimilarImmos;
