import { useDispatch } from 'react-redux';
import { setPageDialogOpenId } from '../../reducers/app';
import Card from './Card';

//ICONS
import IconChat from '../Icons/IconChat3';

import { PAGEDIALOG_CONTACT } from '../../constants.js';

function ContactFormCard({ cssClasses = '', isFAQ = false, id = '' }) {
  cssClasses += ' contact-card';

  const dispatch = useDispatch();

  return (
    <Card cssClasses={cssClasses} cardStyle={'customContent'} id={id}>
      <div className="card__icon">
        <IconChat color="#fff" />
      </div>

      <div className="card__content">
        {isFAQ ? <h2>Sie haben noch Fragen?</h2> : <h2>Kontaktaufnahme</h2>}
        <p>Wir beraten Sie gerne zu Ihrem Anliegen. Nutzen Sie das Kontaktformular – wir melden uns bei Ihnen!</p>

        <div className="mb-100rem">
          <button className="button button--white-outline" onClick={() => dispatch(setPageDialogOpenId(PAGEDIALOG_CONTACT))}>
            Kontaktieren Sie uns!
          </button>
        </div>
      </div>
    </Card>
  );
}

export default ContactFormCard;
