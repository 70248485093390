import { useDispatch } from 'react-redux';
import { PAGEDIALOG_SUCHPROFIL } from '../../constants';
import { useHandleCTAScroll } from '../../hooks/immo/useHandleCTAScroll';
import { setPageDialogOpenId } from '../../reducers/app';
import IconHouseSearch from '../Icons/IconHouseSearch';

function SearchProfilFixedBar() {
  const { opacity, display } = useHandleCTAScroll();

  const dispatch = useDispatch();

  return (
    <div className="fixedbar-wrapper" style={{ opacity: opacity, display: display }}>
      <div className="w-desktop--center p-0">
        <div className="fixedbar__content">
          <div className="d-flex flex-row">
            <div className="circle circle--white">
              <IconHouseSearch color={'#2870ED'} />
            </div>
            <div className="d-flex flex-col">
              <h2>Suchprofil anlegen</h2>
              <p className="m-0">Mit der s REAL Vormerkung finden Sie Ihre Wunschimmobilie</p>
            </div>
          </div>

          <button className="button button--white" onClick={() => dispatch(setPageDialogOpenId(PAGEDIALOG_SUCHPROFIL))}>
            Suchprofil erstellen
          </button>
        </div>
      </div>
    </div>
  );
}

export default SearchProfilFixedBar;
