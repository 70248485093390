import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOfferRealtyList } from '../../api/JustImmo';
import { setKaufanboteList } from '../../reducers/kaufAnbote';
import { getDataForImmoCard } from '../../util/immoObjectParser';
import { MENU_KAUF } from '../../constants';

export const useFetchKaufanbote = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [kaufanboteListLoaded, setKaufanboteListLoaded] = useState(false);

  const getAndSetKaufanbote = async () => {
    try {
      let offerRealtyList = await getOfferRealtyList();

      if (offerRealtyList && offerRealtyList.length > 0) {
				// only seave immodata neccessary for display
				let offerRealtyListParsed = offerRealtyList.map((o) => getDataForImmoCard(o));
				dispatch(setKaufanboteList(offerRealtyListParsed));

				return offerRealtyListParsed;

      } else {
        dispatch(setKaufanboteList([]));
      }

      setKaufanboteListLoaded(true);
    } catch (e) {
      // TODO handle error
      console.log('e', e);
      setKaufanboteListLoaded(true);
    }
  };

  const getSentOffersList = (wunschImmosList) => {
		if(wunschImmosList?.length === 0 || user.sentOffers?.length === 0) return;
		
    wunschImmosList = wunschImmosList.filter((w) => w.immo).map((w) => w.immo);

    // find immo from kaufanboteListe in wunschImmosList (already parsed as full immoObject)
    let list = user.sentOffers?.map((anbot) => {
        let anbotImmo = wunschImmosList.find((w) => w.id === anbot.metaJson?.RealtyId);
        return {
          ...anbotImmo,
          offerCreatedAt: anbot.created,
          offeredPrice: anbot.metaJson?.Price,
        };
      })
      .filter((a) => a !== undefined);

    return list;
  }

  return { getAndSetKaufanbote, getSentOffersList, kaufanboteListLoaded };
};
