import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHandleMenu } from '../../hooks/menu/useHandleMenu';
import IconArrowDown from '../Icons/IconArrowDown';
import MenuCount from './MenuCount';
import Submenu from './Submenu';

function Menu({ cssClasses = '' }) {
  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.user);

  const { menus, linkProfil, getLinkCssClass, getMeineImmobilieLinkItem } = useHandleMenu();

	const [menu, setMenu] = useState(menus[app.menuId].filter((li) => Object.keys(li).length > 0));
	useEffect(() => {
		const currentMenu = menus[app.menuId].filter((li) => Object.keys(li).length > 0);
		setMenu(currentMenu);
  }, [app.menuId, menus]);

  const renderMenuItem = (li, index) => {
    if (li.name === 'Meine Immobilie') {
      li = getMeineImmobilieLinkItem();
    }

    let key = li.name ? li.name.toLowerCase().replace(/[^A-Z0-9]/gi, '') : index;
    
    return (
      <li 
        className={getLinkCssClass(li, li.submenu)} 
        key={`${key}-${index}`} 
        data-id={`linkitem--${key}`}
      >
        {li.link ? (
          <Link to={li.link} title={li.name} className="header__nav__link">
            <span className="header__nav__span">
              {li.name}
              <MenuCount li={li} />
            </span>
          </Link>
        ) : li.submenu ? (
          <span className="header__nav__link header__nav__link--withsubmenu">
            <span className="header__nav__span">{li.name}</span>
            <span className="header__nav__span">
              <IconArrowDown cssClasses="header__nav__arrow" color="#fff" />
            </span>
            <Submenu submenu={li.submenu} />
          </span>
        ) : null}
      </li>
    );
  };

  return (
    <ul className={`header__nav ${cssClasses}`}>
      {menu?.length > 0 && (
        <>
          {menu.map(renderMenuItem)}
          {(user.isLoggedIn || app.demoMode) && (
            <li className={getLinkCssClass(linkProfil)} key={`${linkProfil.name}-mobile`}>
              <Link to={linkProfil.link} title={linkProfil.name} className="header__nav__link">
                <span className="header__nav__span">{linkProfil.name}</span>
              </Link>
            </li>
          )}
        </>
      )}
    </ul>
  );
}

export default Menu;
