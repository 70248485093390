//*React
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

//*Components
import Aktuelles from '../components/Aktuelles/Aktuelles';
import ImmoCardAbgeber from '../components/Cards/ImmoCardAbgeber';
import ImmoDriveCard from '../components/Cards/ImmoDriveCard';
import ImmoDriveEmptyCard from '../components/Cards/ImmoDriveEmptyCard';
import ImmoLiveCard from '../components/Cards/ImmoLiveCard';
import MarktberichtCard from '../components/Cards/MarktberichtCard';
import MarktinfoLightCard from '../components/Cards/MarktinfoLightCard';
import DashboardHeaderStats from '../components/Dashboard/DashboardHeaderStats';
import RecommendationLinkitem from '../components/Linkitems/RecommondationLinkitem';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import NewsTeaserCards from '../components/News/NewsTeaserCards';


//*Images

//*Utils
import DashboardHeaderDocuments from '../components/Dashboard/DashboardHeaderDocuments';
import { abgeberimmo_phase2_parsed } from '../demodata/demo_abgeberimmo';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { useFetchStats } from '../hooks/stats/useFetchStats';
import { useHandleUserRealtyActive } from '../hooks/user/useHandleUserRealtyActive';
import { demofiles } from '../demodata/demofiles';


function DashboardVKPhase2WithActivity() {
  const user = useSelector((state) => state.user);
	const app = useSelector((state) => state.app);
  const [hasRealestates, setHasRealestates] = useState(false);
  const { getData, fetchRealEstates, inseratKlicksData, verschickteAngeboteData, besichtigungenData, dataError } = useFetchStats();

  useEffect(() => {
    (async function () {
      const realEstates = await fetchRealEstates();
      if (realEstates.length > 0) {
        setHasRealestates(true);
        await getData();
      }
			else if(app.demoMode) {
				setHasRealestates(true);
				getData(true);
			}
    })();
  }, []);

  const { files } = useHandleUserRealtyActive();

  const { isMobile } = useHandleScreenResize();

  return (
    <>
      <MetaTags title={'Dashboard | Die Online-Plattform für Ihre Immobilie'} imageName={'sREAL_Sujets_2023_Webhero_16zu9_1.4.jpg'} />

      {hasRealestates ? (
        <DashboardHeaderStats inseratKlicksData={inseratKlicksData} verschickteAngeboteData={verschickteAngeboteData} besichtigungenData={besichtigungenData} dataError={dataError} />
      ) : (
        <DashboardHeaderDocuments files={files} />
      )}

      <MainContent>
				<Aktuelles />

				{ (user.userRealtyActive?.id > 0 || app.demoMode) && (
          <div className={!isMobile ? 'p-0' : 'p-0'}>
            <div className={!isMobile ? 'w-desktop--center pb-200rem' : 'pt-200rem pb-0 mb-0 w-desktop--center'}>
              <h2 className="mt-0">Meine Immobilie</h2>
							{ app.demoMode ? (
								<ImmoCardAbgeber retailObject={abgeberimmo_phase2_parsed} key={abgeberimmo_phase2_parsed.id} />	
							) : (
								<ImmoCardAbgeber retailObject={user.userRealtyActive} key={user.userRealtyActive.id} />
							)}
            </div>
          </div>
        ) }

        <div className={!isMobile ? 'w-desktop--center pb-200rem' : 'pt-200rem pb-0 mb-0 w-desktop--center'}>
          <h2 className="mt-0">Meine Berichte</h2>
          <MarktberichtCard />
        </div>

        <div className="w-desktop--center">
					{ !app.demoMode ? (
						<>
							<div className={!isMobile ? 'elements2cols mt-300rem' : 'elements2cols'}>
								<ImmoLiveCard cssClasses={'elements2cols__col'} immoLiveData={user.userRealtyActive?.immolive} />
							</div>

							{hasRealestates && (
								<div>
									<h2>immo-drive</h2>
									{files.length > 0 ? <ImmoDriveCard files={files} /> : <ImmoDriveEmptyCard />}
								</div>
							)}

							<RecommendationLinkitem />
						</>
					) : (
						<>
							<h2>immo-drive</h2>
							<ImmoDriveCard files={demofiles} />
						</>
					)}
				</div>

        <div className={`w-desktop--center`}>
          <MarktinfoLightCard />
        </div>

        <NewsTeaserCards cssClasses="w-desktop--slider" />
      </MainContent>
    </>
  );
}

export default DashboardVKPhase2WithActivity;
