import React from 'react';

import '../../styles/fullwidthcontent.scss';

function FullWidthContent({ cssClasses = '', children, imagePath, alt = '', id = '' }) {
  return (
    <div className={'fullWidthContent text-center ' + cssClasses} id={id}>
      {children}
      <br />
      <img src={imagePath} alt={alt}></img>
    </div>
  );
}

export default FullWidthContent;
