import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lastCachedDate: null,
  cachedSimilarImmos: [],
};

export const cachedSimilarImmosSlice = createSlice({
  name: 'cachedSimilarImmos',
  initialState,
  reducers: {
    setLastCachedDate: (state, action) => {
      state.lastCachedDate = action.payload;
    },
    setCachedSimilarImmos: (state, action) => {
      state.cachedSimilarImmos = action.payload;
    },
		resetCachedSimilarImmos: () => {
			return initialState;
		}
  },
});

export const { setLastCachedDate, setCachedSimilarImmos, resetCachedSimilarImmos } = cachedSimilarImmosSlice.actions;

export default cachedSimilarImmosSlice.reducer;
