import React from 'react';
import { setGenderRegisterForm } from '../../reducers/registerForm';

import { useDispatch, useSelector } from 'react-redux';
import { setEmailFrequency, setEmailTimeOfDay, setWantsToReceiveMails } from '../../reducers/user';

function FormRadio({ options, id = '', selectedValue, required = false, error = '', formType = '', callback, disabled = false }) {
  const dispatch = useDispatch();
	let app = useSelector((state) => state.app);

  const handleRadioChange = (value) => {
    if (!disabled) {
      if (callback) {
        callback(value);
      } else {
        if (formType === 'registerForm') {
          switch (id) {
            case 'inputGender':
              dispatch(setGenderRegisterForm(value));
              break;
            default:
              break;
          }
        } else if (formType === 'profileEmailSettingsForm') {
          switch (id) {
            case 'emailSettingsFrequency':
              dispatch(setEmailFrequency(+value));
              break;
            case 'emailSettingsTimeOfDay':
              dispatch(setEmailTimeOfDay(+value));
              break;
            default:
              break;
          }
        }
      }
    }
  };

  return (
    <div className="form__row justify-content-start">
      {options.map((option, index) => {
        return (
          <div className={`form__field ${(!disabled && !app.formIsLoading) ? 'form__field--radio' : 'form__field--radio-disabled'}`} key={option.value}>
            <input 
							type="radio" 
							name={id} 
							id={option.value} 
							value={option.value} 
							checked={selectedValue === option.value ? true : false} 
							onChange={(e) => handleRadioChange(e.target.value)}
							disabled={disabled || app.formIsLoading}
						/>

            <label htmlFor={id}>
              {option.label} {!!required ? '*' : ''}
            </label>
          </div>
        );
      })}

      {error && <p className="form__fielderror">{error}</p>}
    </div>
  );
}

export default FormRadio;
