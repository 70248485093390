import { useState } from 'react';

import InfoDialogSuchprofilDeactivate from '../Dialogs/InfoDialogSuchprofilDeactivate';
import { immobilienartOptionsJustImmo } from '../FormFields/util/formOptions';
import Card from './Card';

import '../../styles/card.scss';

// ICONS
import { useDispatch } from 'react-redux';

import { PAGEDIALOG_SUCHPROFIL, SEARCHPROFILE_STATE_ACTIVE, SEARCHPROFILE_STATE_INACTIVE } from '../../constants';

import { setPageDialogOpenId } from '../../reducers/app';
import parseCurrency from '../../util/currencyParser';
import IconMagnifyingGlassWithHouse from '../Icons/IconMagnifyingGlassWithHouse';

function SuchprofilCard({ suchProfilData, cssClasses = '' }) {
  if (!cssClasses) cssClasses = 'suchprofil-card';
  else cssClasses += ' suchprofil-card';

  if (suchProfilData.state === SEARCHPROFILE_STATE_INACTIVE) cssClasses += ' suchprofil-card--inactive';

  const dispatch = useDispatch();

  const [openInfoDialogSuchprofilDeactivate, setOpenInfoDialogSuchprofilDeactivate] = useState(false);
  const handleSetOpenInfoDialogSuchprofilDeactivate = () => {
    setOpenInfoDialogSuchprofilDeactivate(!openInfoDialogSuchprofilDeactivate);
  };

  /**
   * set data for searchprofile form and open pagedialog for editing
   */
  const handleOpenPageDialogSearchProfileEdit = async () => {
		localStorage.setItem('mysreal_searchprofile_edit', JSON.stringify(suchProfilData));
		dispatch(setPageDialogOpenId(PAGEDIALOG_SUCHPROFIL));
  };

  /**
   * get Paramete
   * @param {String} category
   * @returns
   */
  const getParameter = (category) => {
    if (!category) return null;

    if (category === 'exteriorSurface') {
      let außenflächen = [];
      Object.entries(suchProfilData.criteria).forEach(([key, val]) => {
        if (key === 'covered_balcony' && val.enabled) {
          außenflächen.push('Loggia');
        }
        if (key === 'balcony' && val.enabled) {
          außenflächen.push('Balkon');
        }
        if (key === 'terrace' && val.enabled) {
          außenflächen.push('Terrasse');
        }
        if (key === 'garden' && val.enabled) {
          außenflächen.push('Garten');
        }
      });

      if (außenflächen.length > 0) {
        return <li key={category}>Außenflächen: {außenflächen.join(', ')}</li>;
      }
    }

    let value = suchProfilData.criteria[category];
    if (!value) return null;

    switch (category) {
      case 'types':
        let typeId = -1;
        if (value[0] && value[0].id) {
          typeId = value[0].id;
        }

        let type = immobilienartOptionsJustImmo.find((option) => option.id === typeId);
        if (type && type.text) {
          return <li key={category}>Immobilientyp: {type.text}</li>;
        }
        break;
      case 'location':
        if (value.location_ids) {
          let locations = value.location_ids.map((v) => v.text);
          if (locations.length > 0) {
            return <li key={category}>Lage: {locations.join(', ')}</li>;
          }
        }
        break;
      case 'price':
        if (value.from && value.to) {
          return <li key={category}>Preis: {parseCurrency(value.from) + ' - ' + parseCurrency(value.to)}</li>;
        } else if (value.from && !value.to) {
          return <li key={category}>Mindestpreis: {parseCurrency(value.from)}</li>;
        } else if (!value.from && value.to) {
          return <li key={category}>Maximalpreis: {parseCurrency(value.to)}</li>;
        }
        break;
      case 'living_area':
      case 'site_area':
        if (value.from && value.to) {
          return <li key={category}>Fläche: {parseInt(value.from) + 'm² - ' + parseInt(value.to) + 'm²'}</li>;
        } else if (value.from && !value.to) {
          return <li key={category}>Mindestfläche: {parseInt(value.from) + 'm²'}</li>;
        } else if (!value.from && value.to) {
          return <li key={category}>Maximalfläche: {parseInt(value.to) + 'm²'}</li>;
        }
        break;
      case 'rooms':
        if (value.from) {
          return <li key={category}>Zimmer: Mind. {value.from}</li>;
        }
        break;
      case 'furnishings':
        // TBA: Ausstattung temporär entfernt
        let furnishings = value.map((v) => v.name);
        if (furnishings.length > 0) {
          return <li key={category}>Ausstattung: {furnishings.join(', ')}</li>;
        }
        break;
      default:
        break;
    }
  };

  const categoryArray = ['types', 'location', 'living_area', 'site_area', 'price', 'rooms', 'exteriorSurface'];

  return (
    <>
      <Card cardStyle="customContent" cssClasses={cssClasses}>
        <div className={suchProfilData.state === SEARCHPROFILE_STATE_ACTIVE ? 'card__content' : 'card__content card__content--deaktiv'}>
          <div className="d-flex flex-row align-items-center">
            <IconMagnifyingGlassWithHouse color="#0B1F42" />
          </div>

          <h4>{suchProfilData.name}</h4>

          <div className="suchprofil-card__data">
            <h4>Parameter:</h4>

            {categoryArray && categoryArray.length > 0 && (
              <ul className="list-style-none">
                {categoryArray.map((cat) => {
                  return getParameter(cat);
                })}
              </ul>
            )}
          </div>

          <div className="button-panel align-items-center">
            <button className="button button--blue" onClick={() => handleSetOpenInfoDialogSuchprofilDeactivate()}>
              {suchProfilData.state === SEARCHPROFILE_STATE_ACTIVE ? <>Deaktivieren</> : <>Aktivieren</>}
            </button>

            {suchProfilData.state === SEARCHPROFILE_STATE_ACTIVE && (
              <button className="button button--gray-outline" onClick={() => handleOpenPageDialogSearchProfileEdit()}>
                Bearbeiten
              </button>
            )}
          </div>
        </div>
      </Card>

      <InfoDialogSuchprofilDeactivate open={openInfoDialogSuchprofilDeactivate} handleClose={handleSetOpenInfoDialogSuchprofilDeactivate} suchprofil={suchProfilData} />
    </>
  );
}

export default SuchprofilCard;
