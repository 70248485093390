import { useDispatch } from 'react-redux';
import { PAGEDIALOG_SUCHPROFIL } from '../../constants';
import { setPageDialogOpenId } from '../../reducers/app';
import IconHouseSearch from '../Icons/IconHouseSearch';
import Linkitem from './Linkitem';

function SearchProfilCreateLinkitem({ cssClasses = '' }) {
  const dispatch = useDispatch();

  return (
    <Linkitem
      handleLink={() => dispatch(setPageDialogOpenId(PAGEDIALOG_SUCHPROFIL))}
      icon={<IconHouseSearch />}
      cssClasses={`linkitem--blue linkitem--einzeilig-desktop linkitem--IconHouseSearch ${cssClasses}`}
      headline="Suchprofil anlegen"
      text="Mit der s REAL Vormerkung finden Sie Ihre Wunschimmobilie"
    />
  );
}

export default SearchProfilCreateLinkitem;
