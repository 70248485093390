import React from 'react';
import { useDispatch } from 'react-redux';
import { CONTACTFORM_ANLIEGEN_PROFIL, PAGEDIALOG_CONTACT } from '../../constants';
import { setPageDialogOpenId } from '../../reducers/app';

function ProfileForm({ currentUserObject }) {
  const dispatch = useDispatch();

  const handleOpenContactForm = () => {
		localStorage.setItem('mysreal_contact_reason', CONTACTFORM_ANLIEGEN_PROFIL);
		dispatch(setPageDialogOpenId(PAGEDIALOG_CONTACT));
  };

	if(!currentUserObject) {
		return null;
	}
	else {
		return (
			<div className="profileForm">
				<h2 className="form__title">Daten</h2>

				<div className="elements3cols">
					<div className="elements3cols__col">
						<span>Anrede</span>
						<p>{currentUserObject.gender === 'f' ? 'Frau' : 'Herr'}</p>
					</div>
					<div className="elements3cols__col">
						<span>Vorname</span>
						<p>{currentUserObject.firstName}</p>
					</div>
					<div className="elements3cols__col">
						<span>Nachname</span>
						<p>{currentUserObject.lastName}</p>
					</div>
				</div>

				<div className="elements3cols">
					<div className="elements3cols__col">
						<span>Titel vorgestellt</span>
						<p>{currentUserObject.titleBefore !== '' ? currentUserObject.titleBefore : '-'}</p>
					</div>
					<div className="elements3cols__col">
						<span>Titel nachgestellt</span>
						<p>{currentUserObject.titleAfter !== '' ? currentUserObject.titleAfter : '-'}</p>
					</div>
					<div className="elements3cols__col">
						<span>Mobiltelefonnummer</span>
						<p>{currentUserObject.phoneNumber}</p>
					</div>
				</div>

				<div className="elements3cols">
					<div className="elements3cols__col">
						<span>E-Mail-Adresse</span>
						<p>{currentUserObject.userName}</p>
					</div>
				</div>

				<span className="form--info">
					<span
						className="textlink"
						onClick={() => handleOpenContactForm()}
					>
						Stellen Sie hier eine Anfrage
					</span>
					, um Ihre Daten zu ändern.
				</span>
			</div>
		);
	}
}

export default ProfileForm;
