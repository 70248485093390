import InputAdornment from '@mui/material/InputAdornment';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { deDE, DesktopDatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Clock } from '@mui/x-date-pickers/internals/components/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment';
import React from 'react';
import IconCalendar from '../../Icons/IconCalendar.js';
import IconClock from '../../Icons/IconClock.js';

import { Controller, useFormContext } from 'react-hook-form';

import { disableWeekendsAndHolidays, validateDate, validateTime } from '../../../util/datepickerUtils.js';

dayjs.extend(customParseFormat);
const theme = createTheme(
  {
    palette: {
      primary: { main: '#3679ec' }, //$erste-blue color
    },
  },
  deDE, // x-date-pickers translations
);

function DateTimePickerRHF({ id = '' }) {
	const {  control, watch, formState: { errors } } = useFormContext();

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
        <div className="form__field">
          <label htmlFor={`${id}dateInput`}>Datum</label>
          <br />

					<Controller
						name="appointmentDate2"
						control={control}
						rules={{ 
							validate: (value) => validateDate(value),
						}}
						render={({ field }) => (
							<DesktopDatePicker
								className="mui-input"
								id={`${id}dateInput`}
								inputFormat="DD/MM/YYYY"
								shouldDisableDate={disableWeekendsAndHolidays}
								value={field.value ? field.value : ''}
								onChange={field.onChange}
								components={{
									OpenPickerIcon: IconCalendar,
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										inputProps={{
											...params.inputProps,
											placeholder: 'TT/MM/JJ',
										}}
									/>
								)}
							/>
						)}
					/>
        </div>

        <div className="form__field">
          <label htmlFor={`${id}timeInput`}>Uhrzeit</label>
          <br />
					<Controller
						name="appointmentTime2"
						control={control}
						rules={{ 
							validate: (value) => validateTime(watch("appointmentDate2"), value),
						}}
						render={({ field }) => (
							<TimePicker
								className="mui-input"
								components={{
									OpenPickerIcon: IconClock,
								}}
								id={`${id}timeInput`}
								value={field.value !== null ? dayjs(field.value, 'HH:mm:ss', 'de') : ''}
								disabled={!watch("appointmentDate2")}
								onChange={field.onChange}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<Clock />
										</InputAdornment>
									),
								}}
								ampmInClock={false}
								ampm={false}
								renderInput={(params) => (
									<TextField
										{...params}
										inputProps={{
											...params.inputProps,
											placeholder: 'HH:MM',
										}}
									/>
								)}
								minTime={dayjs(`${moment(watch("appointmentDate2")).format('YYYY-MM-DD')}T07:00`)}
								maxTime={dayjs(`${moment(watch("appointmentDate2")).format('YYYY-MM-DD')}T18:00`)}
							/>
						)}
					/>
        </div>

        {errors.appointmentDate2?.message && <p className={'form__fielderror'}>{errors.appointmentDate2?.message}</p> }
				{errors.appointmentTime2?.message && <p className={'form__fielderror'}>{errors.appointmentTime2?.message}</p> }
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default DateTimePickerRHF;
