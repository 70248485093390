//*React
import { memo, useEffect, useMemo, useState } from 'react';
import Countdown, { zeroPad } from 'react-countdown';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { tryAuthorizeUser } from '../../../api/Connect';

//*Components
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import FormInput from '../../FormFields/FormInput';
import LoadingSpinner from '../../LoadingSpinner';

//*API
import { confirm, current, login, token, directRegisterConfirm } from '../../../api/Onboarding';
import { setOAuthClientId, setOAuthRedirectUri, setOAuthResponseType } from '../../../reducers/app';

//*Reducers
import { setFormIsLoading } from '../../../reducers/app';
import { setSiteIndexLoginForm, setTokenLoginForm } from '../../../reducers/loginForm';
import { setSiteIndexRegisterForm, setTokenRegisterForm } from '../../../reducers/registerForm';

//*Utils
import { fetchErrorText } from '../../FormFields/util/formTexts';
import { checkTokenToVerificationCode, validateFields } from '../../FormFields/util/validateFields';


//*Constants
import {
  LOGINFORM_SITEINDEX_ERROR,
  LOGINFORM_SITEINDEX_LOGINSUCCESS,
  REGFORM_SITEINDEX_ERROR,
  REGFORM_SITEINDEX_LOGINSUCCESS,
  REGFORM_SITEINDEX_CHANGEPHONENUMBER,
  DIREKTREGFORM_SITEINDEX_ERROR,
  DIREKTREGFORM_SITEINDEX_LOGINSUCCESS,
  DIREKTREGFORM_SITEINDEX_SUCCESSTOKEN,
} from '../../../constants';
import { useHandleUser } from '../../../hooks/user/useHandleUser';

function FormToken({ formType }) {
  let registerForm = useSelector((state) => state.registerForm);
  let loginForm = useSelector((state) => state.loginForm);

  let app = useSelector((state) => state.app);
  let currentFormValidators = useSelector((state) => state.formValidators);
  let location = useLocation();

  const { setupUser } = useHandleUser(true);

  const dispatch = useDispatch();
  const [error, setError] = useState('');

  const [isDirektReg, setIsDirektReg] = useState(false);
  const [mobilnummer, setMobilnummer] = useState(null);
  useEffect(() => {
    if (registerForm.siteIndex === DIREKTREGFORM_SITEINDEX_SUCCESSTOKEN) {
      setIsDirektReg(true);
    }

    if (formType === 'registerForm') {
      setMobilnummer(registerForm.requestBody.phoneNumber);
    } else if (formType === 'loginForm') {
      setMobilnummer(loginForm.phoneNumber);
    }
  }, []);

  const [timerRunning, setTimerRunning] = useState(true);
  let seconds = useMemo(() => {
    if (timerRunning) {
      let minutesCnt = 60000 * 3; // 3 mins
      let maxTimerCount = Date.now() + minutesCnt;

      const now = new Date().getTime();

      const lastSessionStorageTime = localStorage.getItem('tokenTimerStart');
      if (!lastSessionStorageTime) {
        localStorage.setItem('tokenTimerStart', now);
      } else {
        const diff = Math.floor(now - parseInt(lastSessionStorageTime));

        if (diff >= minutesCnt) {
          localStorage.removeItem('tokenTimerStart');
          maxTimerCount = Date.now();
        }

        maxTimerCount -= diff;
      }

      return maxTimerCount;
    } else {
      return -1;
    }
  }, [timerRunning]);

  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return (
        <span className="font-80 textlink" onClick={resendConfirmationCode}>
          {' '}
          Code erneut anfordern
        </span>
      );
    } else {
      return (
        <span className="font-80">
          SMS-Code gültig für{' '}
          <span>
            {minutes}:{zeroPad(seconds)}
          </span>{' '}
          Minuten.
        </span>
      );
    }
  };

  const forwardAction = async () => {
    setError('');
    if (app.formIsLoading) {
      return;
    }

    if (formType === 'registerForm') {
      await sendToken(registerForm.token, '', registerForm.rememberMe, true, false);
    } else if (formType === 'loginForm') {
      await sendToken(loginForm.token, loginForm.verificationCode, loginForm.requestBody.rememberMe, false, true);
    }
  };

  const getCurrentUserObject = async () => {
    try {
      const currentUser = await current();
      setupUser(currentUser);

      if (formType === 'registerForm' || location.pathname.indexOf('/confirm') >= 0) {
        dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_LOGINSUCCESS));
      } else if (formType === 'registerForm' || isDirektReg) {
        dispatch(setSiteIndexRegisterForm(DIREKTREGFORM_SITEINDEX_LOGINSUCCESS));
      } else if (formType === 'loginForm') {
        dispatch(setSiteIndexLoginForm(LOGINFORM_SITEINDEX_LOGINSUCCESS));
      }
    } catch (error) {
      // Handle user error
      dispatch(setFormIsLoading(false));
      setError(fetchErrorText);
      window.location.hash = LOGINFORM_SITEINDEX_ERROR;
    }
  };

  const sendToken = async (formToken, verificationCode, rememberMe, initial, fromLogin) => {
    let validationResult = validateFields({ token: formToken }, dispatch);

    if (validationResult && verificationCode) {
      validationResult = checkTokenToVerificationCode(formToken, verificationCode, dispatch);
    }
    if (validationResult) {
      dispatch(setFormIsLoading(true));

      try {
        await token({
          token: formToken,
          rememberMe: rememberMe,
          initial: initial,
        });

        getCurrentUserObject();
        if (fromLogin && app.OAuthClientId && app.OAuthResponseType && app.OAuthRedirectUri) {
          const redirectUrl = await tryAuthorizeUser(app.OAuthClientId, app.OAuthResponseType, app.OAuthRedirectUri);
          if (redirectUrl) {
            dispatch(setOAuthClientId(''));
            dispatch(setOAuthRedirectUri(''));
            dispatch(setOAuthResponseType(''));
            window.location.href = redirectUrl;
          } else {
            console.error('Authorization failed or missing redirect URL.');
          }
        }
      } catch (e) {
        dispatch(setFormIsLoading(false));
        setError('Der SMS-Code ist leider falsch.');

        if (formType === 'registerForm') {
          if (isDirektReg) {
            window.location.hash = DIREKTREGFORM_SITEINDEX_ERROR;
          } else {
            window.location.hash = REGFORM_SITEINDEX_ERROR;
          }
        } else if (formType === 'loginForm') {
          window.location.hash = LOGINFORM_SITEINDEX_ERROR;
        }
      }
    }
  };

  const resendConfirmationCode = () => {
    setError('');
    if (app.formIsLoading) {
      return;
    }

    if (location.pathname.indexOf('/confirm') >= 0) {
      formType = 'registerForm';
    }

    if (formType === 'registerForm') {
      dispatch(setTokenRegisterForm(''));
      resendConfirmationCodeRegForm();
    } else if (formType === 'loginForm') {
      dispatch(setTokenLoginForm(''));
      resendConfirmationCodeLoginForm();
    }
  };

  const resendConfirmationCodeLoginForm = async () => {
    try {
      dispatch(setFormIsLoading(true));
      const res = await login(loginForm.requestBody);
      dispatch(setFormIsLoading(false));

      if (typeof res !== 'string') {
        setError(fetchErrorText);
        setTimerRunning(false);
      } else {
        setTimerRunning(true);

        const now = new Date().getTime();
        localStorage.setItem('tokenTimerStart', now);
      }
    } catch (error) {
      setError(fetchErrorText);
      dispatch(setFormIsLoading(false));
    }
  };

  const resendConfirmationCodeRegForm = async () => {
    const { userId, code } = registerForm;

    if (!!userId && !!code) {
      try {
        dispatch(setFormIsLoading(true));
        let res = false;
        if (!isDirektReg) {
          res = await confirm({ userId, code });
        } else {
          res = await directRegisterConfirm({
            userId: userId,
            code: code,
            mode: 0,
            registerDto: registerForm.requestBody,
          });
        }

        dispatch(setFormIsLoading(false));

        if (typeof res !== 'string') {
          setError(fetchErrorText);
          setTimerRunning(false);
        } else {
          setTimerRunning(true);

          const now = new Date().getTime();
          localStorage.setItem('tokenTimerStart', now);
        }
      } catch (error) {
        dispatch(setFormIsLoading(false));
        setError(fetchErrorText);
        setTimerRunning(false);
      }
    } else {
      setError(fetchErrorText);
      setTimerRunning(false);
    }
  };

  const CountdownWrapper = () => <Countdown date={seconds} renderer={renderer} onComplete={() => setTimerRunning(false)} />;

  const MemoCountdown = memo(CountdownWrapper);

  return (
    <>
      <div className="d-flex w-100 justify-content-center pt-40 pb-40">
        <svg data-name="Gruppe 17256" xmlns="http://www.w3.org/2000/svg" width="181.064" height="183.649" viewBox="0 0 181.064 183.649">
          <path
            data-name="Pfad 10497"
            d="M119.988,104.09a5.841,5.841,0,0,0-5.666,5.666v40a5.841,5.841,0,0,1-5.666,5.666H17a5.841,5.841,0,0,1-5.666-5.666V24.1A5.841,5.841,0,0,1,17,18.432h40a5.841,5.841,0,0,0,5.666-5.666A5.618,5.618,0,0,0,56.994,7.1H17a17.07,17.07,0,0,0-17,17V149.753a17.143,17.143,0,0,0,17,17.332h91.324a17.07,17.07,0,0,0,17-17v-40a5.445,5.445,0,0,0-5.333-6"
            transform="translate(0 16.564)"
            fill="#0b1f42"
          />
          <path
            data-name="Pfad 10498"
            d="M14.6,48.766a5.841,5.841,0,0,0,5.666,5.666h17a5.841,5.841,0,0,0,5.666-5.666A5.618,5.618,0,0,0,37.264,43.1h-17A5.618,5.618,0,0,0,14.6,48.766"
            transform="translate(34.062 100.552)"
            fill="#0b1f42"
          />
          <motion.path
            data-name="Pfad 10499"
            d="M123.849,34.777A51.4,51.4,0,0,0,97.8,5.173l-.01-.007A52.578,52.578,0,0,0,74.977,0,51.72,51.72,0,0,0,30.415,77.9L23.6,96.154a5.29,5.29,0,0,0,.9,5.113,5.086,5.086,0,0,0,4.016,1.923,6.1,6.1,0,0,0,.917-.073L53.92,98.624a51.537,51.537,0,0,0,37.853,1.716,51.553,51.553,0,0,0,32.077-65.564m-82.542,44.4a5.547,5.547,0,0,0-.6-4.906,39.285,39.285,0,0,1-6.989-22.591A40.1,40.1,0,0,1,45.734,22.638a40.971,40.971,0,0,1,47.069-7.8,40.814,40.814,0,0,1,19.225,54.855A41.487,41.487,0,0,1,56.966,88.731a5.623,5.623,0,0,0-2.41-.593l-17.815,3.05Z"
            transform="translate(54.356)"
            fill="#0b1f42"
            initial={{ x: [50] }}
            animate={{
              opacity: [1, 1, 1, 1, 1, 1, 0],
              scale: [0, 0, 1, 1, 1, 1, 1],
            }}
            transition={{ repeat: Infinity, duration: 4 }}
          />
        </svg>
      </div>

      <h2>Sie haben eine SMS erhalten</h2>

      {mobilnummer && (
        <>
          <label htmlFor="mobilnummer">Mobilnummer</label>
          <br />
          <span className="d-flex font-120 font-weight-600 lh-200">{mobilnummer}</span>
        </>
      )}

      {formType === 'registerForm' && !isDirektReg && (
        <div className="font-80 mb-200rem">
          <span
            className="textlink"
            onClick={() => {
              dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_CHANGEPHONENUMBER));
            }}
          >
            Mobilnummer ändern
          </span>
        </div>
      )}

      <FormInput type={'text'} id={'inputToken'} label={'SMS-Code'} error={currentFormValidators.tokenError} required={true} formType={formType} cssClasses={'mt-100rem'} />

      <MemoCountdown />

      <LoadingSpinner />

      <div className="button-panel pt-100rem">
        <ButtonForwardAction formType={formType} buttonText="Bestätigen" forwardAction={forwardAction} />
      </div>

      {error && <p className="form__fielderror">{error}</p>}
    </>
  );
}

export default FormToken;
