import { useSelector } from 'react-redux';
import { PHASE1_VERKAUF } from '../../constants';
import DotUnread from '../DotUnread';

function MenuCount({ li }) {
  const notifications = useSelector((state) => state.notifications);
  const app = useSelector((state) => state.app);

  if (app.menuId === PHASE1_VERKAUF) {
    return null;
  } else if(app.demoMode) {
		return (
			<>
        {li.name === 'Besichtigungen' && <DotUnread unreadAmount={6} /> }
        {li.name === 'Weitere Aktivitäten' && <DotUnread unreadAmount={7} /> }
      </>
		)
	}
	else {
    return (
      <>
        {li.name === 'Nachrichten' && notifications?.unreadMessages > 0 && <DotUnread unreadAmount={notifications.unreadMessages} />}

        {li.name === 'Besichtigungen' && notifications?.unreadBesichtigungen > 0 && <DotUnread unreadAmount={notifications.unreadBesichtigungen} />}

        {li.name === 'Weitere Aktivitäten' && notifications?.unreadWeitere > 0 && <DotUnread unreadAmount={notifications.unreadWeitere} />}
      </>
    );
  }
}

export default MenuCount;
