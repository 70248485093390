import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { MENU_KAUF, MENU_VERKAUF } from '../../constants';

import { getMessageStats } from '../../api/Contact';
import {
	PHASE0,
	PHASE1_KAUF,
	PHASE1_VERKAUF,
	PHASE2_KAUF_SP_AKTIV,
	PHASE2_KAUF_SP_BEARBEITUNG,
	PHASE2_VERKAUF_MIT_AKTIVITAET,
	PHASE2_VERKAUF_OHNE_AKTIVITAET,
	PHASE3_KAUF,
	PHASE3_VERKAUF,
	PHASE4_KAUF,
	PHASE4_VERKAUF,
} from '../../constants';
import { setMenuId } from '../../reducers/app';
import { useHandleUserRealtyActive } from '../user/useHandleUserRealtyActive';
import { abgeberimmo_phase2_parsed, abgeberimmo_phase3_parsed, abgeberimmo_phase4_parsed } from '../../demodata/demo_abgeberimmo';

export const useHandleMenu = () => {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);
	const kaufanbote = useSelector((state) => state.kaufAnbote);

	const { getKaeuferImmoPhase3Or4 } = useHandleUserRealtyActive();

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Links
  const linkHome = {
    link: app.root + '/',
    name: 'Übersicht',
  };
  const linkVerkaufen = {
    link: app.root + '/verkaufen',
    name: 'Immobilie verkaufen',
  };
  const linkKaufen = {
    link: app.root + '/kaufen',
    name: 'Immobilie kaufen',
  };
  const linkImmobilien = {
    link: app.root + '/immobiliensuche',
    name: 'Immobiliensuche',
  };
  const linkNeubauprojekte = {
    link: app.root + '/neubauprojekte',
    name: 'Neubauprojekte',
  };

  const submenuImmosuche = [linkImmobilien, linkNeubauprojekte];
  const linkImmosuche = {
    link: '',
    name: 'Immobiliensuche',
    submenu: submenuImmosuche,
  };

  const linkNews = {
    link: app.root + '/news',
    name: 'News',
  };

  const linkFaqs = { link: app.root + '/faqs', name: 'FAQs' };
  const linkNachrichten = {
    link: app.root + '/nachrichten',
    name: 'Nachrichten',
  };
  const linkBesichtigungen = {
    link: app.root + '/besichtigungen',
    name: 'Besichtigungen',
  };
  const linkMarketing = {
    link: app.root + '/aktivitaeten',
    name: 'Weitere Aktivitäten',
  };
  const linkDashboard = {
    link: app.root + '/dashboard',
    name: 'Dashboard',
  };
  const linkStatistiken = {
    link: app.root + '/statistik',
    name: 'Statistik',
  };
  const linkImmoDrive = {
    link: app.root + '/immo-drive',
    name: 'immo-drive',
  };
  const linkProfil = {
    link: app.root + '/profil',
    name: 'Profil',
  };

  const submenuAktuelles = [linkNachrichten, linkBesichtigungen, linkMarketing];
  const linkAktuelles = {
    link: '',
    name: 'Aktuelles',
    submenu: submenuAktuelles,
  };

  const linkAlleAngebote = {
    link: app.root + '/angebote',
    name: 'Alle Angebote',
  };
  const linkFavoriten = {
    link: app.root + '/favoriten',
    name: 'Favoriten',
  };

	const linkMeineImmobilie = {
    link: app.root + '/immobilie/',
    name: 'Meine Immobilie',
  };

	/**
   * get link structure for "Meine Immobilie"
   * @returns Object
   */
	const getMeineImmobilieLinkItem = useCallback(() => {
		let meineImmobilieLink = {};

		if (app.menuType === MENU_VERKAUF) {
			let currentUserRealties = user?.userRealties;

			if(app.demoMode) {
				currentUserRealties = [abgeberimmo_phase2_parsed, abgeberimmo_phase3_parsed, abgeberimmo_phase4_parsed]; 
			}

			const submenuImmobilien = currentUserRealties?.map((immo) => ({
				link: app.root + '/immobilie/' + immo?.url,
				name: immo?.title,
			}));

			if (currentUserRealties?.length > 1) {
				meineImmobilieLink = {
					name: 'Meine Immobilien',
					link: '',
					submenu: submenuImmobilien,
				};
			} else if (user?.userRealtyActive?.id > 0) {
				meineImmobilieLink = {
					name: 'Meine Immobilie',
					link: app.root + '/immobilie/' + user.userRealtyActive.url,
				};
			}
		} else if (app.menuType === MENU_KAUF) {
			let kaeuferPhase3Immo = null;
			if(app.demoMode) {
				// kaeuferPhase3Immo =[immo4]; 
			}
			else {
				kaeuferPhase3Immo = getKaeuferImmoPhase3Or4();
			}
			
			if (kaeuferPhase3Immo) {
				meineImmobilieLink = {
					name: 'Meine Immobilie',
					link: app.root + '/immobilie/' + kaeuferPhase3Immo.url,
				};
			}
		}

		return meineImmobilieLink;
	}, [
		app.menuType,
		user?.userRealties,
		user?.userRealtyActive?.id,
	]);

	const getMeineImmobilieLinkItemDemo = useCallback(() => {
	}, []);

// Menus
  const menuPhase0 = useMemo(() => [linkHome, linkVerkaufen, linkKaufen, linkImmosuche, linkNews, linkFaqs], []);
  const menuPhase1Verkauf = useMemo(() => [linkDashboard, linkNachrichten, linkVerkaufen, linkImmosuche, linkNews, linkFaqs], []);
  const menuPhase1Kauf = useMemo(() => [linkDashboard, linkVerkaufen, linkKaufen, linkImmosuche, linkNews, linkFaqs], []);

  // menu phases
  // VERKAUF
  const menuPhase2VerkaufOhneAktivitaeten = useMemo(() => [linkDashboard, linkNachrichten, linkMeineImmobilie, linkImmoDrive, linkNews, linkFaqs], []);
  const menuPhase2VerkaufMitAktivitaeten = useMemo(() => [linkDashboard, linkStatistiken, linkAktuelles, linkMeineImmobilie, linkImmoDrive, linkNews, linkFaqs], []);
  const menuPhase3Verkauf = useMemo(() => [linkDashboard, linkStatistiken, linkAktuelles, linkMeineImmobilie, linkImmoDrive, linkNews, linkFaqs], []);
  const menuPhase4Verkauf = useMemo(() => [linkDashboard, linkNachrichten, linkImmoDrive, linkNews, linkFaqs], []);

  // KAUF
  const menuPhase2KaufSuchprofilInBearbeitung = useMemo(() => [linkDashboard, linkFavoriten, linkImmosuche, linkNews, linkFaqs], []);
  const menuPhase2KaufSuchprofilAktiv = useMemo(() => [linkDashboard, linkAlleAngebote, linkFavoriten, linkImmosuche, linkNews, linkFaqs], []);
  const menuPhase3Kauf = useMemo(() => [linkDashboard, linkMeineImmobilie, linkImmoDrive, linkFavoriten, linkNews, linkFaqs], []);
  const menuPhase4Kauf = useMemo(() => [linkDashboard, linkMeineImmobilie, linkImmoDrive, linkFavoriten, linkNews, linkFaqs], []);

  const menus = useMemo(() => ({
    [PHASE0]: menuPhase0,
    [PHASE1_VERKAUF]: menuPhase1Verkauf,
    [PHASE1_KAUF]: menuPhase1Kauf,
    [PHASE2_VERKAUF_OHNE_AKTIVITAET]: menuPhase2VerkaufOhneAktivitaeten,
    [PHASE2_VERKAUF_MIT_AKTIVITAET]: menuPhase2VerkaufMitAktivitaeten,
    [PHASE3_VERKAUF]: menuPhase3Verkauf,
    [PHASE4_VERKAUF]: menuPhase4Verkauf,
    [PHASE2_KAUF_SP_BEARBEITUNG]: menuPhase2KaufSuchprofilInBearbeitung,
    [PHASE2_KAUF_SP_AKTIV]: menuPhase2KaufSuchprofilAktiv,
    [PHASE3_KAUF]: menuPhase3Kauf,
    [PHASE4_KAUF]: menuPhase4Kauf,
  }), [
    menuPhase0,
    menuPhase1Verkauf,
    menuPhase1Kauf,
    menuPhase2VerkaufOhneAktivitaeten,
    menuPhase2VerkaufMitAktivitaeten,
    menuPhase3Verkauf,
    menuPhase4Verkauf,
    menuPhase2KaufSuchprofilInBearbeitung,
    menuPhase2KaufSuchprofilAktiv,
    menuPhase3Kauf,
    menuPhase4Kauf
  ]);

  const [menu, setMenu] = useState(menus[app.menuId].filter((li) => Object.keys(li).length > 0));

  const getRealtyPhases = useCallback((userRealtyPhases) => {
		let realtyPhases = [];
		if(!userRealtyPhases) {
			userRealtyPhases = user.userObject?.realtyPhases;
		}

    if (userRealtyPhases) {
			realtyPhases = [...userRealtyPhases];
      realtyPhases = realtyPhases.filter((p) => p !== null && p !== undefined);
      realtyPhases = realtyPhases.sort((a, b) => {
        return b.phase - a.phase;
      });
    }
		
    return realtyPhases;
  }, [user.userObject?.realtyPhases]);


	/**
 * show unread messages count in menu
 * @param {Array} menuToCheck
 * @returns
 */
	const getNachrichtenCount = useCallback(async () => {
		if (!user.isLoggedIn || app.demoMode) return;

		try {
			const messageStats = await getMessageStats();
			return messageStats?.unreadMessagesCount;
		} catch (error) {
			console.error(error);
			return 0;
		}
  }, [user.isLoggedIn]);

	const updateMenu = useCallback((menuId) => {
		// Only update menu if there's a valid menuId and it exists in menus
		if (menuId && menus.hasOwnProperty(menuId)) {
			// Only update state if menu ID has changed
			if (app.menuId !== menuId) {
				dispatch(setMenuId(menuId));

				const currentMenu = menus[menuId].filter((li) => Object.keys(li).length > 0);
				setMenu(currentMenu);
				
				// Navigate to maintain URL state
				navigate(location.pathname + location.hash);
			}


			if(!app.demoMode) {
				// Check if this menu has Nachrichten item (either direct or in submenu)
				const hasNachrichten = menus[menuId].some((m) => 
					m.name === 'Nachrichten' || (m.submenu && m.submenu.some(s => s.name === 'Nachrichten'))
				);
				
				// If this menu includes Nachrichten, get the message count
				if (hasNachrichten) {
					getNachrichtenCount();
				}
			}
		}
	}, [app.demoMode, app.menuId, dispatch, getNachrichtenCount, location.hash, location.pathname, menus, navigate]);

  // Helper function to determine menu ID for MENU_VERKAUF
  const determineVerkaufMenuId = useCallback((realtyPhases, abgeberImmo) => {
    let menuId = PHASE1_VERKAUF;

		if(!realtyPhases) {
			realtyPhases = getRealtyPhases();
		}

		if(!abgeberImmo) {
			abgeberImmo = user.userRealtyActive;
		}

    // Check if active realty exists
    if (abgeberImmo?.id && abgeberImmo?.id > -1) {
      const realtyIdPhase = realtyPhases.find((p) => p?.realtyId === abgeberImmo.id);

      if (realtyIdPhase?.phase) {
        switch (realtyIdPhase.phase) {
          case 4:
            menuId = PHASE4_VERKAUF;
            break;
          case 3:
            menuId = PHASE3_VERKAUF;
            break;
          case 2:
            menuId = abgeberImmo?.activities?.length === 0 
              ? PHASE2_VERKAUF_OHNE_AKTIVITAET 
              : PHASE2_VERKAUF_MIT_AKTIVITAET;
            break;
          case 1:
          default:
            menuId = PHASE1_VERKAUF;
            break;
        }
      }
			else {
        menuId = PHASE1_VERKAUF;
			}
    }
    
    return menuId;
  }, [getRealtyPhases, user.userRealtyActive]);

  // Helper function to determine menu ID for MENU_KAUF
  const determineKaufMenuId = useCallback((realtyPhases, userSearchprofiles, kaufanboteList) => {
    // Gather all relevant realties
		if(!userSearchprofiles) {
			userSearchprofiles = user.searchProfiles;
		}

		const spImmos = userSearchprofiles.filter((sp) => sp.immos?.length > 0);

		if(!kaufanboteList) {
			kaufanboteList = kaufanbote.kaufanboteList || [];
		}

    const kaeuferImmos = [...spImmos, ...kaufanboteList];

		if(!realtyPhases) {
			realtyPhases = getRealtyPhases();
		}

    if (kaeuferImmos.length > 0) {
      const kaeuferImmosIds = kaeuferImmos.map((k) => k.id);
      const realtyIdPhase = realtyPhases.find((p) => kaeuferImmosIds.includes(p?.realtyId));

      if (realtyIdPhase?.phase) {
        switch (realtyIdPhase.phase) {
          case 4:
            return PHASE4_KAUF;
          case 3:
            return PHASE3_KAUF;
          default:
            return PHASE2_KAUF_SP_AKTIV;
        }
      } else {
        // If kaeuferImmos exist but no phase found
        return PHASE2_KAUF_SP_AKTIV;
      }
    } else {
      // Determine based on search profiles
      return userSearchprofiles?.length > 0  ? PHASE2_KAUF_SP_AKTIV : PHASE1_KAUF;
    }
  }, [getRealtyPhases, kaufanbote.kaufanboteList, user.searchProfiles]);

	useEffect(() => {
		if(app.demoMode) return;
		if(user.userRealtyActive?.id && app.menuType === MENU_VERKAUF) {
			const newMenuId = determineVerkaufMenuId();
			updateMenu(newMenuId);
		}
	}, [user.userRealtyActive?.id, user.userRealtyActive?.activities, app.menuType, determineVerkaufMenuId, updateMenu]);

  const getLinkName = (li) => {
    if (!li || !li.link) return '';

    let linkname = li.link.replace(app.root + '/', '');
    linkname = linkname.split('/')[0];
    return linkname;
  };

  /**
   * check which page is active and set css-class
   * @param {Object} li
   * @returns
   */
  const getLinkCssClass = (linkitem, submenu = null) => {		
    let pagename = location.pathname.split('/')[2];
    let active = false;
		const linkname = getLinkName(linkitem);
    if (linkname === pagename) {
      active = true;
    }

    if (submenu) {
      submenu.forEach((sub) => {
        let linknameSub = getLinkName(sub);
        if (linknameSub === pagename) {
          active = true;
        } else if (pagename === 'immobilie' && linkname === 'dashboard') {
          active = true;
        }
      });
    }

    let cssClasses = '';
    if (active) {
      cssClasses = 'active';
    }

    if (linkname === 'profil' || linkname === 'faqs') {
      cssClasses += ' header__nav--desktophidden';
    }
    if (linkname === 'nachrichten' && user.hasMessages === false) {
      cssClasses += ' is-hidden';
    }

    return cssClasses;
  };


 

  return {
    menu,
		menus,
    getLinkCssClass,
    getLinkName,
    getNachrichtenCount,
    linkProfil,
    linkFaqs,
		getMeineImmobilieLinkItem,
		updateMenu,
		determineKaufMenuId,
		determineVerkaufMenuId,
		getRealtyPhases
  };
};
