import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import LoadingSpinner from '../../LoadingSpinner';
function ObjectRequestWiderruf({ submit, error }) {
  
  return (
    <>
      <h2 className="form__title">Widerrufserklärung</h2>
      <p>
        Widerrufsbelehrung und Rücktrittsrechte Der Interessent wird informiert, dass für einen
        Verbraucher bei Abschluss des Maklervertrags außerhalb der Geschäftsräume des Maklers oder
        ausschließlich über Fernabsatz gemäß § 11 FAGG ein Rücktrittsrecht von dieser Vereinbarung
        binnen 14 Tagen besteht. Die Rücktrittsfrist beginnt mit dem Tag des Vertragsabschlusses.
        Die Abgabe der Widerrufserklärung kann unter Verwendung des beigestellten Widerrufsformulars
        erfolgen, ist aber an keine Form gebunden.
      </p>

      <p className="mt-150rem">
        Wenn der Makler vor Ablauf dieser vierzehntägigen Rücktrittsfrist vorzeitig tätig werden
        soll (zB Übermittlung von Detailinformationen, Vereinbarung eines Besichtigungstermins),
        bedarf es einer ausdrücklichen Aufforderung durch den Interessenten, der damit - bei
        vollständiger Vertragserfüllung innerhalb dieser Frist - sein Rücktrittsrecht verliert.
      </p>

      <p className="mt-150rem">
        Als vollständige Dienstleistungserbringung des Immobilienmaklers genügt aufgrund eines
        abweichenden Geschäftsgebrauchs die Namhaftmachung der Geschäftsgelegenheit, insbesondere
        sofern vom Interessenten keine weiteren Tätigkeiten des Maklers gewünscht oder ermöglicht
        werden. In diesem Fall kann der Maklervertrag nicht mehr widerrufen werden und ist Grundlage
        eines Provisionsanspruches, wenn es in der Folge zum Abschluss eines Rechtsgeschäftes über
        eine vom Immobilienmakler namhaft gemachte Geschäftsgelegenheit kommt.
      </p>

      <p className="mt-150rem mb-150rem">
        Im Fall eines Rücktritts nach & 11 FAGG verpflichtet sich der Verbraucher, von den
        gewonnenen Informationen keinen Gebrauch zu machen.
      </p>

      <a
        href={require('../../../assets/documents/Widerrufsformular.pdf')}
        className="textlink"
        download
      >
        Widerrufserklärung herunterladen
      </a>

      <LoadingSpinner />
      <div className="button-panel pt-200rem">
        <ButtonForwardAction
          buttonText="Bestätigen"
          forwardAction={submit}
          preventEnter={true}
        />
      </div>
      {error && <p className="form__fielderror">{error}</p>}
    </>
  );
}

export default ObjectRequestWiderruf;
