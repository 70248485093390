import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

// FORM FIELDS
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import FormSelectPhoneNumberRHF from '../../FormFields/customFields/FormSelectPhoneNumberRHF';
import FormCheckbox from '../../FormFields/FormCheckbox';
import FormInput from '../../FormFields/FormInput';
import FormRadio from '../../FormFields/FormRadio';
import FormSelect from '../../FormFields/FormSelect';
import Textarea from '../../FormFields/Textarea';
import FormTextDsgvo from '../../FormFields/util/formTextDsgvo';
import LoadingSpinner from '../../LoadingSpinner';
import InfoDialogBundesland from '../../Dialogs/InfoDialogBundesland';
import statesWithZips from '../../FormFields/util/statesWithZips';

import { anliegenOptions, anliegenOptionsNotLoggedIn, genderOptions } from '../../FormFields/util/formOptions';

// STORE

import {
  CONTACTFORM_ANLIEGEN_ALLG,
  CONTACTFORM_ANLIEGEN_PROFIL,
  CONTACTFORM_ANLIEGEN_PROFIL_DEAKTIVIEREN,
  CONTACTFORM_ANLIEGEN_SUCHE,
  CONTACTFORM_SITEINDEX_INFO_BUNDESLAND,
} from '../../../constants';
import { checkIfShortRouteContactForm } from '../../../util/generalUtils';

function ContactMain({ handleClose, nextStep, submit, setWindowHash, error }) {
  let user = useSelector((state) => state.user);

  /* FORM HANDLING */
  const {
    control,
    watch,
    handleSubmit,
    resetField,
    formState: { errors },
  } = useFormContext();
  const reason = watch('reason');
  const createUser = watch('createUser');

  /**
   * SET SHORT ROUTE
   * sets short route according to reason
   */
  const [shortRoute, setShortRoute] = useState(true);
  useEffect(() => {
    const isShort = checkIfShortRouteContactForm(reason);
    setShortRoute(isShort);
  }, [reason]);
  /* SET SHORT ROUTE END */

  /* reset password when createUser is false */
  useEffect(() => {
    if (!createUser) {
      resetField('password');
      resetField('passwordConfirm');
    }
  }, [createUser, resetField]);

  /**
   * OPEN INFO DIALOG FOR BUNDESLAND
   * also appends correct window hash
   */
  const [openInfoBundesland, setOpenInfoBundesland] = useState(false);
  const handleSetOpenBundeslandInfo = () => {
    let newVal = !openInfoBundesland;
    if (newVal) {
      window.location.hash = CONTACTFORM_SITEINDEX_INFO_BUNDESLAND;
    } else {
      setWindowHash();
    }
    setOpenInfoBundesland(newVal);
  };
  /* OPEN INFO DIALOG FOR BUNDESLAND END */

  return (
    <>
      <h3 className="form__title">Anfrage</h3>
      {reason?.id === CONTACTFORM_ANLIEGEN_PROFIL_DEAKTIVIEREN ? (
        <p>Wir bedauern, dass Sie Ihr Profil deaktivieren möchten. Wenn Sie unseren Service wieder benötigen, sind wir gerne wieder für Sie da.</p>
      ) : (
        <p>Wir beraten Sie gern individuell zu Ihrem Anliegen. Bitte füllen Sie dafür das Kontaktformular aus. Wir melden uns zeitnah bei Ihnen.</p>
      )}

      {!localStorage.getItem('profildeaktivieren-clicked') && localStorage.getItem('profildeaktivieren-clicked') !== true ? (
        <>
          <h3 className="form__title pt-100rem">Wählen Sie Ihr Anliegen aus</h3>
          <Controller
            name="reason"
            control={control}
            rules={{ required: 'Bitte wählen Sie Ihr Anliegen aus.' }}
            render={({ field }) => (
              <FormSelect id="reason" value={field.value} callback={field.onChange} error={errors.reason?.message} options={user.isLoggedIn ? anliegenOptions : anliegenOptionsNotLoggedIn} />
            )}
          />
        </>
      ) : null}

      <h3 className="form__title">Ihre Daten</h3>
      <Controller
        name="gender"
        control={control}
        rules={{ required: 'Bitte wählen Sie eine Anrede aus.' }}
        render={({ field }) => <FormRadio id="gender" options={genderOptions} error={errors.gender?.message} selectedValue={field.value} callback={field.onChange} />}
      />

      <div className="form__row form__row--double">
        <Controller name="titleBefore" control={control} render={({ field }) => <FormInput id="titleBefore" label={'Titel (vorgestellt)'} value={field.value} callback={field.onChange} />} />

        <Controller name="titleAfter" control={control} render={({ field }) => <FormInput id="titleAfter" label={'Titel (nachgestellt)'} value={field.value} callback={field.onChange} />} />
      </div>

      <Controller
        name="firstName"
        control={control}
        rules={{ required: 'Bitte geben Sie einen Vornamen ein.' }}
        render={({ field }) => <FormInput id="firstName" label={'Vorname'} value={field.value} error={errors.firstName?.message} required={true} callback={field.onChange} />}
      />

      <Controller
        name="lastName"
        control={control}
        rules={{ required: 'Bitte geben Sie einen Nachnamen ein.' }}
        render={({ field }) => <FormInput id={'lastName'} label={'Nachname'} value={field.value} error={errors.lastName?.message} required={true} callback={field.onChange} />}
      />

      <Controller
        name="email"
        control={control}
        rules={{
          required: 'Bitte geben Sie eine E-Mail-Adresse ein.',
          pattern: {
            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
            message: 'Die E-Mail-Adresse ist ungültig.',
          },
        }}
        render={({ field }) => <FormInput id="email" type={'email'} label={'E-Mail-Adresse'} value={field.value} error={errors.email?.message} required={true} callback={field.onChange} />}
      />

      <FormSelectPhoneNumberRHF />

      {reason?.id === CONTACTFORM_ANLIEGEN_ALLG || reason?.id === CONTACTFORM_ANLIEGEN_SUCHE ? (
        <>
          <Controller
            name="state"
            control={control}
            rules={{ required: 'Bitte wählen Sie ein Bundesland aus.' }}
            render={({ field }) => (
              <FormSelect
                id="state"
                label={'Bundesland'}
                value={field.value}
                callback={field.onChange}
                error={errors.state?.message}
                options={statesWithZips.map((state) => {
                  return {
                    id: state.justImmoId,
                    text: state.label,
                  };
                })}
                required={true}
              />
            )}
          />

          <span className="textlink font-80" onClick={handleSetOpenBundeslandInfo}>
            Welches Bundesland wird benötigt?
          </span>
        </>
      ) : null}

      {!!shortRoute ? (
        <>
          {reason?.id === CONTACTFORM_ANLIEGEN_PROFIL ? (
            <h3 className="form__title pt-200rem">Bitte geben Sie Ihren Änderungswunsch bekannt.</h3>
          ) : (
            <h3 className="form__title pt-200rem">Ihre Nachricht (optional)</h3>
          )}

          <Controller name="message" control={control} render={({ field }) => <Textarea id="message" value={field.value} callback={field.onChange} />} />

          {!user.isLoggedIn && (
            <>
              <h3 className="form__title pt-100rem">Zustimmungen</h3>
              <Controller
                name="privacyAggrement"
                control={control}
                rules={{ required: 'Dies ist ein Pflichtfeld, bitte akzeptieren.' }}
                render={({ field }) => (
                  <FormCheckbox id="privacyAggrement" label={<FormTextDsgvo />} required={true} value={field.value} error={errors.privacyAggrement?.message} callback={field.onChange} />
                )}
              />

              <Controller
                name="createUser"
                control={control}
                render={({ field }) => (
                  <FormCheckbox
                    id="createUser"
                    label={'Jetzt direkt für das sREAL Kundenportal registrieren und von den vielen Vorteilen profitieren.'}
                    value={field.value}
                    callback={field.onChange}
                  />
                )}
              />

              {createUser === true && (
                <>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: 'Bitte geben Sie ein Passwort ein.',
                      minLength: {
                        value: 8,
                        message: 'Mindestens 8 Zeichen erforderlich',
                      },
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/,
                        message: 'Passwort ungültig. Beachten Sie die Anforderungen.',
                      },
                    }}
                    render={({ field }) => <FormInput type="password" id="password" label="Passwort" value={field.value} callback={field.onChange} error={errors.password?.message} required={true} />}
                  />

                  <span className="form--info">Mindestens 8 Zeichen, 1 Klein- und Großbuchstabe, 1 Ziffer und 1 Sonderzeichen</span>

                  <Controller
                    name="passwordConfirm"
                    control={control}
                    rules={{
                      required: 'Bitte geben Sie ein Passwort ein.',
                      validate: (value) => value === watch('password') || 'Die Passwörter stimmen nicht überein.',
                    }}
                    render={({ field }) => (
                      <FormInput
                        type="password"
                        id="passwordConfirm"
                        label="Passwort wiederholen"
                        value={field.value}
                        callback={field.onChange}
                        error={errors.passwordConfirm?.message}
                        required={true}
                      />
                    )}
                  />
                </>
              )}
            </>
          )}

          <LoadingSpinner />

          <div className="button-panel pt-150rem">
            <button className="button button--gray-outline" onClick={handleClose}>
              Zurück
            </button>
            <ButtonForwardAction buttonText="Nachricht senden" forwardAction={submit} buttonStyle="blue" />
          </div>

          {error && <p className="form__fielderror">{error}</p>}
        </>
      ) : (
        <>
          <div className="button-panel pt-150rem">
            <ButtonForwardAction forwardAction={handleSubmit(() => nextStep())} buttonStyle={'blue'} buttonText={'Weiter'} />
          </div>
          {error && <p className="form__fielderror">{error}</p>}
        </>
      )}

      <InfoDialogBundesland open={openInfoBundesland} handleClose={handleSetOpenBundeslandInfo} />
    </>
  );
}

export default ContactMain;
