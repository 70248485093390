import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfoDialog from './InfoDialog';
import LoadingSpinner from '../LoadingSpinner';

// API
import { putSearchProfileActive } from '../../api/JustImmo';

import { fetchErrorText } from '../FormFields/util/formTexts';
import { setFormIsLoading } from '../../reducers/app';
import { SEARCHPROFILE_STATE_INACTIVE, SEARCHPROFILE_STATE_ACTIVE } from '../../constants';
import { useFetchSearchprofiles } from '../../hooks/searchprofiles/useFetchSearchprofiles';
import { setCachedSimilarImmos, setLastCachedDate } from '../../reducers/cachedSimilarImmos';

function InfoDialogSuchprofilDeactivate({ open = false, handleClose, suchprofil }) {
	const app = useSelector((state) => state.app);

  const dispatch = useDispatch();
  const [error, setError] = useState('');

  const { getAndSetSearchprofiles, getSearchprofilesByState } = useFetchSearchprofiles();
	let activeSearchprofiles = getSearchprofilesByState(SEARCHPROFILE_STATE_ACTIVE);

  useEffect(() => {
    setError('');
  }, [open]);

  const handleActivateOrDeactivateSuchprofil = async (suchprofil) => {
    dispatch(setFormIsLoading(true));
    setError('');

		if(app.demoMode) {
			setTimeout(() => {
				dispatch(setFormIsLoading(false));
				handleClose();
			}, 1000);

			return;
		}


    //? Wenn Suchprofil deaktiviert ist, muss geschaut werden, ob bereits drei Suchprofile aktiv sind
    //? Wenn ja, dann darf das Suchprofil nicht aktiviert werden
    if (suchprofil.state === SEARCHPROFILE_STATE_INACTIVE) {
      
      if (activeSearchprofiles.length >= 3) {
        dispatch(setFormIsLoading(false));
        setError('Sie können maximal drei Suchprofile aktivieren. Deaktivieren Sie bitte zuerst ein anderes Suchprofil, um dieses zu aktivieren.');
        return;
      }
    }

    try {
      await putSearchProfileActive(suchprofil.id, suchprofil.state === SEARCHPROFILE_STATE_ACTIVE ? false : true);
      await getAndSetSearchprofiles(false);
      dispatch(setCachedSimilarImmos([]));
      dispatch(setLastCachedDate(null));

      getSearchprofilesByState();
      dispatch(setFormIsLoading(false));
      handleClose();
    } catch (e) {
      dispatch(setFormIsLoading(false));
      setError(fetchErrorText);
      console.error('e', e);
    }
  };

  return (
    <InfoDialog handleClose={handleClose} headline={suchprofil.state === SEARCHPROFILE_STATE_INACTIVE ? 'Suchprofil aktivieren' : 'Suchprofil deaktivieren'} open={open} disableESC={true}>
      

			{ activeSearchprofiles.length >= 3 && suchprofil.state === SEARCHPROFILE_STATE_INACTIVE && !app.demoMode ? (
				<div className="error-container">
					Sie haben bereits 3 aktive Suchprofile. Bitte deaktivieren Sie mindestens eines Ihrer Suchprofile, um ein neues erstellen zu können.
				</div>
			) : (
				<>
					<p>Sind Sie sicher, dass Sie dieses Suchprofil {suchprofil.state === SEARCHPROFILE_STATE_INACTIVE ? 'aktivieren' : 'deaktivieren'} wollen?</p>
			
					<LoadingSpinner />
					
					<div className="button-panel">
						<button
							className="button button--blue"
							onClick={async () => {
								await handleActivateOrDeactivateSuchprofil(suchprofil);
							}}
						>
							{suchprofil.state === SEARCHPROFILE_STATE_INACTIVE ? 'Aktivieren' : 'Deaktivieren'}
						</button>
						<button className="button button--gray-outline" onClick={handleClose}>
							Abbrechen
						</button>
					</div>

					{error && <p className="form__fielderror">{error}</p>}
				</>
			)}
    </InfoDialog>
  );
}

export default InfoDialogSuchprofilDeactivate;
