import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  kaufanboteList: [],
};

export const kaufAnboteSlice = createSlice({
  name: 'kaufAnbote',
  initialState,
  reducers: {
    setKaufanboteList: (state, action) => {
      state.kaufanboteList = action.payload;
    },
    setResetFieldsKaufanbotForm: (state) => {
      return initialState;
    }
  }
});

export const { 
  setKaufanboteList,
  setResetFieldsKaufanbotForm
} = kaufAnboteSlice.actions;

export default kaufAnboteSlice.reducer;
