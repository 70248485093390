import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import PageDialog from './PageDialog';

import { closePageDialog, setFormIsLoading, setPageDialogOpenId } from '../../reducers/app';

import {
	CONDITION_NEW_OR_RECENTLY_RENOVATED,
	CONDITION_RENOVATION_NEEDED,
	CONDITION_WELL_MAINTAINED,
	CONTACTFORM_ANLIEGEN_ALLG,
	CONTACTFORM_ANLIEGEN_PROFIL,
	CONTACTFORM_ANLIEGEN_PROFIL_DEAKTIVIEREN,
	CONTACTFORM_ANLIEGEN_SUCHE,
	CONTACTFORM_ANLIEGEN_SUPPORT,
	CONTACTFORM_SITEINDEX_EMAILSENT,
	CONTACTFORM_SITEINDEX_IHREIMMOBILIE,
	CONTACTFORM_SITEINDEX_KONTAKTIEREN,
	CONTACTFORM_SITEINDEX_MAIN,
	CONTACTFORM_SITEINDEX_TERMINWUNSCH,
	PAGEDIALOG_CONTACT,
} from '../../constants';

// STEPS
import { anliegenOptions, phoneNumberPrefixOptions } from '../FormFields/util/formOptions';
import ContactEmailSent from '../Forms/ContactRHF/ContactEmailSent';
import ContactIhreImmobilie from '../Forms/ContactRHF/ContactIhreImmobilie';
import ContactIhreNachricht from '../Forms/ContactRHF/ContactIhreNachricht';
import ContactMain from '../Forms/ContactRHF/ContactMain';
import ContactTerminwunsch from '../Forms/ContactRHF/ContactTerminwunsch';

// API
import { send } from '../../api/Contact';
import { formatAppointmentForRequestbody } from '../../util/datepickerUtils';
import { checkIfHashIsPagedialog, checkIfShortRouteContactForm, findPhonePrefix, isJson } from '../../util/generalUtils';
import { fetchErrorText, registerUsernameAlreadyExists } from '../FormFields/util/formTexts';
import { demoUserObject } from '../../demodata/demouser';

function PageDialogContactForm() {
  const app = useSelector((state) => state.app);
	const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const methods = useForm(); // Provides form state & methods

  const defaulHeadline = 'Kontaktieren & beauftragen';
  const [headline, setHeadline] = useState(defaulHeadline);
  const [step, setStep] = useState(CONTACTFORM_SITEINDEX_MAIN);
	const [error, setError] = useState('');
	const reason = methods.watch('reason');
	
	const austrianPrefix = phoneNumberPrefixOptions.find((option) => option.id === 43);
	
	const initialFormState = useMemo(() => {
		let phoneNum = '';
		let phonePrefix = austrianPrefix;

		let currentUserObject = app.root === '/demo' ? demoUserObject : user.userObject;
		if(currentUserObject.phoneNumber) {
			phoneNum = currentUserObject.phoneNumber;
			const foundPrefix = findPhonePrefix(phoneNum);
			
			if(foundPrefix) {
				phonePrefix = foundPrefix;
				const prefixLength = (foundPrefix.id).toString().length + 1; // + 1 for '+' character
        phoneNum = currentUserObject.phoneNumber.substring(prefixLength);
			}
		}

		return {
			reason: anliegenOptions[CONTACTFORM_ANLIEGEN_ALLG],
			gender: currentUserObject?.gender || '',
			titleBefore: currentUserObject?.titleBefore || '',
			titleAfter: currentUserObject?.titleAfter || '',
			firstName: currentUserObject?.firstName || '',
			lastName: currentUserObject?.lastName || '',
			email: currentUserObject?.userName || '',
			phoneNumberPrefix: phonePrefix,
			phoneNumber: phoneNum,
			privacyAggrement: user.isLoggedIn ? true : false,
		}
	}, [user.userObject, app.root, user.isLoggedIn, austrianPrefix]);

	/**
	 * SET WINDOW HASH
	 * window hash changes when "reason" or step changes
	 * used for tracking
	 */
	const setWindowHash = useCallback(() => {
    if (app.pageDialogOpenId !== PAGEDIALOG_CONTACT) return;

    const hash = reason?.text 
      ? `${step}-${reason.text.toLowerCase()
          .replace(/\s+/g, '-')
          .replace(/ä/g, 'ae')
          .replace(/ö/g, 'oe')
          .replace(/ü/g, 'ue')}`
      : step.toString();

			window.location.hash = hash;
    
  }, [app.pageDialogOpenId, reason, step]);

  useEffect(() => {
		setWindowHash();
  }, [reason, step, app.pageDialogOpenId, dispatch, setWindowHash]);

	useEffect(() => {
		if(app.pageDialogOpenId === '' && checkIfHashIsPagedialog(CONTACTFORM_SITEINDEX_MAIN)) {		
			dispatch(setPageDialogOpenId(PAGEDIALOG_CONTACT));
		}
	}, [window.location.hash]);
	/* SET WINDOW HASH END */


	/**
	 * RESET FORM
	 * clear all form data and set step to 1
	 */
	const resetForm = useCallback(() => {
    setStep(CONTACTFORM_SITEINDEX_MAIN);
		
    methods.reset({
			...initialFormState,
			password: '',
    	passwordConfirm: '',
			message: '',
			appointmentDate: '',
			appointmentTime: '',
			appointmentDate2: '',
			appointmentTime2: '',
			state: '',
			postalCode: '',
			area: '',
			condition: '',
			type: '',
			createUser: false,
			createJustImmoContact: false,
			file: null
		});

		setError('');
  }, [methods, initialFormState]);
	/* RESET FORM END */


	/**
	 * HANDLE CLOSE
	 * closes Pagedialog, resets formIsLoading state to false and resets form
	 */
  const handleClose = useCallback(() => {
    if (app.formIsLoading) return;

    localStorage.removeItem('profildeaktivieren-clicked');

    dispatch(closePageDialog());
    resetForm();
  }, [app.formIsLoading, dispatch, resetForm]);

	// reset form when opened
	useEffect(() => {
		if(app.pageDialogOpenId === PAGEDIALOG_CONTACT) {
			resetForm();

			// set reason if in localStorage
			const lsReason = localStorage.getItem('mysreal_contact_reason');
			if(lsReason && anliegenOptions[lsReason]) {
				methods.setValue('reason', anliegenOptions[lsReason]);
				localStorage.removeItem('mysreal_contact_reason');
			}

			const lsImmo = localStorage.getItem('mysreal_contact_immo');
			if(lsImmo && isJson(lsImmo)){
				const immoValues = JSON.parse(lsImmo);
				methods.setValue('type', immoValues.type);
				methods.setValue('area', immoValues.area);
				methods.setValue('condition', immoValues.condition);
				methods.setValue('postalCode', immoValues.postalCode);
				methods.setValue('state', immoValues.state);

				localStorage.removeItem('mysreal_contact_immo');
			}
		}
	}, [app.pageDialogOpenId, resetForm, methods])
	/* HANDLE CLOSE END */

	/**
	 * HANDLE STEP OF FORM
	 * evaluates which step is shown (prev/next)
	 * sets the headline accordign to new step
	 */
	const headlineMap = useMemo(() => ({
		[CONTACTFORM_SITEINDEX_MAIN]: defaulHeadline,
		[CONTACTFORM_SITEINDEX_TERMINWUNSCH]: 'Terminwunsch',
		[CONTACTFORM_SITEINDEX_IHREIMMOBILIE]: 'Immobilie',
		[CONTACTFORM_SITEINDEX_KONTAKTIEREN]: reason?.id === CONTACTFORM_ANLIEGEN_SUPPORT ? 'Bundesland' : 'Nachricht',
		[CONTACTFORM_SITEINDEX_EMAILSENT]: reason?.id === CONTACTFORM_ANLIEGEN_SUPPORT ? 'Bundesland' : 'Nachricht'
	}), [defaulHeadline, reason]);

  const setStepForm = useCallback((direction = 'next') => {
      const values = methods.getValues();
      const reason = values.reason;

      let currentStep = step;
      let newStep = step;

      if (direction === 'next') {
				
        if (currentStep === CONTACTFORM_SITEINDEX_MAIN) {
					if (reason?.id === CONTACTFORM_ANLIEGEN_SUPPORT) {
						newStep = CONTACTFORM_SITEINDEX_KONTAKTIEREN;
					}
					else {
						newStep = CONTACTFORM_SITEINDEX_TERMINWUNSCH;
					}
        } else if (currentStep === CONTACTFORM_SITEINDEX_TERMINWUNSCH) {
          newStep = CONTACTFORM_SITEINDEX_IHREIMMOBILIE;
        } else if (currentStep === CONTACTFORM_SITEINDEX_IHREIMMOBILIE) {
          newStep = CONTACTFORM_SITEINDEX_KONTAKTIEREN;
        } else if (currentStep === CONTACTFORM_SITEINDEX_KONTAKTIEREN) {
          newStep = CONTACTFORM_SITEINDEX_EMAILSENT;
        }
      } else if (direction === 'prev') {
        if (reason?.id === CONTACTFORM_ANLIEGEN_SUPPORT) {
          newStep = CONTACTFORM_SITEINDEX_MAIN;
        } else if (currentStep === CONTACTFORM_SITEINDEX_MAIN) {
          handleClose();
          return;
        } else if (currentStep === CONTACTFORM_SITEINDEX_TERMINWUNSCH) {
          newStep = CONTACTFORM_SITEINDEX_MAIN;
        } else if (currentStep === CONTACTFORM_SITEINDEX_IHREIMMOBILIE) {
          newStep = CONTACTFORM_SITEINDEX_TERMINWUNSCH;
        } else if (currentStep === CONTACTFORM_SITEINDEX_KONTAKTIEREN) {
          newStep = CONTACTFORM_SITEINDEX_IHREIMMOBILIE;
        } else if (currentStep === CONTACTFORM_SITEINDEX_EMAILSENT) {
          handleClose();
          return;
        }
      }

      setStep(newStep);
			setHeadline(headlineMap[newStep] || defaulHeadline);
    },
    [step, handleClose, methods, headlineMap],
  );

	const handlePrevStep = useCallback(() => setStepForm('prev'), [setStepForm]);
	/* HANDLE STEP OF FORM END */

	/**
	 * HANDLE SUBMIT
	 * send data to BE
	 */

  const onSubmit = useCallback(async (data) => {
		if(app.demoMode) {
			setStep(CONTACTFORM_SITEINDEX_EMAILSENT);
			setHeadline(headlineMap[CONTACTFORM_SITEINDEX_EMAILSENT]);
			return;
		}

		dispatch(setFormIsLoading(true));
		setError('');

		let	appointment = formatAppointmentForRequestbody(data.appointmentDate, data.appointmentTime);
		let	appointment2 = formatAppointmentForRequestbody(data.appointmentDate2, data.appointmentTime2);

		let postalCode = '';
		if(data.postalCode?.label) {
			postalCode = data.postalCode.label.split(' ')[0];
		}

		let condition = 0;
		if(parseInt(data.condition) > 0) {
			switch (data.condition) {
				case 1:
					condition = CONDITION_RENOVATION_NEEDED;
					break;
				case 2:
					condition = CONDITION_WELL_MAINTAINED;
					break;
				case 3:
					condition = CONDITION_NEW_OR_RECENTLY_RENOVATED;
					break;
				default:
					condition = 0;
					break;
				}
		}

		let createJustImmoContact = false;
		if (data.reason?.id === CONTACTFORM_ANLIEGEN_SUCHE && data.createUser === true) {
			createJustImmoContact = true;
		}

		let type = data.type?.text || '';
		let area = data.area || 0;
		let state = data.state?.text || '';

		// reset values in long route if short route is active
		const isShort = checkIfShortRouteContactForm(data.reason);
		if(isShort) {
			// termin
			appointment = '';
			appointment2 = '';

			// immobilie
			postalCode = '';
			type = '';
			area = 0;
			condition = 0;

			if(data.reason?.id === CONTACTFORM_ANLIEGEN_PROFIL || data.reason?.id === CONTACTFORM_ANLIEGEN_PROFIL_DEAKTIVIEREN) {
				state = '';
			}
		}

		// reset values from termin and immobilie if reason is SUPPORT
		if(data.reason?.id === CONTACTFORM_ANLIEGEN_SUPPORT) {
			appointment = '';
      appointment2 = '';

      postalCode = '';
      type = '';
      area = 0;
      condition = 0;
		}

		let requestBody = {
			register: {
				gender: data.gender || '',
				titleBefore: data.titleBefore || '',
				titleAfter: data.titleAfter || '',
				firstName: data.firstName || '',
				lastName: data.lastName || '',
				email: data.email || '',
				phoneNumber: (data.phoneNumberPrefix?.id || '') + ((data.phoneNumber || '').replace(/\s+/g, '')),
				password: data.password || '',
				privacyAggrement: !!data.privacyAggrement,
			},
			reason: data.reason?.text || '',
			message: data.message || '',
			appointment: appointment,
			appointment2: appointment2,
			state: state,
			postalCode: postalCode,
			area: area,
			condition: condition,
			type: type,
			createUser: !!data.createUser,
			createJustImmoContact: createJustImmoContact,
		};

		const file = data.file || null;

		try {
			await send(requestBody, file);
		} catch (e) {
			if (e.response?.data?.detail?.includes('already taken')) {
				setError(registerUsernameAlreadyExists);
			} else {
				setError(fetchErrorText);
			}
		}

		dispatch(setFormIsLoading(false));
		setStep(CONTACTFORM_SITEINDEX_EMAILSENT);
		setHeadline(headlineMap[CONTACTFORM_SITEINDEX_EMAILSENT]);
	}, [headlineMap, dispatch]);

  return (
    <PageDialog
      open={app.pageDialogOpenId === PAGEDIALOG_CONTACT}
      handleClose={handleClose}
      handleBack={handlePrevStep}
      headline={headline}
      showBackArrow={step !== CONTACTFORM_SITEINDEX_MAIN && step !== CONTACTFORM_SITEINDEX_EMAILSENT}
    >
      <FormProvider {...methods}>
        <div className="contactForm p-20">
          {step === CONTACTFORM_SITEINDEX_MAIN ? (
            <ContactMain handleClose={handleClose} nextStep={setStepForm} submit={methods.handleSubmit(onSubmit)} setWindowHash={setWindowHash} error={error} />
          ) : step === CONTACTFORM_SITEINDEX_TERMINWUNSCH ? (
            <ContactTerminwunsch nextStep={setStepForm} prevStep={handlePrevStep} />
          ) : step === CONTACTFORM_SITEINDEX_IHREIMMOBILIE ? (
            <ContactIhreImmobilie nextStep={setStepForm} prevStep={handlePrevStep} setWindowHash={setWindowHash} />
          ) : step === CONTACTFORM_SITEINDEX_KONTAKTIEREN ? (
            <ContactIhreNachricht submit={methods.handleSubmit(onSubmit)} prevStep={handlePrevStep} error={error} />
          ) : step === CONTACTFORM_SITEINDEX_EMAILSENT ? (
            <ContactEmailSent handleClose={handleClose} />
          ) : null}
        </div>
      </FormProvider>
    </PageDialog>
  );
}

export default PageDialogContactForm;
