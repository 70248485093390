function IconLoadingSpinner({ color, cssClasses }) {
  return (
    <svg className={cssClasses} width="23.24" height="18" viewBox="0 0 48 46" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M47.9 34.6003L46.2 27.7003C46.2 27.6003 46.1 27.5003 46.1 27.4003C46.1 27.3003 46 27.2003 46 27.1003C46 27.1003 46 27.0003 45.9 27.0003C45.9 26.9003 45.8 26.9003 45.7 26.8003C45.7 26.8003 45.7 26.7003 45.6 26.7003C45.4 26.5003 45.2 26.4003 45 26.4003C44.9 26.4003 44.8 26.3003 44.7 26.3003C44.6 26.3003 44.6 26.3003 44.5 26.3003H44.4C44.3 26.3003 44.2 26.3003 44.1 26.3003L37.3 28.0003C36.4 28.2003 35.8 29.2003 36.1 30.1003C36.3 31.0003 37.3 31.6003 38.2 31.3003L41.7 30.4003C38.1 37.2003 31.5 41.4003 24 41.9003C16.1 41.9003 8.99996 36.9003 6.29996 29.5003C5.99996 28.6003 4.99996 28.2003 4.09996 28.5003C3.19996 28.8003 2.79996 29.8003 3.09996 30.7003C6.29996 39.5003 14.7 45.4003 24.1 45.4003C32.5 44.9003 39.9 40.3003 44.1 33.3003L44.7 35.5003C44.9 36.3003 45.6 36.8003 46.4 36.8003C46.5 36.8003 46.7 36.8003 46.8 36.7003C47.6 36.4003 48.2 35.5003 47.9 34.6003Z"
        fill={color}
      />
      <path
        d="M2.90001 19.5002C3.10001 19.6002 3.30001 19.6002 3.50001 19.6002C3.60001 19.6002 3.80001 19.6002 3.90001 19.5002L10.8 17.8002C11.7 17.6002 12.3 16.6002 12 15.7002C11.8 14.8002 10.8 14.2002 9.90001 14.5002L6.60001 15.3002C10 9.50019 16.8 4.1002 24 4.1002C32 4.1002 39.1 9.2002 41.7 16.7002C42 17.6002 43 18.1002 43.9 17.7002C44.8 17.3002 45.3 16.4002 44.9 15.5002C41.8 6.7002 33.4 0.700195 24 0.700195C15.8 0.700195 8.10001 6.5002 4.00001 13.0002L3.40001 10.6002C3.20001 9.7002 2.20001 9.1002 1.30001 9.4002C0.400012 9.6002 -0.199988 10.6002 0.100012 11.5002L1.80001 18.4002C1.80001 18.6002 1.90001 18.7002 2.00001 18.9002C2.00001 18.9002 2.00001 18.9002 2.00001 19.0002C2.10001 19.1002 2.20001 19.3002 2.30001 19.4002C2.30001 19.4002 2.40001 19.4002 2.40001 19.5002C2.60001 19.3002 2.70001 19.4002 2.90001 19.5002Z"
        fill={color}
      />
    </svg>
  );
}

export default IconLoadingSpinner;
