//*React
import moment from 'moment';
import { useState, useEffect } from 'react';
//*Components
import PageDialogChat from '../Dialogs/PageDialogChat';
import Linkitem from '../Linkitems/Linkitem';
//*Icons
import IconChat2 from '../Icons/IconChat2';
//*Utils
import { parseDate, parseTime } from '../../util/datetimeParser';
//*Constants
import AktuellesLinkitems from './AktuellesLinkitems';

function AktuellesNachrichten({ cssClasses = '', thread, reloadMessagesCallback, onThreadClick, handleCloseDialog }) {
  const getDisplayDate = (thread) => {
    let date = parseDate(thread.sentAt);
    let isToday = moment(thread.sentAt).isSame(new Date(), 'day');
    if (isToday) {
      date = parseTime(thread.sentAt) + ' Uhr';
    }

    return date;
  };

  const navigateToChat = () => {		
    onThreadClick(thread[0].id);
  };

  let lastChatItem = thread[thread.length - 1];

  let unreadAmount = false;
  const unreadItems = thread.filter((t) => t.author === 'sreal' && !t.isRead);
  if (unreadItems.length === 1) {
    unreadAmount = true;
  } else if (unreadItems.length > 1) {
    unreadAmount = unreadItems.length;
  }

  // render last 3 items to show as linkitems (mobile)
  let nextToLastChatItem = thread[thread.length - 2];
  let nextNextToLastChatItem = thread[thread.length - 3];

  return (
    <>
      <AktuellesLinkitems date={getDisplayDate(lastChatItem)} key={thread.id}>
        <Linkitem
          cssClasses={cssClasses}
          key={thread.id + '-start'}
          handleLink={() => navigateToChat(thread)}
          icon={<IconChat2 />}
          unread={unreadAmount}
          dateOrState={getDisplayDate(lastChatItem)}
          headline={lastChatItem.subject}
          text={lastChatItem.body}
          showArrow={false}
          ebene="ebene0"
        />

        {nextToLastChatItem && (
          <Linkitem
            key={nextToLastChatItem.id}
            handleLink={() => navigateToChat(thread)}
            icon={<IconChat2 />}
            unread={!nextToLastChatItem.isRead}
            dateOrState={getDisplayDate(nextToLastChatItem)}
            headline={nextToLastChatItem.subject}
            text={nextToLastChatItem.body}
            showArrow={false}
            ebene={'ebene1'}
          />
        )}

        {nextNextToLastChatItem && (
          <Linkitem
            key={nextNextToLastChatItem.id}
            handleLink={() => navigateToChat(thread)}
            icon={<IconChat2 />}
            unread={!nextNextToLastChatItem.isRead}
            dateOrState={getDisplayDate(nextNextToLastChatItem)}
            headline={nextNextToLastChatItem.subject}
            text={nextNextToLastChatItem.body}
            showArrow={false}
            ebene={'ebene2'}
          />
        )}
      </AktuellesLinkitems>
    </>
  );
}

export default AktuellesNachrichten;
