import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MENU_VERKAUF } from '../../constants';
import { useHandleMenu } from '../../hooks/menu/useHandleMenu';
import { setUserRealtyActive } from '../../reducers/user';
import FormSelect from '../FormFields/FormSelect';

function SelectIhreImmobilie() {
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

	const dispatch = useDispatch();
	const navigate = useNavigate();

  const [ihreImmobilien, setIhreImmobilien] = useState([]);
	const { updateMenu, determineVerkaufMenuId } = useHandleMenu();

  useEffect(() => {
    if (user.userRealties.length > 0 && app.demoMode === false) {
      let immos = user.userRealties
        .filter((r) => r !== null)
        .map((r) => {
          if (!r.id || !r.title) return null;

          return {
            id: r.id,
            text: r.title,
            immo: r,
          };
        })
        .filter((r) => r !== null);

      setIhreImmobilien(immos);
    }
  }, [user.userRealties]);

	const setActiveUserRealty = useCallback((val) => {
		if(!val || !val.immo) return;

		const immo = val.immo;
		dispatch(setUserRealtyActive(immo));

		// dispatch(setMenuType(MENU_VERKAUF)); // this Select is only visible on MENU_VERKAUF anyway
		const newMenuId = determineVerkaufMenuId(null, immo);
		updateMenu(newMenuId);

		if (immo.url) {
			navigate(app.root + '/immobilie/' + immo.url);
		}
	}, [app.root, navigate, determineVerkaufMenuId, updateMenu, dispatch]);

  if (app.menuType === MENU_VERKAUF && user && user.isLoggedIn && ihreImmobilien.length > 0 && !app.demoMode) {
    return (
      <FormSelect
        showBitteWaehlen={false}
        label={ihreImmobilien.length > 1 ? 'Ihre Immobilien' : 'Ihre Immobilie'}
        options={ihreImmobilien}
        id="userRealties"
        formType="userRealties"
        value={user.userRealtyActive?.id ? { id: user.userRealtyActive.id, text: user.userRealtyActive.title } : ''}
        showResetIcon={false}
				callback={(val) => setActiveUserRealty(val)}
      />
    );
  } else {
    return null;
  }
}

export default SelectIhreImmobilie;
