import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonForwardAction from '../Buttons/ButtonForwardAction';
import SatisfactionScale from '../FormFields/SatisfactionScale';

import { motion } from 'framer-motion';
import { createSurveyZufriedenheit } from '../../api/Contact';
import LoadingSpinner from '../LoadingSpinner';
import PageDialog from './PageDialog';

import {
	PAGEDIALOG_SATISFACTION,
	SATISFACTION_OPTIONS,
	SATISFACTION_SITEINDEX_MESSAGE1,
	SATISFACTION_SITEINDEX_MESSAGE2,
	SATISFACTION_SITEINDEX_Q1,
	SATISFACTION_SITEINDEX_Q2,
	SATISFACTION_SITEINDEX_Q3,
	SATISFACTION_SITEINDEX_Q4,
	SATISFACTION_SITEINDEX_Q5,
	SATISFACTION_SITEINDEX_SUCCESS,
} from '../../constants';

import { closePageDialog, setFormIsLoading } from '../../reducers/app';
import { setSatisfactionSurveyCompleted } from '../../reducers/user';
import { fetchErrorText } from '../FormFields/util/formTexts';

function PageDialogSatisfaction() {
  const app = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const initialState = {
    q1: { q: 'Wie zufrieden sind Sie mit der generellen Erfahrung auf my-sreal.at?', text: '', index: -1 },
    q2: { q: 'War die Handhabung und das Interface der Plattform nachvollziehbar und einfach bedienbar?', text: '', index: -1 },
    q3: { q: 'Sind Sie mit dem Umfang des Services auf der Plattform zufrieden?', text: '', index: -1 },
    q4: { q: 'Würden Sie my-sreal.at weiterempfehlen?', text: '', index: -1 },
    q5: { q: 'Hat die zuständige Makler:in ausreichend auf Fragen und Anliegen reagiert?', text: '', index: -1 },
    message1: '',
    message2: '',
  };

  const [step, setStep] = useState(SATISFACTION_SITEINDEX_Q1);
  const [requestBody, setRequestBody] = useState(initialState);
  const handleClose = () => {
    if (app.formIsLoading) return;

    dispatch(closePageDialog());
    setRequestBody(initialState);
    setStep(SATISFACTION_SITEINDEX_Q1);
  };

  const [error, setError] = useState('');

  const pathVariants = {
    hidden: {
      pathLength: 0,
    },
    visible: {
      pathLength: 1,
      transition: {
        duration: 1.5,
        ease: 'easeInOut',
      },
    },
  };

  const forwardAction = async () => {
    if (app.formIsLoading) return;

    setError('');

    let val = '';
    let newStep = '';
    switch (step) {
      case SATISFACTION_SITEINDEX_Q1:
        val = requestBody.q1;
        newStep = SATISFACTION_SITEINDEX_Q2;
        break;
      case SATISFACTION_SITEINDEX_Q2:
        val = requestBody.q2;
        newStep = SATISFACTION_SITEINDEX_Q3;
        break;
      case SATISFACTION_SITEINDEX_Q3:
        val = requestBody.q3;
        newStep = SATISFACTION_SITEINDEX_Q4;
        break;
      case SATISFACTION_SITEINDEX_Q4:
        val = requestBody.q4;
        newStep = SATISFACTION_SITEINDEX_Q5;
        break;
      case SATISFACTION_SITEINDEX_Q5:
        val = requestBody.q5;
        newStep = SATISFACTION_SITEINDEX_MESSAGE1;
        break;
      case SATISFACTION_SITEINDEX_MESSAGE1:
        val = requestBody.message1;
        newStep = SATISFACTION_SITEINDEX_MESSAGE2;
        break;
      case SATISFACTION_SITEINDEX_MESSAGE2:
        val = requestBody.message2;
        newStep = SATISFACTION_SITEINDEX_SUCCESS;
        break;

      default:
        break;
    }

    val = val.text;

    if (!val && step !== SATISFACTION_SITEINDEX_MESSAGE1 && step !== SATISFACTION_SITEINDEX_MESSAGE2) {
      setError('Bitte wählen Sie eine Option aus.');
    } else if (step === SATISFACTION_SITEINDEX_MESSAGE2) {
      dispatch(setFormIsLoading(true));

			if(app.demoMode) {
				setTimeout(() => {
					dispatch(setFormIsLoading(false));
					setStep(SATISFACTION_SITEINDEX_SUCCESS);
				}, 1000);
	
				return;
			}

			try {
				await createSurveyZufriedenheit(requestBody);
				localStorage.setItem('mysreal-satisfaction-isset', true);
      	dispatch(setSatisfactionSurveyCompleted(true));
				dispatch(setFormIsLoading(false));

				setStep(SATISFACTION_SITEINDEX_SUCCESS);
			} catch (error) {
				console.log('error', error);
				setError(fetchErrorText);
				dispatch(setFormIsLoading(false));
			}
      
    } else {
      setStep(newStep);
    }
  };

  const handleBack = () => {
    if (step === SATISFACTION_SITEINDEX_Q1) {
      handleClose();
    } else {
      let newStep = '';
      switch (step) {
        case SATISFACTION_SITEINDEX_Q2:
          newStep = SATISFACTION_SITEINDEX_Q1;
          break;
        case SATISFACTION_SITEINDEX_Q3:
          newStep = SATISFACTION_SITEINDEX_Q2;
          break;
        case SATISFACTION_SITEINDEX_Q4:
          newStep = SATISFACTION_SITEINDEX_Q3;
          break;
        case SATISFACTION_SITEINDEX_Q5:
          newStep = SATISFACTION_SITEINDEX_Q4;
          break;
        case SATISFACTION_SITEINDEX_MESSAGE1:
          newStep = SATISFACTION_SITEINDEX_Q5;
          break;
        case SATISFACTION_SITEINDEX_MESSAGE2:
          newStep = SATISFACTION_SITEINDEX_MESSAGE1;
          break;
        case SATISFACTION_SITEINDEX_SUCCESS:
          newStep = SATISFACTION_SITEINDEX_MESSAGE2;
          break;
        default:
          break;
      }

      if (newStep !== '') {
        setStep(newStep);
      }
    }
  };

  const handleGetIndexValue = (index) => {
    let val = {};
    if (SATISFACTION_OPTIONS[index]) {
      val = { index: index, text: SATISFACTION_OPTIONS[index] };
    }
    return val;
  };

	const updateRequestBody = (key, value) => {
    const newRequestBody = {...requestBody };
		let newVal = value;
		if(key !== 'message1' && key !== 'message2') {
			newVal = handleGetIndexValue(value);
			newRequestBody[key] = {...newRequestBody[key], ...newVal };
		}
		else {
			newRequestBody[key] = value;
		}
		
    setRequestBody(newRequestBody);
  };

  
  return (
    <PageDialog
			open={app.pageDialogOpenId === PAGEDIALOG_SATISFACTION}
      headline={'Zufrieden mit s REAL?'}
      handleClose={handleClose}
      handleBack={handleBack}
      showBackArrow={step !== SATISFACTION_SITEINDEX_Q1 && step !== SATISFACTION_SITEINDEX_SUCCESS ? true : false}
    >
      <div className="p-100rem">
        {step === SATISFACTION_SITEINDEX_Q1 ? (
          <>
            <h2>Frage 1</h2>
            <p className="mb-300rem lh-140">{requestBody.q1.q}</p>

            <SatisfactionScale selectedValue={requestBody.q1.index} callback={(index) => updateRequestBody('q1', index)} />

            <div className="button-panel">
              <ButtonForwardAction forwardAction={forwardAction} buttonStyle={'blue'} buttonText={'Weiter'} />
            </div>
            {error && <p className="form__fielderror">{error}</p>}
          </>
        ) : step === SATISFACTION_SITEINDEX_Q2 ? (
          <>
            <h2>Frage 2</h2>
            <p className="mb-300rem lh-140">{requestBody.q2.q}</p>

            <SatisfactionScale selectedValue={requestBody.q2.index} callback={(index) => updateRequestBody('q2', index)} />

            <div className="button-panel align-items-center">
              <ButtonForwardAction forwardAction={forwardAction} buttonStyle={'blue'} buttonText={'Weiter'} />
              <span className="textlink mb-10 ml-100rem" onClick={handleBack}>
                Zurück
              </span>
            </div>
            {error && <p className="form__fielderror">{error}</p>}
          </>
        ) : step === SATISFACTION_SITEINDEX_Q3 ? (
          <>
            <h2>Frage 3</h2>
            <p className="mb-300rem lh-140">{requestBody.q3.q}</p>

            <SatisfactionScale selectedValue={requestBody.q3.index} callback={(index) => updateRequestBody('q3', index)} />

            <div className="button-panel align-items-center">
              <ButtonForwardAction forwardAction={forwardAction} buttonStyle={'blue'} buttonText={'Weiter'} />
              <span className="textlink mb-10 ml-100rem" onClick={handleBack}>
                Zurück
              </span>
            </div>
            {error && <p className="form__fielderror">{error}</p>}
          </>
        ) : step === SATISFACTION_SITEINDEX_Q4 ? (
          <>
            <h2>Frage 4</h2>
            <p className="mb-300rem lh-140">{requestBody.q4.q}</p>

            <SatisfactionScale selectedValue={requestBody.q4.index} callback={(index) => updateRequestBody('q4', index)} />

            <div className="button-panel align-items-center">
              <ButtonForwardAction forwardAction={forwardAction} buttonStyle={'blue'} buttonText={'Weiter'} />
              <span className="textlink mb-10 ml-100rem" onClick={handleBack}>
                Zurück
              </span>
            </div>
            {error && <p className="form__fielderror">{error}</p>}
          </>
        ) : step === SATISFACTION_SITEINDEX_Q5 ? (
          <>
            <h2>Frage 5</h2>
            <p className="mb-300rem lh-140">{requestBody.q5.q}</p>

            <SatisfactionScale selectedValue={requestBody.q5.index} callback={(index) => updateRequestBody('q5', index)} />

            <div className="button-panel align-items-center">
              <ButtonForwardAction forwardAction={forwardAction} buttonStyle={'blue'} buttonText={'Weiter'} />
              <span className="textlink mb-10 ml-100rem" onClick={handleBack}>
                Zurück
              </span>
            </div>
            {error && <p className="form__fielderror">{error}</p>}
          </>
        ) : step === SATISFACTION_SITEINDEX_MESSAGE1 ? (
          <>
            <h2>Was hat Ihnen an my-sreal.at besonders gefallen?</h2>
            <h3 className="form__title pt-200rem">Ihre Nachricht (optional)</h3>
						<textarea
							id="message1"
							value={requestBody.message1}
							onChange={(val) => updateRequestBody('message1', val.target.value)}
						/>

            <div className="button-panel align-items-center">
              <ButtonForwardAction buttonText={'Weiter'} buttonStyle={'blue'} forwardAction={forwardAction} />
              <span className="textlink mb-10 ml-100rem" onClick={handleBack}>
                Zurück
              </span>
            </div>
            {error && <p className="form__fielderror">{error}</p>}
          </>
        ) : step === SATISFACTION_SITEINDEX_MESSAGE2 ? (
          <>
            <h2>Was würden Sie gerne verbessert sehen?</h2>
            <h3 className="form__title pt-200rem">Ihre Nachricht (optional)</h3>
						<textarea
							id="message2"
							value={requestBody.message2}
							onChange={(val) => updateRequestBody('message2', val.target.value)}
						/>

            <LoadingSpinner />

            <div className="button-panel align-items-center">
              <ButtonForwardAction buttonText={'Abschließen'} buttonStyle={'blue'} forwardAction={forwardAction} />
              <span className="textlink mb-10 ml-100rem" onClick={handleBack}>
                Zurück
              </span>
            </div>
            {error && <p className="form__fielderror">{error}</p>}
          </>
        ) : step === SATISFACTION_SITEINDEX_SUCCESS ? (
          <>
            <div className="d-flex flex-col align-items-center pt-200rem pb-300rem">
              <svg id="Gruppe_17258" data-name="Gruppe 17258" xmlns="http://www.w3.org/2000/svg" width="130" height="130" viewBox="0 0 130 130">
                <g id="Gruppe_17259" data-name="Gruppe 17259">
                  <path
                    id="Pfad_10500"
                    data-name="Pfad 10500"
                    d="M65,0a65,65,0,1,0,65,65A65.191,65.191,0,0,0,65,0m0,120.792A55.792,55.792,0,1,1,120.792,65,56.061,56.061,0,0,1,65,120.792"
                    fill="#0eb43f"
                  />
                  <motion.path
                    id="Pfad_10501"
                    data-name="Pfad 10501"
                    d="M13.84,50.92,33.118,66.333a4.258,4.258,0,0,0,6.34-1.184L69.41,13.672"
                    transform="translate(23.643 23.356)"
                    fill="none"
                    stroke="#0eb43f"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="10.5"
                    variants={pathVariants}
                    initial="hidden"
                    animate="visible"
                  />
                </g>
              </svg>
            </div>

            <h2>Vielen Dank für Ihre Teilnahme an unserer Zufriedenheitsbefragung.</h2>
            <p className="lh-140 pb-100rem">Wir schätzen Ihr Feedback sehr und werden es nutzen, um unseren Service weiter zu verbessern.</p>

            <div className="button-panel pt-200rem">
              <button className="button button--gray-outline" onClick={handleClose}>
                Schließen
              </button>
            </div>
          </>
        ) : null}
      </div>
    </PageDialog>
  );
}

export default PageDialogSatisfaction;
