import FormSelect from '../FormSelect';
import FormInput from '../FormInput';

import { phoneNumberPrefixOptions } from '../util/formOptions';
import { useFormContext, Controller } from "react-hook-form";

function FormSelectPhoneNumberRHF({ disabled = false, hasCheck = false}) {
	const {  control, watch, formState: { errors } } = useFormContext();

	

	/* Custom Phone Number Validation Function */
	const validatePhoneNumber = (phoneNumber, prefix) => {
		if (!prefix) {
			return "Bitte wählen Sie die Vorwahl aus.";
		}

		const phone = phoneNumber.toString().replace(/ \(.*?\)/g, ''); // Remove unwanted characters
		if (phone.length < 7) {
			return "Bitte geben Sie eine gültige Mobilnummer ein.";
		}

		if (prefix.id === 43) {
			const austrianMobileRegex = /^\+43\s?(644|650|660|663|664|665|667|670|676|677|678|680|681|686|688|690|699)\s?\d+/;
			const fullNumber = '+' + prefix.id + phone;
			if (!austrianMobileRegex.test(fullNumber)) {
				return "Die eingegebene Nummer scheint keine österreichische Mobilnummer zu sein.";
			}
		}

		return true; // Validation passed
	};

  return (
			<div className="form__row form__row--double form__row--double--phone">
				<Controller
					name="phoneNumberPrefix"
					control={control}
					rules={{ required: "" }}
					render={({ field }) => (
						<FormSelect
							id="phoneNumberPrefix"
							label={'Vorwahl'}
							value={field.value}
							callback={field.onChange}
							options={phoneNumberPrefixOptions}
							disabled={disabled}
						/>
					)}
				/>

				<Controller
					name="phoneNumber"
					control={control}
					rules={{ 
						required: "Bitte geben Sie eine Mobilnummer ein.",
						validate: (value) => validatePhoneNumber(value, watch("phoneNumberPrefix")), // Use custom validation function
					}}
					render={({ field }) => (
						<FormInput
							id={'phoneNumber'}
							label={'Mobilnummer'}
							value={field.value}
							callback={field.onChange}
							required={true}
							disabled={disabled}
						/>
					)}
				/>

				{errors.phoneNumber?.message && <p className={'form__fielderror'}>{errors.phoneNumber?.message}</p> }
		</div>
  );
}

export default FormSelectPhoneNumberRHF;
