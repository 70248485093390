import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import PageDialogMarkinfoLight from '../Dialogs/PageDialogMarkinfoLight';
import MarktinfoLightForm from '../Forms/MarktinfoLightForm';
import MarktinfoLightResult from '../Forms/MarktinfoLightResult';
import Card from './Card';

import { scrollToElement } from '../../util/generalUtils';

//Icons
import IconLock from '../Icons/IconLock';

// STORE
import { setResetFieldsMarktinfoLightForm } from '../../reducers/marktinfoLight';
import { setPageDialogOpenRegisterForm, setResetFieldsRegisterForm } from '../../reducers/registerForm';

import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import '../../styles/marktinfo.scss';

function MarktinfoLightCard({ cssClasses }) {
  const user = useSelector((state) => state.user);
	const app = useSelector((state) => state.app);

  const dispatch = useDispatch();

  const { isMobile } = useHandleScreenResize();

  let currentMarktinfoLight = useSelector((state) => state.marktinfoLight);

  const [showForm, setShowForm] = useState(true);

  useEffect(() => {
    if (!isMobile) {
      setShowForm(!currentMarktinfoLight.pageDialogOpen);
    }
  }, [currentMarktinfoLight.pageDialogOpen]);

  useEffect(() => {
    if (Object.keys(user.marktinfoLastResults).length > 0) {
      setShowForm(false);
    } else {
      setShowForm(true);
    }
  }, [user.marktinfoLastResults]);

  cssClasses += ' marktinfolight-card';

  const handleOpenRegisterForm = () => {
    dispatch(setResetFieldsRegisterForm());
    dispatch(setPageDialogOpenRegisterForm(true));
  };

  const handleShowForm = () => {
    setShowForm(true);
    dispatch(setResetFieldsMarktinfoLightForm());
    scrollToElement('#marktinfoLight');
  };

  return (
    <div id="marktinfoLight">
      {showForm || app.demoMode ? (
        <Card cssClasses={cssClasses} cardStyle={isMobile ? 'topImage' : 'topImage2ColsReversed'} imagePath={require('../../assets/images/marktinfo.png')}>
          <div className="marktinfolight__content">
            <h2 className="mb-200rem">Marktanalyse</h2>
            <p className="mb-200rem">Jetzt Wohnqualität in der Umgebung der Immobilie analysieren</p>
            <MarktinfoLightForm />
          </div>
        </Card>
      ) : (
        <Card cssClasses={cssClasses} cardStyle="customContent">
          <div className="marktinfolight-card__marktanalyse">
            <div className="marktinfolight-card__marktanalys__headline">
              <h2 className="mb-200rem font-weight-800">Marktanalyse</h2>
            </div>
            <p className="marktinfolight-card__marktanalys__txt-mobil font-weight-600">Das Ergebnis Ihrer Marktanalyse:</p>

            <MarktinfoLightResult />

            <div className="button-panel-marktinfo">
              {user.isLoggedIn && Object.keys(user.marktinfoLastResults).length < 10 ? (
                <>
                  <button className="button button--blue mb-100rem" onClick={handleShowForm}>
                    Neue Analyse starten
                  </button>
                </>
              ) : !user.isLoggedIn ? (
                <>
                  <button className="button button--withicon mb-100rem" disabled={true}>
                    <IconLock color={'#fff'} />
                    Neue Analyse starten
                  </button>

                  <p>
                    <span className="textlink" onClick={handleOpenRegisterForm}>
                      Jetzt registrieren,
                    </span>{' '}
                    um weitere Marktanalysen durchzuführen.
                  </p>
                </>
              ) : null}
            </div>
          </div>
        </Card>
      )}

      <PageDialogMarkinfoLight />
    </div>
  );
}

export default MarktinfoLightCard;
