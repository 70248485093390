import axios from 'axios';
const baseURLSreal = 'https://www.sreal.at';

const sRealClient = axios.create({
  baseURL: 'https://www.sreal.at',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  // withCredentials: true,
});

const getRealtySearch = async (searchData, page) => {
  const formData = new FormData();
  formData.append('f[buyingType]', 'buy');

  Object.entries(searchData).forEach(([key, value]) => {
    if (value) {
      switch (key) {
        case 'address':
          value?.forEach((v) => {
            formData.append('f[location_or_id][]', v.value);
          });
          break;
        case 'areaFrom':
          formData.append('f[area][min]', value);
          break;
        case 'areaTo':
          formData.append('f[area][max]', value);
          break;
        case 'priceFrom':
          formData.append('f[price][min]', value);
          break;
        case 'priceTo':
          formData.append('f[price][max]', value);
          break;
        case 'roomsFrom':
          formData.append('f[rooms][min]', value);
          break;
        case 'roomsTo':
          formData.append('f[rooms][max]', value);
          break;
        case 'surfaceAreaFrom':
          formData.append('f[surface_area][min]', value);
          break;
        case 'surfaceAreaTo':
          formData.append('f[surface_area][max]', value);
          break;
        case 'estateType':
          value?.forEach((v) => {
            formData.append('f[objectType][]', v.id);
          });
          break;
        case 'subEstateType':
          value?.forEach((v) => {
            formData.append('f[objectSubType][]', v.id);
          });
          break;
        case 'features':
          value?.forEach((v) => {
            formData.append('f[features][]', v.id);
          });
          break;
        case 'fullText':
          formData.append('f[fulltext]', value);
          break;
        case 'sorting':
          formData.append('f[sorting]', value.id);
          break;
        case 'projects_only':
          formData.append('f[projects_only]', '1');
          break;
        default:
          break;
      }
    }
  });

  formData.append('p', page);

  try {
    const resp = await sRealClient.post('/api/realties', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getResidentialRealtySearch = async (searchData, page) => {
  let query = '';
  Object.entries(searchData).forEach(([key, value]) => {
    if (value) {
      switch (key) {
        case 'state':
          value?.map((v) => {
            query += `&f[federal_state][]=${v.text.replace(' ', '+')}`;
          });
          break;
				case 'estateType':
					let estateTypeId = null;
					if(value.id === 'flat') {
						estateTypeId = 2;
					}
					else if(value.id === 'house') {
						estateTypeId = 3;
					}
					if(estateTypeId) {
						query += `&f[realty_type_id][]=${estateTypeId}`;
					}
					
					break;
				case 'usageType':
					query += `&f[type_of_usage][]=${value.id}`;
					break;
        default:
          break;
      }
    }
  });

	query += '&p=' + page;

	// remove first &
	if(query) {
		query = query.replace('&', '');
	}

  try {
    const resp = await sRealClient.get(`/api/residential-realties?${query}`);
    return resp.data;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

const getRealtySubTypes = async (realtyTypes) => {
  if (!realtyTypes) return [];

  const realtyTypeData = realtyTypes
    .map((realtyType) => {
      return `realtyTypes%5B%5D=${realtyType}`;
    })
    .join('&');

  try {
    const resp = await axios.post(`${baseURLSreal}/search/sub-realty-types`, realtyTypeData, {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });

    const subTypes = resp.data.map((row) => {
      return { id: row.value, text: row.label };
    });
    return subTypes;
  } catch (err) {
    console.error(err);
    throw err;
  }
};

const getLocations = async (query) => {
  try {
    const resp = await sRealClient.get(`/search/locations?q=${query}`);
    return resp.data.slice(0, 20);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export { getRealtySearch, getResidentialRealtySearch, getRealtySubTypes, getLocations };
