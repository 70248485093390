import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	immo: {},
};

export const objectRequestSlice = createSlice({
  name: 'objectRequest',
  initialState,
  reducers: {
		setImmoObjectRequest: (state, action) => {
      state.immo = action.payload;
    },
    setResetFieldsObjectRequest: (state) => {
      return initialState;
    },
  },
});

export const { setImmoObjectRequest, setResetFieldsObjectRequest } = objectRequestSlice.actions;

export default objectRequestSlice.reducer;
