import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { AKTUELLES_FILTER_ALLE, PHASE1_VERKAUF, VERSION_MAJOR, VERSION_MINOR, VERSION_PATCH } from '../constants';
import { removeHashFromUrl } from '../util/generalUtils';

import { root  } from '../constants';

let googleMapsKeyPROD = 'AIzaSyBVhmPzn1GSq8Sc3bBKYmjAQzcWePcYHKA';
let googleMapsKeyABNAHME = 'AIzaSyDyP8IF3NcdF6C8nJnPoIeR7uj13G8Bo1s';
let googleMapsKey = googleMapsKeyPROD;

// ABNAHME + DEV-Server
if (window.location.host.indexOf('sreal.k25-dev.at') > -1 || window.location.host.indexOf('frontend.stable.srealportal.microf.cs.eb.lan.at') > -1 || window.location.host.indexOf('localhost') > -1) {
  googleMapsKey = googleMapsKeyABNAHME;
}

const initialState = {
  mobileMenuOpen: false,
  desktopMenuOpen: false,
  formIsLoading: false,
  companyName: 's Real Immobilien',
  companyEmail: 'kundenbetreuung@sreal.at',
  root: root,
	demoMode: false,
  dragging: false,
  aktuellesActiveFilter: AKTUELLES_FILTER_ALLE,
  menuType: '',
  versionMajor: VERSION_MAJOR,
  versionMinor: VERSION_MINOR,
  versionPatch: VERSION_PATCH,
  menuId: PHASE1_VERKAUF,
  googleMapsKey: googleMapsKey,
  OAuthClientId: '',
  OAuthResponseType: '',
  OAuthRedirectUri: '',
	pageDialogOpenId: ''
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleDesktopMenu: (state, action) => {
      state.desktopMenuOpen = !state.desktopMenuOpen;
    },
    toggleMobileMenu: (state, action) => {
      state.mobileMenuOpen = !state.mobileMenuOpen;
    },
    setCompanyName: (state, action) => {
      state.companyName = action.payload;
    },
    setRoot: (state, action) => {
			const newRoot = action.payload;
			if(newRoot === '/demo') {
				state.demoMode = true;
			}
			else {
				state.demoMode = false;
			}

      state.root = newRoot;
    },
    clearStorage: () => {
      storage.removeItem('persist:root');
      return initialState;
    },
    setFormIsLoading: (state, action) => {
      state.formIsLoading = action.payload;
    },
    setAktuellesActiveFilter: (state, action) => {
			let newFilterKey = AKTUELLES_FILTER_ALLE; // ALLE
			if(action.payload) {
				newFilterKey = typeof action.payload === 'string' ? parseInt(action.payload) : action.payload;
			}
			
      state.aktuellesActiveFilter = newFilterKey;
    },
    toggleDragging: (state, action) => {
      state.dragging = !state.dragging;
    },
    setMenuType: (state, action) => {
      state.menuType = action.payload;
    },
    setMenuId: (state, action) => {
      state.menuId = action.payload;
    },
    setVersionMajor: (state, action) => {
      state.versionMajor = action.payload;
    },
    setVersionMinor: (state, action) => {
      state.versionMinor = action.payload;
    },
    setVersionPatch: (state, action) => {
      state.versionPatch = action.payload;
    },
    setOAuthClientId: (state, action) => {
      state.OAuthClientId = action.payload;
    },
    setOAuthResponseType: (state, action) => {
      state.OAuthResponseType = action.payload;
    },
    setOAuthRedirectUri: (state, action) => {
      state.OAuthRedirectUri = action.payload;
    },
		setPageDialogOpenId: (state, action) => {
			state.pageDialogOpenId = action.payload;
		},
		closePageDialog: (state, action) => {
			removeHashFromUrl();
      state.pageDialogOpenId = '';
    },
  },
});

export const {	
  toggleMobileMenu,
  toggleDesktopMenu,
  setCompanyName,
  clearStorage,
  setRoot,
  toggleDragging,
  setFormIsLoading,
  setAktuellesActiveFilter,
  setMenuId,
  setMenuType,
  setVersionMajor,
  setVersionMinor,
  setVersionPatch,
  setOAuthClientId,
  setOAuthResponseType,
  setOAuthRedirectUri,
	setPageDialogOpenId,
	closePageDialog,ø
} = appSlice.actions;

export default appSlice.reducer;
