import { createSlice } from '@reduxjs/toolkit';
import { bitteWaehlenOption, immobilienartOptionsJustImmo } from '../components/FormFields/util/formOptions';
import { findAndParsePhoneNumber } from '../util/generalUtils';

const initialState = {
  isLoggedIn: false,
  lastLoggedIn: '',
  userObject: {
    phoneNumber: '+43',
  },
  userRealties: [],
  userRealtyActive: {},
  favourites: [],
	sentOffers: [],
  searchProfiles: [],
  wohnwertLastResults: [],
  wohnwertResultsActive: bitteWaehlenOption,
  marktinfoLastResults: [],
  marktinfoResultActive: bitteWaehlenOption,
  emailSettings: {
    wantsToReceiveMails: true,
    // Mögliche Optionen: 6, 12, 18
    hour: 6,
    // Mögliche Optionen: 1, 2, 7
    intervalDays: 2,
    mailTypes: [
      {
        id: 'documents',
        value: true,
      },
      {
        id: 'viewings',
        value: true,
      },
      {
        id: 'activities',
        value: true,
      },
    ],
  },
  hasMessages: false,
  satisfactionSurveyCompleted: false,
  recommendationSurveyCompleted: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setLastLoggedIn: (state, action) => {
      state.lastLoggedIn = action.payload;
    },
    setUserObject: (state, action) => {
			// Create a copy of the payload to avoid mutating the original action
			const payloadToSave = {...action.payload};

			let results = payloadToSave?.results || [];
			if(payloadToSave?.results) {
				const s = results.filter((r) => r.type === 'SentOffer');
				if(s) {
					state.sentOffers = s;

					// remove SentOffers from results (better memory usage)
					results = results.filter((r) => r.type !== 'SentOffer');
				}
			}

			// Remove emails from the user object to reduce stored data size
			if(payloadToSave.emails) {
				delete payloadToSave.emails;
			}

			if(payloadToSave.claims) {
				delete payloadToSave.claims;
			}

      state.userObject = {
				...payloadToSave,
				results
			};
    },
    setUserPhoneNumber: (state, action) => {
      findAndParsePhoneNumber(state, 'phoneNumber', action.payload);
    },
    setWohnwertLastResults: (state, action) => {
      state.wohnwertLastResults = action.payload;
    },
    setWohnwertResultsActive: (state, action) => {
      state.wohnwertResultsActive = action.payload;
    },
		setMarktinfoLastResults: (state, action) => {
      let newResultsPayload = {...action.payload};
      
      // dont save "Valuation" in state
      if(newResultsPayload && Object.keys(newResultsPayload).length > 0) {
				const filteredResults = {};
				Object.entries(newResultsPayload).forEach(([key, data]) => {
					if (Array.isArray(data)) {
						const filtered = data.filter(d => !d.Valuation);
						if (filtered.length > 0) {
							filteredResults[key] = filtered;
						}
					}
				});
				newResultsPayload = filteredResults;
      }
      
      state.marktinfoLastResults = newResultsPayload;
    },
    setMarktinfoResultActive: (state, action) => {
      state.marktinfoResultActive = action.payload;
    },
    setUserRealties: (state, action) => {
      let userRealties = action.payload;
      state.userRealties = userRealties;

      // if no userRealtyActive set - put first realty
      if (!state.userRealtyActive?.id && userRealties[0]) {
        state.userRealtyActive = userRealties[0];
      }
    },
    setUserRealtyActive: (state, action) => {
      state.userRealtyActive = action.payload;
    },
    setUserSearchProfiles: (state, action) => {
      state.searchProfiles = action.payload;
    },
    setUserSentOffers: (state, action) => {
      state.sentOffers = action.payload;
    },
    setUserFavourites: (state, action) => {
      state.favourites = action.payload;
    },
    setResetFieldsUser: (state, action) => {
      return initialState;
    },
    updateSearchProfileInUser: (state, action) => {
      const spData = action.payload;
      let updatedSearchProfile = state.searchProfiles.find((sp) => sp.id === spData.id);

      if (updatedSearchProfile) {
        updatedSearchProfile.name = spData.name;
        updatedSearchProfile.criteria = { ...spData.criteria };

        let typeId = spData.criteria.type_ids[0];
        if (typeId) {
          let type = immobilienartOptionsJustImmo.find((option) => option.id === typeId);
          updatedSearchProfile.criteria.types = [type];
        }
      }
    },
    tryUpdateUserResult: (state, action) => {
      const newUserResult = action.payload;

      const updatedSearchProfile = state.userObject.results.filter((res) => res.type === 'SearchProfile').find((sp) => sp.metaJson.id === newUserResult.id);

      if (updatedSearchProfile) {
        updatedSearchProfile.metaJson.name = newUserResult.name;
      }
    },
    setWantsToReceiveMails: (state, action) => {
      state.emailSettings.wantsToReceiveMails = action.payload;
      if (!action.payload) {
        state.emailSettings.mailTypes.forEach((mailType) => {
          mailType.value = false;
        });
      } else {
        state.emailSettings.mailTypes.forEach((mailType) => {
          mailType.value = true;
        });
      }
    },
    setEmailFrequency: (state, action) => {
      state.emailSettings.intervalDays = action.payload;
    },
    setEmailTimeOfDay: (state, action) => {
      state.emailSettings.hour = action.payload;
    },
    setMailTypes: (state, action) => {
      const mailType = state.emailSettings.mailTypes.find((mailType) => mailType.id === action.payload);
      mailType.value = !mailType.value;
    },
    setHasMessages: (state, action) => {
      state.hasMessages = action.payload;
    },
    setSatisfactionSurveyCompleted: (state, action) => {
      state.satisfactionSurveyCompleted = action.payload;
    },
    setRecommendationSurveyCompleted: (state, action) => {
      state.recommendationSurveyCompleted = action.payload;
    },
  },
});

export const {
  setIsLoggedIn,
  setLastLoggedIn,
  setUserObject,
  setUserSearchProfiles,
  setUserSentOffers,
  setWohnwertLastResults,
  setWohnwertResultsActive,
  setMarktinfoLastResults,
  setMarktinfoResultActive,
  setUserRealties,
  setUserRealtyActive,
  setResetFieldsUser,
  setUserFavourites,
  setUserPhoneNumber,
  updateSearchProfileInUser,
  tryUpdateUserResult,
  setWantsToReceiveMails,
  setEmailFrequency,
  setEmailTimeOfDay,
  setMailTypes,
  setHasMessages,
  setSatisfactionSurveyCompleted,
  setRecommendationSurveyCompleted,
} = userSlice.actions;

export default userSlice.reducer;