const demoUserObject = {
  userName: 'maxmuster.sreal@gmail.de',
  firstName: 'Max',
  lastName: 'Mustermann',
  justImmoContacts: [
    {
      id: 30031486,
      catalogue_number: 234,
      tenant_id: 3255,
      created_at: '2023-10-18T09:53:09+00:00',
      updated_at: '2025-03-17T18:14:56+00:00',
      email: 'thomas.beckam@10minmail.de',
      email_addresses: [
        {
          id: 29522299,
          address: 'thomas.beckam@10minmail.de',
          used_for_communication: true,
        },
      ],
      phone_mobile: '+436602522252',
      phone_private: null,
      phone_business: null,
      address: null,
      mailing_address: null,
      mailing_salutation: 'Sehr geehrte(r) Frau/Herr beckam',
      economic_relationship: null,
      categories: [
        {
          id: 39690,
          name: 'Interessent',
          is_marketing_category: false,
          is_newsletter_category: false,
          additionalProperties: {},
        },
        {
          id: 39696,
          name: 'Abgeber',
          is_marketing_category: false,
          is_newsletter_category: false,
          additionalProperties: {},
        },
        {
          id: 106360,
          name: 'my-sreal User',
          is_marketing_category: false,
          is_newsletter_category: false,
          additionalProperties: {},
        },
      ],
      realties: [],
    },
  ],
  gender: 'm',
  results: [
    {
      id: 'd8e10709-1602-4a34-bbed-08dbcf184cf3',
      type: 'SearchProfile',
      key: '',
      request:
        '{"contact_id":30031486,"agent_id":23618961,"creator_id":30031486,"expires_in":6,"created_at":"2023-10-18T09:53:11.5894136+00:00","criteria":{"type_ids":[3],"occupancy":"residential","marketing_type":"buy","price":{"from":null,"to":null},"rooms":{"from":null,"to":null},"site_area":{"from":null,"to":null},"living_area":{"from":null,"to":null},"commission_free":false,"location":{"location_ids":[{"type":"city_zip","id":19035}]},"furnishing_ids":[],"covered_balcony":{"enabled":false,"from":null},"balcony":{"enabled":false,"from":null},"terrace":{"enabled":false,"from":null},"garden":{"enabled":false,"from":null},"accessible":false}}',
      response: '2946676',
      created: '2023-10-18T09:53:11.776+00:00',
      meta: '{"name":"SP 1","id":2946676,"tenant_id":3255}',
      metaJson: {
        name: 'SP 1',
        id: 2946676,
        tenant_id: 3255,
      },
    },
  ],
  titleBefore: '',
  titleAfter: '',
  created: '2025-03-19T09:16:31.382+00:00',
  phoneNumber: '+436601234567',
  realtyActivitiesSeen: '0',
  emailNotificationHour: 0,
  emailNotificationIntervalDays: 0,
  emailNotificationDocuments: false,
  emailNotificationActivities: false,
  emailNotificationViewings: false,
  lastVendorActivity: '2025-02-19T16:14:19.065+00:00',
  directRegistration: null,
  directRegistrationCompleted: null,
  emails: [],
  surveys: [],
  realtyPhases: [],
  claims: [],
};

export { demoUserObject };
