import { Autocomplete, Chip, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import IconClose from '../Icons/IconClose';
import { useEffect, useState } from 'react';

import { isEmpty } from 'lodash';

function FormAutocomplete({
  cssClasses = '',
  label,
  hasLockIcon = false,
  id = '',
  required = false,
  disabled = false,
  loading = false,
  options = [],
  callback,
  callbackInput,
  placeholder = '',
  error = '',
  value,
  multiple = false,
}) {
  const getOptionLabelFunc = (option) => {
    if (option?.label) return option.label;
    else return '';
  };

  return (
    <div className={`${cssClasses} form__field`}>
      {label && (
        <InputLabel htmlFor={id}>
          {label} {!!required ? '*' : ''}
        </InputLabel>
      )}

      <div className={`select-wrapper ${hasLockIcon && disabled ? 'locked' : ''}`}>
        <Autocomplete
          className={`select autocomplete`}
          filterOptions={(options) => options}
          getOptionDisabled={(option) => option.disabled}
          fullWidth
          loading={loading}
          loadingText={'Daten werden geladen...'}
          noOptionsText={'Keine Ergebnisse gefunden.'}
          disabled={disabled}
          multiple={multiple}
          clearOnBlur={true}
          freeSolo={false} // no values that are not in options
          isOptionEqualToValue={(option, value) => option.value === value.value} // needed if options are objects
          getOptionLabel={getOptionLabelFunc}
          options={options}
          value={value || ''}
          onBlur={() => (typeof value !== 'object' ? callback('') : null)} // callback with '' if no option (typeof object) has been selected
          onChange={(_, newValue) => {
            callback(newValue);
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              {...params}
              className="autocomplete__input"
              onChange={(newVal) => callbackInput(newVal?.target?.value)}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                },
              }}
              disabled={disabled}
              variant="standard"
              placeholder={!value || isEmpty(value) ? placeholder : ''}
            />
          )}
        />
      </div>

      {error && <p className="form__fielderror">{error}</p>}
    </div>
  );
}

export default FormAutocomplete;
