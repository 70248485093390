import React from 'react';
import { useSelector } from 'react-redux';

function Textarea({ id = '', label, required = false, error = '', value, callback, disabled = false }) {
	const app = useSelector((state) => state.app);
  return (
    <div className="form__field">
      {label && (
        <label htmlFor={id}>
          {label} {!!required ? '*' : ''}
        </label>
      )}

      <textarea
				id={id}
				onChange={(e) => callback(e.target.value)}
				defaultValue={value}
				disabled={disabled || app.formIsLoading}
			/>

      {error !== '' && <p className="form__fielderror">{error}</p>}
    </div>
  );
}

export default Textarea;
