export const demofiles = [
	{
			"type": 1,
			"storage_key": "7uVOhXO9Ucw4yn2AyiCgjr.pdf",
			"url": "Abgeberbrief.pdf",
			"original_filename": "Abgeberbrief.pdf",
			"title": "",
			"description": "",
			"sort_order": 99999,
			"uploaded_at": "2024-08-28T16:11:44+02:00",
			"visibility": 1,
			"active": true,
			"group": {
					"id": 39
			},
			"original_filesize": 13062,
			"mime_type": "application/pdf",
			"width": 0,
			"height": 0,
			"thumbnails": null,
			"additionalProperties": {},
			"headline": "Abgeberbrief",
			"text": "PDF, 12.8 KB",
			"isPrivate": true,
			"isDemo": true,
			"filter_id": 4,
			"dateFrom": "2025-03-24T16:11:44+02:00"
	},
	{
		"type": 1,
		"storage_key": "7uVOhXO9Ucw4yn2AyiCgjr.pdf",
		"url": "Betriebskostenauflistung.pdf",
		"original_filename": "Betriebskostenauflistung.pdf",
		"title": "",
		"description": "",
		"sort_order": 99999,
		"uploaded_at": "2024-08-28T16:11:44+02:00",
		"visibility": 1,
		"active": true,
		"group": {
				"id": 39
		},
		"original_filesize": 13062,
		"mime_type": "application/pdf",
		"width": 0,
		"height": 0,
		"thumbnails": null,
		"additionalProperties": {},
		"headline": "Betriebskostenauflistung",
		"text": "PDF, 12.8 KB",
		"isPrivate": true,
		"isDemo": true,
		"filter_id": 4,
		"dateFrom": "2025-03-24T16:11:44+02:00"
	},
	{
		"type": 1,
		"storage_key": "7uVOhXO9Ucw4yn2AyiCgjr.pdf",
		"url": "Energieausweis.pdf",
		"original_filename": "Energieausweis.pdf",
		"title": "",
		"description": "",
		"sort_order": 99999,
		"uploaded_at": "2024-08-28T16:11:44+02:00",
		"visibility": 1,
		"active": true,
		"group": {
				"id": 39
		},
		"original_filesize": 13062,
		"mime_type": "application/pdf",
		"width": 0,
		"height": 0,
		"thumbnails": null,
		"additionalProperties": {},
		"headline": "Energieausweis",
		"text": "PDF, 12.8 KB",
		"isPrivate": true,
		"isDemo": true,
		"filter_id": 4,
		"dateFrom": "2025-03-24T16:11:44+02:00"
	},
	{
		"type": 1,
		"storage_key": "7uVOhXO9Ucw4yn2AyiCgjr.pdf",
		"url": "Grundbuchauszug.pdf",
		"original_filename": "Grundbuchauszug.pdf",
		"title": "",
		"description": "",
		"sort_order": 99999,
		"uploaded_at": "2024-08-28T16:11:44+02:00",
		"visibility": 1,
		"active": true,
		"group": {
				"id": 39
		},
		"original_filesize": 13062,
		"mime_type": "application/pdf",
		"width": 0,
		"height": 0,
		"thumbnails": null,
		"additionalProperties": {},
		"headline": "Grundbuchauszug",
		"text": "PDF, 12.8 KB",
		"isPrivate": true,
		"isDemo": true,
		"filter_id": 4,
		"dateFrom": "2025-03-24T16:11:44+02:00"
	},
	{
		"type": 1,
		"storage_key": "7uVOhXO9Ucw4yn2AyiCgjr.pdf",
		"url": "Grundriss.pdf",
		"original_filename": "Grundriss.pdf",
		"title": "",
		"description": "",
		"sort_order": 99999,
		"uploaded_at": "2024-08-28T16:11:44+02:00",
		"visibility": 1,
		"active": true,
		"group": {
				"id": 39
		},
		"original_filesize": 13062,
		"mime_type": "application/pdf",
		"width": 0,
		"height": 0,
		"thumbnails": null,
		"additionalProperties": {},
		"headline": "Grundriss",
		"text": "PDF, 12.8 KB",
		"isPrivate": true,
		"isDemo": true,
		"filter_id": 4,
		"dateFrom": "2025-03-24T16:11:44+02:00"
	},
	{
		"type": 1,
		"storage_key": "7uVOhXO9Ucw4yn2AyiCgjr.pdf",
		"url": "Lageplan.pdf",
		"original_filename": "Lageplan.pdf",
		"title": "",
		"description": "",
		"sort_order": 99999,
		"uploaded_at": "2024-08-28T16:11:44+02:00",
		"visibility": 1,
		"active": true,
		"group": {
				"id": 39
		},
		"original_filesize": 13062,
		"mime_type": "application/pdf",
		"width": 0,
		"height": 0,
		"thumbnails": null,
		"additionalProperties": {},
		"headline": "Lageplan",
		"text": "PDF, 12.8 KB",
		"isPrivate": true,
		"isDemo": true,
		"filter_id": 4,
		"dateFrom": "2025-03-24T16:11:44+02:00"
	},
	{
		"type": 1,
		"storage_key": "7uVOhXO9Ucw4yn2AyiCgjr.pdf",
		"url": "Legitimation.pdf",
		"original_filename": "Legitimation.pdf",
		"title": "",
		"description": "",
		"sort_order": 99999,
		"uploaded_at": "2024-08-28T16:11:44+02:00",
		"visibility": 1,
		"active": true,
		"group": {
				"id": 39
		},
		"original_filesize": 13062,
		"mime_type": "application/pdf",
		"width": 0,
		"height": 0,
		"thumbnails": null,
		"additionalProperties": {},
		"headline": "Legitimation",
		"text": "PDF, 12.8 KB",
		"isPrivate": true,
		"isDemo": true,
		"filter_id": 4,
		"dateFrom": "2025-03-24T16:11:44+02:00"
	},
];