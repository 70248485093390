import Card from './Card';

import { useHandleScreenResize } from '../../hooks/app/useHandleScreenResize';
import FinanzierungsRechnerForm from '../Forms/FinanzierungsRechnerForm';

function FinanzierungsCard() {
  const { isMobile } = useHandleScreenResize();

  return (
    <Card 
			imagePath={require('../../assets/images/Finanzierenbild.jpg')} 
			cardStyle={isMobile ? 'topImage' : 'topImage2ColsReversedLeft'} 
			cssClasses="card--finanzierung"
		>
      <FinanzierungsRechnerForm />
    </Card>
  );
}

export default FinanzierungsCard;
