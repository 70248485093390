//*React
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//*Icons
import IconLogout from '../Icons/IconLogout';
//*API
import { logout } from '../../api/Onboarding';
//*Reducers
import { useHandleApp } from '../../hooks/app/useHandleApp';
import { setMenuId, setMenuType, toggleDesktopMenu } from '../../reducers/app';
import { PHASE0 } from '../../constants';

function LogoutButton() {
  let user = useSelector((state) => state.user);
	let app = useSelector((state) => state.app);

  const { resetStore } = useHandleApp();

	const dispatch = useDispatch();

  // Logout
  const logoutErrorMsg = 'Logout hat leider nicht funktioniert. Bitte wenden Sie sich an unsere Kundenbetreuung.';
  const [logoutError, setLogoutError] = useState('');
  const handleLogout = async () => {
		if(app.demoMode) {
			dispatch(toggleDesktopMenu());
			return;
		}

    try {
      await logout();
      resetStore();

			dispatch(setMenuType(''));
			dispatch(setMenuId(PHASE0));
    } catch (e) {
      setLogoutError(logoutErrorMsg);
    }
  };

  if (user.isLoggedIn || app.demoMode) {
    return (
      <>
        <div className="d-flex flex-row align-items-center mt-100rem">
          <div className="image--round">
            <IconLogout color="#fff" cssClasses="position-center" />
          </div>

          <span className="textlink ml-10" onClick={() => handleLogout()}>
            Abmelden
          </span>
        </div>
        {logoutError && <p className="form__fielderror">{logoutError}</p>}
      </>
    );
  } else return null;
}

export default LogoutButton;
