import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import DateTimePickerRHF from '../../FormFields/customFields/DateTimePickerRHF';
import DateTimePicker2RHF from '../../FormFields/customFields/DateTimePicker2RHF';

// STORE
import { useFormContext } from 'react-hook-form';

function ContactTerminwunsch({ prevStep, nextStep }) {
  const { handleSubmit, resetField } = useFormContext();

	const skip = () => {
		// reset data found in long route
		resetField('appointmentDate');
		resetField('appointmentTime');

		resetField('appointmentDate2');
		resetField('appointmentTime2');

		nextStep();
	}
	
  return (
    <>
      <h3 className="form__title">Terminwunsch (optional)</h3>
      <p>Wir berücksichtigen Ihren Terminwunsch soweit wie möglich. Für eine bessere Planbarkeit bitten wir Sie, zwei mögliche Termine auszuwählen.</p>

      <h3 className="form__title pt-100rem">Terminvorschlag 1</h3>
      <div className="form__row form__row--double">
        <DateTimePickerRHF id={'inputAppointment'} />
      </div>

      <h3 className="form__title pt-100rem">Terminvorschlag 2</h3>
      <div className="form__row form__row--double">
        <DateTimePicker2RHF id={'inputAppointment2'} />
      </div>

      <div className="button-panel align-items-center">
        <button className="button button--gray-outline" onClick={handleSubmit(() =>  prevStep())}>
          Zurück
        </button>
        <ButtonForwardAction forwardAction={handleSubmit(() =>  nextStep())} buttonStyle={'blue'} buttonText={'Weiter'} />
        <span className="textlink mb-10" onClick={skip}>
          Überspringen
        </span>
      </div>
    </>
  );
}

export default ContactTerminwunsch;
