import Slider from '@mui/material/Slider';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useOutsideAlerter from '../useOutsideAlerter';


import { setMarktBerichtCity, setMarktBerichtNumber, setMarktBerichtStreet, setMarktBerichtZip } from '../../reducers/marktBericht';

import {
	setEmailRegisterForm,
	setFirstNameRegisterForm,
	setLastNameRegisterForm,
	setParsedPhoneNumberRegisterForm,
	setPasswordConfirmRegisterForm,
	setPasswordRegisterForm,
	setPhoneNumberRegisterForm,
	setTitleAfterRegisterForm,
	setTitleBeforeRegisterForm,
	setTokenRegisterForm,
} from '../../reducers/registerForm';

import { setEmailLoginForm, setPasswordLoginForm, setTokenLoginForm } from '../../reducers/loginForm';

import { setWohnwertLightDistrict, setWohnwertLightRealEstateSize, setWohnwertLightTypedPostCode } from '../../reducers/wohnwertLight';

import { setMarktinfoLightStreet, setMarktinfoLightTypedZip, setMarktinfoLightZip } from '../../reducers/marktinfoLight';

import { useState } from 'react';
import { autocomplete, findMarketReportStreet, findMarketReportStreetNumber } from '../../api/JustImmo';
import statesWithZips from './util/statesWithZips';
import { setChangedParsedPhoneNumber, setChangedParsedPhoneNumberCheck } from '../../reducers/registerFormChangePhoneNumber';

function FormInput({
  type = 'text',
  id = '',
  label,
  required = false,
  error = '',
  value,
  sliderValue,
  showFurtherInfoText,
  placeholder = '',
  formType = '',
  disabled = false,
  hasLockIcon = false,
  minValue,
  maxValue,
  stepValue,
  hasSlider = false,
  sliderLabels,
  hasMarks = false,
  hasList = false,
  cssClasses = '',
  callback,
}) {
  if (hasList) cssClasses += ' form__field--haslist';
  if (type === 'password') cssClasses += ' position-relative';
	cssClasses += ' form__field';
	cssClasses = cssClasses.trim();

  const dispatch = useDispatch();

  const app = useSelector((state) => state.app);
  const currentWohnwertLightForm = useSelector((state) => state.wohnwertLight);
  const currentMarktinfoLight = useSelector((state) => state.marktinfoLight);
	const currentMarktBerichtForm = useSelector((state) => state.marktBericht);

  const [typing, setTyping] = useState(false);
  const [callAutocompleteVal, setCallAutocompleteVal] = useState(false);
  const [list, setList] = useState([]);

  const [showResetIcon, setShowResetIcon] = useState(false);
  const [errorMin, setErrorMin] = useState(false);
  const [errorMax, setErrorMax] = useState(false);

  useEffect(() => {
    if (!hasLockIcon && !disabled && value) {
      setShowResetIcon(true);
    }
  }, []);

  useEffect(() => {
		if(app.formIsLoading || hasSlider) {
			setShowResetIcon(false);
		}
		else {
      if (String(value) === String(minValue)) {
        setShowResetIcon(false);
      } else if (!hasLockIcon && !disabled && value) {
        setShowResetIcon(true);
      } else {
        setShowResetIcon(false);
      }
    }
  }, [value, disabled, app.formIsLoading, hasSlider, hasLockIcon, minValue]);

  // validate min & max value
  useEffect(() => {
    if (!hasSlider) {
      let minInt = parseInt(minValue);
      let maxInt = parseInt(maxValue);

      // validate min + max
      if (maxInt > 0 && parseInt(value) > maxInt) {
        setErrorMax(true);
        setErrorMin(false);
      } else {
        setErrorMax(false);
      }

      if (minInt > 0 && parseInt(value) < minInt) {
        setErrorMin(true);
        setErrorMax(false);
      } else {
        setErrorMin(false);
      }

      if (minInt === 0) {
        setErrorMin(false);
      }

      if (maxInt === 0) {
        setErrorMax(false);
      }
    }
  }, [value, minValue, maxValue]);

  useEffect(() => {
    setList([]);
    if (typing) {
      const typingTimeout = setTimeout(() => {
        setTyping(false);
      }, 500);

      return () => clearTimeout(typingTimeout);
    } else {
      if (callAutocompleteVal) {
       if (formType === 'wohnwertLight' || formType === 'marktinfoLight') {
          let currentState = {};
          if (formType === 'wohnwertLight') {
            currentState = currentWohnwertLightForm.federalState;
          }

          let foundPostCodes = [];
          let filteredPostCodes = [];

          if (formType === 'marktinfoLight' && !currentState?.id) {
            // marktinfoLight Käufer - has no state, show all zips
            foundPostCodes = statesWithZips
              .filter((state) => state.label !== 'TESTACCOUNT')
              .map((state) => state.zipCodes)
              .flat();
          } else {
            foundPostCodes = statesWithZips.filter((state) => state.label !== 'TESTACCOUNT').find((state) => state.priceHubbleId === currentState.id)?.zipCodes || [];
          }

          if (foundPostCodes.length > 0) {
            filteredPostCodes = foundPostCodes
              .filter((zip) => zip.includes(callAutocompleteVal))
              .map((postCode) => {
                return { label: postCode, value: postCode };
              });
          }

          if (filteredPostCodes.length === 0) {
            filteredPostCodes = [{ value: '', label: 'Keine Ergebnisse gefunden' }];
          }

          setList(filteredPostCodes);
          setCallAutocompleteVal(false);
        } 
      }
    }
  }, [typing]);

  const getAndSetAutocompleteValue = async (val, currentState) => {
    try {
      let autoCompleteResults = await autocomplete(val);
      autoCompleteResults = autoCompleteResults.filter((r) => r.type === 5);
      const foundPostCodes = statesWithZips.filter((state) => state.label !== 'TESTACCOUNT').find((state) => state.justImmoId === currentState.id)?.zipCodes || [];

      var isNumberReg = /^\d+$/;
      if (isNumberReg.test(val)) {
        const filteredValues = foundPostCodes.filter((zip) => zip.includes(val));

        autoCompleteResults = autoCompleteResults.filter((r) => {
          let label = r.label;
          let zip = label.split(' ')[0];
          if (filteredValues.includes(zip)) {
            return r;
          }
        });
      } else {
        autoCompleteResults = autoCompleteResults.filter((a) => {
          let label = a.label;
          let zip = label.split(' ')[0];
          if (foundPostCodes.includes(zip)) {
            return a;
          }
        });
      }

      if (autoCompleteResults.length === 0) {
        autoCompleteResults = [{ value: '', label: 'Keine Ergebnisse gefunden' }];
      }

      setList(autoCompleteResults);
      setCallAutocompleteVal(false);
    } catch (e) {
      // handle autocomplete error
      console.error('e', e);
      setCallAutocompleteVal(false);
    }
  };

  //disable scrolling in input fileds
  const blurActiveElement = (event) => {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur();
    }
  };

  useEffect(() => {
    document.addEventListener('wheel', blurActiveElement);
    return () => window.removeEventListener('wheel', blurActiveElement);
  }, []);

  const handleListChange = (option) => {
    if (option.value === '') {
      handleResetList();
    } else {
      if (formType === 'wohnwertLight') {
        option = {
          id: parseInt(option.value),
          text: option.label,
        };
        dispatch(setWohnwertLightDistrict(option));
        dispatch(setWohnwertLightTypedPostCode(option.text));
        setList([]);
      } else if (formType === 'marktinfoLight') {
        option = {
          id: parseInt(option.value),
          text: option.label,
        };
        dispatch(setMarktinfoLightZip(option));
        dispatch(setMarktinfoLightTypedZip(option.text));
        setList([]);
      } else if (formType === 'marktBericht') {
        if (id === 'inputStreet') {
          let location = JSON.parse(option.value);
          dispatch(setMarktBerichtStreet(location.streetName));
          dispatch(setMarktBerichtZip(location.zipCode));
          dispatch(setMarktBerichtCity(location.cityName));
          setList([]);
        } else if (id === 'inputNumber') {
          dispatch(setMarktBerichtNumber(option.value));
          setList([]);
        }
      }
    }
  };

  const handleResetList = () => {
    setList([]);
  };

  const listRef = useRef(null);
  useOutsideAlerter(listRef, handleResetList);

  const handleFocus = (e) => {
    e.target.select();
  };

  const handleBlur = ({ id }) => {
    if (formType === 'wohnwertLight') {
      let district = currentWohnwertLightForm.district;
      let typedPostCode = currentWohnwertLightForm.typedPostCode;
      if (!district || !district.id) {
        dispatch(setWohnwertLightTypedPostCode(''));
      } else if (district.text !== typedPostCode) {
        dispatch(setWohnwertLightTypedPostCode(district.text));
      }
    } else if (formType === 'marktinfoLight') {
      let zip = currentMarktinfoLight.zip;
      let typedZip = currentWohnwertLightForm.typedZip;
      if (!zip || !zip.id) {
        dispatch(setMarktinfoLightTypedZip(''));
      } else if (zip.text !== typedZip) {
        dispatch(setMarktinfoLightTypedZip(zip.text));
      }
    }
  };

  const handleReset = () => {
    handleInputChange('');
    setErrorMin(false);
    setErrorMax(false);
  };

	const handleSliderInputChange = (inputValue) => {
		if (!isNaN(inputValue)) {
			callback(inputValue);
		}
  };

  const handleInputChange = (val) => {
    // if val under 0 - change val to positive number
    if (minValue === 0 && type === 'number' && parseInt(val) < 0) {
      val = val.replace(/[-]/g, '');
    }

    if (callback) {
      callback(val);
    } else {
      setTyping(true);
      if (formType === 'loginForm') {
        switch (id) {
          case 'inputEmail':
            dispatch(setEmailLoginForm(val));
            break;
          case 'inputPassword':
            dispatch(setPasswordLoginForm(val));
            break;
          case 'inputToken':
            dispatch(setTokenLoginForm(val));
            break;
          default:
            break;
        }
      } else if (formType === 'registerForm') {
        switch (id) {
          case 'inputTitleBefore':
            dispatch(setTitleBeforeRegisterForm(val));
            break;
          case 'inputTitleAfter':
            dispatch(setTitleAfterRegisterForm(val));
            break;
          case 'inputFirstName':
            dispatch(setFirstNameRegisterForm(val));
            break;
          case 'inputLastName':
            dispatch(setLastNameRegisterForm(val));
            break;
          case 'inputEmail':
            dispatch(setEmailRegisterForm(val));
            break;
          case 'inputPhoneNumber':
            dispatch(setParsedPhoneNumberRegisterForm(val.replace(/[\D]/g, '')));
            dispatch(setPhoneNumberRegisterForm(val.replace(/[\s\D]/g, '')));
            break;
          case 'inputPassword':
            dispatch(setPasswordRegisterForm(val));
            break;
          case 'inputPasswordConfirm':
            dispatch(setPasswordConfirmRegisterForm(val));
            break;
          case 'inputToken':
            dispatch(setTokenRegisterForm(val));
            break;
          default:
            break;
        }
      } else if (formType === 'registerFormChangePhoneNumber') {
        switch (id) {
          case 'inputPhoneNumber':
            dispatch(setChangedParsedPhoneNumber(val));
            break;
          case 'inputPhoneNumberCheck':
            dispatch(setChangedParsedPhoneNumberCheck(val));
            break;
          default:
            break;
        }
      } else if (formType === 'wohnwertLight') {
        switch (id) {
          case 'realEstateSize':
            dispatch(setWohnwertLightRealEstateSize(val));
            break;
          case 'inputDistrict':
            dispatch(setWohnwertLightTypedPostCode(val));
            setCallAutocompleteVal(val);

            if (!val) {
              dispatch(setWohnwertLightDistrict({}));
            }
            break;
          default:
            break;
        }
      } else if (formType === 'marktinfoLight') {
        switch (id) {
          case 'inputStreet':
            dispatch(setMarktinfoLightStreet(val));
            break;
          case 'inputZip':
            dispatch(setMarktinfoLightTypedZip(val));
            setCallAutocompleteVal(val);

            if (!val) {
              dispatch(setMarktinfoLightZip({}));
            }
            break;
          default:
            break;
        }
      } 
    }
  };

  return (
    <div className={cssClasses}>
      <label htmlFor={id}>
        {label} {!!required ? '*' : ''}
      </label>

      <div className={`${hasSlider ? 'form__field--slider-container' : ''}`}>
        {hasSlider && (
          <div className="slider-wrapper">
            <div>
              {sliderLabels.map((label, index) => {
                return (
                  <span key={index} className="slider-label">
                    {label}
                  </span>
                );
              })}
            </div>
            <Slider marks={hasMarks} value={sliderValue} min={minValue} max={maxValue} step={stepValue} onChange={(e) => handleInputChange(e.target.value)} />
          </div>
        )}
				
        <div className={`input-wrapper
              ${hasLockIcon && disabled ? 'locked' : ''} 
              ${callAutocompleteVal.length > 1 ? 'input--loading' : ''}
            `}
        >
          <input
            className={`input ${showFurtherInfoText === 'currency' ? 'input--currency' : ''} ${showFurtherInfoText === 'years' ? 'input--years' : ''}`}
            disabled={disabled || app.formIsLoading}
            id={id}
            data-formtype={formType}
            name={id}
            min={minValue}
            max={maxValue}
            type={type}
            autoComplete="off"
            required={required}
            placeholder={placeholder}
            onFocus={handleFocus}
            value={value}
            onChange={(e) => handleInputChange(e.target.value)}
            onBlur={(e) => handleBlur({ id: id })}
          />
          {showResetIcon ? (
            <span className="form__field--reset" onClick={() => handleReset()}></span>
          ) : null}
        </div>

        {hasList && list.length > 0 && (
          <div className="input-list" ref={listRef}>
            <ul>
              <>
                {list.map((option) => {
                  let value = option.value;
                  if (value.includes('optgroup_')) {
                    return (
                      <li key={value} className="input-list__headline">
                        {option.label}
                      </li>
                    );
                  } else {
                    return (
                      <li key={value} onClick={() => handleListChange(option)}>
                        {option.label}
                      </li>
                    );
                  }
                })}
              </>
            </ul>
          </div>
        )}
      </div>

      {error ? (
        <p className={'form__fielderror'}>{error}</p>
      ) : (
        <>
          {errorMin && <p className="form__fielderror">Der Wert muss größer als {minValue} sein.</p>}
          {errorMax && <p className="form__fielderror">Der Wert muss kleiner als {maxValue} sein.</p>}
        </>
      )}
    </div>
  );
}

export default FormInput;
