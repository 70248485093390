import moment from 'moment';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

const holidays = ['1 0', '6 0', '1 4', '15 7', '26 9', '1 10', '8 11', '25 11', '26 11'];

// IS IT WEEKEND?
const isWeekend = (date) => {
  return date.getDay() === 0 || date.getDay() === 6;
};
// IS IT HOLIDAY?
const isHoliday = (date) => {
  const day = `${date.getDate()} ${date.getMonth()}`;
  return holidays.includes(day);
};

function disableWeekendsAndHolidays(date) {
  let val = date.valueOf();
  let localTime = moment.utc(val).toDate();
  return isWeekend(localTime) || isHoliday(localTime);
}

const validateDate = (newDateValue) => {
  if (!newDateValue) return true;

  let val = newDateValue.valueOf();
  let localTime = moment.utc(val).toDate();
  let todayDate = Date.now();
  let isPast = dayjs(val).isBefore(todayDate);

  if (isPast) {
    return 'Dieses Datum liegt in der Vergangenheit. Bitte wählen Sie ein anderes aus.';
  } else if (isWeekend(localTime)) {
    return 'Dieses Datum fällt auf ein Wochenende. Bitte wählen Sie ein anderes aus.';
  } else if (isHoliday(localTime)) {
    return 'Dieses Datum fällt auf einen Feiertag. Bitte wählen Sie ein anderes aus.';
  }

  return true;
};

const validateTime = (dateValue, timeValue) => {
  if (dateValue && !timeValue) {
    return 'Bitte wählen Sie auch eine Uhrzeit für den Wunschtermin aus.';
  } else return true;
};

const formatDateForRequestbody = (dateValue) => {
	if (!dateValue) return '';
	return moment(dateValue).format('YYYY-MM-DD');
}

const formatTimeForRequestbody = (timeValue) => {
  if (!timeValue) return '';
  let hours = dayjs(timeValue).hour();
  let mins = dayjs(timeValue).minute();

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (mins < 10) {
    mins = '0' + mins;
  }

  return hours + ':' + mins + ':00';
};

const formatAppointmentForRequestbody = (dateValue, timeValue) => {
	if(!dateValue || !timeValue) return '';
	
	const dateFormatted = formatDateForRequestbody(dateValue);
	const timeFormatted = formatTimeForRequestbody(timeValue);
	return dateFormatted + 'T' + timeFormatted;	
}

export { disableWeekendsAndHolidays, validateDate, validateTime, formatAppointmentForRequestbody };
