import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import PageDialog from './PageDialog';
import RegisterForm from '../Forms/RegisterForm';

// STORE
import { setPageDialogOpenRegisterForm, setResetFieldsRegisterForm, setSiteIndexRegisterForm } from '../../reducers/registerForm';
import { setResetFormValidator } from '../../reducers/formValidators';
import { setFormIsLoading } from '../../reducers/app';

import {
  REGFORM_SITEINDEX_START,
  REGFORM_SITEINDEX_EMAIL,
  REGFORM_SITEINDEX_SUCCESSTOKEN,
  REGFORM_SITEINDEX_CONFIRMERROR,
  REGFORM_SITEINDEX_ALMOSTTHERE,
  REGFORM_SITEINDEX_LINKEXPIRED,
  REGFORM_SITEINDEX_PW,
  REGFORM_SITEINDEX_USEREXISTS,
  REGFORM_SITEINDEX_PWSUCCESS,
  DIREKTREGFORM_SITEINDEX_SUCCESSTOKEN,
	DIREKTREGFORM_SITEINDEX_START,
	DIREKTREGFORMINTERESSENT_SITEINDEX_START,
	DIREKTREGFORM_SITEINDEX_PW
} from '../../constants';
import { resetInitial } from '../../reducers/registerFormChangePhoneNumber';

function PageDialogRegister() {
  let app = useSelector((state) => state.app);
  let registerForm = useSelector((state) => state.registerForm);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleBack = () => {
    dispatch(setFormIsLoading(false));

    if (location.hash.includes('registrieren-telefonnummer')) {
      dispatch(resetInitial());
    }

    if (location.pathname.indexOf('set_password') >= 0) {
      handleClose();
      dispatch(setResetFormValidator());
    } else if (location.pathname.indexOf('/confirm') >= 0) {
      dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_SUCCESSTOKEN));
    } else {
      dispatch(setSiteIndexRegisterForm(REGFORM_SITEINDEX_START));
    }
  };

  const handleClose = (event, reason) => {
    //prevent dialog from closing when clicking outside or pressing ESC on certain dialog-pages
    if (
      reason &&
      (reason === 'backdropClick' || reason === 'escapeKeyDown') &&
      (registerForm.siteIndex === REGFORM_SITEINDEX_SUCCESSTOKEN || registerForm.siteIndex === REGFORM_SITEINDEX_ALMOSTTHERE || registerForm.siteIndex === REGFORM_SITEINDEX_PW  || registerForm.siteIndex === DIREKTREGFORM_SITEINDEX_SUCCESSTOKEN || registerForm.siteIndex === DIREKTREGFORM_SITEINDEX_START || registerForm.siteIndex === DIREKTREGFORMINTERESSENT_SITEINDEX_START || registerForm.siteIndex === DIREKTREGFORM_SITEINDEX_PW)
    ) {
      return;
    }

    if (app.formIsLoading) {
      return;
    }

    dispatch(setPageDialogOpenRegisterForm(false));
    dispatch(setResetFormValidator());

    if (registerForm.siteIndex === REGFORM_SITEINDEX_SUCCESSTOKEN || location.pathname.indexOf('set_password') >= 0 || location.pathname.indexOf('/confirm') >= 0  || location.pathname.indexOf('/direct-registration-confirm') >= 0) {
      // go back to home
      navigate('../' + app.root + '/');
    }
  };

  return (
    <PageDialog
      open={registerForm.pageDialogOpen}
      handleClose={handleClose}
      handleBack={handleBack}
      headline={registerForm.headline}
      showBackArrow={
        registerForm.siteIndex !== REGFORM_SITEINDEX_START &&
        registerForm.siteIndex !== REGFORM_SITEINDEX_SUCCESSTOKEN &&
        registerForm.siteIndex !== DIREKTREGFORM_SITEINDEX_SUCCESSTOKEN &&
        registerForm.siteIndex !== REGFORM_SITEINDEX_CONFIRMERROR &&
        registerForm.siteIndex !== REGFORM_SITEINDEX_EMAIL &&
        registerForm.siteIndex !== REGFORM_SITEINDEX_ALMOSTTHERE &&
        registerForm.siteIndex !== REGFORM_SITEINDEX_LINKEXPIRED &&
        registerForm.siteIndex !== REGFORM_SITEINDEX_USEREXISTS &&
        registerForm.siteIndex !== REGFORM_SITEINDEX_PWSUCCESS &&
        !(location.pathname.includes('confirm') && location.hash !== '#registrieren-telefonnummer')
      }
    >
      <RegisterForm handleClose={handleClose} />
    </PageDialog>
  );
}

export default PageDialogRegister;
