import React from 'react';
import { useDispatch } from 'react-redux';
import IconSmiley from '../Icons/IconSmiley';

import { setWohnwertLightRealEstateCondition } from '../../reducers/wohnwertLight';

const smileyOptions = [1, 2, 3, 4];

function SatisfactionScale({ error = '', color = '#3679ec', detailsColor = '#ffffff', formType = '', selectedValue, callback }) {
  const dispatch = useDispatch();

  const handleChange = (index) => {
    if (callback) {
      callback(index);
    } else {
      if (formType === 'wohnwertLight') {
        dispatch(setWohnwertLightRealEstateCondition(index));
      } 
    }
  };

	
  return (
    <div className="form__row form__satisfactionscale">
      {smileyOptions.map((smiley, index) => {
        return (
          <div onClick={() => { handleChange(index); }} key={index} className="form__field">
            <IconSmiley level={index} color={color} detailsColor={detailsColor} active={index === selectedValue} />
          </div>
        );
      })}

      {error !== '' && <p className="form__fielderror">{error}</p>}
    </div>
  );
}

export default SatisfactionScale;
