import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ProductHighlightsKaufImg1 from '../assets/images/producthighlights-img-kauf-1-Suchprofile.png';
import ProductHighlightsKaufImg2 from '../assets/images/producthighlights-img-kauf-2.png';
import ProductHighlightsKaufImg3 from '../assets/images/producthighlights-img-kauf-3-Suche.png';
import ProductHighlightsKaufImg4 from '../assets/images/producthighlights-img-kauf-4-abwickeln.png';
import headerImage from '../assets/images/s-REAL-Sujets-2023-Webhero-16zu-Mariam-Phase4.jpg';
import headerImageMobile from '../assets/images/sREAL-2024-Webhero-Mobil-950x593px-1-0-Mariam.jpg';
import ContactFormCard from '../components/Cards/ContactFormCard';
import MarktinfoLightCard from '../components/Cards/MarktinfoLightCard';
import Header from '../components/Header/Header';
import HeaderCtaButtons from '../components/Header/HeaderCtaButtons';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import TableComparison from '../components/TableComparison';
import { setPageDialogOpenRegisterForm } from '../reducers/registerForm';
//HOOKS
//*Constants
import ProductHighlights from '../components/ContentBlocks/ProductHighlights';
import IconWithRegistration from '../components/Icons/IconWithRegistration';
import IconWithoutRegistration from '../components/Icons/IconWithoutRegistration';
import Quicklinks from '../components/Quicklinks';
import { MENU_KAUF, MENU_VERKAUF } from '../constants';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { setMenuType } from '../reducers/app';
import { useHandleMenu } from '../hooks/menu/useHandleMenu';

function Kaeufer() {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);

  const { isMobile } = useHandleScreenResize();

  const navigate = useNavigate();
	const {  updateMenu, determineKaufMenuId } = useHandleMenu();

  const goToDashboard = () => {
		if(app.menuType === MENU_VERKAUF) {
			dispatch(setMenuType(MENU_KAUF));
			const newMenuId = determineKaufMenuId();
			updateMenu(newMenuId);
		}

    navigate('../' + app.root + '/dashboard');
  };

  const headerCtabuttons = [
    <HeaderCtaButtons key="headerCtabuttons">
      {!user.isLoggedIn ? (
        <button className="button button--orange ml-0" onClick={() => dispatch(setPageDialogOpenRegisterForm(true))}>
          Jetzt registrieren
        </button>
      ) : (
        <button className="button button--orange ml-0" onClick={() => goToDashboard()}>
          Dashboard anzeigen
        </button>
      )}
    </HeaderCtaButtons>,
  ];

  return (
    <>
      <MetaTags
        title={'Immobilie kaufen auf my-sreal.at | Die Online-Plattform für Ihre Immobilie'}
        description={'Sie wollen eine Wohnung kaufen oder ein Haus kaufen? my-sreal.at ist die Online-Plattform für Ihren Immobilienkauf. Einfach, transparent und digital kaufen.'}
        imageName={'mysreal_hero_kauf.jpg'}
      />
      <Header cssClasses="header--default header--withimage header--immosuche">
        {!isMobile ? (
          <img src={headerImage} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
        ) : (
          <img src={headerImageMobile} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
        )}
        <div className="header__content-wrapper">
          <div className="header__content">
            <h1 className="mt-0 mb-0 text-white">
              Das moderne <br />
              Kundenportal für Ihren <br />
              Immobilienkauf
            </h1>
            {headerCtabuttons}
          </div>
        </div>
      </Header>
      <Quicklinks
        list={[
          { label: 'Immobilie – gesucht gefunden', id: 'immobilie' },
          { label: 'Marktanalyse', id: 'marktanalyse' },
          { label: 'Die Vorteile von my-sreal', id: 'vorteile' },
          { label: 'Kontakt', id: 'kontakt' },
        ]}
      />
      <MainContent>
        <div className="w-desktop--center">
          <div id="immobilie">
            <ProductHighlights
              mainHeadline="Immobilie - gesucht gefunden!"
              mainSubline="Die passende Immobilie zu finden, war noch nie so bequem. Favoriten setzen, Suchprofile flexibel anpassen, mit Marktanalyse die Lage überprüfen oder mit wenigen Klicks eine Finanzierung checken. Das und noch viel mehr können Sie als Sucher:in auf my-sreal."
              isMobile={isMobile}
              imagePath1={ProductHighlightsKaufImg1}
              imagePath2={ProductHighlightsKaufImg2}
              imagePath3={ProductHighlightsKaufImg3}
              imagePath4={ProductHighlightsKaufImg4}
              headline1="Praktisches Sucherdashboard"
              headline2="Marktanalyse"
              headline3="Immobiliensuche"
              headline4="Kauf digital abwickeln"
              button1="Mehr anzeigen"
              button2="Mehr anzeigen"
              button3="Mehr anzeigen"
              button4="Mehr anzeigen"
              buttonlink1="/immobilie-kaufen#such-profile"
              buttonlink2="/immobilie-kaufen#markt-analyse"
              buttonlink3="/immobiliensuche"
              buttonlink4="/immobilie-kaufen#fDigital"
            />
          </div>
        </div>

        <div className="w-desktop--center pt-0" id="marktanalyse">
          <h2 className="text-center">Gute Lage oder sehr gute Lage?</h2>
          <p className="text-center pb-400rem">
            Nutzen Sie auf my-sreal die praktische Marktanalyse um zu einer Adresse den Lage-Score abzurufen. Wie steht es um Gastronomie, Bildung und Freizeit? So wissen Sie bei Ihrer Immobiliensuche
            sofort, ob die Umgebung Ihren Ansprüchen entspricht.{' '}
          </p>
          <MarktinfoLightCard />
        </div>

        <div className="gray-background" id="vorteile">
          <div className="w-desktop--center">
            <h2 className="text-center">Immobilien suchen wie ein Profi</h2>
            <p className="mb-200rem text-center">
              Finden Sie Ihre Traumimmobilie mit s REAL. Als Nutzer:in von my-sreal profitieren Sie dabei von vielen nützlichen Profi-Funktionen. Das Beste: die Registrierung ist völlig kostenlos.{' '}
            </p>
          </div>

          <TableComparison
            showMoreButton={false}
            cssClasses={`${isMobile ? 'table-mobile' : 'w-desktop--center'}`}
            headlines={[
              { headline: 'my-sreal.at ', headlineBold: 'ohne Registrierung', icon: <IconWithoutRegistration color={'#7598ab'} /> },
              { headline: 'my-sreal.at ', headlineBold: 'mit kostenloser Registrierung', icon: <IconWithRegistration color={'#2870ed'} /> },
            ]}
            listData={[
              { label: 'Ihre Immobiliensuche', isSubline: true, groupid: 1 },
              { label: 'Beratungsservice und Besichtigung', value1: true, value2: true, groupid: 1 },
              { label: 'Angebotsübersicht im Dashboard', value1: false, value2: true, groupid: 1 },
              { label: 'Flexible Suchprofile und Favoriten', value1: false, value2: true, groupid: 1 },
              { label: 'Praktische Marktanalyse', value1: false, value2: true, groupid: 1 },
              // { label: '', isShowMoreButton: true, groupid: 1 },
              { label: 'Ihr Immobilienkauf', isSubline: true, groupid: 2 },
              { label: 'Persönliche Maklerbetreuung', value1: true, value2: true, groupid: 2 },
              { label: 'Dokumentenbibliothek immo-drive', value1: false, value2: true, groupid: 2 },
              { label: 'Kaufprozess online einsehbar', value1: false, value2: true, groupid: 2 },
              // { label: '', isShowMoreButton: true, groupid: 2 },
            ]}
          >
            <p className="mt-200rem mb-200rem">
              Kostenhinweis: Erst im Falle der erfolgreichen Vermittlung einer Immobilie fällt ein vorab im Vermittlungsauftrag vereinbartes Vermittlungshonorar entsprechend des österreichischen
              Maklergesetzes an. Die Nutzung des my-sreal Kundenportals führt zu keinen zusätzlichen Kosten für die Kund:in
            </p>
            {isMobile && !user.isLoggedIn && (
              <button className="button button--orange button--tablecomparison mt-100rem mb-200rem" onClick={() => dispatch(setPageDialogOpenRegisterForm(true))}>
                Jetzt kostenlos registrieren
              </button>
            )}
            {!isMobile && !user.isLoggedIn && (
              <div className="tableRowContent tableRowContent--comparison">
                <div className="tableColumn"></div>
                <div className="tableColumn"></div>
                <div className="tableColumn">
                  <button className="button button--orange button--tablecomparison mt-100rem mb-200rem" onClick={() => dispatch(setPageDialogOpenRegisterForm(true))}>
                    Jetzt kostenlos registrieren
                  </button>
                </div>
              </div>
            )}
          </TableComparison>
        </div>

        <div className="w-desktop--center mt-200rem" id="kontakt">
          <h2 className="mt-500rem desktop-hidden text-center">Kontaktieren & beauftragen</h2>
          <ContactFormCard />
        </div>
      </MainContent>
    </>
  );
}

export default Kaeufer;
