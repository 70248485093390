//*React
import { useDispatch, useSelector } from 'react-redux';
//*Components
import Card from '../components/Cards/Card';
import ProfilAnalysenCard from '../components/Cards/ProfilAnalysenCard';
import ProfileEmailSettingsForm from '../components/Forms/ProfileEmailSettingsForm';
import ProfileForm from '../components/Forms/ProfileForm';
import HeaderSmallWithIcon from '../components/Header/HeaderSmallWithIcon';
import IconPerson from '../components/Icons/IconPerson';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';
import ProfileImmobilien from '../components/Profile/ProfileImmobilien';
import ProfileSuchprofile from '../components/Profile/ProfileSuchprofile';

import { parseDate } from '../util/datetimeParser';

import { CONTACTFORM_ANLIEGEN_PROFIL_DEAKTIVIEREN, PAGEDIALOG_CONTACT } from '../constants';

import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { setPageDialogOpenId } from '../reducers/app';
import { demoUserObject } from '../demodata/demouser.js';
import { abgeberimmo_phase2_parsed, abgeberimmo_phase3_parsed, abgeberimmo_phase4_parsed } from '../demodata/demo_abgeberimmo.js';
import { useEffect, useState } from 'react';
import { demoSearchProfile } from '../demodata/demosearchprofile.js';

function Profil() {
  const user = useSelector((state) => state.user);
	const app = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const { isMobile } = useHandleScreenResize();

  const handleDeactivateProfile = () => {
		
    localStorage.setItem('profildeaktivieren-clicked', true);
		localStorage.setItem('mysreal_contact_reason', CONTACTFORM_ANLIEGEN_PROFIL_DEAKTIVIEREN);
		dispatch(setPageDialogOpenId(PAGEDIALOG_CONTACT));
  };

	const [currentUserObject, setCurrentUserObject] = useState(user.userObject);
	const [currentUserRealties, setCurrentUserRealties] = useState(user.userRealties);
	const [currentSearchProfiles, setCurrentSearchProfiles] = useState(user.searchProfiles);

	
	const [demoMode, setDemoMode] = useState(false);
	useEffect(() => {
    if(app.root === '/demo') {
			setCurrentUserObject(demoUserObject);
			setCurrentUserRealties([abgeberimmo_phase2_parsed, abgeberimmo_phase3_parsed, abgeberimmo_phase4_parsed]);
			setCurrentSearchProfiles([demoSearchProfile]);
			setDemoMode(true);
		}
  }, [app.root]);

  return (
    <>
      <MetaTags title={'Profil | Die Online-Plattform für Ihre Immobilie'} />
      <HeaderSmallWithIcon
        icon={<IconPerson />}
        iconActive={true}
        headlineDesktop="Profil"
        headlineDesktopRight={currentUserObject.firstName + ' ' + currentUserObject.lastName}
        subheadlineDesktopRight={'Registriert seit ' + parseDate(currentUserObject.created)}
      />

      <MainContent>
        <div className="w-desktop--center ">
          <h1 className="headline--mobile">Profil</h1>
					{ currentUserObject && Object.keys(currentUserObject).length > 0 ? (
						<>
							<h2 className="headline--mobile font-weight-600 font-170">
							{currentUserObject.firstName}
							<br />
							{currentUserObject.lastName}
							</h2>
							<p className="headline--mobile pb-200rem">Registriert seit {parseDate(currentUserObject.created)}</p>
						</>
					) : null }

          {!isMobile ? (
            <>
              <Card cardStyle="customContent" cssClasses="p-150rem bg-grey">
                <ProfileForm currentUserObject={currentUserObject} />
              </Card>

							{ !demoMode && (
								<Card cardStyle="customContent" cssClasses="p-150rem bg-grey">
									<ProfileEmailSettingsForm />
								</Card>
							)}
            </>
          ) : (
            <>
              <ProfileForm currentUserObject={currentUserObject} />
              { !demoMode && <ProfileEmailSettingsForm /> }
            </>
          )}

          <ProfileImmobilien currentUserRealties={currentUserRealties} />
        </div>

        <ProfileSuchprofile currentSearchProfiles={currentSearchProfiles} />

        <div className={!isMobile ? 'w-desktop--center mt-200rem' : 'w-desktop--center'}>
          <ProfilAnalysenCard />

          <div className="mt-200rem mb-200rem">
            <span className="textlink textlink--dark-blue d-flex w-100" onClick={() => handleDeactivateProfile()}>
              Profil deaktivieren
            </span>
          </div>
        </div>
      </MainContent>
    </>
  );
}

export default Profil;
