import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Card from './Card';

// ICONS
import IconHeart from '../Icons/IconHeart';
import IconHouse from '../Icons/IconHouse';

// STORE
import InfoDialogUnfavourite from '../Dialogs/InfoDialogUnfavourite';
import { MENU_KAUF, PHASE1_KAUF } from '../../constants';
import { getKaufpreis, getMainArea } from '../../util/immoObjectParser';

// HOOKS
import { useHandleFavorite } from '../../hooks/immo/useHandleFavorite';

function ImmoCardSmall({ retailObject }) {
  const { addToFavourites } = useHandleFavorite(retailObject);
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);
  const [kaufpreis, setKaufpreis] = useState('');
  const [area, setArea] = useState(false);
  const [coverImage, setCoverImage] = useState(null);

  
  const [openRemoveFavDialog, setOpenRemoveFavDialog] = useState(false);

  /**
   * opens infodialog for unfavouriting
   */
  const handleOpenRemoveFavDialog = () => {
    setOpenRemoveFavDialog(!openRemoveFavDialog);
  };

  const handleFavFunc = () => {
    let favFound = user.favourites.find((f) => f?.id === (retailObject?.id).toString());

    if (!favFound) {
      addToFavourites(retailObject);
    } else {
      handleOpenRemoveFavDialog();
    }
  };

  useEffect(() => {
    let preis = getKaufpreis(retailObject);
    setKaufpreis(preis);

    if (retailObject.imagesPublic && retailObject.imagesPublic[0]?.url) {
      setCoverImage(retailObject.imagesPublic[0].url);
    } else if (retailObject.cover_image?.url) {
      setCoverImage(retailObject.cover_image.url);
    }

    let area = getMainArea(retailObject, false);
    setArea(area);
  }, []);

  const handleLink = (e) => {
    // Checke hier, ob das Herz-Icon geklickt wurde, falls nicht, verlinke auf die Detailseite
    e.preventDefault();
    
    if (e.target.nodeName === 'svg' || e.target.nodeName === 'path') {
      // Favorite Icon wurde geklickt
      handleFavFunc();
    } else {
      if (!openRemoveFavDialog) {
        window.location.href = e.currentTarget.querySelector('.card-link').getAttribute('href');
      }
    }
  };

  return (
    <>
      <div onClick={(e) => handleLink(e)}>
        <Card
          cardStyle={'teaserMiniImage'}
          linkTo={`${app.root}/immobilie/${retailObject.url}`}
          cssClasses={'linkOnFullCard bg-white with-shadow'}
          imagePath={coverImage}
          icon={<IconHouse cssClasses={'scale-1-5'} color="#fff" />}
        >
          <div className="immo-card__image--container">
            <img className="immo-card__image" src={coverImage} alt={retailObject.title} />
          </div>
          <div className="immo-card__content--container font-80">
            <span className="font-80">
              {retailObject.address?.zip} {retailObject.address?.city}
            </span>
            <div>
              <span className="font-80">{area ? area : ''}</span>
              <span className="font-80">{retailObject.rooms > 0 && <>, {retailObject.rooms?.toString().replace('.', ',')} Zi.</>}</span>
            </div>
            <span className="font-90 text-bold">{kaufpreis}</span>
          </div>
          <div className="immo-card__icon--container">
            {user.isLoggedIn && app.menuType === MENU_KAUF && app.menuId !== PHASE1_KAUF && (
              <div className="immo-card__favicon">
                <IconHeart color="#3679ec" filled={user.favourites.find((f) => f.id === retailObject.id.toString())} />
              </div>
            )}
          </div>
        </Card>
        <InfoDialogUnfavourite retailObject={retailObject} handleClose={handleOpenRemoveFavDialog} open={openRemoveFavDialog} />
      </div>
    </>
  );
}

export default ImmoCardSmall;
