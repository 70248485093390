import { useCallback, useEffect, useRef, useState } from 'react';

// FORM FIELDS
import ButtonForwardAction from '../../Buttons/ButtonForwardAction';
import InfoDialogZustandImmo from '../../Dialogs/InfoDialogZustandImmo';
import FormAutocomplete from '../../FormFields/FormAutocomplete';
import FormInput from '../../FormFields/FormInput';
import FormSelect from '../../FormFields/FormSelect';
import SatisfactionScale from '../../FormFields/SatisfactionScale';
import InfoMark from '../../InfoMark';

import { immobilienartOptions } from '../../FormFields/util/formOptions';

// STORE

import { Controller, useFormContext } from 'react-hook-form';
import { autocomplete } from '../../../api/JustImmo';
import {
	CONTACTFORM_SITEINDEX_INFO_BUNDESLAND
} from '../../../constants';
import InfoDialogBundesland from '../../Dialogs/InfoDialogBundesland';
import statesWithZips from '../../FormFields/util/statesWithZips';


function ContactIhreImmobilie({ prevStep, nextStep, setWindowHash }) {
	const { handleSubmit, setValue, getValues, resetField, trigger, watch, control, formState: { errors } } = useFormContext();

  const [openInfoDialogZustandImmo, setOpenInfoDialogZustandImmo] = useState(false);
  const [openInfoDialogBundesland, setOpenInfoDialogBundesland] = useState(false);

  const handleSetOpenInfoDialogBundesland = () => {
    let newVal = !openInfoDialogBundesland;
    if (newVal) {
      window.location.hash = CONTACTFORM_SITEINDEX_INFO_BUNDESLAND;
    } else {
      setWindowHash();
    }
    setOpenInfoDialogBundesland(newVal);
  };


  const federalStateOptions = statesWithZips.map((state) => {
    return {
      id: state.justImmoId,
      text: state.label,
    };
  });

	const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
	const postalCode = watch("postalCode");
	const previousPostalCodeRef = useRef(postalCode);

	const state = watch("state");
	const previousStateRef = useRef(state);

  const autocompletePostalCode = useCallback(async (query) => {
		if(typeof query !== "string") return;

    if (!query || query.length < 2) {
      setOptions([]); // Clear options if input is too short
      return;
    }

    setLoading(true);
    try {
      let autoCompleteResults = await autocomplete(query);
			autoCompleteResults = autoCompleteResults.filter((r) => r.type === 5);

			const currentState = state;
			const foundPostCodes = statesWithZips.filter((state) => state.label !== 'TESTACCOUNT').find((s) => s.justImmoId === currentState.id)?.zipCodes || [];

			var isNumberReg = /^\d+$/;
      if (isNumberReg.test(query)) {
        const filteredValues = foundPostCodes.filter((zip) => zip.includes(query));

        autoCompleteResults = autoCompleteResults.filter((r) => {
          let label = r.label;
          let zip = label.split(' ')[0];
          if (filteredValues.includes(zip)) {
            return r;
          }
        });
      } else {				
        autoCompleteResults = autoCompleteResults.filter((a) => {
          let label = a.label;
          let zip = label.split(' ')[0];
          if (foundPostCodes.includes(zip)) {
            return a;
          }
        });
      }

      setOptions(autoCompleteResults);
    } catch (error) {
      console.error("Error fetching autocomplete data:", error);
			setOptions([]);
    } finally {
      setLoading(false);
    }
  }, [state]);
		
	const [typedPostalCode, setTypedPostalCode] = useState('');
	useEffect(() => {
		if(typedPostalCode) {
			autocompletePostalCode(typedPostalCode);
		}
  }, [typedPostalCode, autocompletePostalCode]);

	useEffect(() => {
		// hard check if state has changed!!
    if (previousStateRef?.current !== state) {
			resetField("postalCode");
  		setValue("postalCode", "");
      setOptions([]);
    }
  }, [state, resetField, setValue]);

  return (
    <>
      <h3 className="form__title">Ihre Immobilie (optional)</h3>
			<Controller
				name="state"
				control={control}
				rules={{ required: "Bitte wählen Sie ein Bundesland aus." }}
				render={({ field }) => (
					<FormSelect
						id={'state'}
						label={'Bundesland'}
						placeholder="Bitte wählen"
						value={field.value}
						error={errors.state?.message}
						required={true}
						options={federalStateOptions}
						callback={field.onChange}
					/>
				)}
			/>

      <span className="textlink font-80 mb-20" onClick={handleSetOpenInfoDialogBundesland}>
        Welches Bundesland wird benötigt?
      </span>

			<Controller
				name="postalCode"
				control={control}
				defaultValue=""
				render={({ field }) => (
					<FormAutocomplete 
						id="postalCode"
						label='Postleitzahl'
						hasLockIcon={!state}
						disabled={!state}
						loading={loading}
						placeholder={!state ? 'Bitte erst Bundesland auswählen' : 'Postleitzahl'}
						options={options}
						callback={field.onChange}
						callbackInput={setTypedPostalCode}
						value={field.value}
					/>
				)}
			/>

			<Controller
				name="type"
				control={control}
				render={({ field }) => (
					<FormSelect
						id={'type'}
						label={'Immobilienart'}
						placeholder="Bitte wählen"
						value={field.value}
						options={immobilienartOptions}
						callback={field.onChange}
					/>
				)}
			/>

			<Controller
				name="area"
				control={control}
				render={({ field }) => (
					<FormInput
						id="inputArea"
						label={'Fläche in m²'}
						value={field.value} 
						callback={field.onChange}
						type={'number'}
						placeholder={'Wert eingeben'}
						minValue={0}
					/>
				)}
			/>

      <div className="mt-200rem mb-200rem d-flex flex-row align-items-center">
        <span className="pr-50rem">Zustand der Immobilie</span>
        <InfoMark handleClick={() => setOpenInfoDialogZustandImmo(!openInfoDialogZustandImmo)} character={'?'} />
      </div>

			<Controller
				name="condition"
				control={control}
				render={({ field }) => (
      		<SatisfactionScale selectedValue={field.value} callback={field.onChange} />
				)}
			/>

      <div className="button-panel align-items-center">
        <button className="button button--gray-outline" onClick={prevStep}>
          Zurück
        </button>
        <ButtonForwardAction forwardAction={handleSubmit((data) => {nextStep()})} buttonStyle={'blue'} buttonText={'Weiter'} />
      </div>

      <InfoDialogZustandImmo handleClose={() => setOpenInfoDialogZustandImmo(!openInfoDialogZustandImmo)} open={openInfoDialogZustandImmo} />
      <InfoDialogBundesland open={openInfoDialogBundesland} handleClose={handleSetOpenInfoDialogBundesland} />
    </>
  );
}

export default ContactIhreImmobilie;
