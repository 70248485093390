import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import headerImage from '../assets/images/sREAL-2024-Webhero-16zu9-1-5-Mariam-Handy.jpg';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ImmoCard from '../components/Cards/ImmoCard';
import PageDialogSearchProfileForm from '../components/Dialogs/PageDialogSearchProfileForm';
import FormSelect from '../components/FormFields/FormSelect';
import SearchForm from '../components/Forms/SearchForm';
import Header from '../components/Header/Header';
import IconArrowDown from '../components/Icons/IconArrowDown';
import LoadingSpinner from '../components/LoadingSpinner';
import MainContent from '../components/MainContent';
import MetaTags from '../components/MetaTags';

import { sortOptions } from '../components/FormFields/util/formOptions';
import { immoSearchNoResultsText } from '../components/FormFields/util/formTexts';
import { parseImmoSreal } from '../util/immoObjectParser';

// HOOKS
import SearchProfilFixedBar from '../components/fixedbar/SearchProfilFixedBar';
import { useHandleScreenResize } from '../hooks/app/useHandleScreenResize';
import { useFetchImmoSearch } from '../hooks/immo/useFetchImmoSearch';
import { useHandleFavorite } from '../hooks/immo/useHandleFavorite';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import { isJson } from '../util/generalUtils';

function Immosuche() {
  const methods = useForm();
  const { getValues, setValue, control, reset } = methods;

  const { setRealtySearchList, error, immoList, immoListCnt } = useFetchImmoSearch();
  const { reloadBasicFavList } = useHandleFavorite();

	const app = useSelector((state) => state.app);
  const { isMobile } = useHandleScreenResize();

	const [page, setPage] = useState(1);
  const initialFormState = useMemo(() => {
    return {
      address: [],
      estateType: [],
      subEstateType: [],
      areaFrom: '',
      areaTo: '',
      priceTo: '',
      priceFrom: '',
      surfaceAreaFrom: '',
      surfaceAreaTo: '',
      roomsFrom: '',
      roomsTo: '',
      features: [],
      fullText: '',
      sorting: sortOptions[0],
    };
  }, []);

  /**
   * RESET FORM
   * clear all form data and set step to 1
   */
  const resetForm = useCallback(() => {
    reset(initialFormState);
    setRealtySearchList({});
  }, [methods, initialFormState]);

  /* SUBMIT FORM */
  const onSubmit = useCallback(async (data) => {
      setPage(1);
      setRealtySearchList(data, 1);

			/* save data in local storage for searchprofile form */
			localStorage.setItem('mysreal_searchform_values', JSON.stringify(data));
    }, [setRealtySearchList]);
	
	const onChangeSortings = useCallback((newVal) => {
		setValue('sorting', newVal);

		const formData = getValues();
		setRealtySearchList(formData, page);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getValues, page]);

  /* LOAD ON FIRST PAGE LOAD */
  useEffect(() => {

		/* set values from local storage if exist */
		const lsSearchForm = localStorage.getItem('mysreal_searchform_values');
		if(lsSearchForm && isJson(lsSearchForm)) {
			const searchFormValues = JSON.parse(lsSearchForm);
			Object.entries(searchFormValues).forEach(([key, value]) => {
				if(value) {	
					setValue(key, value);
				}
			});

			setRealtySearchList(searchFormValues);
		}
		else {
			setRealtySearchList();
		}

    reloadBasicFavList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* SHOW MORE IMMOS */
  const handleShowMoreImmos = () => {
    const formData = getValues();

    let currentPage = page;
    const newPage = currentPage + 1;
    setPage(newPage);

    setRealtySearchList(formData, newPage);
  };

  return (
    <>
      <MetaTags
        title={'Immobilien suchen auf my-sreal.at | Die Online-Plattform für Ihre Immobilie'}
        description={
          'Sie sind auf der Suche, denn Sie wollen eine Wohnung kaufen oder ein Haus kaufen? my-sreal.at ist die Online-Plattform für Ihren Immobilienkauf. Einfach, transparent und digital suchen, finden und kaufen.'
        }
        imageName={'mysreal_hero_immosuche.jpg'}
      />

      <FormProvider {...methods}>
        {!isMobile ? (
          <Header cssClasses="header--default header--withimage header--immosuche" overlappedContent={true}>
            <img src={headerImage} alt="Foto: Der beste Partner für Ihre Immobilie" className="header__image" />
            <div className="header__content-wrapper header__content-wrapper--top">
              <h1 className="text-white mt-500rem mb-0">Immobiliensuche</h1>
              <h3 className="text-white">Kaufimmobilien aus ganz Österreich - hier werden Sie fündig!</h3>
            </div>
          </Header>
        ) : (
          <Header cssClasses="header--immosuche header--immosuche-mobil">
            <div className="header__content-wrapper header__content-wrapper--top">
              <h1>Immobiliensuche</h1>
            </div>
            <Accordion className="accordion accordion--searchform" defaultExpanded={true}>
              <AccordionSummary expandIcon={<IconArrowDown color="#fff" />} aria-controls="panel1bh-content" id="panel1bh-header">
                <h2 className="pl-100rem pr-100rem">Suche anpassen</h2>
              </AccordionSummary>
              <AccordionDetails>
                <SearchForm resetForm={resetForm} submit={methods.handleSubmit(onSubmit)} />
              </AccordionDetails>
            </Accordion>
          </Header>
        )}

        <SearchProfilFixedBar />

        <MainContent cssClasses="main-content--immosuche">
          <div className="w-desktop--center">
            <div className="immosuche">
              <SearchForm resetForm={resetForm} submit={methods.handleSubmit(onSubmit)} />
            </div>

            <div className="pb-100rem" id="immoresult-list">
              {error ? (
                <>
                  <h2 className="mb-200rem">Ergebnisse (0)</h2>
                  <p>{error}</p>
                </>
              ) : (
                <>
                  <div className="immosuche__ergebnisheader">
                    <h2>Ergebnisse ({immoListCnt})</h2>
										<Controller
											name="sorting"
											control={control}
											defaultValue={sortOptions[0]}
											render={({ field }) => (
												<FormSelect
													id={'sorting'}
													label={'Sortieren nach'}
													placeholder="Ausstattungsmerkmale wählen"
													value={field.value}
													showBitteWaehlen={false}
													options={sortOptions}
													callback={onChangeSortings}
												/>
											)}
										/>                    
                  </div>

                  {immoList.length > 0 ? (
                    <>
                      <div className="cardgrid">
												{immoList?.map((group, groupIndex) => (
													<Fragment key={groupIndex}>
														{group?.length > 0 && group.map((immo) => (
															<ImmoCard wohneinheiten={immo.wohneinheiten} retailObject={parseImmoSreal(immo)} key={immo.id} />
														))}
													</Fragment>
												))}
                      </div>

                      {immoListCnt > immoList.flat().length && (
                        <div className="d-flex justify-content-center">
                          {app.formIsLoading ? (
                            <LoadingSpinner />
                          ) : (
                            <span className="textlink" onClick={handleShowMoreImmos}>
                              Weitere Ergebnisse laden
                            </span>
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <p>{immoSearchNoResultsText}</p>
                  )}
                </>
              )}
            </div>
          </div>
        </MainContent>
      </FormProvider>

      <PageDialogSearchProfileForm></PageDialogSearchProfileForm>
    </>
  );
}

export default Immosuche;
